import { combineReducers } from '@reduxjs/toolkit';
import { residents } from './residents/state';
import { systemPersist } from './systemPersist/state';
import { cases } from './cases/state';
import { ui } from './ui/state';
import { auth } from './auth/state';
import { authPersist } from './authPersist/state';
import { facilities } from './facilities/state';
import { lists } from './lists/state';
import { companies } from './companies/state';
import { settings } from './settings/state';
import { analytics } from './analytics/state';

const reducers = {
  ui: ui.reducer,
  auth: auth.reducer,
  authPersist: authPersist.reducer,
  systemPersist: systemPersist.reducer,
  lists: lists.reducer,
  residents: residents.reducer,
  cases: cases.reducer,
  companies: companies.reducer,
  facilities: facilities.reducer,
  settings: settings.reducer,
  analytics: analytics.reducer,
};

export const rootReducer = combineReducers({ ...reducers });
