import {
  userTypes,
  companyTypes,
  convertNullFieldsToEmptyString,
  ListCategoryTypes,
} from '../../../../lib';
import _isEqual from 'lodash.isequal';
import _isEmpty from 'lodash.isempty';
import {
  ResidentFields,
  ResidentFilters,
  ResidentLists,
} from './ResidentListConfig';
import {
  ApplicationFields,
  ApplicationFilters,
  ApplicationLists,
} from './ApplicationListConfig';
import { BoardFilters } from './BoardConfig';

// #region define the data sets
export const ListCategories = {
  Resident: {
    category: ListCategoryTypes.Resident,
    dataURL: '/residents',
    resource: 'residents',
    name: 'Residents',
    name_AC: 'Clients',
  },
  Application: {
    category: ListCategoryTypes.Application,
    dataURL: '/applications/residents',
    resource: 'applications',
    name: 'Applications',
  },
};
// #endregion

// #region helper methods

export function GetFieldsForCategory(category) {
  switch (category) {
    case ListCategoryTypes.Resident:
      return ResidentFields;
    case ListCategoryTypes.Application:
      return ApplicationFields;
    case ListCategoryTypes.Board:
    default:
      return [];
  }
}
export function GetFiltersForCategory(category) {
  switch (category) {
    case ListCategoryTypes.Resident:
      return ResidentFilters;
    case ListCategoryTypes.Application:
      return ApplicationFilters;
    case ListCategoryTypes.Board:
      return BoardFilters;
    default:
      return [];
  }
}
export function GetListsForCategory(category) {
  switch (category) {
    case ListCategoryTypes.Resident:
      return ResidentLists;
    case ListCategoryTypes.Application:
      return ApplicationLists;
    case ListCategoryTypes.Board:
    default:
      return [];
  }
}

export function GetListSettingName(setting, category, companyType) {
  if (setting.isSystemView) {
    const list = GetListsForCategory(category)[setting.type];
    if (list?.name_AC && companyType === companyTypes.Application) {
      return list?.name_AC;
    } else {
      return list?.name;
    }
  } else {
    return setting.name;
  }
}

/**
 * Gets all fields that are applicable for a list category,
 * also checking applicability for NRIWorkflow and companyType
 */
export function GetAllListFields(
  category,
  hasNRIWorkflow,
  companyType,
  isExport = false,
) {
  const fields = GetFieldsForCategory(category);
  return fields.filter(
    (f) =>
      (hasNRIWorkflow || !f.isForNRIWorkflow) &&
      (!f.companyType || f.companyType === companyType) &&
      // CT: this logic looks redundant with whats in GetListFields - for now adding to both
      (!f.exportOnly || f.exportOnly === isExport),
  );
}

/**
 * Gets the fields for a Category - based on the selectedFields passed in,
 *  if no fields are passed in, returns the default ones for the listType.
 *  also checking applicability for NRIWorkflow and companyType
 */
export function GetListFields(
  category,
  listType,
  companyType,
  hasNRIWorkflow,
  selectedFields = [],
  isExport = false,
) {
  let listFields = [];
  const fields = GetFieldsForCategory(category);

  if (selectedFields.length) {
    const fieldIds = selectedFields.map(({ field }) => field);
    listFields = fields
      .filter((f) => fieldIds.includes(f.id))
      .sort((o1, o2) => {
        const sortOrder1 = selectedFields.find(
          (f) => f.field === o1.id,
        )?.sortOrder;
        const sortOrder2 = selectedFields.find(
          (f) => f.field === o2.id,
        )?.sortOrder;
        return !sortOrder1 ? 1 : sortOrder1 > sortOrder2 ? 0 : -1;
      });
  }

  // if none selected, set selection to default for list type
  if (!listFields.length) {
    listFields = fields.filter(
      (f) =>
        (f.general || f.defaultLists?.includes(listType)) &&
        (hasNRIWorkflow || !f.isForNRIWorkflow) &&
        (!f.companyType || f.companyType === companyType) &&
        // CT: this logic looks redundant with whats in GetAllListFields - for now adding to both
        (!f.exportOnly || f.exportOnly === isExport),
    );
  }

  return listFields;
}

/**
 * Gets the default fields for the listType
 */
export function GetListFieldsDefaults(
  category,
  listType,
  companyType,
  hasNRIWorkflow,
) {
  const defaultFields = GetListFields(
    category,
    listType,
    companyType,
    hasNRIWorkflow,
  ).map((f, i) => ({
    field: f.id,
    sortOrder: i + 1,
  }));
  return defaultFields;
}

/**
 * Gets all filters that are applicable for a list category,
 * also checking applicability for NRIWorkflow, companyType, userType
 */
export function GetAllListFilters(
  category,
  hasNRIWorkflow,
  userType,
  companyType = companyTypes.Facility,
) {
  const filters = GetFiltersForCategory(category);
  return filters
    .filter(
      (f) =>
        (hasNRIWorkflow || !f.isForNRIWorkflow) &&
        (!f.adminOnly || userType === userTypes.Admin) &&
        (!f.companyType || companyType === f.companyType),
    )
    .map((f) => {
      if (f.label_AC && companyType === companyTypes.Application) {
        f.label = f.label_AC;
      }
      if (f.searchPlaceholder_AC && companyType === companyTypes.Application) {
        f.searchPlaceholder = f.searchPlaceholder_AC;
      }
      if (f.menuItemsSelector_AC && companyType === companyTypes.Application) {
        f.menuItemsSelector = f.menuItemsSelector_AC;
      }
      return f;
    });
}

/**
 * Gets the query to use for updatePageQueryParams from a view:  `{id, fields, filters}`
 * Will only include filter values that don't match up to the defaults
 */
export function GetQueryFromListView(category, view) {
  const allFilters = GetFiltersForCategory(category);
  const { fields, filters } = view;
  const params = {
    'list-view': view.id,
    'list-type': view.listType,
    fields: fields?.map((f) => f.field + '|sort' + f.sortOrder),
    orFilters: {}, //set it as a nested object first and then convert to JSON below
  };
  allFilters.forEach((f) => {
    const filterValue = filters && filters[f.name];
    params[f.queryParam] =
      filterValue === f.defaultValue ? undefined : filterValue;
  });

  //do the orFilters also
  allFilters
    .filter((f) => f.isOrFilter)
    .forEach((f) => {
      const filterValue = filters?.orFilters && filters.orFilters[f.name];
      params.orFilters[f.queryParam] =
        filterValue === f.defaultValue ? undefined : filterValue;
    });

  //need to use JSON stringify since the query-string library doesn't support nested objects
  params.orFilters = _isEmpty(params.orFilters)
    ? undefined
    : JSON.stringify(params.orFilters);

  return params;
}

//this calculates the active filters from a list of applied filters - comparing to the list of allFilters passed in
function GetActiveFiltersFromAppliedFilters(allFilters, filters) {
  const activeFilters = allFilters.filter((f) => {
    //a dependant filter forces the primary filter to be active, doesn't get included separately
    const filterValue = filters[f.name];
    const dependsOnFilterValue = filters[f.dependsOn];
    const dependsOnDefaultValue =
      f.dependsOn &&
      allFilters.find((f2) => f2.name === f.dependsOn)?.defaultValue;
    return (
      filterValue !== undefined &&
      f.type !== 'dependant' &&
      (!_isEqual(filterValue, f.defaultValue) ||
        !_isEqual(dependsOnFilterValue, dependsOnDefaultValue))
    );
  });
  return activeFilters;
}

export function GetActiveFiltersFromListView(category, view) {
  const allFilters = GetFiltersForCategory(category);
  const { filters } = view;
  const activeFilters = GetActiveFiltersFromAppliedFilters(allFilters, filters);
  return activeFilters;
}

export function GetActiveOrFiltersFromListView(category, view) {
  const allOrFilters = GetFiltersForCategory(category).filter(
    (f) => f.isOrFilter,
  );
  const { filters: { orFilters = {} } = {} } = view;
  const activeFilters = GetActiveFiltersFromAppliedFilters(
    allOrFilters,
    orFilters,
  );
  return activeFilters;
}

export function GetClearedListViewQuery(category) {
  const filters = GetFiltersForCategory(category);
  const clearedQuery = {
    'list-view': undefined,
    fields: undefined,
    orFilters: '{}', //set to blank JSON object
  };
  filters.forEach((f) => (clearedQuery[f.queryParam] = undefined));
  return clearedQuery;
}

/**
 * validate that query param fields and filters are valid for this list and NRI workflow setting
 * (to ensure shared urls and the like don't lead to invalid views)
 */
export function ValidateListViewQuery(
  category,
  hasNRIWorkflow,
  userType,
  _params,
  companyType,
) {
  const validListFields = GetAllListFields(
    category,
    hasNRIWorkflow,
    companyType,
  );
  const validListFilters = GetAllListFilters(
    category,
    hasNRIWorkflow,
    userType,
    companyType,
  );

  //first take a deep copy of 'params' before we modify it
  const params = { ..._params };

  const { fields, orFilters, ...filters } = params;
  let hasInvalidFieldsOrFilters = false;

  Object.keys(filters).forEach((key) => {
    if (!validListFilters.find((f) => f.queryParam === key)) {
      hasInvalidFieldsOrFilters = true;
      params[key] = undefined;
    }
  });

  // //check the orFilters as well, parse and then stringify it back in case we edited it
  const paramsOrFilters = JSON.parse(orFilters || '{}');
  Object.keys(paramsOrFilters).forEach((key) => {
    if (!validListFilters.find((f) => f.isOrFilter && f.queryParam === key)) {
      hasInvalidFieldsOrFilters = true;
      paramsOrFilters[key] = undefined;
    }
  });
  params.orFilters = _isEmpty(paramsOrFilters)
    ? undefined
    : JSON.stringify(paramsOrFilters);

  if (fields?.length) {
    //if only one field is selected, its not an array
    const fieldsArray = Array.isArray(fields) ? fields : [fields];
    const invalidFields = fieldsArray.filter(
      (field) => !validListFields.find((f) => f.id === field.split('|sort')[0]),
    );
    if (invalidFields.length) {
      hasInvalidFieldsOrFilters = true;
      params.fields = fields.filter((f) => !invalidFields.includes(f));
    }
  }

  return { hasInvalidFieldsOrFilters, validatedParams: params };
}

export function GetListViewFromQuery(
  params,
  category,
  listType,
  companyType,
  hasNRIWorkflow,
) {
  const view = {
    listType,
    fields: [],
    filters: {
      orFilters: {},
    },
  };

  const allFilters = GetFiltersForCategory(category);

  const getFilterValue = (value, defaultValue) => {
    let returnValue = value;
    if (returnValue !== undefined) {
      // query value can be either a string (if only one) or an array - put into array if it is a single value and filter type is array
      if (Array.isArray(defaultValue) && !Array.isArray(returnValue)) {
        returnValue = [returnValue];
      }
      //convert number strings to integers, and true/false strings to bools
      const convertNumbers = (v) => (v && !isNaN(v) ? +v : v);
      returnValue = Array.isArray(returnValue)
        ? returnValue.map(convertNumbers)
        : returnValue === true || returnValue === false //orFilters were converted to/from JSON so true/false not in string
        ? returnValue
        : returnValue === 'true'
        ? true
        : returnValue === 'false'
        ? false
        : convertNumbers(returnValue);
    } else {
      returnValue = defaultValue;
    }
    return returnValue;
  };

  allFilters.forEach((filter) => {
    const { defaultValue, name, queryParam } = filter;
    let value = params[queryParam];
    value = getFilterValue(value, defaultValue);
    view.filters[name] = value;
  });

  //`Or` filters
  //nested object, using JSON parse/stringify
  const paramsOrFilters = JSON.parse(params.orFilters || '{}');

  allFilters
    .filter((f) => f.isOrFilter)
    .forEach((filter) => {
      const { defaultValue, name, queryParam } = filter;
      let value = paramsOrFilters && paramsOrFilters[queryParam];
      value = getFilterValue(value, defaultValue);
      view.filters.orFilters[name] = value;
    });

  if (params.fields?.length) {
    //if only one field is selected, its not an array
    const fieldsArray = Array.isArray(params.fields)
      ? params.fields
      : [params.fields];
    view.fields = fieldsArray
      .map((f) => {
        const fieldParts = f.split('|sort');
        return {
          field: fieldParts[0],
          sortOrder: +fieldParts[1],
        };
      })
      .filter((f) => !!f);
  } else {
    //default the fields
    view.fields = GetListFieldsDefaults(
      category,
      listType,
      companyType,
      hasNRIWorkflow,
    );
  }

  return view;
}

export function GetListApiFilterFromQuery(
  params,
  category,
  listType,
  companyType,
  hasNRIWorkflow,
) {
  const apiFilter = {};
  const allFilters = GetFiltersForCategory(category);

  //nested object, using JSON parse/stringify
  const paramsOrFilters = JSON.parse(params.orFilters || '{}');

  Object.keys(params).forEach((key) => {
    const filter = allFilters.find((f) => f.queryParam === key);

    if (filter) {
      const name = filter.name;
      const value = params[key];
      apiFilter[name] = value;
    }
  });

  //api needs orFilters as a nested object: `orFilters.{queryParam}`
  Object.keys(paramsOrFilters).forEach((key) => {
    const filter = allFilters.find((f) => f.isOrFilter && f.queryParam === key);
    if (filter) {
      const name = filter.name;
      const value = paramsOrFilters[key];
      apiFilter[`orFilters.${name}`] = value;
    }
  });

  if (params.fields?.length) {
    //if only one field is selected, its not an array
    const fieldsArray = Array.isArray(params.fields)
      ? params.fields
      : [params.fields];
    apiFilter.fields = fieldsArray
      .map((f) => f.split('|sort')[0])
      .filter((f) => !!f);

    //here set the extra param to know whether we want the notes fields
    if (apiFilter.fields.some((f) => f.toLowerCase().includes('latestnote'))) {
      apiFilter.includeLatestNotes = true;
    }
  } else {
    //default the fields
    apiFilter.fields = GetListFields(
      category,
      listType,
      companyType,
      hasNRIWorkflow,
    ).map((f) => f.id);
  }

  return apiFilter;
}

export const formatViewForForm = (view) =>
  view && {
    ...view,
    // replace null values with empty strings for filter inputs
    filters: {
      ...convertNullFieldsToEmptyString(view.filters),
      orFilters: convertNullFieldsToEmptyString(view.filters.orFilters),
    },
  };

export function hasAdvancedFilters(category) {
  return [ListCategoryTypes.Resident, ListCategoryTypes.Board].includes(
    category,
  );
}

export function hasColumnSelection(category) {
  return [ListCategoryTypes.Resident, ListCategoryTypes.Application].includes(
    category,
  );
}

export function hasSplitViewToggle(category) {
  return [ListCategoryTypes.Resident, ListCategoryTypes.Application].includes(
    category,
  );
}

export function hasExport(category) {
  return [ListCategoryTypes.Resident, ListCategoryTypes.Application].includes(
    category,
  );
}

// #endregion
