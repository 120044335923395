import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { uiActions } from '../state/';

export function useNotify() {
  const dispatch = useDispatch();
  const cb = useCallback(
    /**
     * @param {string} message
     * @param {"success"|"error"|"warning"|"info"} variant
     */
    (message, variant = 'success', duration = undefined) => {
      dispatch(
        uiActions.showNotification({
          message,
          variant,
          duration,
        }),
      );
    },
    [dispatch],
  );
  return cb;
}
