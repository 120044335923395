import React from 'react';
import _get from 'lodash.get';
import { Typography } from '@mui/material';
import { formatPhone } from '../../../../lib';

// import { useStyles } from './fields.styles';

/** TextField props.
 * @param {PhoneFieldProps} props
 */
export function PhoneField({
  className,
  typographyProps = {},
  source,
  extSource = '',
  record = {},
  orderBy,
  useParenthesis,
  ...rest
}) {
  // const classes = useStyles();
  const value = _get(record, source);
  const ext = _get(record, extSource);
  const displayExt = ext ? `x${ext}` : '';
  return (
    <Typography
      component='span'
      variant='body2'
      className={className}
      {...typographyProps}
    >
      {typeof value === 'string'
        ? `${formatPhone(value, useParenthesis)} ${displayExt}`
        : ''}
    </Typography>
  );
}

// #region Typedefs
/** @typedef {object} PhoneFieldProps
 * @property {import("@mui/material/Typography").TypographyProps} [typographyProps]
 * @property {import("react").HTMLAttributes} [className]
 * @property {import("react").HTMLAttributes} [headerClassName] class applied to the header
 * @property {import("csstype").Properties} [cellStyle]
 * @property {string} source
 * @property {string} [extSource] To display the extension with the number
 * @property {string} label
 * @property {string} [disablePadding]
 * @property {string} [align]
 * @property {string} [sortBy] Defaults to `source`
 * @property {false} [sortable] Set to false to disable sorting for this column
 */
// #endregion
