import { authGet, authPost } from '../../lib';
import { resourceActions } from '../../state';
import { uiActions } from '..';
import { settings } from './state';

const { actions } = settings;

export const settingActions = {
  ...actions,
  getIntegrationSettings() {
    return async (dispatch, getState) => {
      const companyID = getState().authPersist.data?.companyID;
      const response = await authGet(`/Companies/${companyID}/integration`);
      const { data } = response;
      dispatch(actions.setIntegrations(data));
    };
  },
  postIntegrationSettings(isEnabled) {
    return async (dispatch, getState) => {
      const companyID = getState().authPersist.data?.companyID;
      const response = await authPost(
        `/Companies/${companyID}/integration?isEnabled=${isEnabled}`,
      );
      const { data } = response;
      dispatch(actions.setIntegrations(data));
    };
  },
  getCompanyWebhooks() {
    return async (dispatch, getState) => {
      const companyID = getState().authPersist.data?.companyID;
      const response = await authGet(`/Companies/${companyID}/webhook`);
      const { data } = response;
      dispatch(actions.setCompanyWebhooks(data));
    };
  },
  postCompanyWebhooksURL(url) {
    return async (dispatch, getState) => {
      const companyID = getState().authPersist.data?.companyID;
      const response = await authPost(`/Companies/${companyID}/webhook`, {
        url: url,
      });
      const { data } = response;
      dispatch(actions.setCompanyWebhooks(data));
    };
  },
  postCompanyWebhooksEvent(event, isActive) {
    return async (dispatch, getState) => {
      const companyID = getState().authPersist.data?.companyID;
      const response = await authPost(`/Companies/${companyID}/webhook/event`, {
        event: event.id,
        isActive,
      });
      const { data } = response;
      dispatch(actions.setCompanyWebhooks({ updatedEvent: data }));
    };
  },
  rotateToken() {
    return async (dispatch, getState) => {
      const companyID = getState().authPersist.data?.companyID;
      const response = await authPost(
        `/Companies/${companyID}/integration/rotateToken`,
      );
      const { data } = response;
      dispatch(actions.setIntegrations(data));
    };
  },
  postCustomCompanyCaseTag(payload) {
    return async (dispatch, getState) => {
      const companyID = getState().authPersist.data?.companyID;
      const response = await dispatch(
        resourceActions.postResource({
          baseUrl: `/Companies/${companyID}/caseTags`,
          payload,
        }),
      );
      const { data } = response;
      return data;
    };
  },
  postCompanyCaseTag({ id, isEnabled = null, stateIDs = null }) {
    return async (dispatch, getState) => {
      const companyID = getState().authPersist.data?.companyID;
      const response = await authPost(
        `/Companies/${companyID}/caseTags/${id}`,
        {
          isEnabled,
          stateIDs,
        },
      );
      const { data, error } = response;
      if (error) {
        dispatch(
          uiActions.showError({
            message: error.message || 'Something went wrong',
          }),
        );
        return { error };
      }

      return { data };
    };
  },
  getCompanyListSettings(category) {
    return async (dispatch, getState) => {
      const companyID = getState().authPersist.data?.companyID;
      const response = await authGet([
        `/Companies/${companyID}/settings/lists`,
        { category },
      ]);
      const { data, error } = response;
      if (error) {
        dispatch(
          uiActions.showError({
            message: error.message || 'Something went wrong',
          }),
        );
        return { error };
      }

      return { data };
    };
  },
  saveCompanyListSettings(category, listSettings) {
    return async (dispatch, getState) => {
      const companyID = getState().authPersist.data?.companyID;
      const response = await dispatch(
        resourceActions.postResource({
          baseUrl: `/Companies/${companyID}/settings/lists`,
          payload: { category, listSettings },
        }),
      );

      return response;
    };
  },
};
