import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  activity: {},
};

export const analytics = createSlice({
  name: 'analytics',
  initialState,
  reducers: {
    setActivity(state, action) {
      state.activity = action.payload;
    },
  },
});
