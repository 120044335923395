import { Box, Stack } from '@mui/material';
import {
  CalendarItem,
  CalendarNameWithStatusAndHover,
  LongTextComponent,
  dateQueryParamFormat,
  getDateItemDisplay,
} from './utils';
import { formatDate } from '../../../lib';
import { DateListItem, DateListLookup } from '../../../state';

type Props = {
  dateListLookup: DateListLookup;
  date: Date;
};

export default function DayCalendar({ dateListLookup, date }: Props) {
  // if we're on the day calendar we should only be getting back the events for the currently selected day
  const formattedDate: string = formatDate(date, dateQueryParamFormat);
  const dayEvents = dateListLookup?.[formattedDate] || [];
  return (
    <Stack spacing='6px'>
      {dayEvents.map((e, i) => (
        <DayCalendarRow key={i} event={e} />
      ))}
    </Stack>
  );
}

const DayCalendarRow = ({ event }: { event: DateListItem }) => (
  <CalendarItem display='grid' gridTemplateColumns='3.5fr 1fr'>
    <LongTextComponent text={getDateItemDisplay(event)} maxLength={75} />
    <Box textAlign='right'>
      <CalendarNameWithStatusAndHover
        event={event}
        residentNameSize='caption2'
      />
    </Box>
  </CalendarItem>
);
