import React from 'react';
import { Tooltip } from '@mui/material';

export function CustomArrowTooltip({ title, placement, children }) {
  return (
    <Tooltip
      placement={placement}
      arrow
      title={title}
      componentsProps={{
        tooltip: {
          sx: {
            backgroundColor: '#fff',
            border: '1px solid',
            borderColor: 'primary.light',
            color: '#000',
            width: 264,
            padding: 1.5,
            '& .MuiTooltip-arrow:before': {
              backgroundColor: '#fff',
              border: '1px solid',
              borderColor: 'primary.light',
            },
          },
        },
      }}
    >
      {children}
    </Tooltip>
  );
}
