import React from 'react';
import { ListItem as MuiListItem } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: 14,
    justifyContent: 'space-between',
    paddingTop: 16,
    paddingBottom: 16,

    '& span:first-child': {
      fontWeight: 600,
    },
    '& span:not(:last-child)': {
      flex: 1,
    },
  },
}));

export function ListItem({ children }) {
  const classes = useStyles();

  return (
    <MuiListItem classes={{ root: classes.root }} disableGutters divider>
      {children}
    </MuiListItem>
  );
}
