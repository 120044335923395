import makeStyles from '@mui/styles/makeStyles';

export const inputStyles = (theme) => ({
  root: {
    margin: theme.spacing(1),
    width: '25ch',
  },
  inputRoot: {
    '& $notchedOutline': {
      borderColor: theme.palette.outlineTheme.main,
    },
    '&:hover:not($disabled):not($error) $notchedOutline': {
      borderColor: theme.palette.outlineTheme.main,
    },
    '&$focused:not($disabled):not($error) $notchedOutline': {
      borderColor: theme.palette.outlineTheme.main,
    },
    // datepicker styles
    '& .react-datepicker-wrapper': {
      width: '100%',
    },
    '& .react-datepicker-popper': {
      zIndex: 30,
      marginTop: -4,
      '& svg': {
        display: 'none',
      },
    },
    '& .react-datepicker': {
      border: 'none',
      boxShadow:
        'rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px;',
    },
    '& .react-datepicker__navigation--years': {
      display: 'none',
    },
    '& .react-datepicker__header': {
      background: 'transparent',
      borderBottom: 'none',
      fontFamily: 'Poppins',
      '& h2': {
        fontWeight: 500,
      },
      '& .react-datepicker__header__dropdown': {
        marginTop: 8,
        marginBottom: 16,
      },
    },
    '& .react-datepicker__month-dropdown, & .react-datepicker__year-dropdown': {
      background: 'white',
      padding: '8px 0',
    },
    '& .react-datepicker__day-names': {
      padding: '0 16px',
    },
    '& .react-datepicker-time__header': {
      textAlign: 'center',
    },
    '& .react-datepicker__day:not([aria-disabled=true]):hover': {
      borderRadius: '100%',
    },
    '& .react-datepicker__day--selected': {
      backgroundColor: 'rgb(9, 110, 248)',
      color: '#fff',
      borderRadius: '100%',
    },
    '& .react-datepicker__day--keyboard-selected': {
      backgroundColor: '#FFF',
      color: '#000',
    },
    '& .react-datepicker__day--today': {
      fontWeight: '500',
      border: '1px solid',
      borderRadius: '100%',
    },
    '& .react-datepicker__time-list-item': {
      display: 'flex',
      alignItems: 'center',
    },
    '& .react-datepicker__time-list-item--selected': {
      fontWeight: 500,
      backgroundColor: 'rgb(9, 110, 248)',
    },
    '& .react-datepicker__year-dropdown--scrollable': {
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
  },

  smallInputRoot: {
    padding: '0 8px !important',
    height: 36,
  },
  input: {
    display: 'flex',
    padding: '16.5px 14px 14.5px 14px',
  },
  get autocompleteInputRoot() {
    return {
      ...this.inputRoot,
      padding: '0px !important',
    };
  },
  get autocompleteInput() {
    return {
      ...this.input,
      padding: '16.5px 14px 14.5px 14px !important',
    };
  },
  label: {
    color: theme.palette.textTheme.main,
    '&$focused:not($disabled):not($error)': {
      color: theme.palette.textTheme.main,
    },
  },
  notchedOutline: {},
  disabled: {},
  error: {},
  focused: {},
  helperText: {
    position: 'absolute',
    bottom: -21,
  },
  addressSearchContainer: {
    flexGrow: 1,
    position: 'relative',
    display: 'flex',
  },
  addressSearchPaper: {
    position: 'absolute',
    zIndex: 10,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
    top: 45,
    maxHeight: 350,
    overflowY: 'auto',
  },
  menuItemsHeader: {
    fontSize: 16,
    fontWeight: 500,
    margin: '8px 16px',
  },
  avatar: {
    marginRight: 12,

    fontSize: 10,
    fontWeight: 500,
    height: 24,
    width: 24,
    cursor: 'pointer',
  },
  selectOnlyLink: {
    width: '100%',
    textAlign: 'right',

    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.primary.light,
    cursor: 'pointer',
  },
  clearIcon: {
    fontSize: 14,
  },
  datePicker: {
    width: '100%',
    '& input': {
      marginBottom: 0,
      height: 52,
      border: '1px solid #9DA7B9',
      borderRadius: 4,
      width: '100%',
      fontFamily: 'Poppins',
      // important to override react-datepicker styles when you show the calendar icon
      padding: '0 16px !important',
    },
  },
});

export const useStyles = makeStyles(inputStyles);
