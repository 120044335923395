import { memo } from 'react';
import { IconType } from '../../lib';
import { SvgIconProps } from '@mui/material';

type Props = {
  Icon: IconType;
  iconColor?: string;
} & SvgIconProps;
export const ColoredIcon = memo(function ({
  Icon,
  iconColor,
  ...iconProps
}: Props) {
  return <Icon {...iconProps} sx={{ color: iconColor }} />;
});
