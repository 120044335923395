import { Tooltip as MuiTooltip, TooltipProps } from '@mui/material';
import { ReactElement } from 'react';

type Props = {
  children: ReactElement;
} & TooltipProps;

export function CustomTooltip({ children, ...tooltipProps }: Props) {
  return (
    <MuiTooltip {...tooltipProps}>
      <span>{children}</span>
    </MuiTooltip>
  );
}
