import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  integrations: {
    isEnabled: false,
    userID: null,
    apiToken: '',
  },
  companyWebhooks: {
    url: '',
    events: [],
  },
};

export const settings = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setIntegrations(state, action) {
      state.integrations = action.payload;
    },
    setCompanyWebhooks(state, action) {
      if (action.payload.updatedEvent) {
        const updatedEvent = action.payload.updatedEvent;
        state.companyWebhooks.events = state.companyWebhooks.events.map(
          (event) => {
            if (event.id === updatedEvent.id) {
              return updatedEvent;
            } else {
              return event;
            }
          },
        );
      } else {
        state.companyWebhooks.url = action.payload.url;
        state.companyWebhooks.events = action.payload.events;
      }
    },
  },
});
