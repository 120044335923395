import React, { useState } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { Button, CircularProgress } from '@mui/material';
import { AuthLayout } from './AuthLayout';
import { Routes, validateOnSubmit } from '../../lib';
import { authActions, uiSelectors } from '../../state';
import { TextInput } from '../../components';
import { useStyles } from './Auth.styles';

export const ForgotPassword = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const uiLoading = useSelector(uiSelectors.loading);
  const [email, setEmail] = useState('');
  // const [view, setView] = useState('form');
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const {
      target: { value },
    } = e;
    setEmail(value);
  };
  const submit = async (e) => {
    e.preventDefault();
    validateOnSubmit({ values: { email }, schema, setErrors }).then(
      async () => {
        const response = await dispatch(authActions.forgotPassword({ email }));
        if (response.error) {
          return;
        }
        // setView('message');
      },
    );
  };
  return (
    <AuthLayout>
      <h1 className={clsx(classes.title, classes.welcome)}>Forgot password</h1>
      <p className={classes.welcomeSubText}>
        Enter your email and we’ll send you a link to reset your password.
      </p>
      <form noValidate onSubmit={submit} className={classes.form}>
        <TextInput
          className={classes.inputSpacer}
          required
          fullWidth
          id='email'
          label='Email'
          type='email'
          name='email'
          autoComplete='email'
          autoFocus
          value={email}
          onChange={handleChange}
          disabled={uiLoading}
          schema={schema}
          error={errors['email']}
        />
        <div className={classes.actions}>
          <div className={classes.submitWrapper}>
            <Button
              type='submit'
              variant='contained'
              color='primary'
              className={classes.submit}
              disabled={!email}
              disableFocusRipple
              disableRipple
              disableTouchRipple
            >
              Send
            </Button>
          </div>
          <Button
            to={Routes.login.path}
            color='primary'
            component={Link}
            className={classes.forgotPassword}
          >
            Return to sign in
          </Button>
        </div>
        {uiLoading && (
          <div className={classes.loader}>
            <CircularProgress size={24} />
          </div>
        )}
      </form>
    </AuthLayout>
  );
};

const schema = yup.object().shape({
  email: yup
    .string('Email address must be a string')
    .email('Invalid email')
    .required('Email address is required'),
});
