import { styled, Box } from '@mui/material';

export const FlexBetween = styled(Box)(({ align }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: align,
}));

export const FlexCenter = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

export const FlexColumn = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));
