import { Link } from 'react-router-dom';
import {
  generatePath,
  ResidentCaseViews,
  ResidentOverviewQueryParams,
  Routes,
} from '../../lib';
import { NameLinkProps } from './types';

export function getNameLinkProps({
  caseId,
  caseView = ResidentCaseViews.Application,
}: NameLinkProps) {
  return {
    component: Link,
    to: generatePath(
      Routes.caseDetails.path,
      { id: caseId },
      { [ResidentOverviewQueryParams.view]: caseView },
    ),
    state: { search: window.location.search },
    sx: {
      textDecoration: 'none', // to remove the link underline
      transition: 'opacity 300ms linear',
      '&:hover': { opacity: 0.5 },
    },
  };
}

export const contactTooltipStyles = {
  borderRadius: '6px',
  backgroundColor: '#FFF',
  padding: 1.5,
  boxShadow: '0 2px 32px 0 rgba(0,0,0,0.08), 0 2px 24px 0 rgba(0,0,0,0.12)',
  color: 'text.primary',
  display: 'flex',
  alignItems: 'center',
};
