import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  layout: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
    background: 'linear-gradient(180deg, #061BEE 0%, #0514B3 100%)',
    height: '100vh',
  },
  mainText: {
    color: '#FFFFFF',
    fontSize: 200,
    fontWeight: 600,
    marginTop: 72,
  },
  subText: {
    color: '#FFFFFF',
    fontSize: 24,
    fontWeight: 500,
    marginBottom: 40,
  },
  btn: {
    height: 48,
    width: 160,
    backgroundColor: '#FFFFFF',
    color: '#061BEE',
    textTransform: 'capitalize',
    borderRadius: 4,
    letterSpacing: 1.43,
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 500,
    zIndex: 1,
    '&:hover': {
      backgroundColor: '#FFFFFF',
    },
  },
  pageImg: {
    position: 'absolute',
    bottom: 16,
  },
}));
