import { createSlice } from '@reduxjs/toolkit';
import { Auth } from '../../lib';

const residentProps = {
  stayDetails: {
    loading: false,
  },
  rfmsInfo: {
    loading: false,
  },
  longTermCoverage: {},
  coverageGapBreakdown: [],
  coverageGapOverrides: [],
  latestAuth: {} as Auth,
  //...resident overview data
};

const initialState = {
  resident: {
    ...residentProps,
  },
  spouse: {
    ...residentProps,
  },
};

export const residents = createSlice({
  name: 'residents',
  initialState,
  reducers: {
    setResidentStayDetailsLoading(state, action) {
      const { loading, isSpouse } = action.payload;
      if (isSpouse) {
        state.spouse.stayDetails.loading = loading;
      } else {
        state.resident.stayDetails.loading = loading;
      }
    },
    setResidentStayDetails(state, action) {
      state.resident.stayDetails = action.payload;
    },
    setResidentSpouseStayDetails(state, action) {
      state.spouse.stayDetails = action.payload;
    },
    setResidentOverview(state, action) {
      state.resident = { ...state.resident, ...action.payload };
    },
    setSpouseOverview(state, action) {
      state.spouse = { ...state.spouse, ...action.payload };
    },
    setResidentLongTermPayer(state, action) {
      state.resident.longTermCoverage = action.payload;
    },
    setSpouseLongTermPayer(state, action) {
      state.spouse.longTermCoverage = action.payload;
    },
    clearResidentAndSpouse(state, action) {
      state.resident = { ...residentProps };
      state.spouse = { ...residentProps };
    },
    setRFMSInfo(state, action) {
      state.resident.rfmsInfo = action.payload;
    },
    setResidentCoverageGapBreakdown(state, action) {
      state.resident.coverageGapBreakdown = action.payload;
    },
    setSpouseCoverageGapBreakdown(state, action) {
      state.spouse.coverageGapBreakdown = action.payload;
    },
    setResidentCoverageGapOverrides(state, action) {
      state.resident.coverageGapOverrides = action.payload;
    },
    setSpouseCoverageGapOverrides(state, action) {
      state.spouse.coverageGapOverrides = action.payload;
    },
    setResidentLatestAuth(state, action) {
      state.resident.latestAuth = action.payload;
    },
  },
});
