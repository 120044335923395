import { ReactElement } from 'react';
import { CustomTooltip } from '../ui';
import { StatusField } from '../common';
import { getPendingStatus } from '../../lib';

type Props = {
  payerName?: string;
  payerIsPending?: boolean;
  emptyState?: string | ReactElement;
  className?: string;
  tooltipSuffix?: string;
  isLongTerm?: boolean;
};

export function PayerNameWithStatus({
  payerName = '',
  payerIsPending = false,
  emptyState = '',
  className = '',
  tooltipSuffix = '',
  isLongTerm = false,
}: Props) {
  const payerNameDisplay = `${payerName}${
    isLongTerm && payerIsPending ? ' Pending' : ''
  }`;

  return (
    <>
      {payerName || (!isLongTerm && payerIsPending) ? (
        <CustomTooltip
          title={`${getPendingStatus(payerIsPending)}${tooltipSuffix}`}
          placement='left'
        >
          <StatusField
            className={className}
            value={payerNameDisplay || '[Pending Payer]'}
            badgeClassName={getPendingStatus(payerIsPending)}
          />
        </CustomTooltip>
      ) : (
        emptyState
      )}
    </>
  );
}
