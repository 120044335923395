import { Box, Divider, useTheme } from '@mui/material';
import { CalendarNameWithStatusAndHover, LongTextComponent } from './utils';
import { FlexColumn, PrintTypography } from '../../../components';

import { addDays, eachDayOfInterval, isToday } from 'date-fns';
import {
  CalendarItem,
  dateQueryParamFormat,
  getDateItemDisplay,
} from './utils';
import { formatDate } from '../../../lib';

import { DateListItem, DateListLookup } from '../../../state';

const DaysOfWeek = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Weekend',
] as const;

type Props = {
  dateListLookup: DateListLookup;
  dateRange: Date[];
};

export default function WeekCalendar({ dateListLookup, dateRange }: Props) {
  // we're assuming the date range was set properly earlier
  const [monday, sunday] = dateRange;

  // even though we're retrieving the events through Sunday,
  // the calendar only displays the dates through Saturday ("Weekend")
  const saturday = addDays(sunday, -1);
  const daysInThisWeek = eachDayOfInterval({ start: monday, end: saturday });

  return (
    <Box
      display='grid'
      gridTemplateColumns='repeat(6, minmax(0, 1fr))'
      columnGap='8px'
      paddingRight='8px' // put some space between the content and the scrollbar
    >
      {daysInThisWeek.map((date, i) => {
        const formattedDate = formatDate(date, dateQueryParamFormat);
        let dayEvents = dateListLookup[formattedDate] || [];
        if (i === 5) {
          // on the weekend column display events from both Saturday and Sunday
          const formattedSundayDate = formatDate(sunday, dateQueryParamFormat);
          const sundayEvents = dateListLookup[formattedSundayDate] || [];
          dayEvents = [...dayEvents, ...sundayEvents];
        }
        return <WeekCalendarColumn key={i} date={date} events={dayEvents} />;
      })}
    </Box>
  );
}

const WeekCalendarColumn = ({
  date,
  events,
}: {
  date: Date;
  events: DateListItem[];
}) => {
  const theme = useTheme();
  return (
    <Box>
      <Box
        textAlign='center'
        bgcolor='#FFFFFF'
        color={isToday(date) ? theme.palette.primary.main : undefined}
        marginBottom='6px'
        position='sticky'
        top={0}
        // kludge: StatusField (resident name + active symbol) was showing through this Box
        // It has a zIndex of 1; removing that didn't help but this does
        zIndex={2}
      >
        <PrintTypography variant='caption'>
          {DaysOfWeek[date.getDay() - 1]}
        </PrintTypography>
        <PrintTypography variant='subtitle2'>{date.getDate()}</PrintTypography>
        <Divider />
      </Box>
      <FlexColumn rowGap='4px'>
        {events.map((e, i) => (
          <WeekCalendarColumnItem key={i} event={e} />
        ))}
      </FlexColumn>
    </Box>
  );
};

const WeekCalendarColumnItem = ({ event }: { event: DateListItem }) => (
  <CalendarItem>
    <LongTextComponent
      text={getDateItemDisplay(event)}
      customWrap={true}
      typographyProps={{ sx: { overflowWrap: 'break-word' } }}
    />
    <CalendarNameWithStatusAndHover event={event} residentNameSize='caption2' />
  </CalendarItem>
);
