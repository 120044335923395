import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  list: {
    flexGrow: 1,
    transition: theme.transitions.create(['all'], {
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  noteActions: {
    color: theme.palette.textTheme.main,
    '& svg': {
      opacity: 0,
      transition: 'opacity 300ms linear',
      fontSize: 16,
    },
  },
  showNoteActions: {
    '& svg': {
      opacity: 1,
    },
  },
  listItem: {
    display: 'flex',
    paddingBottom: 16,
    paddingTop: 16,
    transition: 'opacity 300ms linear',
  },
  icon: {
    marginRight: 12,
    marginTop: 4,
    '& svg': {
      height: 16,
      width: 16,
    },
  },
  listItemSubText: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'end',
    marginTop: 8,
    '& span': {
      color: theme.palette.textTheme.main,
      fontSize: '12px !important',
      letterSpacing: 0,
    },
  },
}));
