import { Box } from '@mui/material';
import { StatusField } from '../common';
import { getNameLinkProps } from './utils';
import { ResidentNameDisplayProps } from './types';

export function NameWithStatus({
  residentName,
  isResidentActive,
  residentNameSize = 'label',
  ...nameLinkPropValues
}: ResidentNameDisplayProps) {
  const nameLinkProps = nameLinkPropValues.caseId
    ? getNameLinkProps(nameLinkPropValues)
    : {};

  return (
    <Box component='span' marginLeft='10px' lineHeight={1}>
      <StatusField
        typographyProps={{
          variant: residentNameSize,
          color: 'text.secondary',
          ...nameLinkProps,
        }}
        value={residentName}
        badgeClassName={isResidentActive ? 'active' : 'inactive'}
      />
    </Box>
  );
}
