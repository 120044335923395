import { Tooltip } from '@mui/material';
import { memo } from 'react';
import { AllInclusiveIcon } from '../../themes';
import { MaritalStatus } from '../../lib';

export const MarriedIcon = memo(function () {
  return (
    <Tooltip title={MaritalStatus.Married}>
      <AllInclusiveIcon fontSize='xsmall' />
    </Tooltip>
  );
});
