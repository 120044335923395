import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  autoIdleLogout: false,
};

export const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginUser(state, action) {
      state.error = undefined;
    },
    loginFailed(state, action) {
      state.error = { ...action.payload };
    },
    setAfterLoginRoute(state, action) {
      const { redirectTo } = action.payload;
      state.afterLoginRedirect = redirectTo;
    },
    setAutoIdleLogout(state, action) {
      state.autoIdleLogout = action.payload;
    },
  },
});
