import { memo } from 'react';
import { Box, Typography } from '@mui/material';
import LazyCaseTagIcon from './LazyCaseTagIcon';
import { CaseTag } from '../../../lib';
import { CustomTooltip, FlexCenter } from '../../ui';

export const CaseTagIcons = memo(function ({
  caseTags = [],
  maxDisplay,
}: {
  caseTags: CaseTag[];
  maxDisplay?: number; // if not set then display all
}) {
  const haveExtraCaseTags = !!maxDisplay && caseTags.length > maxDisplay;
  // if we have exactly maxDisplay number of case tags we'll display all of them
  // but if we have more than that then display one less, to leave room for the "+X"
  const maxDisplayWhenHaveExtra = maxDisplay ? maxDisplay - 1 : 0;

  const caseTagsToDisplay = [...caseTags];
  const extraCaseTags = haveExtraCaseTags
    ? // note splice modified the original array, by using the return value we are effectively splitting the array into two
      caseTagsToDisplay.splice(maxDisplayWhenHaveExtra)
    : [];

  return (
    <FlexCenter>
      <Box>
        {caseTagsToDisplay.map((t, i) => (
          <CustomTooltip title={t.name} key={i}>
            <LazyCaseTagIcon caseTag={t} />
          </CustomTooltip>
        ))}
      </Box>
      {haveExtraCaseTags && (
        <CustomTooltip
          title={extraCaseTags.map((t, i) => (
            <FlexCenter key={i} gap={1}>
              <LazyCaseTagIcon caseTag={t} />
              <Typography variant='caption'>{t.name}</Typography>
            </FlexCenter>
          ))}
        >
          <Typography variant='label'>+{extraCaseTags.length}</Typography>
        </CustomTooltip>
      )}
    </FlexCenter>
  );
});
