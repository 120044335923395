import { useCallback, useEffect, useState } from 'react';
import { useNotify } from '../../../hooks';

import { Box, Divider, Stack, styled } from '@mui/material';
import { NoPrint, PrintTypography } from '../../../components';
import {
  CalendarNameWithStatusAndHover,
  LongTextComponent,
  getDateItemDescription,
} from './utils';
import { ExpandLessIcon, ExpandMoreIcon } from '../../../themes';

import { compareAsc } from 'date-fns';
import { CalendarItem, getDateSummaryDisplay } from './utils';
import { formatDate, toRestPropName } from '../../../lib';

import {
  DateListItem,
  DateListLookup,
  DateTypeGroup,
  ResidentDateListGrouping,
  ResidentDatesQueryType,
  ResidentDatesResult,
  ResidentDateTypeType,
} from '../../../state';

type Props = {
  eventGroups: DateTypeGroup[];
  loadResidentDates: GetResidentDates;
  allExpanded: boolean;
  allCollapsed: boolean;
  setAllExpanded: (arg0: boolean) => void;
  setAllCollapsed: (arg0: boolean) => void;
};

export default function SummaryCalendar({
  eventGroups,
  loadResidentDates,
  allExpanded,
  allCollapsed,
  setAllExpanded,
  setAllCollapsed,
}: Props) {
  // when this component receives the allExpanded flag from the parent it must fetch the data for all rows
  // before passing the flag down to the child components, hence the local state
  const [expandAllRows, setExpandAllRows] = useState(false);
  const [dataForExpansion, setDataForExpansion] = useState(
    {} as DateListLookup,
  );

  const loadDataForExpansion = useCallback(
    async (dateType?: ResidentDateTypeType | undefined) => {
      const data = await loadResidentDates('Range', dateType, 'DateType');
      return data.dateListLookup || {};
    },
    [loadResidentDates],
  );

  useEffect(() => {
    (async function () {
      if (allExpanded) {
        const data = await loadDataForExpansion();

        // now we have the data needed to expand the rows, so now we can pass the flag to the rows to expand
        setDataForExpansion(data);
        setExpandAllRows(true);
      }
    })();
  }, [allExpanded, loadDataForExpansion]);

  // let the parent component know that the rows might not all be in the same state anymore (in case allCollapsed or allExpanded was set)
  const onCollapseRow = useCallback(() => {
    setAllExpanded(false);
    setExpandAllRows(false);
  }, [setAllExpanded]);

  const onExpandRow = useCallback(
    () => setAllCollapsed(false),
    [setAllCollapsed],
  );

  return (
    <Stack spacing='6px'>
      {eventGroups.map((g) => {
        // the values of g.dateType are capitalized but the keys of dataForExpansion are camel case
        const dateType = toRestPropName(g.dateType);
        return (
          <SummaryCalendarRow
            key={dateType}
            eventGroup={g}
            dataForExpansion={dataForExpansion[dateType]}
            loadDataForExpansion={loadDataForExpansion}
            expandRow={expandAllRows}
            collapseRow={allCollapsed}
            onCollapseRow={onCollapseRow}
            onExpandRow={onExpandRow}
          />
        );
      })}
    </Stack>
  );
}

type RowProps = {
  eventGroup: DateTypeGroup;
  dataForExpansion?: DateListItem[];
  loadDataForExpansion: LoadDataForExpansion;
  expandRow: boolean;
  collapseRow: boolean;
  onCollapseRow: () => void;
  onExpandRow: () => void;
};
const SummaryCalendarRow = ({
  eventGroup,
  dataForExpansion = [],
  loadDataForExpansion,
  expandRow,
  collapseRow,
  onCollapseRow,
  onExpandRow,
}: RowProps) => {
  const notify = useNotify();

  const [expanded, setExpanded] = useState(false);
  const [expandedData, setExpandedData] = useState(dataForExpansion);

  const localOnExpandRow = useCallback(() => {
    setExpanded(true);
    onExpandRow();
  }, [onExpandRow]);

  const localOnCollapseRow = useCallback(() => {
    setExpanded(false);
    setExpandedData([]); // we want to re-request the data the next time the row is expanded
    onCollapseRow();
  }, [onCollapseRow]);

  useEffect(() => {
    if (expandRow) {
      localOnExpandRow();
    }
  }, [expandRow, localOnExpandRow]);

  useEffect(() => {
    if (collapseRow) {
      localOnCollapseRow();
    }
  }, [collapseRow, localOnCollapseRow]);

  const sortAndSetExpandedData = useCallback((data: DateListItem[]) => {
    const sortedData = data.sort((d1, d2) =>
      compareAsc(new Date(d1.date), new Date(d2.date)),
    );
    setExpandedData(sortedData);
  }, []);

  useEffect(() => {
    if (dataForExpansion.length) {
      sortAndSetExpandedData(dataForExpansion);
    }
  }, [dataForExpansion, sortAndSetExpandedData]);

  useEffect(() => {
    (async function () {
      // need to fetch the data locally only if the row was expanded locally, not when "expand all" is clicked
      if (expanded && !expandRow && !expandedData.length) {
        const data = await loadDataForExpansion(eventGroup.dateType);
        // the value of eventGroup.dateType is capitalized but the keys of data are camel case
        const dataOfThisType = data[toRestPropName(eventGroup.dateType)];
        sortAndSetExpandedData(dataOfThisType);
      }
    })();
  }, [
    expanded,
    expandRow,
    expandedData.length,
    eventGroup.dateType,
    loadDataForExpansion,
    sortAndSetExpandedData,
    notify,
  ]);

  return (
    <>
      <CalendarItem
        display='flex'
        justifyContent='space-between'
        onClick={expanded ? localOnCollapseRow : localOnExpandRow}
      >
        <PrintTypography>
          {eventGroup.count} {getDateSummaryDisplay(eventGroup)}
        </PrintTypography>
        {/* when useMediaQuery('print') works then can adjust the fontSize of the icons to 'tiny' when printing, instead of hiding them completely */}
        {/* https://github.com/mui/material-ui/issues/17595 */}
        <NoPrint lineHeight={0}>
          {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </NoPrint>
      </CalendarItem>
      {expanded && (
        <Stack
          divider={<Divider />}
          spacing='4px' // when useMediaQuery('print') works then conditionally set the spacing to 0 when printing https://github.com/mui/material-ui/issues/17595
          // margins have to be set as inline style to override the default Stack style (from the parent Stack in the SummaryCalendar component)
          style={{ marginLeft: '28px', marginRight: '28px' }}
        >
          {Object.values(expandedData || []).map((d, i) => (
            <SummaryCalendarSubItem key={i} event={d} />
          ))}
        </Stack>
      )}
    </>
  );
};

const SummaryCalendarSubItem = ({ event }: { event: DateListItem }) => {
  const descriptionText = getDateItemDescription(event);

  return (
    <CalendarSubItem display='grid' gridTemplateColumns='.75fr 2.25fr 1fr'>
      <PrintTypography variant='caption' color='text.secondary'>
        {formatDate(event.date)}
      </PrintTypography>
      <LongTextComponent
        text={descriptionText || ''}
        typographyProps={{ variant: 'caption', color: 'text.secondary' }}
      />
      <Box textAlign='right'>
        <CalendarNameWithStatusAndHover
          event={event}
          residentNameSize='caption2'
        />
      </Box>
    </CalendarSubItem>
  );
};

const CalendarSubItem = styled(CalendarItem)(() => ({
  backgroundColor: '#FFFFFF',
}));

type GetResidentDates = (
  queryType: ResidentDatesQueryType,
  dateType?: ResidentDateTypeType,
  groupBy?: ResidentDateListGrouping,
) => Promise<ResidentDatesResult>;

type LoadDataForExpansion = (
  dateType: ResidentDateTypeType | undefined,
) => Promise<DateListLookup>;
