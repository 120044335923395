import React from 'react';
import { Button } from '@mui/material';
import { useStyles } from './listFilters.styles';

export default function FilterBase({ content, onChange, handleClose }) {
  const classes = useStyles();

  return (
    <div style={{ padding: 24 }}>
      {content}

      <div className={classes.filterActions}>
        <Button className={classes.closeButton} onClick={handleClose}>
          Close
        </Button>
        <Button
          color='primary'
          variant='contained'
          onClick={onChange}
          className={classes.updateButton}
          size='small'
        >
          Update
        </Button>
      </div>
    </div>
  );
}
