import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  swimlanes: {
    display: 'flex',
    flexDirection: 'row',
    gap: '16px',
  },
  swimlanesColumn: {
    minWidth: '15rem',
    flex: 1,
    background: '#F2F2F3',
    borderRadius: '5px',
  },
  laneHeader: {
    color: '#9BA0B1',
    margin: '12px 24px 8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  circle: {
    fontSize: '1rem',
    paddingTop: 5,
  },
  laneHeaderTitle: {
    fontSize: 14,
  },
  laneHeaderCount: {
    fontStyle: 'italic',
    color: '#0B0A0A',
  },
  swimlanesList: { padding: '0 12px 8px', margin: '0' },
  swimlanesListItem: {
    listStyleType: 'none',
    margin: '0.5rem 0',
    // padding: "1rem",
    background: '#FFF',
    borderRadius: '0.25rem',
    '&:hover': {
      color: theme.palette.primary.light,
    },
  },
  taskCard: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#F2F2F3',
    },
  },
  card: {
    boxShadow: '0 2px 12px 0 #F5F6FE, 0 2px 8px 0 #D5D8EC !important',
  },
  avatar: {
    height: 24,
    width: 24,
  },
  avatarMe: {
    backgroundColor: '#0F0190',
    color: 'ffffff',
  },
  avatarAssignee: {
    backgroundColor: theme.palette.primary.light,
    color: '#ffffff',
  },
}));
