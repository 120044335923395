import { uiActions } from '../../state';
import { authGet, arrayToObjByEnumOrId } from '../../lib';
import { lists } from './state';

const { actions } = lists;
export const listActions = {
  ...actions,

  /**
   *
   * @param {fetchList} fetchListProps
   * @returns {normalizeReturn}
   */
  fetchList({
    resource,
    baseUrl,
    order,
    orderBy,
    page,
    rowsPerPage,
    filter = {},
    noPagination,
    hasLoader,
  }) {
    return async (dispatch) => {
      if (hasLoader) {
        dispatch(uiActions.setUILoading(true));
      } else {
        dispatch(uiActions.setListLoading(true));
      }
      if (!rowsPerPage) {
        return Promise.resolve({ data: {} });
      }
      const response = await authGet([
        baseUrl || '/' + resource,
        {
          order: !noPagination ? order : undefined,
          orderBy: !noPagination ? orderBy : undefined,
          resultsPerPage: !noPagination ? rowsPerPage : undefined,
          pageNumber: !noPagination ? page + 1 : undefined, // the server is 1 based
          ...filter,
        },
      ]);

      if (hasLoader) {
        dispatch(uiActions.setUILoading(false));
      } else {
        dispatch(uiActions.setListLoading(false));
      }
      const { data, error } = response;
      if (error) {
        dispatch(
          uiActions.showError({
            message: error.message || 'Failed to fetch the data',
          }),
        );
        return { error };
      }
      const { results, numberOfRows } = data;
      const sortIds = [];
      dispatch(
        actions.populateList({
          list: resource,
          data: {
            name: resource,
            data: arrayToObjByEnumOrId(results, sortIds),
            ids: sortIds,
            params: {
              numberOfRows,
              filter,
              hasList: true,
              rowsPerPage,
            },
          },
        }),
      );
      return { data };
    };
  },
};

// #region Typedefs
/**
 *  @typedef {object} fetchList
 * @property {string} resource
 * @property {string} [baseUrl] Use for nested resources eg `users/:userId`
 * @property {string | number} order
 * @property {string} orderBy
 * @property {string | number} start
 * @property {string | number} end
 */
// #endregion
