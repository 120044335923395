import { styled, Tab, tabClasses, Tabs, tabsClasses } from '@mui/material';

export const TabsContainer = styled(Tabs)(() => ({
  padding: '16px 0px',
  [`& .${tabsClasses.indicator}`]: {
    display: 'none',
  },
  '& .MuiTabs-flexContainer': {
    gap: '8px',
  },
}));

export const TabItem = styled(Tab)(({ theme }) => ({
  borderRadius: '9px',
  textAlign: 'center',
  transition: 'all .5s',
  padding: '4px 10px',
  color: theme.palette.text.primary,
  backgroundColor: '#EEF1F7',
  height: 'auto',
  margin: '10px 0px',
  fontSize: '13px',
  fontWeight: '500',
  minHeight: '0px',
  minWidth: '0px',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  [`&.${tabClasses.selected}`]: {
    color: '#FFFFFF',
    backgroundColor: theme.palette.action.secondaryColor,
  },
}));
