import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useGetQueryParams } from '../../hooks';
import { authActions } from '../../state';
const { REACT_APP_EXTENSION_ID } = process.env;

/**
 * used by extension to trigger logout
 * @returns
 */
export const Logout = () => {
  const dispatch = useDispatch();
  const { extId } = useGetQueryParams();

  useEffect(() => {
    if (extId && extId === REACT_APP_EXTENSION_ID) {
      dispatch(
        authActions.logout(true, `chrome-extension://${extId}/index.html`),
      );
    }
  }, [extId, dispatch]);

  return <div />;
};
