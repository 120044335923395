import { Suspense, lazy } from 'react';
import { CaseTag } from '../../../lib';

const InternalCaseTagIcon = lazy(() => import('./InternalCaseTagIcon'));

export default function LazyCaseTagIcon({ caseTag }: { caseTag: CaseTag }) {
  return (
    <Suspense>
      <InternalCaseTagIcon caseTag={caseTag} />
    </Suspense>
  );
}
