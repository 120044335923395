import { useState, Fragment, useCallback } from 'react';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { useStateWithCallbackInstant } from 'use-state-with-callback';
import { Divider, Fade, Typography, useTheme, Box } from '@mui/material';
import { List, NotesField, DataGrid, FunctionField } from '../../';
import { NoteForm } from '../../modals/NoteForm';
import { DeleteConfirmation } from '../../modals/DeleteConfirmation';
import { formatDate, getNoteTypeColor } from '../../../lib';
import {
  EditIcon,
  DeleteIcon,
  FilledFileIcon,
  CopyOutlinedIcon,
  UnfoldLessIcon,
  UnfoldMoreIcon,
} from '../../../themes';
import { resourceActions } from '../../../state';
import {
  useGlobalRefresh,
  useLoader,
  useNotify,
  useCopyToClipboard,
} from '../../../hooks';
import { useStyles } from './notesList.styles';
import { FlexBetween, FlexColumn } from '../../ui/FlexDiv';

export function NotesList({
  baseUrl,
  customViewVersion,
  resource,
  filter,
  actions,
  openAddNote,
  setOpenAddNote,
  noteType,
  hideFooter = false,
  noteCategories = [],
  refresh,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const globalRefresh = useGlobalRefresh();
  if (!refresh) {
    refresh = globalRefresh;
  }
  const notify = useNotify();
  const setLoader = useLoader();
  const [openEditNote, setOpenEditNote] = useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [editId, setEditId] = useStateWithCallbackInstant(null, (noteId) =>
    setOpenEditNote(!!noteId),
  );
  const [noteToDelete, setNoteToDelete] = useStateWithCallbackInstant(
    null,
    (noteId) => setOpenDeleteConfirmation(!!noteId),
  );
  const [expandedNoteId, setExpandedNoteId] = useState(null);

  const handleDelete = useCallback(async () => {
    setLoader(true);
    const response = await dispatch(
      resourceActions.deleteResource({
        baseUrl,
        id: noteToDelete,
      }),
    );
    setLoader(false);
    const { error } = response;
    if (!error) {
      notify('Note Deleted');
      refresh();
      setNoteToDelete(false);
    }
  }, [
    baseUrl,
    dispatch,
    noteToDelete,
    notify,
    refresh,
    setLoader,
    setNoteToDelete,
  ]);

  return (
    <Fragment>
      <List
        baseUrl={baseUrl}
        customViewVersion={customViewVersion}
        resource={resource}
        filter={filter}
        bulkActionButtons={false}
        className={classes.list}
        actions={actions}
        hideHeader={true}
        hideFooter={hideFooter}
        order='desc'
        orderBy='UpdatedOn'
      >
        <DataGrid className={classes.cell}>
          <FunctionField
            source=''
            label=''
            cellStyle={{ borderBottom: '0px' }}
            render={(record) => (
              <NoteListItem
                note={record}
                setEditId={setEditId}
                setNoteToDelete={setNoteToDelete}
                expandedNoteId={expandedNoteId}
                setExpandedNoteId={setExpandedNoteId}
              />
            )}
          />
        </DataGrid>
      </List>
      {openAddNote && (
        <NoteForm
          open={true}
          handleClose={() => setOpenAddNote(false)}
          categories={noteCategories}
          noteType={noteType}
          apiURL={baseUrl}
          refresh={refresh}
        />
      )}
      {openEditNote && (
        <NoteForm
          open={true}
          handleClose={() => setEditId(null)}
          categories={noteCategories}
          noteType={noteType}
          editId={editId}
          apiURL={baseUrl}
          refresh={refresh}
        />
      )}
      {openDeleteConfirmation && (
        <DeleteConfirmation
          open={true}
          title={'Note'}
          text='Are you sure you want to delete this note?'
          handleClose={() => setNoteToDelete(null)}
          handleDelete={handleDelete}
        />
      )}
    </Fragment>
  );
}

export function NoteListItem({
  note,
  setEditId,
  setNoteToDelete,
  expandedNoteId,
  setExpandedNoteId,
  authorInfoMultiLine,
  variant = 'body1',
}) {
  const classes = useStyles();
  const theme = useTheme();
  const copyToClipboard = useCopyToClipboard();
  const [showNoteActions, setShowNoteActions] = useState(false);

  const {
    id,
    text,
    htmlContent: _htmlContent,
    type,
    typeFriendlyName,
    editedOn,
    editedByFirstName,
    createdOn,
  } = note;

  const expanded = expandedNoteId === id;
  const ExpandIcon = expanded ? UnfoldLessIcon : UnfoldMoreIcon;
  const htmlContent = _htmlContent || text;

  return (
    <Fragment key={id}>
      <div
        className={classes.listItem}
        onMouseEnter={() => setShowNoteActions(true)}
        onMouseLeave={() => setShowNoteActions(false)}
      >
        <span className={classes.icon}>
          <FilledFileIcon style={{ color: getNoteTypeColor(type, theme) }} />
        </span>
        <div style={{ width: '100%' }}>
          <FlexBetween>
            {expanded ? (
              <Fade in={expanded}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: htmlContent,
                  }}
                />
              </Fade>
            ) : (
              <NotesField
                label='Note'
                source='text'
                typographyProps={{ variant }}
                record={{ ...note }}
              />
            )}

            <ExpandIcon
              onClick={() => {
                setExpandedNoteId(expanded ? null : id);
              }}
              style={{
                cursor: 'pointer',
                marginBottom: 8,
                marginLeft: 8,
                fontSize: 16,
              }}
            />
          </FlexBetween>
          <div className={classes.listItemSubText}>
            <span>
              {editedOn ? (
                <>
                  <Typography
                    variant={'caption'}
                    component={authorInfoMultiLine && 'p'}
                  >
                    Created on {formatDate(createdOn)}
                  </Typography>
                  {!authorInfoMultiLine && ' · '}
                  <Typography variant={'caption'}>
                    Last edited by {editedByFirstName} on {formatDate(editedOn)}
                  </Typography>
                </>
              ) : (
                <Typography variant={'caption'}>
                  By {editedByFirstName} on {formatDate(createdOn)}
                </Typography>
              )}
            </span>
            <FlexColumn>
              <Box
                className={clsx(
                  classes.noteActions,
                  showNoteActions ? classes.showNoteActions : undefined,
                )}
                textAlign={'right'}
              >
                <CopyOutlinedIcon
                  onClick={() => {
                    copyToClipboard(htmlContent, text);
                  }}
                  style={{ cursor: 'pointer' }}
                />

                <EditIcon
                  onClick={() => setEditId(id)}
                  style={{ cursor: 'pointer', marginLeft: 7 }}
                />
                {setNoteToDelete && typeof setNoteToDelete === 'function' && (
                  <DeleteIcon
                    onClick={() => setNoteToDelete(id)}
                    style={{ cursor: 'pointer', marginLeft: 7 }}
                  />
                )}
              </Box>

              <Typography component={'span'} textAlign={'right'}>
                In{' '}
                <span style={{ color: getNoteTypeColor(type, theme) }}>
                  {typeFriendlyName}
                </span>
              </Typography>
            </FlexColumn>
          </div>
        </div>
      </div>
      <Divider />
    </Fragment>
  );
}
