import React from 'react';

export function ApplicationRenewalIcon(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      width='18'
      height='20'
      viewBox='0 0 18 20'
      className='customSvgIcon'
      {...props}
    >
      <defs>
        <path
          id='path-1'
          d='M16 2h-4.18C11.4.84 10.3 0 9 0 7.7 0 6.6.84 6.18 2H2C.9 2 0 2.9 0 4v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zM9 2c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm2.828 7.175A3.979 3.979 0 009.003 8a3.995 3.995 0 00-3.995 4 3.991 3.991 0 007.86 1h-1.04c-.41 1.165-1.52 2-2.825 2-1.655 0-3-1.345-3-3s1.345-3 3-3c.83 0 1.57.345 2.11.89l-1.61 1.61h3.5V8l-1.175 1.175z'
        ></path>
      </defs>
      <g fill='none' fillRule='evenodd' stroke='none' strokeWidth='1'>
        <g>
          <mask id='mask-2' fill='#fff'>
            <use xlinkHref='#path-1'></use>
          </mask>
          <g fill={props.fill} mask='url(#mask-2)'>
            <path d='M0 0H50V50H0z' transform='translate(-16 -15)'></path>
          </g>
        </g>
      </g>
    </svg>
  );
}
