import React, { useState } from 'react';
import { Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { InlineTextEdit } from './InlineTextEdit';

export function PreviewItem({ Icon, text, editProps, tooltipText = '' }) {
  const classes = makeStyles((theme) => ({
    previewItem: {
      color: theme.palette.text.secondary,
      fontSize: 14,
      display: 'flex',
      alignItems: 'center',
      marginRight: 34,
    },
    icon: {
      marginRight: 4,
      fontSize: 20,
    },
    textHover: {
      padding: 4,
      borderRadius: 3,
      '&:hover': {
        backgroundColor: '#E5E7F5',
      },
    },
  }))();

  const {
    readOnly = false,
    textInput = false,
    dateInput = false,
    onSave,
    value = '',
    inputProps,
  } = editProps;
  const [editingText, setEditingText] = useState(false);

  return (
    <div className={classes.previewItem}>
      {Icon && <Icon className={classes.icon} />}
      {readOnly ? (
        <Tooltip title={tooltipText}>
          <span>{text}</span>
        </Tooltip>
      ) : textInput || dateInput ? (
        <InlineTextEdit
          setEditing={setEditingText}
          editing={editingText}
          onSave={onSave}
          value={value}
          dateInput={dateInput}
          renderPreview={() => (
            <Tooltip title={`${tooltipText} click to edit`}>
              <span
                className={classes.textHover}
                onClick={() => setEditingText(true)}
              >
                {text}
              </span>
            </Tooltip>
          )}
          inputProps={inputProps}
        />
      ) : null}
    </div>
  );
}
