import { uiActions } from '..';
import { authPost, authGet, authPatch } from '../../lib';

export const applicationActions = {
  upsertApplication({ payload }) {
    return async (dispatch) => {
      const response = await authPost(`/Applications`, payload);
      const { data, error } = response;
      if (error) {
        dispatch(
          uiActions.showError({
            message: error.message || 'Something went wrong',
          }),
        );
        return { error };
      }
      return { data };
    };
  },
  getApplicationById({ applicationId, path, query = {} }) {
    return async (dispatch) => {
      const response = await authGet([
        `/Applications/${applicationId}/${path}`,
        query,
      ]);
      const { data, error } = response;
      if (error) {
        return { error };
      }
      return { data };
    };
  },
  patchApplication({ applicationId, caseId, payload }) {
    return async (dispatch) => {
      const response = await authPatch(
        [`/Applications/${applicationId}`, { caseId }],
        payload,
      );
      const { data, error } = response;
      if (error) {
        dispatch(
          uiActions.showError({
            message: error.message || 'Something went wrong',
          }),
        );
        return { error };
      }
      dispatch(
        uiActions.showNotification({
          message: 'Changes Saved',
          variant: 'success',
        }),
      );
      return { data };
    };
  },
  updateApplicationAssignees({ applicationId, payload }) {
    return async (dispatch) => {
      const response = await authPost(
        [`/Applications/${applicationId}/assignees`],
        payload,
      );
      const { data, error } = response;
      if (error) {
        dispatch(
          uiActions.showError({
            message: error.message || 'Something went wrong',
          }),
        );
        return { error };
      }
      dispatch(
        uiActions.showNotification({
          message: 'Changes Saved',
          variant: 'success',
        }),
      );
      return { data };
    };
  },
  getTimeline(applicationId) {
    return async (dispatch) => {
      const response = await authGet([
        `/Applications/${applicationId}/timeline`,
      ]);
      const { data, error } = response;
      if (error) {
        dispatch(
          uiActions.showError({
            message:
              error.message || 'Application timeline could not be retrieved',
          }),
        );
        return { error };
      }
      return { data };
    };
  },
  getStatusChange(applicationId, id) {
    return async (dispatch) => {
      const response = await authGet([
        `/Applications/${applicationId}/status-change/${id}`,
      ]);
      const { data, error } = response;
      if (error) {
        dispatch(
          uiActions.showError({
            message: error.message || 'Status change data cannot be retrieved',
          }),
        );
        return { error };
      }
      return { data };
    };
  },
  upsertApplicationStatusChange({ payload, caseId, applicationId }) {
    return async (dispatch) => {
      const response = await authPost(
        [`/Applications/${applicationId}/status-change/${payload.id}`],
        payload,
      );
      const { data, error } = response;
      if (error) {
        dispatch(
          uiActions.showError({
            message: error.message || 'Something went wrong',
          }),
        );
        return { error };
      }
      return { data };
    };
  },
};
