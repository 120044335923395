import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme) => ({
    main: {
      display: 'flex',
      minHeight: '100vh',
      justifyContent: 'center',
      [theme.breakpoints.down('lg')]: {
        flexDirection: 'column',
      },
    },
    formSection: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      flex: 1,
      boxSizing: 'border-box',
      maxWidth: 600,
      padding: '0 60px 0 60px',
    },
    formImgSection: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
      background: 'linear-gradient(180deg, #061BEE 0%, #0514B3 100%)',
      color: '#FFFFFF',
      flex: 1,
      '& img': {
        [theme.breakpoints.down('md')]: {
          height: 260,
        },
      },
    },
    imgSpacer: {
      [theme.breakpoints.down('lg')]: {
        height: 48,
      },
    },
    form: {
      width: '100%',
    },
    titleContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    title: {
      fontSize: 28,
      fontWeight: 500,
      marginBottom: 10,
    },
    subTitle: {
      fontSize: 18,
      marginTop: 0,
    },
    smallText: {
      fontSize: 14,
      marginBottom: 25,
      marginTop: 40,
    },
    logo: {
      marginTop: 40,
      [theme.breakpoints.down('lg')]: {
        marginBottom: 20,
      },
    },
    welcome: {
      color: '#000000',
    },
    welcomeSubText: {
      fontSize: 16,
      color: theme.palette.textTheme.main,
      marginTop: 0,
      marginBottom: 40,
    },
    inputSpacer: {
      marginBottom: 32,
    },
    forgotPassword: {
      color: '#003FED',
      fontFamily: 'Roboto',
      fontSize: 14,
      textTransform: 'none',
      padding: 0,
      '&:hover': {
        backgroundColor: 'transparent',
        textDecoration: 'none',
      },
    },
    actions: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
    },
    submitWrapper: {
      [theme.breakpoints.down('lg')]: {
        marginBottom: 20,
      },
    },
    submit: {
      backgroundColor: '#061BEE',
      padding: '8px 34px 8px 34px',
      '&:hover': {
        backgroundColor: '#061BEE',
      },
    },
    bottomText: {
      fontFamily: 'Roboto',
      fontSize: 16,
      [theme.breakpoints.down('lg')]: {
        marginBottom: 30,
      },
    },
    link: {
      color: '#003FED',
      cursor: 'pointer',
      textDecoration: 'none',
    },
    linkDescriptionText: {
      fontSize: 14,
      color: theme.palette.textTheme.main,
      marginTop: 24,
    },
    loader: {
      width: '100%',
      textAlign: 'center',
      paddingTop: 30,
    },
    checkbox: {
      color: theme.palette.outlineTheme.main,
      '&$checkboxChecked': {
        color: '#061BEE',
      },
    },
    checkboxChecked: {},
  }),
  {
    classNamePrefix: 'Auth',
  },
);
