import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  systemSettings: {},
  settings: { listSplitMode: true, scanner: {}, showAlertsListView: false },
  superAdminCompanyHeader: { id: '' },
};

export const systemPersist = createSlice({
  name: 'systemPersist',
  initialState,
  reducers: {
    setSystemSettings(state, action) {
      state.systemSettings = action.payload;
    },
    setPreferredScanner(state, action) {
      state.settings = {
        ...(state.settings || {}),
        scanner: {
          ...(state.settings?.scanner || {}),
          preferredName: action.payload,
        },
      };
    },
    setListSplitMode(state, action) {
      state.settings = {
        ...state.settings,
        listSplitMode: action.payload,
      };
    },
    setShowAlertsListView(state, action) {
      state.settings = {
        ...state.settings,
        showAlertsListView: action.payload,
      };
    },
    setSuperAdminCompanyHeader(state, action) {
      state.superAdminCompanyHeader = { id: action.payload };
    },
  },
});
