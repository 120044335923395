import React from 'react';
import _get from 'lodash.get';
import { Typography } from '@mui/material';

// import { useStyles } from './fields.styles';

/** TextField props.
 * @param {TextFieldProps} props
 */
export function TextField({
  className,
  typographyProps = {},
  source,
  record = {},
  emptyText,
  orderBy,
  ...rest
}) {
  // const classes = useStyles();

  const value = _get(record, source);
  return (
    <Typography
      component='span'
      variant='body2'
      className={className}
      {...typographyProps}
    >
      {value !== null && typeof value !== 'string'
        ? JSON.stringify(value)
        : value || emptyText}
    </Typography>
  );
}

// #region Typedefs
/** @typedef {object} TextFieldProps
 * @property {import("@mui/material/Typography").TypographyProps} [typographyProps]
 * @property {import("react").HTMLAttributes} [className]
 * @property {import("react").HTMLAttributes} [headerClassName] class applied to the header
 * @property {import("csstype").Properties} [cellStyle]
 * @property {string} source
 * @property {string} label
 * @property {string} [emptyText]
 * @property {string} [disablePadding]
 * @property {string} [align]
 * @property {string} [sortBy] Defaults to `source`
 * @property {false} [sortable] Set to false to disable sorting for this column
 */
// #endregion
