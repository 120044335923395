import React, {
  useEffect,
  useRef,
  cloneElement,
  Children,
  isValidElement,
  Fragment,
} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  TableContainer,
  Table,
  TablePagination,
  Paper,
  Tooltip,
  IconButton,
  Typography,
  Toolbar,
} from '@mui/material';
import { listActions, listSelectors, uiSelectors } from '../../../state';
import {
  useGetQueryParams,
  useIsTablet,
  useUpdatePageQueryParams,
} from '../../../hooks';
import { FilterListIcon } from '../../../themes';
import { ListBody } from './ListBody';
import { ListLoading } from './ListLoading';
import { ListToolbar } from './ListToolbar';
import { ListAside } from './ListAside';
import { useStyles } from './list.styles';

/** TextField props.
 * @param {listProps} props
 */
export function List({
  body = <ListBody />,
  hideShadow,
  children,
  rowsPerPage: _rowsPerPage,
  order: _order = 'asc',
  orderBy: _orderBy = 'id',
  tableProps = {},
  headerProps = {},
  rowProps,
  cellProps,
  className,
  customViewVersion,
  // expand,
  filter = {},
  aside,
  title,
  bulkActionButtons,
  // onToggleItem,
  resource,
  baseUrl,
  rowClick,
  mouseEnter,
  mouseLeave,
  rowStyle,
  rowsPerPageOptions,
  hideHeader = false,
  hideFooter = false,
  hideTable = false,
  hasLoader = false,
  actions = <ListActions hideHeader={hideHeader} />,
  showActionsOnLoad = false,
  noResultsMessage = 'No results found',
  flex,
  disableDefaultOrderBy = false,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isTablet = useIsTablet();
  const updatePageQueryParams = useUpdatePageQueryParams();
  const {
    'aside-open': asideOpen,
    rowsPerPage: queryRowsPerPage,
    page: queryPage,
    orderBy: queryOrderBy,
    order: queryOrder,
  } = useGetQueryParams();
  const isSidebarOpen = useSelector(uiSelectors.isSidebarOpen);
  const listLoading = useSelector(uiSelectors.listLoading);
  const reduxViewVersion = useSelector(uiSelectors.globalViewVersion); // needed to refresh on edit
  const viewVersion = customViewVersion ?? reduxViewVersion;
  const listRedux = useSelector(
    (state) => listSelectors.listByResource(state, resource),
    shallowEqual,
  );

  const {
    ids = [],
    data: rows = {},
    params: {
      hasList,
      order,
      orderBy,
      page,
      rowsPerPage,
      numberOfRows,
      selectedIds = [],
    } = {},
  } = listRedux || {};

  const isFirstRender = useRef(true);

  useEffect(() => {
    if (!rowsPerPage) {
      const defaultRowsPerPage =
        _rowsPerPage || Array.isArray(rowsPerPageOptions)
          ? rowsPerPageOptions[0]
          : 50;

      dispatch(
        listActions.updateListParams({
          list: resource,
          params: {
            order: queryOrder || _order,
            orderBy: queryOrderBy || (disableDefaultOrderBy ? null : _orderBy),
            rowsPerPage: queryRowsPerPage
              ? +queryRowsPerPage
              : defaultRowsPerPage,
            page: queryPage ? +queryPage - 1 : 0,
          },
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    _order,
    _orderBy,
    _rowsPerPage,
    dispatch,
    resource,
    rowsPerPage,
    rowsPerPageOptions,
  ]);

  // `filter` is causing the useEffect to render too many times.
  // See https://github.com/kentcdodds/use-deep-compare-effect
  useDeepCompareEffect(() => {
    // reset page if filter changes
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    if (page > 0) {
      dispatch(
        listActions.updateListParams({
          list: resource,
          params: {
            page: 0,
          },
        }),
      );
    } else {
      dispatch(
        listActions.fetchList({
          resource,
          baseUrl,
          order,
          orderBy,
          page,
          rowsPerPage,
          filter,
          noPagination: hideFooter,
          hasLoader,
        }),
      );
    }
  }, [filter]);

  useEffect(() => {
    const orderValue = !hideFooter ? order : undefined;
    const orderByValue = !hideFooter ? orderBy : undefined;
    const pageValue = !hideFooter ? +page + 1 : undefined;
    const rowsPerPageValue = !hideFooter ? rowsPerPage : undefined;
    dispatch(
      listActions.fetchList({
        resource,
        baseUrl,
        order,
        orderBy,
        page,
        rowsPerPage,
        filter,
        noPagination: hideFooter,
        hasLoader,
      }),
    );
    updatePageQueryParams({
      order: orderValue,
      orderBy: orderByValue,
      page: pageValue,
      rowsPerPage: rowsPerPageValue,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    baseUrl,
    order,
    orderBy,
    page,
    resource,
    rowsPerPage,
    viewVersion,
    hasLoader,
  ]);

  const handleChangePage = (event, newPage) => {
    dispatch(
      listActions.updateListParams({
        list: resource,
        params: { page: newPage },
      }),
    );
    updatePageQueryParams({ page: +newPage + 1 });
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(
      listActions.updateListParams({
        list: resource,
        params: { page: 0, rowsPerPage: parseInt(event.target.value, 10) },
      }),
    );
    updatePageQueryParams({
      page: 1,
      rowsPerPage: parseInt(event.target.value, 10),
    });
  };

  const loader = listLoading && !hasList && (
    <ListLoading
      classes={classes}
      className={className}
      hasBulkActions={bulkActionButtons !== false}
      nbChildren={Children.count(children)}
      size={tableProps.size || isTablet ? 'small' : 'medium'}
    />
  );
  return !showActionsOnLoad && loader ? (
    loader
  ) : (
    <div
      className={classes.root}
      style={
        hideTable
          ? { display: 'none' }
          : flex && ids?.length
          ? { display: 'flex' }
          : {}
      }
    >
      {isValidElement(actions) &&
        cloneElement(actions, {
          resource,
          title,
          classes,
          rows,
          ids,
          selectedIds,
          loading: listLoading,
        })}
      <div
        style={{
          boxShadow: hideShadow
            ? 'none'
            : '0 2px 12px 0 #F5F6FE, 0 2px 8px 0 #D5D8EC',
        }}
      >
        {showActionsOnLoad && loader ? (
          loader
        ) : (
          <>
            <Paper
              className={clsx(
                classes.paper,
                { [classes.asideOpen]: !!asideOpen },
                className,
              )}
              elevation={0}
            >
              <ListToolbar
                numSelected={selectedIds.length}
                ids={ids}
                rows={rows}
                selectedIds={selectedIds}
                bulkActionButtons={bulkActionButtons}
              />
              {ids?.length ? (
                <Fragment>
                  <TableContainer
                    classes={{
                      root: clsx(classes.tableContainer, {
                        [classes.tableContainerSidebarOpen]: isSidebarOpen,
                      }),
                    }}
                  >
                    <Table
                      aria-labelledby='tableTitle'
                      aria-label={'enhanced table'}
                      size={tableProps.size || isTablet ? 'small' : 'medium'}
                      {...tableProps}
                    >
                      {cloneElement(
                        body,
                        {
                          rowProps,
                          cellProps,
                          rowClick,
                          mouseEnter,
                          mouseLeave,
                          rows,
                          ids,
                          hasBulkActions: bulkActionButtons !== false,
                          // onToggleItem,
                          resource,
                          rowStyle,
                          selectedIds,
                          rowsPerPage,
                          page,
                          classes,
                          numSelected: selectedIds.length,
                          headerProps,
                          order,
                          orderBy,
                          rowCount: ids.length,
                          updateListParams: listActions.updateListParams,
                          bulkActionButtons,
                          hideHeader,
                        },
                        children,
                      )}
                    </Table>
                  </TableContainer>
                  {!hideFooter && (
                    <TablePagination
                      rowsPerPageOptions={rowsPerPageOptions || [10, 25, 50]}
                      component='div'
                      count={numberOfRows}
                      rowsPerPage={rowsPerPage || 10}
                      page={page || 0}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  )}
                </Fragment>
              ) : (
                <Typography variant='body2' style={{ padding: 24 }}>
                  {noResultsMessage}
                </Typography>
              )}
            </Paper>
            {isValidElement(aside) &&
              cloneElement(<ListAside />, { rows, ids }, aside)}
          </>
        )}
      </div>
    </div>
  );
}

function ListActions({ title, resource, hideHeader }) {
  const classes = useStyles();
  if (hideHeader) return null;

  return (
    <Toolbar className={classes.actions}>
      <Typography
        className={classes.title}
        variant='h6'
        id='tableTitle'
        component='div'
      >
        {title || resource}
      </Typography>
      <Tooltip title='Filter list'>
        <IconButton aria-label='filter list' size='large'>
          <FilterListIcon />
        </IconButton>
      </Tooltip>
    </Toolbar>
  );
}

// #region Typedefs
/** @typedef {object} listProps
 * @property {import("react").Component} [body] To render a custom list
 * @property {import("react").Component} [actions] A component that will render the header. Pass in `null` to hide the default header
 * @property {import("react").Component} [aside] A component that will render the aside
 * @property {import("react").Component|false} [bulkActionButtons] A component that will render the the actions when the checkbox on the row is checked. Leave blank to get the default component. `False` to hide the checkboxes
 * @property {import("react").ReactNode} children
 * @property {import("react").HTMLAttributes} [className]
 * @property {number} [rowsPerPage]
 * @property {Object} [filter] An object to filter the api call
 * @property {"asc"|"desc"} [order] The default order
 * @property {string} [orderBy] The default property to order by
 * @property {string} [title] The display title. Only use if not providing an `actions` component
 * @property {string} resource The name of the resource. This will be used as the URL for api calls if `baseUrl` is not provided ```NOTE You must register the resource in registerLists.js```
 * @property {string} [baseUrl] The api URL if different then the resource name
 * @property {number[]} [rowsPerPageOptions]
 * @property {(id, record) =>} [rowClick] A function when the row is clicked
 * @property {(id, record) =>} [mouseEnter] A function when the row is hovered
 * @property {(id, record) =>} [mouseLeave] A function when mouse is leaving the row
 * @property {(id, record) =>} [rowStyle] A function to style a row
 * @property {import("@mui/material/Table").TableProps} [tableProps]
 * @property {import("@mui/material/TableHead").TableHeadProps} [headerProps]
 * @property {import("@mui/material/TableRow").TableRowProps} [rowProps]
 * @property {import("@mui/material/TableCell").TableCellProps} [cellProps]
 * @property {bool} [hideHeader]
 * @property {bool} [hideFooter]
 * @property {string} [noResultsMessage] The message to display when there are no records in the list
 * @property {string} [customViewVersion] custom view version to use instead of the global one to trigger the list to refresh.
 */
// #endregion

List.propTypes = {
  body: PropTypes.element,
  children: PropTypes.node,
  rowsPerPage: PropTypes.number,
  order: PropTypes.oneOf(['asc', 'desc']),
  orderBy: PropTypes.string,
  tableProps: PropTypes.objectOf(import('@mui/material/Table').TableProps),
  headerProps: PropTypes.objectOf(
    import('@mui/material/TableHead').TableHeadProps,
  ),
  rowProps: PropTypes.objectOf(import('@mui/material/TableRow').TableRowProps),
  cellProps: PropTypes.objectOf(
    import('@mui/material/TableCell').TableCellProps,
  ),
  className: PropTypes.oneOfType([
    PropTypes.objectOf(import('react').HTMLAttributes),
    PropTypes.string,
  ]),
  filter: PropTypes.object,
  actions: PropTypes.element,
  aside: PropTypes.element,
  title: PropTypes.string,
  bulkActionButtons: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.oneOf([false]),
  ]),
  resource: PropTypes.string.isRequired,
  baseUrl: PropTypes.string,
  rowClick: PropTypes.func,
  mouseEnter: PropTypes.func,
  mouseLeave: PropTypes.func,
  rowStyle: PropTypes.func,
  rowsPerPageOptions: PropTypes.array,
  hideHeader: PropTypes.bool,
  hideFooter: PropTypes.bool,
};
