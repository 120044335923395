import React from 'react';
import { IconButton, Box, Dialog } from '@mui/material';
import { CloseIcon } from '../../themes';
import { PaperComponent } from '../modals/PaperComponent';
import { useStyles } from '../modals/formModal.styles';
import { FlexBetween } from '../ui';

export function AnnouncementPopup({
  open,
  handleClose,
  subjectHtmlContent,
  bodyHtmlContent,
}) {
  const classes = useStyles();

  if (!bodyHtmlContent && !subjectHtmlContent) {
    return null;
  }

  return (
    <Dialog open={open} fullWidth={true} PaperComponent={PaperComponent}>
      <div className={classes.scrollableContent}>
        <FlexBetween pr={1}>
          <Box
            sx={{
              pl: 4,
              pr: 2,
              cursor: 'move',
              fontSize: 18,
              fontWeight: 600,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              '& a': {
                color: '#096EF8',
                textDecoration: 'none',
              },
            }}
            id='draggable-dialog'
          >
            <div
              dangerouslySetInnerHTML={{
                __html: subjectHtmlContent,
              }}
            />
          </Box>
          <IconButton onClick={handleClose} size='large'>
            <CloseIcon />
          </IconButton>
        </FlexBetween>

        <Box
          sx={{
            px: 4,
            pb: 4,
            pt: 1,
            '& a': {
              color: '#096EF8',
              textDecoration: 'none',
            },
          }}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: bodyHtmlContent,
            }}
          />
        </Box>
      </div>
    </Dialog>
  );
}
