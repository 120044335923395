import React, { useEffect, useState } from 'react';
import FilterBase from './FilterBase';
import { TextInput } from '../../../../components';

export default function TextInputFilter({
  name,
  value,
  onChange,
  handleClose,
  endAdornment,
}) {
  const [textVal, setTextVal] = useState(value);

  //reset when value changes - handle clear all
  useEffect(() => {
    setTextVal(value);
  }, [value, setTextVal]);

  return (
    <FilterBase
      content={
        <TextInput
          fullWidth
          name={name}
          onChange={(e) => setTextVal(e.target.value)}
          value={textVal}
          endAdornment={endAdornment}
        />
      }
      onChange={() => onChange(textVal)}
      handleClose={handleClose}
    />
  );
}
