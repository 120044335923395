import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';
import { PaperComponent } from './PaperComponent';
import { useStyles } from './formModal.styles';

export function DeleteConfirmation({
  open,
  text,
  title,
  handleClose,
  handleDelete,
  btnText = 'Delete',
}) {
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      aria-labelledby='form-dialog-title'
      fullWidth
      disableScrollLock
      PaperComponent={PaperComponent}
      PaperProps={{ size: 'xs' }}
    >
      <DialogTitle style={{ cursor: 'move' }} id='draggable-dialog'>
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText color='textPrimary'>{text}</DialogContentText>
        <DialogActions>
          <Button onClick={handleDelete} className={classes.deleteBtn}>
            {btnText}
          </Button>
          <Button
            color='primary'
            variant='contained'
            className={classes.cancelBtn}
            onClick={handleClose}
          >
            Cancel
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}
