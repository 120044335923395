import { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, IconButton, Typography, useTheme } from '@mui/material';
import { Chip, CustomTooltip, FlexCenter } from '../../../../components';
import { AddIcon, LabelIcon } from '../../../../themes';
import { ApplicationTagsInput } from './ApplicationTagsInput';
import { useGetUsersData } from '../../../../hooks';
import { generatePath, PermissionClaims } from '../../../../lib';
import { uiActions, uiSelectors } from '../../../../state';
import { Link } from 'react-router-dom';
import { Routes, dataTooltipStyles } from '../../../../lib';

export function ApplicationTag({ tag }) {
  const theme = useTheme();
  return (
    <Box mr={1}>
      <Chip
        label={tag.name}
        labelVariant='label'
        color={theme.palette.text.secondary}
      />
    </Box>
  );
}

export function ManageApplicationTags({
  currentTags,
  applicationId,
  updateTags,
}) {
  const dispatch = useDispatch();
  const theme = useTheme();

  const { PermissionClaim = [] } = useGetUsersData();
  const tagOptions = useSelector(uiSelectors.applicationTagOptions);

  const tagsButtonRef = useRef(null);
  const [tagsAnchorEl, setTagsAnchorEl] = useState(null);

  const hasPermissionToCreateTags = PermissionClaim.includes(
    PermissionClaims.CompanyApplicationTagEditClaim,
  );
  const hasCompanyTagsSetUp = !!tagOptions.length;

  useEffect(() => {
    dispatch(uiActions.getApplicationTagOptions());
  }, [dispatch]);

  return hasCompanyTagsSetUp || hasPermissionToCreateTags ? (
    <>
      <FlexCenter mr={4.25}>
        <LabelIcon sx={{ mr: 0.5, fontSize: 20 }} />
        {currentTags.length ? (
          currentTags.map((t, i) => <ApplicationTag tag={t} key={i} />)
        ) : (
          <Typography variant='label' color='text.secondary' mr={1}>
            Application labels
          </Typography>
        )}

        <CustomTooltip
          componentsProps={hasCompanyTagsSetUp ? undefined : dataTooltipStyles}
          title={
            hasCompanyTagsSetUp ? (
              'Manage labels'
            ) : (
              <Typography>
                No labels found. Go to the{' '}
                <Link
                  style={{
                    color: theme.palette.primary.dark,
                    textDecoration: 'none',
                  }}
                  to={generatePath(
                    Routes.adminSettings.path,
                    {},
                    { view: 'applicationTags' },
                  )}
                >
                  settings page
                </Link>{' '}
                to set them up
              </Typography>
            )
          }
        >
          <IconButton
            onClick={() => setTagsAnchorEl(tagsButtonRef.current)}
            ref={tagsButtonRef}
            sx={{
              border: '1px dashed #B9BDD4',
              borderRadius: '3px',
              padding: 0,
              height: '21px',
              width: '21px',
            }}
            disabled={tagOptions.length < 1}
          >
            <AddIcon fontSize='xsmall' />
          </IconButton>
        </CustomTooltip>
      </FlexCenter>
      <ApplicationTagsInput
        applicationId={applicationId}
        initialTags={currentTags}
        anchorEl={tagsAnchorEl}
        setAnchorEl={setTagsAnchorEl}
        open={Boolean(tagsAnchorEl)}
        updateTags={updateTags}
        tagOptions={tagOptions}
      />
    </>
  ) : null;
}
