import React from 'react';
import { Link } from 'react-router-dom';
import { Link as MuiLink } from '@mui/material';

/**
 * `Material-UI` link with `react-router-dom` routing using our pages data
 * structure.
 * @param {import("react-router-dom").LinkProps & import("@mui/material/Link").LinkProps} props
 */
export function MuiPageLink(props) {
  const { children, ...rest } = props;
  return (
    <MuiLink component={Link} underline='none' {...rest}>
      {children}
    </MuiLink>
  );
}
