import { useNavigate } from 'react-router-dom';
import {
  Avatar as MuiAvatar,
  Button,
  Tooltip,
  Typography,
  useTheme,
  Fade,
} from '@mui/material';
import {
  NotificationsIcon,
  DoneIcon,
  UnfoldLessIcon,
  UnfoldMoreIcon,
} from '../../../themes';
import {
  formatDate,
  generatePath,
  getTodoPriorityColor,
  PermissionClaims,
  ResidentCaseViews,
  Routes,
} from '../../../lib';
import { useStyles } from './alerts.styles';
import {
  Avatar,
  ColoredIcon,
  FlexCenter,
  FlexColumn,
} from '../../../components';

const dateChipStyles = {
  marginLeft: 1,
  fontSize: 12,
  fontWeight: 500,
  borderRadius: '3px',
  height: 24,
  display: 'flex',
  alignItems: 'center',
  paddingLeft: 0.5,
  paddingRight: 0.5,
  whiteSpace: 'nowrap',
};

export default function AlertsListViewItem({
  todo,
  disableFromMarkingOtherTasks,
  handleUndo,
  permissionClaim,
  residentPage,
  isDone,
  setCurrentItem,
  setEditId,
  setShowNote,
  userId,
  expandedTaskId,
  setExpandedTaskId,
}) {
  const {
    canDismiss,
    caseID,
    casePersonID,
    categoryDisplay,
    dateDue,
    dismissedOn,
    dismissedNote,
    doneOn,
    id,
    personFirstName,
    personLastName,
    taskAssignedToID,
    taskAssignedToName,
    title,
    todoType,
    priorityType,
    taskDetailsNote,
  } = todo;

  const classes = useStyles();
  const navigate = useNavigate();
  const theme = useTheme();

  const hasNameColumn = casePersonID && !residentPage;
  const expanded = expandedTaskId === id;
  const ExpandIcon = expanded ? UnfoldLessIcon : UnfoldMoreIcon;

  return (
    <span
      onClick={() => todoType === 'UserGeneratedTask' && setEditId(id)}
      style={{
        borderTop: '1px solid rgba(0, 0, 0, 0.12)',
        cursor: todoType === 'UserGeneratedTask' ? 'pointer' : '',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'start',
        paddingTop: 16,
        paddingBottom: 16,
      }}
    >
      <span
        style={{
          display: 'grid',
          gridTemplateColumns: hasNameColumn ? '216px 1fr' : '32px 1fr',
        }}
      >
        <span style={{ display: 'flex', alignItems: 'start', gap: 8 }}>
          {isDone ? (
            <MuiAvatar
              sx={{
                backgroundColor: '#0FC076',
                height: 20,
                width: 20,

                border: '2px solid #0FC076',
              }}
            >
              <ColoredIcon Icon={DoneIcon} iconColor='#FFF' fontSize='small' />
            </MuiAvatar>
          ) : (
            ((todoType === 'SystemGeneratedAlert' && canDismiss) ||
              (todoType === 'UserGeneratedTask' &&
                (!disableFromMarkingOtherTasks ||
                  taskAssignedToID.toString() === userId))) && (
              <Tooltip
                placement='bottom-start'
                title={`${
                  todoType === 'SystemGeneratedAlert'
                    ? 'Dismiss alert'
                    : 'Mark task done'
                }`}
              >
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    setCurrentItem(todo);
                  }}
                  sx={{
                    border: '1px solid #B9BDD4',
                    borderRadius: '100%',
                    height: 20,
                    width: 20,
                    minWidth: 'unset',
                  }}
                ></Button>
              </Tooltip>
            )
          )}

          {hasNameColumn && (
            <Typography
              onClick={() =>
                navigate(
                  generatePath(
                    Routes.caseDetails.path,
                    { id: caseID },
                    { view: ResidentCaseViews.Todos },
                  ),
                  { state: { search: window.location.search } },
                )
              }
              sx={{
                cursor: 'pointer',
                fontWeight: 500,
                transition: 'opacity 300ms linear',
                '&:hover': { opacity: 0.5 },
              }}
            >
              {`${personFirstName} ${personLastName}`}
            </Typography>
          )}
        </span>
        <FlexColumn>
          <span style={{ marginBottom: expanded ? 8 : 0 }}>
            {categoryDisplay && <span>{categoryDisplay}: &nbsp;</span>}
            <span style={{ marginRight: 0, color: '#000000' }}>{title}</span>
          </span>
          {expanded && (
            <Fade in={expanded}>
              <div
                dangerouslySetInnerHTML={{
                  __html: taskDetailsNote.htmlContent,
                }}
              />
            </Fade>
          )}
        </FlexColumn>
      </span>
      <span
        style={{
          display: 'grid',
          gridTemplateColumns: 'auto 26px',
          alignItems: 'center',
          marginLeft: 8,
        }}
      >
        <FlexCenter component='span'>
          {taskAssignedToID && (
            <span>
              <Avatar
                color={theme.palette.primary.light}
                className={classes.avatar}
                name={taskAssignedToName || ''}
                typographyProps={{ fontSize: 10, fontWeight: 500 }}
                tooltip
              />
            </span>
          )}
          {todoType === 'SystemGeneratedAlert' && (
            <NotificationsIcon
              sx={{
                color: '#B9BDD4',
                fontSize: 20,
                marginRight: 0.25,
              }}
            />
          )}
          {doneOn || dismissedOn ? (
            <Typography
              sx={{
                backgroundColor: `rgba(0, 0, 0, .12)`,
                color: '#000',
                ...dateChipStyles,
              }}
            >
              {todoType === 'SystemGeneratedAlert' &&
                `Dismissed ${formatDate(dismissedOn, 'MM/dd', true)}`}
              {todoType === 'UserGeneratedTask' &&
                `Done ${formatDate(doneOn, 'MM/dd', true)}`}
            </Typography>
          ) : (
            <Typography
              sx={{
                backgroundColor: `${
                  getTodoPriorityColor(priorityType).backgroundColor
                }`,
                color: `${getTodoPriorityColor(priorityType).color}`,
                ...dateChipStyles,
              }}
            >
              Due {formatDate(dateDue, 'MM/dd')}
            </Typography>
          )}
          {doneOn &&
            permissionClaim.includes(PermissionClaims.CompanyEditClaim) && (
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  handleUndo(todo);
                }}
                sx={{
                  fontSize: 12,
                  color: 'primary.light',
                }}
              >
                Undo
              </Button>
            )}
          {dismissedNote && dismissedNote !== '' && (
            <Button
              onClick={() => {
                setCurrentItem(todo);
                setShowNote(true);
              }}
              sx={{
                fontSize: 12,
                color: 'primary.light',
              }}
            >
              Reason
            </Button>
          )}
        </FlexCenter>

        {taskDetailsNote?.htmlContent && (
          <ExpandIcon
            onClick={(e) => {
              e.stopPropagation();
              setExpandedTaskId(expanded ? null : id);
            }}
            style={{
              cursor: 'pointer',
              marginLeft: 8,
              fontSize: 18,
            }}
          />
        )}
      </span>
    </span>
  );
}
