import React, { useState, useCallback, useEffect, useMemo } from 'react';
import clsx from 'clsx';
import { TextField, MenuItem, Typography, Avatar } from '@mui/material';
import { reach } from 'yup';
import { CustomCheckbox } from './CheckboxInput';
import { useStyles } from './inputs.styles';
import { ClearIcon } from '../../../themes';

/**
 * Material-ui TextField with validation TextInput
 * @param {import("@mui/material/TextField").TextFieldProps & import("@mui/material/Select").SelectProps & selectInputProps}
 */
export function SelectInput({
  menuItems = [],
  menuItemsGroupBy,
  schema,
  nestedKey,
  referenceInputProps = {},
  labelProps = {},
  SelectProps = {},
  ...props
}) {
  const {
    className,
    placeholder,
    fullWidth,
    onChange,
    onClear, //will have a 'x' icon to clear using this function
    clearable, //will add a blank option
    onSelectAll,
    onSelectOnly,
    onBlur,
    error,
    context,
    addHelperTextStyle = true,
    alphabetize = false, //if true, sort the options alphabetically
    ...otherProps
  } = props;
  const { hasMore, setPage, shouldFetchMore } = referenceInputProps;

  const classes = useStyles();
  const [isDirty, setIsDirty] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [valueObj, setValueObj] = useState({});

  const menuItemsSorted = useMemo(
    () =>
      alphabetize
        ? [...menuItems].sort((m1, m2) => m1.name.localeCompare(m2.name))
        : menuItems,
    [menuItems, alphabetize],
  );

  useEffect(() => {
    if (SelectProps.multiple) {
      const objById = menuItemsSorted.reduce((acc, cur) => {
        const { enumValue, id, name } = cur;
        const value = enumValue || id;
        acc[value + ''] = name;
        return acc;
      }, {});
      setValueObj(objById);
    }
  }, [menuItemsSorted, SelectProps.multiple]);

  useEffect(() => {
    // this is for when the validation is based of of another field
    // and the values of the context is true/false for example the pasRequested field on facilityIntake
    if (context) {
      const valuesArr = Object.keys(context).reduce((acc, cur) => {
        acc.push(context[cur]);
        return acc;
      }, []);
      if (valuesArr.every((v) => v === false)) {
        setErrorMessage(undefined);
      }
    }
  }, [context]);

  const handleBlur = useCallback(
    (event) => {
      typeof onBlur === 'function' && onBlur(event);
      if (schema) {
        const { name, value } = event.target;
        setIsDirty(true);
        reach(schema, nestedKey || name)
          .validate(value, { context })
          .then(() => setErrorMessage(false))
          .catch((err) => setErrorMessage(err.errors?.[0]));
      }
    },
    [context, nestedKey, onBlur, schema],
  );

  const handleChange = useCallback(
    (event) => {
      const { name, value } = event.target;
      if (onSelectAll && value?.indexOf('all') >= 0) {
        return onSelectAll(event);
      }
      typeof onChange === 'function' && onChange(event);
      if (schema && isDirty) {
        reach(schema, nestedKey || name)
          .validate(value, { context })
          .then(() => setErrorMessage(false))
          .catch((err) => setErrorMessage(err.errors?.[0]));
      }
    },
    [context, isDirty, nestedKey, onChange, onSelectAll, schema],
  );

  return (
    <TextField
      select
      variant='outlined'
      className={clsx({ [classes.root]: !fullWidth }, className)}
      fullWidth={fullWidth}
      onBlur={handleBlur}
      onChange={handleChange}
      error={!!errorMessage || (errorMessage !== false && !!error)}
      helperText={errorMessage !== false ? errorMessage || error : undefined}
      FormHelperTextProps={{
        classes: { root: clsx({ [classes.helperText]: addHelperTextStyle }) },
      }}
      InputProps={{
        classes: {
          root: classes.inputRoot,
          focused: classes.focused,
          disabled: classes.disabled,
          error: classes.error,
          input: classes.input,
          notchedOutline: classes.notchedOutline,
        },
        endAdornment:
          onClear && otherProps.value?.toString().length ? (
            <ClearIcon
              onClick={onClear}
              style={{
                color: 'rgba(0, 0, 0, 0.54)',
                cursor: 'pointer',
                fontSize: 14,
                marginRight: 18,
              }}
            />
          ) : undefined,
      }}
      InputLabelProps={{
        classes: {
          root: classes.label,
          focused: classes.focused,
          error: classes.error,
          disabled: classes.disabled,
        },
        ...labelProps,
      }}
      SelectProps={{
        renderValue: SelectProps.multiple
          ? (selected) => selected.map((v) => valueObj[v]).join(', ')
          : undefined,
        ...SelectProps,
      }}
      {...otherProps}
    >
      {placeholder && (
        <MenuItem value='' disabled>
          {placeholder}
        </MenuItem>
      )}
      {clearable && <MenuItem value=''>{'---'}</MenuItem>}
      {onSelectAll && (
        <MenuItem key='all' value='all' className={classes.menuItems}>
          <CustomCheckbox
            checked={otherProps.value.length === menuItems.length}
          />
          Select all
        </MenuItem>
      )}

      {menuItemsSorted.map((m, i) => {
        const { enumValue, id, name, isResetOption } = m;
        const value = enumValue || id;
        const showDivider =
          menuItemsGroupBy &&
          (i < 1 ||
            m[menuItemsGroupBy] !== menuItemsSorted[i - 1][menuItemsGroupBy]);
        const multiChecked =
          SelectProps.multiple && otherProps.value.indexOf(value) > -1;
        const showSelectOnly =
          onSelectOnly && multiChecked && otherProps.value.length > 1;

        const menuItem = (
          <MenuItem key={value} value={value} className={classes.menuItems}>
            {SelectProps.multiple && !isResetOption && (
              <CustomCheckbox checked={multiChecked} />
            )}
            {SelectProps.avatar && m.avatar && (
              <Avatar className={clsx(classes.avatar, m.avatar.className)}>
                {m.avatar.display}
              </Avatar>
            )}
            {/* <ListItemText primary={name} /> */}
            {name}
            {showSelectOnly && (
              <div className={classes.selectOnlyLink}>
                <span
                  onClick={(e) => {
                    e.stopPropagation();
                    onSelectOnly(value);
                  }}
                >
                  only
                </span>
              </div>
            )}
          </MenuItem>
        );

        if (showDivider) {
          return [
            <div className={classes.menuItemsHeader}>
              {m[menuItemsGroupBy]}
            </div>,
            menuItem,
          ];
        }

        return menuItem;
      })}
      {shouldFetchMore && (
        <MenuItem value={null} disabled={!hasMore}>
          <Typography
            color='primary'
            onClick={() => {
              setPage((p) => p + 1);
            }}
          >
            View More
          </Typography>
        </MenuItem>
      )}
    </TextField>
  );
}

// #region Typedefs
/** @typedef {object} selectInputProps
 * @property {Object} [context] An object with the name and value of the field to use as Yup context
 * @property {import("@mui/material/InputLabel")} labelProps The defined Yup schema
 * @property {Object} schema The defined Yup schema
 * @property {string} [nestedKey] If the value is a nested value provide the path. Example nestedKey='residentContacts.firstName'.
 * @property {string} error The error message when failing validation on submit
 * @property {boolean} [addHelperTextStyle] Boolean to add position absolute to helper text. Defaults to true.
 */
// #endregion
