import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  globalSearchWrapper: {
    width: 420,
    margin: '8px 0',
    [theme.breakpoints.down('md')]: {
      width: 'auto',
    },
    '& input': {
      boxSizing: 'border-box',
      height: 48,
    },
  },
  inputRoot: {
    backgroundColor: '#F5F6FE',
    borderRadius: 3,
    '&$focused': {
      backgroundColor: '#F5F6FE',
    },
    '&:hover': {
      backgroundColor: '#F5F6FE',
    },
    '&$disabled': {
      backgroundColor: '#F5F6FE',
    },
  },
  label: {
    color: theme.palette.text.disabled,
    '&$focused': {
      color: theme.palette.text.disabled,
    },
  },
  disabled: {},
  error: {},
  focused: {},
  input: {},
  searchPaper: {
    minWidth: 660,
    padding: 16,
  },
  popoverPaper: {
    border: 'solid rgba(0, 0, 0, 0.12) 1px',
    boxShadow:
      '0px 5px 5px -3px rgb(0 0 0 / 0%), 0px 1px 1px 1px rgb(0 0 0 / 0%), 0px 3px 14px 2px rgb(0 0 0 / 5%)',
  },
  notchedOutline: {},
  outlinedRoot: {
    '& $notchedOutline': {
      borderColor: theme.palette.outlineTheme.main,
    },
    '&:hover $notchedOutline': {
      borderColor: theme.palette.outlineTheme.main,
    },
    '&$focused $notchedOutline': {
      borderColor: theme.palette.outlineTheme.main,
    },
  },
  divider: {
    marginLeft: -16,
    marginRight: -16,
  },
  quickFilterButton: {
    textTransform: 'none',
    marginRight: 16,
    marginTop: 24,
    marginBottom: 30,
  },
  scrollableOptions: {
    overflow: 'auto',
    overflowX: 'hidden',
    maxHeight: 540,
    marginLeft: -16,
    marginRight: -16,
  },
  searchOption: {
    padding: 16,
    paddingLeft: 22,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    textTransform: 'capitalize',
  },
  searchOptionQuickActions: {
    padding: 6,
    color: '#B9BDD4',
  },
  optionName: {
    fontSize: 16,
    fontWeight: 500,
  },
  optionFacility: {
    color: theme.palette.text.disabled,
    fontSize: 14,
    paddingLeft: 4,
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
}));
