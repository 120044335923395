import { Box, Typography } from '@mui/material';
import { Avatar, FlexCenter, FlexColumn } from '.';
import { CallOutlinedIcon, EmailOutlinedIcon, MenuIcon } from '../../themes';
import { CompanyContact, formatPhoneDots, IconType } from '../../lib';

type Props = {
  contact: CompanyContact;
  roleOverride?: string;
  color?: string;
};

export const NO_VALUE = '----';

export const CompanyContactCard = ({ contact, roleOverride, color }: Props) => {
  const {
    firstName,
    lastName,
    roleTypeDisplay,

    officeNumber,
    officeNumberExtension,
    email,
    noteText,
  } = contact;

  const fullName = `${firstName} ${lastName}`;

  return (
    <FlexColumn gap={2} padding={1}>
      <FlexCenter gap={1}>
        <Avatar
          name={fullName}
          color={color}
          variant='rounded'
          sx={{ height: '34px', width: '34px' }}
        />
        <FlexColumn>
          <Typography variant='subtitle2'>{fullName}</Typography>
          <Typography variant='caption' color='text.disabled'>
            {roleOverride || roleTypeDisplay}
          </Typography>
        </FlexColumn>
      </FlexCenter>

      <FlexColumn gap={0.5}>
        <ContactInfo
          Icon={CallOutlinedIcon}
          data={formatPhoneDots(officeNumber, officeNumberExtension)}
        />

        <ContactInfo Icon={EmailOutlinedIcon} data={email} />

        <ContactInfo Icon={MenuIcon} data={noteText} />
      </FlexColumn>
    </FlexColumn>
  );
};

const ContactInfo = ({ Icon, data }: { Icon: IconType; data?: string }) => (
  <Box
    display='grid'
    gridTemplateColumns='.25fr 2fr'
    alignItems='center'
    gap={1}
  >
    <Icon fontSize='xsmall' />
    <Typography variant='caption'>{data || NO_VALUE}</Typography>
  </Box>
);
