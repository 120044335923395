import {
  authGet,
  authPost,
  authDelete,
  formatUsers,
  getResidentsList,
} from '../../lib';
import { Dispatch, GetState, residentActions, uiActions } from '..';
import { cases } from './state';

const { actions } = cases;

export const caseActions = {
  ...actions,
  createIntake(payload: any) {
    return async (dispatch: Dispatch) => {
      dispatch(uiActions.setUILoading(true));
      const response = await authPost('/cases/intake', payload);
      dispatch(uiActions.setUILoading(false));
      const { data, error } = response;
      if (error) {
        dispatch(
          uiActions.showError({
            message: error.message || 'Something went wrong',
          }),
        );
        return { error };
      }
      dispatch(
        uiActions.showNotification({
          message: 'Changes Saved',
        }),
      );
      return { data };
    };
  },
  getNri(caseId: number | string) {
    return async (dispatch: Dispatch) => {
      if (caseId) {
        dispatch(actions.setNriLoading(true));
        const response = await authGet([`/cases/${caseId}/nri`]);
        const { data, error } = response;
        if (error) {
          dispatch(actions.setNriLoading(false));
          return;
        }
        dispatch(actions.setNri(data));
      }
    };
  },
  //only get companyUsers with access to this caseId
  getCaseCompanyUsers(caseId?: number) {
    return async (dispatch: Dispatch, getState: GetState) => {
      if (caseId) {
        const response = await authGet([
          `/cases/${caseId}/current-facility-users`,
        ]);
        const { data, error } = response;
        if (!error) {
          const companyId = getState().authPersist.data?.companyID;
          return formatUsers(data, companyId);
        } else {
          dispatch(
            uiActions.showError({
              message:
                error.message ||
                'An error occurred while fetching company users',
            }),
          );
          return [];
        }
      }
    };
  },
  getCasePeople(caseId?: number) {
    return async (dispatch: Dispatch) => {
      if (caseId) {
        const response = await authGet([`/cases/${caseId}/people`]);
        const { data, error } = response;
        if (!error) {
          dispatch(actions.setCasePeople(data));
        }
      }
    };
  },
  //get the case tag options for a specific case
  getCompanyCaseTagsOptions(caseId?: number) {
    return async (dispatch: Dispatch) => {
      const response = await authGet([`/Cases/caseTags/basic`, { caseId }]);
      const { data } = response;
      dispatch(actions.setCompanyCaseTagOptions(data));
    };
  },
  getCaseNotes(
    caseId: number | string,
    searchTerm?: string,
    noteTypes?: any,
    authors?: any,
    pageNumber?: number,
  ) {
    return async (dispatch: Dispatch, getState: GetState) => {
      if (caseId) {
        const {
          cases: {
            case: { notes },
          },
        } = getState();
        const params = {
          authors,
          Text: searchTerm,
          noteTypes,
          pageNumber,
          order: 'DESC',
        };
        const response = await authGet([`/cases/${caseId}/notes`, params]);
        const { data, error } = response;
        if (error) {
          return error;
        }
        data.loading = false;
        if (!pageNumber || pageNumber === 1) {
          dispatch(actions.setCaseNotes(data));
        } else {
          const { results, ...rest } = data;
          dispatch(
            actions.setCaseNotes({
              results: [...notes.results].concat(data.results),
              ...rest,
            }),
          );
        }
        return data;
      }
    };
  },
  getTodosList(caseId: number | string, pageNumber: number) {
    return async (dispatch: Dispatch, getState: GetState) => {
      if (caseId) {
        const {
          cases: {
            case: { todos },
          },
        } = getState();
        const params = {
          pageNumber,
          getOption: 'todo',
          caseId,
        };
        const response = await authGet([`/alerts`, params]);
        const { data, error } = response;
        if (error) {
          return error;
        }
        if (!pageNumber || pageNumber === 1) {
          dispatch(actions.setTodos(data));
        } else {
          const { results, ...rest } = data;
          dispatch(
            actions.setTodos({
              results: [...todos.results].concat(data.results),
              ...rest,
            }),
          );
        }
        return { data };
      }
    };
  },
  getCaseProfile(caseId: number | string) {
    return async (dispatch: Dispatch) => {
      if (caseId) {
        dispatch(actions.setCaseProfileLoading(true));
        const response = await authGet([`/cases/${caseId}/profile`]);
        const { data, error } = response;

        if (error) {
          dispatch(actions.setCaseProfileLoading(false));
          return;
        }

        dispatch(actions.setCaseProfileData(data));
        return data;
      }
    };
  },
  savePrimaryProfile(caseId: number | string, payload: any) {
    return async (dispatch: Dispatch) => {
      dispatch(actions.setCaseProfileLoading(true));
      const response = await authPost(`/cases/${caseId}/profile`, payload);
      const { data, error } = response;

      dispatch(actions.setCaseProfileLoading(false));
      if (!error) {
        dispatch(actions.setPrimaryProfile(data));
      }

      return response;
    };
  },
  saveSpouseProfile(caseId: number | string, payload: any) {
    return async (dispatch: Dispatch, getState: GetState) => {
      dispatch(actions.setCaseProfileLoading(true));
      //pull the primary resident's personID
      const primaryPersonID = getState().cases.case.profileData.profile.id;
      const response = await authPost(`/cases/${caseId}/profile/spouse`, {
        ...payload,
        primaryPersonID,
      });
      const { data, error } = response;

      dispatch(actions.setCaseProfileLoading(false));
      if (!error) {
        dispatch(actions.setSpouseProfile(data));
      }

      return response;
    };
  },
  saveMedicaidInfo(caseId: number | string, payload: any) {
    return async (dispatch: Dispatch) => {
      dispatch(actions.setCaseProfileLoading(true));
      const response = await authPost(
        `/cases/${caseId}/medicaid-info`,
        payload,
      );
      const { data, error } = response;
      dispatch(actions.setCaseProfileLoading(false));
      if (!error) {
        dispatch(actions.setProfileCaseDetails(data));
      }

      return response;
    };
  },
  saveProfileCaseDetails(caseId: number | string, payload: any) {
    return async (dispatch: Dispatch) => {
      dispatch(actions.setCaseProfileLoading(true));
      const response = await authPost(
        `/cases/${caseId}/profile/case-details`,
        payload,
      );
      const { data, error } = response;
      dispatch(actions.setCaseProfileLoading(false));
      if (!error) {
        dispatch(actions.setProfileCaseDetails(data));
      }

      return response;
    };
  },
  saveReferralDetails(caseId: number | string, payload: any) {
    return async (dispatch: Dispatch) => {
      dispatch(actions.setCaseProfileLoading(true));
      const response = await authPost(`/cases/${caseId}/referrer`, payload);
      const { data, error } = response;
      dispatch(actions.setCaseProfileLoading(false));
      if (!error) {
        dispatch(actions.setProfileReferrer(data));
      }

      return response;
    };
  },
  saveCaseContact(caseId: number | string, payload: any) {
    return async (dispatch: Dispatch, getState: GetState) => {
      dispatch(actions.setCaseProfileLoading(true));
      const response = await authPost(`/cases/${caseId}/contact`, payload);
      const { data, error } = response;
      dispatch(actions.setCaseProfileLoading(false));
      if (!error) {
        const { id } = payload;
        let stateContacts = [...getState().cases.case.profileData.contacts];
        const editIndex = stateContacts.findIndex((c) => c.id === id);
        if (editIndex !== -1) {
          stateContacts[editIndex] = data;
        } else {
          stateContacts = [...stateContacts, data];
        }
        dispatch(actions.setCaseContacts(stateContacts));
      }

      return response;
    };
  },
  deleteCaseContact(caseId: number | string, contactId: number) {
    return async (dispatch: Dispatch, getState: GetState) => {
      const response = await authDelete(`cases/${caseId}/contact/${contactId}`);
      const { error } = response;
      if (!error) {
        const stateContacts = [...getState().cases.case.profileData.contacts];
        const updatedList = stateContacts.filter((c) => c.id !== contactId);
        dispatch(actions.setCaseContacts(updatedList));
      }
      return response;
    };
  },
  getCaseOverview({
    caseId,
    isGlobal = true,
    residentProfileCacheID = undefined,
  }: {
    caseId: number | string;
    isGlobal?: boolean;
    residentProfileCacheID?: string;
  }) {
    return async (dispatch: Dispatch) => {
      if (caseId) {
        const response = await authGet([
          `/cases/${caseId}/overview`,
          { residentProfileCacheID },
        ]);
        const { data, error } = response;

        if (error) {
          return { error };
        }

        if (isGlobal) {
          const { resident, spouse } = data;
          dispatch(caseActions.setCasePeople(getResidentsList(data)));
          dispatch(residentActions.setResidentOverview(resident));
          dispatch(residentActions.setSpouseOverview(spouse));
        }

        return { data };
      }
    };
  },

  getApplicationDetails(
    caseId: number | string,
    applicationId?: number | string,
  ) {
    return async (dispatch: Dispatch) => {
      if (caseId) {
        // @ts-ignore
        const response = await authGet([
          `/cases/${caseId}/application-details`,
          // if applicationId is 0 reset to undefined, otherwise the BE will try to fetch an application with ID 0
          { applicationId: applicationId || undefined },
        ]);
        const { data, error } = response;

        if (error) {
          dispatch(
            uiActions.showError({
              message:
                error.message ||
                `Application details could not be retrieved for application ID ${applicationId}`,
            }),
          );

          return { error };
        }

        return { data };
      }
    };
  },
};
