import React from 'react';
import clsx from 'clsx';
import _get from 'lodash.get';
import { Chip } from '@mui/material';

import { useStyles } from './fields.styles';

/** ChipField props.
 * @param {ChipFieldProps} props
 */
export function ChipField({
  className,
  chipProps = {},
  source,
  record = {},
  orderBy,
  ...rest
}) {
  const classes = useStyles();
  const value = _get(record, source);
  if (!value) return null;
  return (
    <Chip
      className={clsx(classes.chip, className)}
      label={value}
      size='small'
      {...chipProps}
    />
  );
}

// #region Typedefs
/** @typedef {object} ChipFieldProps
 * @property {import("@mui/material/Chip").ChipProps} [typographyProps]
 * @property {import("react").HTMLAttributes} [className]
 * @property {import("react").HTMLAttributes} [headerClassName] class applied to the header
 * @property {import("csstype").Properties} [cellStyle]
 * @property {string} source
 * @property {string} label
 * @property {string} [disablePadding]
 * @property {string} [align]
 * @property {string} [sortBy] Defaults to `source`
 * @property {false} [sortable] Set to false to disable sorting for this column
 */
// #endregion
