import { resourceActions } from '..';

export const alertActions = {
  setTodoActions({ data, refresh, message }) {
    return async (dispatch) => {
      const { id, doneOn, snoozedUntil, dismissedNote, dismissedOn } = data;
      const payload = {
        todoID: id,
        doneOn,
        snoozedUntil,
        dismissedNote,
        dismissedOn,
      };
      const response = await dispatch(
        resourceActions.postResource({
          baseUrl: '/alerts/actions',
          payload,
          message,
        }),
      );
      if (response.data) refresh();
    };
  },
};
