import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import _isEqual from 'lodash.isequal';
import { uiSelectors } from '../../../../state';
import FilterOption from '../../ListViews/filters/FilterOption';
import { AssigneeDisplayButton } from '../AssigneeDisplayButton';
import {
  getAdditionalNames,
  getCaseworkerDisplay,
  sortById,
} from '../../../../lib';

export function AssigneeAutocompleteInput({
  usePotentialCaseAssignees = false,
  assigneeIDs,
  onChange,
  assigneeOptions, //can leave out if usePotentialCaseAssignees is true
}) {
  const potentialCompanyUsers = useSelector(uiSelectors.potentialCompanyUsers);

  const assignees = usePotentialCaseAssignees
    ? potentialCompanyUsers
    : assigneeOptions;

  const selectedAssigneeList = assignees.filter((a) =>
    assigneeIDs.some((i) => i === a.id),
  );

  const onChangeAssignees = useCallback(
    (values) => {
      const oldVal = sortById(assigneeIDs, (i) => i);
      const newVal = sortById(values, (i) => i);

      if (!_isEqual(oldVal, newVal)) {
        onChange({ target: { value: values, name: 'assigneeIDs' } });
      }
    },
    [assigneeIDs, onChange],
  );

  return (
    <FilterOption
      menuItems={assignees}
      selectedMenuItems={selectedAssigneeList}
      label={''}
      onChange={onChangeAssignees}
      multiple
      searchPlaceholder={'Search...'}
      groupedOptionsLabel={'All users'}
      renderButton={({ onClick, ref }) => (
        <AssigneeDisplayButton
          displayText={getCaseworkerDisplay(selectedAssigneeList)}
          tooltipText={getAdditionalNames(selectedAssigneeList)}
          actionText={'Add assignee'}
          onClick={onClick}
          otherProps={{ ref }}
        />
      )}
    />
  );
}
