import React from 'react';
import clsx from 'clsx';
import { TextField } from '@mui/material';
import { FilterMenu } from '../../..';
import { useStyles } from './inputs.styles';

/**
 * Material-ui TextField with validation TextInput
 * @param {import("@mui/material/TextField").TextFieldProps & filtersSelectProps} props
 */
export function FiltersSelect({
  label = 'Filters',
  queryParam = 'list-filters',
  filters,
  className,
  sideEffectUpdateQuery, //use this for any additional query params that should be set whenever this filter changes
  ...rest
}) {
  const classes = useStyles();

  return (
    <TextField
      select
      variant='outlined'
      label={label}
      InputProps={{
        classes: {
          root: clsx(classes.inputRoot, className),
          focused: classes.focused,
          disabled: classes.disabled,
          error: classes.error,
          input: classes.input,
          notchedOutline: classes.notchedOutline,
        },
      }}
      InputLabelProps={{
        classes: {
          root: classes.label,
          focused: classes.focused,
          error: classes.error,
          disabled: classes.disabled,
        },
      }}
      {...rest}
    >
      <FilterMenu
        queryParam={queryParam}
        filters={filters}
        sideEffectUpdateQuery={sideEffectUpdateQuery}
      />
    </TextField>
  );
}

// #region Typedefs
/** @typedef {object} filtersSelectProps
 * @property {String} [label] The label for the input. Defaults to `Filters`
 * @property {String} [queryParam] The query name to store in the URL. Defaults to `list-filters`
 * @property {Object} filters
 * @property {import("react").HTMLAttributes} [className]
 */
// #endregion
