import React from 'react';
import { Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useExport, useNotify } from '../../../hooks';

import { GetAppIcon } from '../../../themes';

export function ExportButton({ path, queryParams, headersArray, fileName }) {
  const classes = useStyles();
  const notify = useNotify();
  const exportData = useExport();

  return (
    <Button
      aria-label='export'
      className={classes.btn}
      onClick={() =>
        exportData({ path, queryParams, notify, headersArray, fileName })
      }
    >
      <GetAppIcon />
    </Button>
  );
}

const useStyles = makeStyles((theme) => ({
  btn: {
    backgroundColor: '#E5E7F5',
    minWidth: 36,
  },
}));

//#region typedef
/**
 * @typedef {object} exportProps
 * @property {string} path The API URL
 * @property {object} queryParams
 * @property {Array.<headersObj>} headersArray
 * @property {string} fileName The name of the generated csv
 */

/**
 * @typedef {object} headersObj
 * @property {string} key the field name sent to the API
 * @property {string} label the display name
 * @property {string} [formatProperty] value to display if passing in an array of objects
 * @property {"date"|"phone"|"currency"} [format] how to format the value
 */

//#region
