import { authGet } from '../../lib';

export const saasEndpoints = {
  createCompany: () => '/saas/createCompany',
  getCompany: (id: number | string) => `/saas/companies/${id}`,
  editCompany: () => '/saas/companies',
  getCompanies: () => '/saas/companies',
  getCompaniesBasic: () => '/saas/companies/basic',
};

export const saasActions = {
  getCompany(id: number | string) {
    return async () => {
      const response = await authGet(saasEndpoints.getCompany(id));
      return response;
    };
  },
  getCompaniesBasic() {
    return async () => {
      const response = await authGet(saasEndpoints.getCompaniesBasic());
      return response;
    };
  },
};
