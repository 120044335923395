import React, { memo, useState, useEffect, useCallback } from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { validateOnSubmit, Routes } from '../../lib';
import { Button, CircularProgress } from '@mui/material';
import { AuthLayout } from './AuthLayout';
import { TextInput } from '../../components';
import { uiActions, authActions, uiSelectors } from '../../state';
import { useStyles } from './Auth.styles';
import { useGetQueryParams } from '../../hooks';

const _SetPassword = () => {
  const { id: email, token } = useGetQueryParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const uiLoading = useSelector(uiSelectors.loading);
  const classes = useStyles();
  const [state, setState] = useState({
    password: '',
    confirmPassword: '',
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (!token || !email) {
      dispatch(
        uiActions.showError({
          message: `Invalid set password link: Missing ${
            !token ? 'token' : 'id'
          }`,
        }),
      );
      navigate(Routes.login.path, { replace: true });
    } else {
      setState((s) => ({ ...s, email, token }));
    }
    // eslint-disable-next-line
  }, [token, dispatch]);

  const handleChange = useCallback((e) => {
    const {
      target: { name, value },
    } = e;
    setState((s) => ({ ...s, [name]: value }));
  }, []);

  const submit = async (e) => {
    e.preventDefault();
    const { email, password, token } = state;
    validateOnSubmit({ values: state, schema, setErrors }).then(() => {
      dispatch(authActions.setPassword({ email, password, token, navigate }));
    });
  };

  const getPasswordValidationStyle = (validator) => {
    const style = {};

    if (password && validator(password)) {
      style.color = '#0FC076';
    } else if (Object.keys(errors).length) {
      style.color = '#F44336';
    }

    return style;
  };

  const { password, confirmPassword } = state;
  return (
    <AuthLayout>
      <h1 className={clsx(classes.title, classes.welcome)}>Set new password</h1>
      <div className={classes.welcomeSubText}>
        New password must contain:
        <p style={getPasswordValidationStyle(pwLengthValidator)}>
          ● At least 8 characters
        </p>
        <p style={getPasswordValidationStyle(pwLowerCharValidator)}>
          ● A lowercase character
        </p>
        <p style={getPasswordValidationStyle(pwUpperCharValidator)}>
          ● An uppercase character
        </p>
        <p style={getPasswordValidationStyle(pwDigitCharValidator)}>
          ● A number between 0-9
        </p>
        <p style={getPasswordValidationStyle(pwSpecialCharValidator)}>
          ● A special character
        </p>
      </div>
      <form onSubmit={submit} className={classes.form}>
        <TextInput
          className={classes.inputSpacer}
          required
          fullWidth
          name='password'
          label='Password'
          type='password'
          id='password'
          value={password}
          onChange={handleChange}
          disabled={uiLoading}
          schema={schema}
          error={errors['password']}
        />
        <TextInput
          className={classes.inputSpacer}
          required
          fullWidth
          name='confirmPassword'
          label='Confirm Password'
          value={confirmPassword}
          type='password'
          id='confirmPassword'
          onChange={handleChange}
          // schema={schema}
          // error={errors['confirmPassword']}
          //note: matching passwords check in the schema is not allowing the error to be reset when passwords are matched; we are therefore manually checking if the passwords match here and passing through the validation error only when they do not match
          error={
            errors['confirmPassword'] && confirmPassword !== password
              ? 'Passwords must match'
              : ''
          }
        />
        <div className={classes.submitWrapper}>
          <Button
            type='submit'
            variant='contained'
            color='primary'
            className={classes.submit}
            disabled={!password || !confirmPassword || uiLoading}
            disableFocusRipple
            disableRipple
            disableTouchRipple
          >
            Save new password
          </Button>
        </div>
        {uiLoading && (
          <div style={{ width: '100%', textAlign: 'center' }}>
            <CircularProgress size={24} />
          </div>
        )}
      </form>
    </AuthLayout>
  );
};

export const SetPassword = memo(_SetPassword);

const pwLengthValidator = (pw) => pw?.length >= 8;
const pwUpperCharValidator = (pw) => pw?.match(/[A-Z]/g);
const pwLowerCharValidator = (pw) => pw?.match(/[a-z]/g);
const pwDigitCharValidator = (pw) => pw?.match(/[0-9]/g);
const pwSpecialCharValidator = (pw) => pw?.match(/[^a-zA-Z0-9\s]/g);

function validatePassword(pw) {
  return (
    pwLengthValidator(pw) &&
    pwUpperCharValidator(pw) &&
    pwLowerCharValidator(pw) &&
    pwDigitCharValidator(pw) &&
    pwSpecialCharValidator(pw)
  );
}

const schema = yup.object().shape({
  password: yup
    .string('Password must be a string')
    .required('Password is required')
    .test('valid-password', 'Invalid password', validatePassword),
  confirmPassword: yup
    .string('Password must be a string')
    .oneOf([yup.ref('password')], 'Passwords must match'),
});
