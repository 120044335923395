import React from 'react';
import { Button } from '@mui/material';

export function CancelButton({ onClick, disabled, text = 'Cancel' }) {
  return (
    <Button
      variant={'contained'}
      onClick={onClick}
      disabled={disabled}
      sx={{
        backgroundColor: '#E5E7F5',
        '&:hover': {
          backgroundColor: '#E5E7F5',
        },
        color: 'text.primary',
        py: 1,
        px: 1.75,
      }}
    >
      {text}
    </Button>
  );
}

export function SaveButton({ onClick, disabled, text = 'Save' }) {
  return (
    <Button
      variant={'contained'}
      onClick={onClick}
      disabled={disabled}
      color={'primary'}
      sx={{
        py: 1,
        px: 1.75,
        minWidth: '90px',
      }}
    >
      {text}
    </Button>
  );
}
