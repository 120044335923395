import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
  useRef,
} from 'react';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import debounce from 'lodash.debounce';
import { IconButton, TextField } from '@mui/material';
import { Autocomplete } from '@mui/material';
import { ClearIcon, SearchIcon } from '../../../themes';
import { useGetQueryParams, useUpdatePageQueryParams } from '../../../hooks';
import { useStyles } from './inputs.styles';
import { authGet, capitalize } from '../../../lib';
import { uiActions } from '../../../state';

export function AutocompleteSearchInput(props) {
  const {
    label = 'Search',
    queryParam = 'search',
    className,
    apiUrl,
    minToFetch,
  } = props;

  //hooks
  const classes = useStyles();
  const dispatch = useDispatch();
  const updatePageQueryParams = useUpdatePageQueryParams();
  const { [queryParam]: search } = useGetQueryParams();

  //state
  const [value, setValue] = useState(search || '');
  const [inputValue, setInputValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);

  //refs
  const isFirstRender = useRef(true);

  //useCallback
  const fetchSuggestions = useCallback(async () => {
    const response = await authGet([
      apiUrl,
      {
        Text: inputValue,
      },
    ]);
    const { data, error } = response;
    if (error) {
      dispatch(
        uiActions.showError({
          message: error.message || 'Failed to fetch the data',
        }),
      );
      return;
    }
    setSuggestions(data);
  }, [apiUrl, dispatch, inputValue]);

  const handleKeyDown = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        setValue(inputValue);
      }
    },
    [inputValue],
  );

  //useMemo
  // See here why we use useMemo and not useCallback
  // https://github.com/facebook/react/issues/19240#issuecomment-652945246
  const debounceSuggestions = useMemo(
    () =>
      debounce(() => {
        fetchSuggestions();
      }, 300),
    [fetchSuggestions],
  );

  //useEffect
  useEffect(() => {
    //when updating the search value we need to set the page to 1
    // don't reset on initial render
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    updatePageQueryParams({ [queryParam]: value || null, page: 1 });
  }, [value, queryParam, updatePageQueryParams]);

  //get suggestions when input value changes
  useEffect(() => {
    if (inputValue.trim().length >= (minToFetch ?? 3)) {
      debounceSuggestions();
    } else if (inputValue === '') {
      setSuggestions([]);
    }
  }, [inputValue, minToFetch, debounceSuggestions]);

  return (
    <Autocomplete
      isOptionEqualToValue={(option, value) => value === option}
      getOptionLabel={(option) => capitalize(option)}
      style={{ width: 280 }}
      filterOptions={(x, y) => x}
      options={suggestions}
      freeSolo={true}
      inputValue={inputValue}
      value={value}
      disableClearable //so we can have our own icon
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          fullWidth
          {...params}
          variant='outlined'
          label={label}
          name='searchText'
          size={'small'}
          onKeyDown={handleKeyDown}
          InputProps={{
            ...params.InputProps,
            classes: {
              root: clsx(classes.inputRoot, classes.smallInputRoot, className),
              focused: classes.focused,
              disabled: classes.disabled,
              error: classes.error,
              input: classes.input,
              notchedOutline: classes.notchedOutline,
            },
            endAdornment: (
              <>
                <IconButton
                  style={{ visibility: inputValue ? 'visible' : 'hidden' }}
                  onClick={() => {
                    setValue('');
                    setInputValue('');
                  }}
                  size='small'
                >
                  <ClearIcon size='small' className={classes.clearIcon} />
                </IconButton>
                <IconButton onClick={() => setValue(inputValue)} size='small'>
                  <SearchIcon className={classes.label} />
                </IconButton>
              </>
            ),
          }}
          InputLabelProps={{
            classes: {
              root: classes.label,
              focused: classes.focused,
              error: classes.error,
              disabled: classes.disabled,
            },
          }}
        />
      )}
    />
  );
}

// #region Typedefs
/** @typedef {object} searchInputProps
 * @property {String} [label] The label for the input. Defaults to `Search`
 * @property {String} [queryParam] The query name to store in the URL. Defaults to `search`
 * @property {import("react").HTMLAttributes} [className]
 */
// #endregion
