import React, { useCallback, useState, useEffect } from 'react';
import clsx from 'clsx';
import debounce from 'lodash.debounce';
import useDeepCompareEffect from 'use-deep-compare-effect';
import PropTypes from 'prop-types';
import { reach } from 'yup';
import Downshift from 'downshift';
import { MenuItem, Paper, TextField, Popper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useDispatch } from 'react-redux';
import { uiActions } from '../../../state';
import { authGet } from '../../../lib';

function renderInput(inputProps) {
  const {
    InputProps: _InputProps,
    classes,
    ref,
    onChange,
    autoFocus,
    fullWidth,
    className,
    parentOnBlur,
    parentValue,
    name,
    disabled,
    ...other
  } = inputProps;
  const { onBlur, ...InputProps } = _InputProps;

  function handleBlur(e) {
    onBlur(e);
    if (typeof parentOnBlur === 'function') {
      const event = { target: { name, value: parentValue } };
      parentOnBlur(event);
    }
  }

  return (
    <TextField
      variant='outlined'
      fullWidth={fullWidth}
      className={clsx(className)}
      disabled={disabled}
      autoFocus={autoFocus}
      FormHelperTextProps={{ classes: { root: classes.helperText } }}
      InputProps={{
        inputRef: ref,
        onChange,
        classes: {
          root: clsx(classes.root, classes.textFieldRoot),
          input: clsx(classes.inputRoot),
          notchedOutline: classes.notchedOutline,
          focused: classes.focused,
          disabled: classes.disabled,
          error: classes.error,
        },
        ...InputProps,
        onBlur: handleBlur,
      }}
      InputLabelProps={{
        classes: {
          root: classes.label,
          focused: classes.focused,
          error: classes.error,
          disabled: classes.disabled,
        },
      }}
      {...other}
    />
  );
}

renderInput.propTypes = {
  /**
   * Override or extend the styles applied to the component.
   */
  classes: PropTypes.object.isRequired,
  InputProps: PropTypes.object,
};

function renderSuggestion(suggestionProps) {
  const {
    suggestion,
    index,
    itemProps,
    highlightedIndex,
    selectedItem,
    valueField,
  } = suggestionProps;
  const idValue = suggestion[valueField] ?? suggestion.id;
  const isHighlighted = highlightedIndex === index;
  const isSelected = selectedItem?.target?.value === idValue ?? false;
  const { onClick, ...rest } = itemProps;
  const handleClick = (e) => {
    onClick(e);
  };

  return (
    <MenuItem
      {...rest}
      onClick={handleClick}
      key={idValue}
      selected={isHighlighted}
      component='div'
      style={{
        fontWeight: isSelected ? 500 : 400,
        minHeight: 33,
      }}
    >
      {suggestion.name === '' ? '' : suggestion.name}
    </MenuItem>
  );
}

renderSuggestion.propTypes = {
  highlightedIndex: PropTypes.oneOfType([
    PropTypes.oneOf([null]),
    PropTypes.number,
  ]).isRequired,
  index: PropTypes.number.isRequired,
  itemProps: PropTypes.object.isRequired,
  selectedItem: PropTypes.string.isRequired,
  suggestion: PropTypes.shape({
    label: PropTypes.string.isRequired,
  }).isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  textFieldRoot: {
    '& $notchedOutline': {
      borderColor: theme.palette.outlineTheme.main,
    },
    '&:hover:not($disabled):not($error) $notchedOutline': {
      borderColor: theme.palette.outlineTheme.main,
    },
    '&$focused:not($disabled):not($error) $notchedOutline': {
      borderColor: theme.palette.outlineTheme.main,
    },
  },
  inputRoot: {
    padding: '16.5px 14px 14.5px 14px',
  },
  label: {
    color: theme.palette.textTheme.main,
    '&$focused:not($disabled):not($error)': {
      color: theme.palette.textTheme.main,
    },
  },
  notchedOutline: {},
  disabled: {},
  error: {},
  focused: {},
  padding: {
    padding: '14.5px 14px',
  },
  container: {
    flexGrow: 1,
    position: 'relative',
    display: 'flex',
  },
  paper: {
    ...theme.typography.body1,
    overflow: 'hidden',
    margin: '4px 0',
  },
  popper: {
    zIndex: theme.zIndex.modal + 1,
  },
  // popperDisablePortal: {
  //   position: 'absolute',
  // },
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
  divider: {
    height: theme.spacing(2),
  },
  helperText: {
    position: 'absolute',
    bottom: -21,
  },
}));

export const Autocomplete = ({
  suggestions: _suggestions = [],
  label,
  name,
  valueField,
  secondaryValueField,
  placeholder,
  onChange,
  onBlur: parentOnBlur,
  onSelectedItemChange,
  clearable,
  otherInputProps = {},
  classes: classesOverrides = {},
  openOnFocus,
  autoFocus,
  value: parentValue,
  initialInputValue,
  disabled,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const clearableValueField = valueField || 'id';
  const suggestions = clearable
    ? [{ [clearableValueField]: 0, name: '' }, ..._suggestions]
    : _suggestions;
  return (
    <div>
      <Downshift
        initialInputValue={initialInputValue}
        onChange={onSelectedItemChange}
        itemToString={(item) => item?.target?.selectedItemName ?? ''}
      >
        {({
          getInputProps,
          getItemProps,
          getLabelProps,
          getMenuProps,
          highlightedIndex,
          inputValue,
          isOpen,
          selectedItem,
          openMenu,
        }) => {
          const { onBlur, onFocus, ...inputProps } = getInputProps({
            placeholder: placeholder || '',
          });

          return (
            <div className={classes.container}>
              {renderInput({
                disabled,
                fullWidth: true,
                autoFocus,
                classes: { ...classes, ...classesOverrides },
                label: label || '',
                InputLabelProps: getLabelProps(),
                InputProps: {
                  onBlur,
                  onFocus: openOnFocus ? openMenu : onFocus,
                  ref: setAnchorEl,
                },
                inputProps,
                ...otherInputProps,
                onChange,
                parentValue,
                parentOnBlur,
                name,
              })}

              <div {...getMenuProps()}>
                <Popper
                  open={isOpen && !!anchorEl}
                  className={clsx(classes.popper, classes.disablePortal)}
                  style={{
                    width: anchorEl ? anchorEl.clientWidth : null,
                  }}
                  role='presentation'
                  anchorEl={anchorEl}
                >
                  <Paper className={classes.paper}>
                    {suggestions.map((suggestion, index) =>
                      renderSuggestion({
                        suggestion,
                        index,
                        itemProps: getItemProps({
                          item: {
                            target: {
                              name,
                              selectedItemName: suggestion.name,
                              value: suggestion[valueField] ?? suggestion.id,
                              secondaryValue:
                                suggestion[secondaryValueField] ??
                                suggestion.id,
                            },
                          },
                        }),

                        highlightedIndex,
                        selectedItem,
                        valueField,
                      }),
                    )}
                  </Paper>
                </Popper>
              </div>
            </div>
          );
        }}
      </Downshift>
    </div>
  );
};

/**
 * Material-ui TextField with validation TextInput
 * @param {import("@mui/material/TextField").TextFieldProps & autocompleteProps} props
 */
export function AutocompleteAsync(props) {
  const classes = useStyles();
  const {
    schema,
    context,
    nestedKey,
    className,
    onChange,
    onBlur,
    fullWidth,
    error,
    options = {},
    url,
    minToFetch,
    required,
    disabled,
    value: parentValue, // we only need the value to handle onBlur
    secondaryValueField,
    ...otherProps
  } = props;
  const dispatch = useDispatch();
  const [isDirty, setIsDirty] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [data, setData] = useState([]);
  const [searchFilter, setFilter] = useState('');
  const debounceFilter = debounce((value) => {
    setFilter(value);
  }, 300);

  const onFilterChange = (e) => {
    debounceFilter(e.target.value);
    const { value } = e.target;
    if (schema && isDirty) {
      reach(schema, nestedKey || otherProps.name)
        .validate(value, { context })
        .then(() => setErrorMessage(false))
        .catch((err) => setErrorMessage(err.errors?.[0]));
    }
  };

  useDeepCompareEffect(() => {
    const { order, orderBy, filter = {} } = options;
    async function fetchData() {
      const response = await authGet([
        url,
        {
          order,
          orderBy,
          ResultsPerPage: 50,
          PageNumber: 1, // the server is 1 based
          Text: searchFilter,
          ...filter,
        },
      ]);
      const { data, error } = response;
      if (error) {
        dispatch(
          uiActions.showError({
            message: error.message || 'Failed to fetch the data',
          }),
        );
        return;
      }
      setData(data);
    }
    if (searchFilter.trim().length >= (minToFetch ?? 3)) {
      fetchData();
    }
  }, [dispatch, minToFetch, options, searchFilter, url]);

  const handleBlur = useCallback(
    (event) => {
      typeof onBlur === 'function' && onBlur(event);
      if (schema) {
        setIsDirty(true);
        reach(schema, nestedKey || otherProps.name)
          .validate(parentValue, { context })
          .then(() => setErrorMessage(false))
          .catch((err) => setErrorMessage(err.errors?.[0]));
      }
    },
    [context, nestedKey, onBlur, otherProps.name, parentValue, schema],
  );

  useEffect(() => {
    // this is for when the validation is based of of another field
    // and the values of the context is true/false for example the pasRequested field on facilityIntake
    if (context) {
      const valuesArr = Object.keys(context).reduce((acc, cur) => {
        acc.push(context[cur]);
        return acc;
      }, []);
      if (valuesArr.every((v) => v === false)) {
        setErrorMessage(undefined);
      }
    }
  }, [context]);

  const handleSelectedItemChange = useCallback(
    (event) => {
      typeof onChange === 'function' && onChange(event);
      const { value } = event.target;
      if (schema && isDirty) {
        reach(schema, nestedKey || otherProps.name)
          .validate(value, { context })
          .then(() => setErrorMessage(false))
          .catch((err) => setErrorMessage(err.errors?.[0]));
      }
    },
    [context, isDirty, nestedKey, onChange, otherProps.name, schema],
  );

  return (
    <Autocomplete
      disabled={disabled}
      suggestions={data}
      onBlur={handleBlur}
      onChange={onFilterChange}
      onSelectedItemChange={handleSelectedItemChange}
      otherInputProps={{
        className: clsx({ [classes.root]: !fullWidth }, className),
        fullWidth,
        error: !!errorMessage || (errorMessage !== false && !!error),
        helperText: errorMessage !== false ? errorMessage || error : undefined,
        required,
      }}
      secondaryValueField={secondaryValueField}
      {...otherProps}
    />
  );
}

// #region Typedefs
/** @typedef {object} autocompleteProps
 * @property {boolean} [clearable] Will show first row empty to allow to clear
 * @property {Object} [context] An object with the name and value of the field to use as Yup context
 * @property {Object} schema The defined Yup schema
 * @property {string} [nestedKey] If the value is a nested value provide the path. Example nestedKey='residentContacts.firstName'.
 * @property {boolean} [openOnFocus] Boolean to open results on focus
 * @property {number} [minToFetch] The minimum characters entered before fetching results. Defaults to 3.
 * @property {string} error The error message when failing validation on submit
 * @property {object} [options]
 * @property {"asc"|"desc"} [options.order]
 * @property {string} [options.orderBy]
 * @property {object} [options.filter] An object to filter the api call
 * @property {string} secondaryValueField An optional field name to pull in as a secondary value from the selected record. i.e. in the onchange: `id` would be e.target.value and then `caseID` would be e.target.secondaryValue
 */
// #endregion
