import { authGet, arrayToObjByEnumOrId } from '../../lib';
import { systemPersist } from './state';
const { actions } = systemPersist;

export const systemPersistActions = {
  ...actions,
  getSystemSettings() {
    return async (dispatch) => {
      const response = await authGet('/System');
      const { data, error } = response;
      if (!error) {
        const contactRelationshipsObj = arrayToObjByEnumOrId(
          data.contactRelationships,
        );
        const applicationContactTypesObj = arrayToObjByEnumOrId(
          data.applicationContactTypes,
        );
        const statesObj = arrayToObjByEnumOrId(data.states);
        const propertyStatusesObj = arrayToObjByEnumOrId(data.propertyStatuses);
        const lifeInsuranceStatusesObj = arrayToObjByEnumOrId(
          data.lifeInsuranceStatuses,
        );
        const bankAccountTypesObj = arrayToObjByEnumOrId(data.bankAccountTypes);
        const investmentAccountTypesObj = arrayToObjByEnumOrId(
          data.investmentAccountTypes,
        );
        const trustFundedByOptionsObj = arrayToObjByEnumOrId(
          data.trustFundedByOptions,
        );
        const payerTypesObj = arrayToObjByEnumOrId(data.payerTypes);
        const facilityTypesObj = arrayToObjByEnumOrId(data.facilityTypes);
        const caseNoteTypesObj = arrayToObjByEnumOrId(data.caseNoteTypes);
        const applicationStatusObj = arrayToObjByEnumOrId(
          data.applicationStatuses,
        );
        const facilityCaseTypesObj = arrayToObjByEnumOrId(
          data.facilityCaseTypes,
        );
        const acCaseTypesObj = arrayToObjByEnumOrId(data.acCaseTypes);
        const deductionTypesObj = arrayToObjByEnumOrId(data.deductionTypes);
        const incomeTypesObj = arrayToObjByEnumOrId(data.incomeTypes);
        const frequencyTypesObj = arrayToObjByEnumOrId(data.frequencyTypes);
        const documentTypesObj = arrayToObjByEnumOrId(data.documentTypes);
        dispatch(
          actions.setSystemSettings({
            ...data,
            bankAccountTypesObj,
            investmentAccountTypesObj,
            contactRelationshipsObj,
            applicationContactTypesObj,
            statesObj,
            trustFundedByOptionsObj,
            payerTypesObj,
            facilityTypesObj,
            caseNoteTypesObj,
            applicationStatusObj,
            facilityCaseTypesObj,
            acCaseTypesObj,
            deductionTypesObj,
            incomeTypesObj,
            frequencyTypesObj,
            propertyStatusesObj,
            lifeInsuranceStatusesObj,
            documentTypesObj,
          }),
        );
      }
    };
  },
};
