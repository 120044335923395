import React, { useCallback, useMemo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppBar, Box, Button, IconButton, Toolbar } from '@mui/material';
import { uiActions, uiSelectors } from '../../state';
import { CloseIcon } from '../../themes';
import { AnnouncementTypes } from '../../lib';
import { AnnouncementPopup } from '..';
import { useGetUsersData, useIdbKeyVal } from '../../hooks';

const DISMISSED_BANNERS_KEY = 'aidace-persist:DismissedBanners';
const DISMISSED_POPUPS_KEY = 'aidace-persist:DismissedPopups';
const emptyObj = {};
// structure should be like this:  object with keys - the userId, value is an array of banner ids.
// i.e. { 1: [1,2,3], 2: [2]}
//NOTE: we may need to clear this out eventually if it gets too large for the browser...(or start storing this info on BE instead)

export function AnnouncementBanner() {
  const dispatch = useDispatch();

  const { userId } = useGetUsersData();

  const [showAnnouncementBanner, setShowAnnouncementBanner] = useState(false);
  const [showAnnouncementPopup, setShowAnnouncementPopup] = useState(false);

  const [dismissedPopups, saveDismissedPopupsToStorage] = useIdbKeyVal(
    DISMISSED_POPUPS_KEY,
    emptyObj,
  );
  const [dismissedBanners, saveDismissedBannersToStorage] = useIdbKeyVal(
    DISMISSED_BANNERS_KEY,
    emptyObj,
  );

  const { subjectHtmlContent, type, bodyHtmlContent, displayUntilDate, id } =
    useSelector(uiSelectors.currentAnnouncement);
  const showReadMoreButton = type === AnnouncementTypes.BannerAndPopUp;

  //if the dismissed list didn't load yet, consider it dismissed
  const currentPopupIsDismissed = useMemo(
    () => !dismissedPopups || (dismissedPopups[userId] || []).includes(id),
    [id, dismissedPopups, userId],
  );
  const currentBannerIsDismissed = useMemo(
    () => !dismissedBanners || (dismissedBanners[userId] || []).includes(id),
    [id, dismissedBanners, userId],
  );

  const setBannerAsDismissed = useCallback(() => {
    const updated = {
      ...dismissedBanners,
      [userId]: [...(dismissedBanners[userId] || []), id],
    };
    saveDismissedBannersToStorage(updated);
  }, [dismissedBanners, id, userId, saveDismissedBannersToStorage]);

  const setPopupAsDismissed = useCallback(() => {
    const updated = {
      ...dismissedPopups,
      [userId]: [...(dismissedPopups[userId] || []), id],
    };
    saveDismissedPopupsToStorage(updated);
  }, [dismissedPopups, id, userId, saveDismissedPopupsToStorage]);

  const closeBanner = useCallback(() => {
    setBannerAsDismissed();
    setShowAnnouncementBanner(false);
  }, [setBannerAsDismissed]);

  const handleClosePopup = useCallback(() => {
    if (!currentPopupIsDismissed) {
      setPopupAsDismissed();
    }
    setShowAnnouncementPopup(false);
  }, [setPopupAsDismissed, currentPopupIsDismissed]);

  useEffect(() => {
    dispatch(uiActions.getCurrentAnnouncement());
  }, [dispatch]);

  useEffect(() => {
    if (!id) {
      return;
    }
    if (!currentPopupIsDismissed && type !== AnnouncementTypes.BannerOnly) {
      setShowAnnouncementPopup(true);
    } else if (
      !currentBannerIsDismissed &&
      type !== AnnouncementTypes.PopUpOnly
    ) {
      setShowAnnouncementBanner(true);
    }
  }, [currentBannerIsDismissed, currentPopupIsDismissed, dispatch, type, id]);

  if (displayUntilDate < new Date()) {
    return null;
  }
  return (
    <>
      {showAnnouncementBanner && (
        <AppBar
          /* NOTE: position sticky is not actually working b/c there are two appbars (and the other is sticky).
            we dont want to fix now (by nesting the appbar within the other one) b/c it will affect the rest of the pages sticky top height */
          position='sticky'
          elevation={0}
          sx={{
            backgroundColor: '#0514B3',
          }}
        >
          <Toolbar>
            <Box
              sx={{
                width: '98%',
                display: 'flex',
                alignItems: 'center',
                fontSize: 16,
                fontWeight: 500,
                justifyContent: 'center',
                '& a': {
                  color: 'unset',
                },
              }}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: subjectHtmlContent,
                }}
              />
              {showReadMoreButton && (
                <Button
                  variant='text'
                  sx={{
                    color: '#FFFF',
                    fontWeight: 600,
                    fontSize: 16,
                    ml: 0.5,
                  }}
                  onClick={() => setShowAnnouncementPopup(true)}
                >
                  Read More
                </Button>
              )}
            </Box>
            <IconButton onClick={closeBanner} sx={{ color: '#FFFF', mr: -2 }}>
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
      )}
      {showAnnouncementPopup && (
        <AnnouncementPopup
          subjectHtmlContent={subjectHtmlContent}
          bodyHtmlContent={bodyHtmlContent}
          handleClose={handleClosePopup}
          open={showAnnouncementPopup}
        />
      )}
    </>
  );
}
