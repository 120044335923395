const emptyArray = [];
export const facilitySelectors = {
  facilityOptions: (state) => state.facilities.facilityOptions || emptyArray,
  facilityOptionsLoading: (state) => state.facilities.facilityOptionsLoading,
  hasNoFacilities: (state) => state.facilities.hasNoFacilities,
  facilities: (state) => state.facilities.facilities,

  payersByFacilityId: (state, id) =>
    state.facilities.facilities[id]?.payers || emptyArray,
};
