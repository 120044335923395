import React from 'react';

export function ApplicationConversionIcon(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      width='18'
      height='20'
      viewBox='0 0 18 20'
      className='customSvgIcon'
      {...props}
    >
      <defs>
        <path
          id='path-3'
          d='M16,2 L11.82,2 C11.4,0.84 10.3,0 9,0 C7.7,0 6.6,0.84 6.18,2 L2,2 C0.9,2 0,2.9 0,4 L0,18 C0,19.1 0.9,20 2,20 L16,20 C17.1,20 18,19.1 18,18 L18,4 C18,2.9 17.1,2 16,2 L16,2 Z M11.7222222,10.2777778 L11.7222222,14.5616667 L13.5555556,14.5616667 L11.1111111,17 L8.66666667,14.5616667 L10.5,14.5616667 L10.5,10.2777778 L11.7222222,10.2777778 Z M7.44444444,6 L9.88888889,8.43833333 L8.05555556,8.43833333 L8.05555556,12.7222222 L6.83333333,12.7222222 L6.83333333,8.43833333 L5,8.43833333 L7.44444444,6 Z M9,2 C9.55,2 10,2.45 10,3 C10,3.55 9.55,4 9,4 C8.45,4 8,3.55 8,3 C8,2.45 8.45,2 9,2 Z'
        ></path>
      </defs>
      <g fill='none' fillRule='evenodd' stroke='none' strokeWidth='1'>
        <g transform='translate(0 0)'>
          <mask id='mask-3' fill='#fff'>
            <use xlinkHref='#path-3'></use>
          </mask>
          <g fill={props.fill} mask='url(#mask-3)'>
            <g transform='translate(-16 -15)' id='Rectangle'>
              <rect x='0' y='0' width='50' height='50'></rect>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
