import { useSelector, shallowEqual } from 'react-redux';
import { authPersistSelectors } from '../state';

export function useGetUsersData() {
  const authData = useSelector(authPersistSelectors.data, shallowEqual);

  const {
    PermissionClaim,
    'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier':
      userId,
  } = authData;
  const permissionClaim = Array.isArray(PermissionClaim)
    ? PermissionClaim
    : [PermissionClaim];
  return {
    ...authData,
    PermissionClaim: permissionClaim,
    userId: userId ? parseInt(userId) : undefined,
  };
}
