import React, { Fragment } from 'react';
import _get from 'lodash.get';
import { Typography, Dialog, DialogContentText, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { limitStringSize } from '../../../../lib';
import { Tooltip } from '@mui/material';
import { PaperComponent } from '../../../modals/PaperComponent';

const useStyles = makeStyles((theme) => ({
  closeBtn: {
    backgroundColor: '#E5E7F5',
    '&:hover': {
      backgroundColor: '#E5E7F5',
    },
    color: theme.palette.text.primary,
  },
  btnContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    margin: 20,
  },
  title: {
    color: '#000000',
    fontSize: 24,
    fontWeight: 500,
    textTransform: 'capitalize',
    cursor: 'move',
  },
  note: {
    fontSize: 16,
  },
  tooltip: {
    backgroundColor: '#9B9DA9',
  },
}));

/** NotesField props.
 * @param {NotesFieldProps} props
 */
export function NotesField({
  className,
  typographyProps = {},
  source,
  record = {},
  maxCharacterLength,
}) {
  const value = _get(record, source);
  const truncated = limitStringSize(value, maxCharacterLength, true);
  const classes = useStyles();

  return (
    <Fragment>
      <Typography
        component='div'
        variant='body2'
        className={className}
        {...typographyProps}
      >
        {maxCharacterLength && value?.length > maxCharacterLength ? (
          <Tooltip title={value} classes={classes}>
            <div>{truncated}</div>
          </Tooltip>
        ) : (
          value
        )}
      </Typography>
    </Fragment>
  );
}

export function NotesModal({ note, open, handleClose, title }) {
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      aria-labelledby='form-dialog-title'
      fullWidth
      disableScrollLock
      PaperComponent={PaperComponent}
    >
      <div style={{ padding: '8px 8px 8px 24px' }}>
        <div>
          <h1 className={classes.title} id='draggable-dialog'>
            {title || 'Note'}
          </h1>
          <DialogContentText color='textPrimary' className={classes.note}>
            {note}
          </DialogContentText>
        </div>

        <div className={classes.btnContainer}>
          <Button
            color='primary'
            variant='contained'
            className={classes.closeBtn}
            onClick={handleClose}
          >
            Close
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

// #region Typedefs
/** @typedef {object} NotesFieldProps
 * @property {import("@mui/material/Typography").TypographyProps} [typographyProps]
 * @property {import("react").HTMLAttributes} [className]
 * @property {import("react").HTMLAttributes} [headerClassName] class applied to the header
 * @property {import("csstype").Properties} [cellStyle]
 * @property {string} source
 * @property {string} label
 * @property {string} maxCharacterLength The max characters that will show with a `show more` button
 * @property {string} [emptyText]
 * @property {string} [modalTitle] The title of the notes modal default `Notes`
 * @property {string} [disablePadding]
 * @property {string} [align]
 * @property {string} [sortBy] Defaults to `source`
 * @property {false} [sortable] Set to false to disable sorting for this column
 */
// #endregion
