import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useMemo,
  useState,
} from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import clsx from 'clsx';
import { DocumentViewModal } from './DocumentViewModal';
import {
  Divider,
  IconButton,
  Grid,
  List,
  ListItem,
  Typography,
  Link,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  FileTypes,
  getFileExtension,
  getFileName,
  checkHasSetting,
  companySettingTypes,
} from '../../lib';
import {
  DeleteIcon,
  DescriptionIcon,
  UploadFileIcon,
  AddLinkIcon,
  CheckCircleIcon,
  CancelIcon,
  InsertLinkIcon,
  EditIcon,
} from '../../themes';
import { AddToEMR, ReadOnlyComponent, TextInput } from '../common';
import { sharedFormStyles } from './sharedStyles';
import { systemPersistSelectors, uiSelectors } from '../../state';

export const useStyles = makeStyles((theme) => ({
  ...sharedFormStyles(theme),
  formPadding: {
    padding: '0px 32px',
  },
  sectionHeader: {
    fontSize: 18,
    fontWeight: 500,
    marginBottom: 16,
  },
  borderColor: {
    color: theme.palette.outlineTheme.main,
  },
  documentsListItem: {
    padding: '16px 0 16px 0',
  },
  file: {
    display: 'flex',
    alignItems: 'center',
    color: 'rgba(0, 0, 0, 0.85)',
    lineHeight: '16px',
  },
  fileIcon: {
    color: theme.palette.text.secondary,
    paddingRight: 8,
  },
  required: {
    color: '#f44336',
    fontSize: 14,
  },

  fileLinkUrl: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'block',
    whiteSpace: 'nowrap',
  },
  filePreviewLink: {
    color: theme.palette.primary.main,
    fontSize: 14,
    cursor: 'pointer',
  },
}));

//when generic doc list=true: allows editing for the doc names, no document type specified
export function DocumentsList({
  addToEMR,
  error,
  documents = [],
  onChange,
  genericDocList = false,
  hideHeader = false,
  useFormPadding = true,
  readOnly = false,
  readOnlyMessage = '',
}) {
  const classes = useStyles();

  const documentTypesObj =
    useSelector(systemPersistSelectors.documentTypesObj, shallowEqual) || {};

  const [previewFile, setPreviewFile] = useState(false);

  const onUpload = useCallback(
    (e, index) => {
      e.stopPropagation();
      e.preventDefault();
      var upload = e.target.files[0];
      if (!upload) {
        return;
      }

      const newDocuments = [...documents];
      const fileExtension = getFileExtension(upload.name);
      const name = getFileName(upload.name);
      newDocuments[index] = {
        ...documents[index],
        file: upload,
        fileExtension,
        name,
        previewURL: URL.createObjectURL(upload),
      };
      onChange(newDocuments);
    },
    [documents, onChange],
  );

  const onSaveLink = useCallback(
    (url, index) => {
      const newDocuments = [...documents];
      const { documentType } = documents[index];
      newDocuments[index] = {
        ...documents[index],
        customName: documentType,
        fileLocation: url,
        fileType: FileTypes.External,
      };
      onChange(newDocuments);
    },
    [documents, onChange],
  );

  const handleDelete = (index) => {
    const newDocuments = [...documents];
    if (genericDocList) {
      newDocuments.splice(index, 1);
    } else {
      const { documentType, required } = documents[index];
      newDocuments[index] = { documentType, required };
    }

    onChange(newDocuments);
  };

  const onSaveChanges = useCallback(
    (document, index) => {
      const newDocuments = [...documents];
      newDocuments[index] = { ...newDocuments[index], ...document };
      onChange(newDocuments);
    },
    [documents, onChange],
  );

  return (
    <>
      {!hideHeader && (
        <div className={useFormPadding ? classes.formPadding : undefined}>
          <div className={classes.sectionTitle}>Documents</div>
          {error && <div className={classes.required}>{error}</div>}
        </div>
      )}
      <Grid
        container
        className={useFormPadding ? classes.formPadding : undefined}
      >
        <Grid item xs style={{ padding: 0 }}>
          <Divider className={classes.borderColor} />
          <List>
            {Object.keys(documents).map((key, i) => (
              <DocumentListItem
                document={documents[key]}
                documentTypesObj={documentTypesObj}
                handleDelete={handleDelete}
                key={key}
                index={key}
                onUpload={onUpload}
                setPreviewFile={setPreviewFile}
                onSaveLink={onSaveLink}
                genericDocList={genericDocList}
                onSaveChanges={onSaveChanges}
                useFormPadding={useFormPadding}
                readOnly={readOnly}
                readOnlyMessage={readOnlyMessage}
                addToEMR={addToEMR}
              />
            ))}
          </List>
        </Grid>
        {!!previewFile && (
          <DocumentViewModal
            open={true}
            handleClose={() => setPreviewFile(null)}
            file={previewFile}
          />
        )}
      </Grid>
    </>
  );
}

function DocumentListItem({
  document,
  documentTypesObj,
  handleDelete,
  index,
  onUpload,
  setPreviewFile,
  onSaveLink,
  genericDocList = false,
  onSaveChanges, //use this when genericDocList is true, save editing of the names/links
  useFormPadding = true,
  readOnly = false, //no editing/adding of the documents allowed, just view the list
  readOnlyMessage = 'editing not allowed',
  addToEMR,
}) {
  const {
    customName,
    name,
    fileExtension,
    documentType,
    required,
    fileType,
    fileLocation,
    file,
  } = document;

  const companySettings = useSelector(uiSelectors.companySettings);
  const hasDefaultPushDocsToEMR = useMemo(
    () =>
      !!checkHasSetting(
        companySettings,
        companySettingTypes.DefaultPushDocsToEMR,
      ),
    [companySettings],
  );

  const classes = useStyles();
  const inputFile = useRef(null);
  const [editing, setEditing] = useState(false);
  const [fileUrl, setFileUrl] = useState('');
  const [fileName, setFileName] = useState('');
  const [uploadToEMR, setUploadToEMR] = useState(hasDefaultPushDocsToEMR);

  //if the file changes, keep the state up to date for edit mode
  useEffect(() => {
    setFileUrl(fileLocation);
    setFileName(fileType === FileTypes.External ? customName : name);
  }, [fileLocation, name, customName, fileType]);

  const onUploadClick = () => {
    // `current` points to the mounted file input element
    inputFile.current.click();
  };

  const onCancelEdit = useCallback(() => {
    setEditing(false);
    //reset the edit values
    setFileUrl(fileLocation);
    setFileName(fileType === FileTypes.External ? customName : name);
  }, [fileLocation, fileType, customName, name]);

  const onChangeUrl = useCallback((e) => {
    setFileUrl(e.target.value);
  }, []);
  const onChangeFileName = useCallback((e) => {
    setFileName(e.target.value);
  }, []);

  const handleSaveLink = useCallback(() => {
    onSaveLink(fileUrl, index);
    setEditing(false);
  }, [fileUrl, index, onSaveLink]);

  const handleSaveChanges = useCallback(
    (uploadToEMR) => {
      const customNameUpdate =
        fileType === FileTypes.External
          ? fileName
          : `${fileName}.${fileExtension}`;
      onSaveChanges(
        {
          fileLocation: fileUrl,
          customName: customNameUpdate,
          name: fileName,
          addToEMR: uploadToEMR,
        },
        index,
      );
      setEditing(false);
    },
    [fileUrl, fileName, fileExtension, index, fileType, onSaveChanges],
  );

  const handleKeyDown = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        handleSaveChanges();
      }
    },
    [handleSaveChanges],
  );

  //spelling out all the logic:
  //The non-generic doc list uses the first column for the doc type and the second one gets the url/name
  //the generic doc list uses the first column for the name and the second one is the url/preview, there is no doc type here.
  const showFirstColumnNameTypeReadOnly = !editing || !genericDocList;
  const showFirstColumnNameEdit = editing && genericDocList;
  const showSecondColumnUrlEdit =
    editing && ((!file && !fileLocation) || fileType === FileTypes.External);
  const showSecondColumnPreview =
    (file || fileLocation) && (!editing || fileType !== FileTypes.External);
  const showEditIcon = !editing && genericDocList;
  const showDeleteIcon = !editing && (fileLocation || file);
  const showAddDocumentIcons = !editing && !file && !fileLocation;

  return (
    <>
      <ListItem
        classes={{ root: useFormPadding && classes.documentsListItem }}
        sx={{
          display: genericDocList ? 'grid' : 'flex',
          gridTemplateColumns: 'calc(100% - 336px) 120px 136px 80px',
        }}
      >
        {/* first column doc type/name */}
        <div
          style={{ width: genericDocList ? '100%' : '20%', paddingRight: 16 }}
        >
          {showFirstColumnNameTypeReadOnly && (
            <span
              style={{ fontWeight: 600 }}
              className={genericDocList ? classes.fileLinkUrl : undefined}
            >
              {genericDocList ? (
                fileType === FileTypes.External ? (
                  customName
                ) : (
                  `${name}.${fileExtension}`
                )
              ) : (
                <>
                  {documentTypesObj[documentType]?.name}
                  {required && <span className={classes.required}>*</span>}
                </>
              )}
            </span>
          )}
          {showFirstColumnNameEdit && (
            <TextInput
              label='Document name'
              value={fileName}
              onChange={onChangeFileName}
              onKeyDown={handleKeyDown}
              fullWidth
              inputProps={{ maxLength: 50 }}
            />
          )}
        </div>
        {/* second column file name/link/preview */}
        <div
          style={{
            width: genericDocList ? '100%' : '70%',
            paddingRight: genericDocList ? 16 : 26,
          }}
        >
          {showSecondColumnPreview && (
            <div className={classes.file}>
              <div className={classes.fileIcon}>
                {fileType === FileTypes.External ? (
                  <InsertLinkIcon />
                ) : (
                  <DescriptionIcon />
                )}
              </div>

              {fileType === FileTypes.External ? (
                <Link
                  href={fileLocation}
                  target='_blank'
                  rel='noopener noreferrer'
                  underline='none'
                  className={classes.fileLinkUrl}
                >
                  {fileLocation}
                </Link>
              ) : (
                <Typography
                  onClick={() => setPreviewFile(document)}
                  className={clsx(classes.filePreviewLink, classes.fileLinkUrl)}
                >
                  {genericDocList
                    ? 'preview'
                    : customName ?? `${name}.${fileExtension}`}
                </Typography>
              )}
            </div>
          )}

          {showSecondColumnUrlEdit && (
            <div>
              <TextInput
                label='URL'
                value={fileUrl}
                onChange={onChangeUrl}
                fullWidth
              />
            </div>
          )}
        </div>
        <div>
          {addToEMR && (
            <AddToEMR
              checked={uploadToEMR}
              onChange={() => {
                setUploadToEMR(!uploadToEMR);
                handleSaveChanges(!uploadToEMR);
              }}
            />
          )}
        </div>
        <div
          style={{ width: genericDocList ? '100%' : '10%', textAlign: 'right' }}
        >
          {showEditIcon && (
            <ReadOnlyComponent isReadOnly={readOnly} message={readOnlyMessage}>
              <IconButton
                onClick={() => setEditing(true)}
                className={classes.secondaryIconButtons}
                size='large'
              >
                <EditIcon />
              </IconButton>
            </ReadOnlyComponent>
          )}
          {showDeleteIcon && (
            <ReadOnlyComponent isReadOnly={readOnly} message={readOnlyMessage}>
              <IconButton
                onClick={() => handleDelete(index)}
                className={classes.secondaryIconButtons}
                size='large'
              >
                <DeleteIcon />
              </IconButton>
            </ReadOnlyComponent>
          )}
          {editing && (
            <>
              <IconButton
                className={classes.checkCircleIcon}
                onClick={genericDocList ? handleSaveChanges : handleSaveLink}
                size='large'
              >
                <CheckCircleIcon />
              </IconButton>
              <IconButton
                onClick={onCancelEdit}
                className={classes.secondaryIconButtons}
                size='large'
              >
                <CancelIcon />
              </IconButton>
            </>
          )}
          {showAddDocumentIcons && (
            <>
              <span>
                <input
                  type='file'
                  id='file'
                  name='documents'
                  onClick={(e) => (e.target.value = '')}
                  onChange={(e) => onUpload(e, index)}
                  ref={inputFile}
                  style={{ display: 'none' }}
                  multiple={true}
                />
                <ReadOnlyComponent
                  isReadOnly={readOnly}
                  message={readOnlyMessage}
                >
                  <IconButton
                    size='medium'
                    className={classes.secondaryIconButtons}
                    onClick={onUploadClick}
                  >
                    <UploadFileIcon />
                  </IconButton>
                </ReadOnlyComponent>
              </span>
              <ReadOnlyComponent
                isReadOnly={readOnly}
                message={readOnlyMessage}
              >
                <IconButton
                  size='medium'
                  className={classes.secondaryIconButtons}
                  onClick={() => setEditing(true)}
                >
                  <AddLinkIcon />
                </IconButton>
              </ReadOnlyComponent>
            </>
          )}
        </div>
      </ListItem>
      <Divider className={classes.borderColor} />
    </>
  );
}
