import React from 'react';
import _get from 'lodash.get';
import { Typography } from '@mui/material';
import { formatCurrency } from '../../../../lib';

// import { useStyles } from './fields.styles';

/** CurrencyField props.
 * @param {CurrencyFieldProps} props
 */
export function CurrencyField({
  className,
  typographyProps = {},
  source,
  record = {},
  orderBy,
  showZero = false,
  ...rest
}) {
  // const classes = useStyles();
  const value = _get(record, source);
  if (value === null || value === '' || (!value && !showZero)) return null;
  return (
    <Typography
      component='span'
      variant='body2'
      className={className}
      {...typographyProps}
    >
      {formatCurrency(value, 2)}
    </Typography>
  );
}

// #region Typedefs
/** @typedef {object} CurrencyFieldProps
 * @property {import("@mui/material/Typography").TypographyProps} [typographyProps]
 * @property {import("react").HTMLAttributes} [className]
 * @property {import("react").HTMLAttributes} [headerClassName] class applied to the header
 * @property {import("csstype").Properties} [cellStyle]
 * @property {string} source
 * @property {string} label
 * @property {string} [disablePadding]
 * @property {string} [align]
 * @property {string} [sortBy] Defaults to `source`
 * @property {false} [sortable] Set to false to disable sorting for this column
 */
// #endregion
