import makeStyles from '@mui/styles/makeStyles';
import { sharedFormStyles } from '../../../../components/modals/sharedStyles';

export const useStyles = makeStyles((theme) => ({
  ...sharedFormStyles(theme),
  residentsFilterPopperButtons: {
    backgroundColor: (props) =>
      props.open ? theme.palette.action.selected : null,
    minHeight: 34,
  },
  filterOptionClearable: {
    paddingRight: 2,
  },
  input: {
    borderBottom: '1px solid #dfe2e5',
  },
  paper: {
    boxShadow: 'none',
    margin: 0,
    fontSize: 13,
  },
  menuPaper: {
    minWidth: 160,
    overflow: 'auto',
    maxHeight: 450,
  },
  option: {
    color: '#000000',
    paddingLeft: 19,
    margin: 0,

    fontSize: 12,
  },
  popperDisablePortal: {
    position: 'relative !important',
    transform: 'translate3d(0, 0, 0) !important',
  },
  multiSelectOption: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  selectOnlyLink: {
    fontSize: 13,
    fontWeight: 500,
    color: theme.palette.primary.light,
    cursor: 'pointer',
  },
  cancelIcon: {
    cursor: 'pointer',
    color: '#8990B6',
    marginRight: 8,
  },
  clearSelectedItemsButton: {
    textTransform: 'none',
    color: theme.palette.primary.light,

    fontSize: 12,
    fontWeight: 500,
    cursor: 'pointer',
    marginLeft: 16,
    marginBottom: 4,
  },
  allItemsLabel: {
    fontSize: 14,
    fontWeight: 600,
    marginBotton: 4,
  },
  allItemsLabelWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    marginLeft: 16,
    marginTop: 20,
    marginRight: 16,
  },

  closeButton: {
    color: theme.palette.primary.light,
    textTransform: 'none',
    marginRight: 8,
  },
  updateButton: {
    fontSize: 13,
  },
  filterActions: {
    display: 'flex',
    justifyContent: 'end',
    marginTop: 24,
  },
}));
