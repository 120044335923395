import React from 'react';
// import clsx from 'clsx';
import { Tabs, Tab } from '@mui/material';
import { useStyles } from './actionsTabs.styles';

export function ActionsTabs({ tabs, tabPosition, onClick, style, variant }) {
  const classes = useStyles();

  const handleChange = (event, newValue) => {
    onClick(event, newValue);
  };

  return (
    <Tabs
      //if tabPosition is undefined, set value to false to turn off the errors that its not a valid value.  There will just be no selected tab
      value={tabPosition || false}
      onChange={handleChange}
      classes={{ indicator: classes.indicator }}
      style={style}
      variant={variant || 'standard'}
    >
      {tabs.map((t, i) => {
        const { name, value, disabled } = t;
        return (
          <Tab
            key={i}
            value={value}
            label={name}
            disabled={disabled}
            disableFocusRipple
            disableRipple
            disableTouchRipple
            classes={{
              root: classes.root,
              selected: classes.selected,
            }}
          />
        );
      })}
    </Tabs>
  );
}
