import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGetQueryParams, useUpdatePageQueryParams } from '../../hooks';
import FilterOption from '../../pages/main/ListViews/filters/FilterOption';
import { facilityActions, facilitySelectors } from '../../state';

const emptyArray: any[] = [];

export const FacilitiesFilterOption = memo(function () {
  const dispatch = useDispatch();

  const { facilities = emptyArray } = useGetQueryParams();
  const updatePageQueryParams = useUpdatePageQueryParams();

  const facilityOptionsLoading = useSelector(
    facilitySelectors.facilityOptionsLoading,
  );
  const hasNoFacilities = useSelector(facilitySelectors.hasNoFacilities);
  const allFacilities = useSelector(facilitySelectors.facilityOptions);

  const selectedFacilityIds = useMemo(
    () => (Array.isArray(facilities) ? facilities : [facilities]),
    [facilities],
  );

  useEffect(() => {
    if (
      !facilityOptionsLoading &&
      allFacilities.length === 0 &&
      !hasNoFacilities
    ) {
      dispatch(facilityActions.getFacilities());
    }
  }, [dispatch, allFacilities, hasNoFacilities, facilityOptionsLoading]);

  const onChangeSelectedFacilities = useCallback(
    (newValue: number | number[]) =>
      updatePageQueryParams({ facilities: newValue }),
    [updatePageQueryParams],
  );

  return (
    <FilterOption
      label='Facility'
      menuItems={allFacilities}
      multiple
      name='facility'
      onChange={onChangeSelectedFacilities}
      searchPlaceholder='Find facilities...'
      selectedMenuItems={selectedFacilityIds.map((f) => ({
        id: parseInt(f),
      }))}
    />
  );
});
