import makeStyles from '@mui/styles/makeStyles';
const drawerWidth = 272;
export const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(8),
    },
  },
  drawerBorder: {
    borderRight: '1px solid #E5E7F5',
    justifyContent: 'space-between',
  },

  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingTop: 8,
    paddingLeft: 16,
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
      cursor: 'pointer',
    },
  },
  button: {
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
      cursor: 'pointer',
    },
  },
  icon: {
    color: '#FFFFFF',
  },
  link: {
    color: theme.palette.textTheme.main,
    fontSize: 16,
    '& .MuiListItemIcon-root': {
      color: theme.palette.outlineTheme.main,
    },
    '&.active': {
      '& .MuiListItemIcon-root': {
        color: theme.palette.primary.main,
      },
    },
  },
  active: {
    color: theme.palette.text.primary,
    '& .MuiListItemIcon-root': {
      color: '#003FED',
    },
  },
  badge: {
    width: 15,
    minWidth: 15,
    height: 15,
    fontSize: 10,
    right: 5,
    top: 3,
    backgroundColor: '#E91F31',
    fontWeight: 600,
  },
  avatar: {
    height: 32,
    width: 32,
    fontSize: 14,
  },
  listItemText: {
    '& span': {
      fontFamily: 'Roboto',
    },
  },
  linkColor: {
    '& span': {
      color: '#6C7493',
    },
  },
}));
