import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    color: '#000000',
    fontSize: 18,
    fontWeight: 500,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  menu: {
    minWidth: 160,
  },
  menuItem: {
    '& .MuiSvgIcon-root': {
      color: theme.palette.outlineTheme.main,
      fontSize: 30,
      paddingRight: 10,
    },
    fontSize: 14,
  },
  //since this icon is imported differently, it doesnt have the mui class
  mdIcon: {
    color: theme.palette.outlineTheme.main,
    fontSize: 30,
    paddingRight: 10,
  },
  userDetails: {
    paddingLeft: 8,
    paddingTop: 16,
    paddingBottom: 12,
    paddingRight: 8,
  },
  userType: {
    color: '#8990B6',
    fontSize: 8,
    fontWeight: 600,
    textTransform: 'uppercase',
    paddingBottom: 2,
  },
  userName: {
    fontSize: 14,
    fontWeight: 500,
    paddingBottom: 2,
  },
  email: {
    color: '#8990B6',
    fontSize: 12,
  },
}));
