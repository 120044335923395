import React, { memo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Snackbar, IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { uiActions, uiSelectors } from '../../state';
import { CloseIcon, CheckCircleIcon } from '../../themes';

const useStyles = makeStyles((theme) => ({
  loadingBackdrop: {
    backgroundColor: 'transparent',
    zIndex: theme.zIndex.modal + 100,
  },
  error: {
    background: theme.palette.error.light,
  },
  warning: {
    background: theme.palette.warning.light,
  },
  success: {
    background: '#0FC076',
  },
  message: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'pre',
  },
  icon: {
    marginRight: 6,
  },
}));

export const Notification = memo(() => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const notification = useSelector(uiSelectors.notification);
  const { message, variant, duration = 6000 } = notification || {};

  const onClose = useCallback(
    (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      dispatch(uiActions.hideNotification());
    },
    [dispatch],
  );

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      autoHideDuration={duration}
      open={!!notification}
      onClose={onClose}
      ContentProps={{
        'aria-describedby': 'notification-message',
        classes: { root: classes[variant] },
      }}
      message={
        <span id='notification-message' className={classes.message}>
          {variant === 'success' && (
            <CheckCircleIcon className={classes.icon} />
          )}
          {message}
        </span>
      }
      action={
        <IconButton
          key='close'
          aria-label='close'
          color='inherit'
          onClick={onClose}
          size='large'
        >
          <CloseIcon />
        </IconButton>
      }
    />
  );
});
