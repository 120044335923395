import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  filterDropdownContainer: {
    position: 'relative',
    height: '100%',
    '& > div': {
      height: '100%',
    },
  },
  filterDropdownLabel: {
    padding: 8,
    border: '1px solid #D5D8EC',
    borderRadius: 4,
    textTransform: 'capitalize',
    fontWeight: 400,
    height: '100%',
  },
  filterDropdown: {
    width: 280,
    height: 200,
    marginLeft: 20,
    boxShadow: '0 2px 32px 0 rgba(0,0,0,0.08), 0 2px 24px 0 rgba(0,0,0,0.12)',
    borderRadius: 1,
    overflow: 'hidden',
    paddingBottom: '25px',
    background: '#fff',
    cursor: 'pointer',
  },
  search: {
    width: '100%',
    '& > div': {
      padding: '12px 16px 8px',
      marginBottom: 16,
    },
    '& .MuiInput-underline:before': {
      borderBottom: '1px solid #E5E7F5',
    },
    '& input': {
      fontSize: 14,
      fontFamily: 'Roboto',
    },
  },
  filtersDropdownList: {
    marginTop: -16,
    height: 'calc(200px - 46px)',
    marginBottom: 0,
    overflow: 'auto',
    '& li': {
      listStyleType: 'none',
    },
  },
  filtersDropdownListItem: {
    height: 24,
    '& .MuiButtonBase-root:(not):(.Mui-checked)': {
      color: '#B9BDD4',
    },
    '& .MuiTypography-body1': {
      fontSize: 14,
    },
  },
}));
