import { memo } from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { AppBar as MuiAppBar, Box, Toolbar } from '@mui/material';
import { useStyles } from './appBar.styles';
import { useIsTablet, useGetUsersData } from '../../src/hooks';
import { SuperAdminCompanyDropdown } from '../components/ui/SuperAdminCompanyDropdown';
import { GlobalSearch } from '../components/ui/GlobalSearch';
import { uiSelectors } from '../state';

export const AppBar = memo(({ fullWidth }) => {
  const classes = useStyles();
  const isTablet = useIsTablet();
  const pageNameHeader = useSelector(uiSelectors.pageNameHeader);
  const tabbedHeader = useSelector(uiSelectors.tabbedHeader);
  const { companyLogoUrl, WorkingAsCompanyIdClaim } = useGetUsersData();

  const isExternalUser = !!WorkingAsCompanyIdClaim;
  const showExternalUserBadge = isExternalUser && !fullWidth;

  return (
    <MuiAppBar
      position='sticky'
      className={clsx(classes.appBar, {
        // [classes.appBarShift]: isSidebarOpen,
        [classes.tabbedHeader]: tabbedHeader,
      })}
      elevation={0}
      sx={{
        '@media print': {
          display: 'none',
        },
      }}
    >
      <Toolbar
        className={clsx(classes.toolBar, {
          [classes.tabbedHeader]: tabbedHeader,
        })}
      >
        {pageNameHeader ? (
          <div className={classes.title}>{pageNameHeader || ''}</div>
        ) : isTablet ? null : (
          // div needed to hold space for flex justify
          <Box sx={{ width: 420 }}></Box>
        )}
        <div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img
              src={
                companyLogoUrl ||
                'https://storage.googleapis.com/aidace-public/aidace-logo-full.svg'
              }
              alt=''
              className={
                showExternalUserBadge ? classes.guestLogo : classes.logo
              }
              draggable={false}
            />
            <SuperAdminCompanyDropdown />
          </div>
          {showExternalUserBadge && (
            <div className={classes.guestText}>External User</div>
          )}
        </div>
        <GlobalSearch />
      </Toolbar>
    </MuiAppBar>
  );
});
