import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Provider, useDispatch } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { IntercomProvider } from 'react-use-intercom';
import jwtDecode from 'jwt-decode';
import ReactGA from 'react-ga4';
import { create } from 'jss';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import StylesProvider from '@mui/styles/StylesProvider';
import jssPreset from '@mui/styles/jssPreset';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import { defaultTheme } from './themes';
import { store, persistor, activateStore } from './store';
import { systemPersistActions, uiActions, authActions } from './state';
import {
  setAuthRequestToken,
  setSuperAdminCompanyIdHeader,
  PermissionClaims,
  Routes,
} from './lib';
import { useGetUsersData } from './hooks';
import Router from './lib/routing/Router';
import RegisterLists from './RegisterLists';
import { IdentifyUser } from './components/error/ErrorLogging';
import { differenceInMinutes } from 'date-fns';
import { ServiceWorkerWrapper } from './ServiceWorkerWrapper';

import './themes/print.css';

ReactGA.initialize('G-WTLRYQ7VLW');

const jss = create({
  ...jssPreset(),
  // Define a custom insertion point that JSS will look for when injecting the styles into the DOM.
  // see https://material-ui.com/styles/advanced/#css-injection-order
  insertionPoint: document.getElementById('jss-insertion-point'),
});

/**
 * Decoded authorization data.
 * @type {{userId:string, roles?:string[]}}
 */
let authTokenData;

/**
 * Loads the token from storage or redux and registers it with `AuthRequest`.
 */
function preloadAuthToken() {
  if (authTokenData) {
    return;
  }
  const state = store.getState();
  const {
    authPersist: {
      data: { expiration, token, requiresMfa } = {},
      lastActiveTime,
    } = {},
  } = state;
  if (token) {
    //check if the user was idle/off the screen for longer than specified idle time -> require them to login again
    if (
      lastActiveTime &&
      differenceInMinutes(new Date(), new Date(lastActiveTime)) >=
        process.env.REACT_APP_INACTIVITY_TIMEOUT
    ) {
      store.dispatch(authActions.setAutoIdleLogout(true));
      return;
    }
    authTokenData = jwtDecode(token);
    setAuthRequestToken(token, expiration, requiresMfa);
  }
}

let companyIdHeader;

/**
 * Loads the companyIdHeader from storage or redux and registers it with `AuthRequest`.
 */
function preloadSuperAdminCompanyIdHeader() {
  if (companyIdHeader) {
    return;
  }
  const state = store.getState();
  const { systemPersist: { superAdminCompanyHeader: { id } = {} } = {} } =
    state;
  if (
    id &&
    authTokenData?.PermissionClaim.includes(
      PermissionClaims.SuperAdminEditClaim,
    )
  ) {
    companyIdHeader = id;
    setSuperAdminCompanyIdHeader(id);
  }
}

function FetchSystemData() {
  const dispatch = useDispatch();
  const { token, requiresMfa, companyID } = useGetUsersData();
  useEffect(() => {
    if (token && !requiresMfa) {
      dispatch(systemPersistActions.getSystemSettings());
      dispatch(uiActions.getUserSettings());
      dispatch(uiActions.getTodosCount());
    }
  }, [dispatch, token, requiresMfa]);

  useEffect(() => {
    if (token && !requiresMfa && companyID) {
      dispatch(uiActions.getCompanySettings(companyID));
      dispatch(uiActions.getPotentialCompanyUsers());
    }
  }, [companyID, token, requiresMfa, dispatch]);

  return null;
}

LicenseInfo.setLicenseKey(
  'de501077a5ff808a98f2975fb32c65d6Tz02OTA2OCxFPTE3MTg4OTM1NjA3NDgsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=',
);

function render() {
  const isExtensionTokenRequest = window.location.href.includes(
    Routes.getToken.path,
  );
  const state = store.getState();
  const {
    authPersist: {
      data: { userName, userId, intercomUserHash },
    },
  } = state;
  ReactDOM.render(
    <React.StrictMode>
      <StylesProvider jss={jss}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={defaultTheme}>
            <ServiceWorkerWrapper />
            <IntercomProvider
              appId={process.env.REACT_APP_INTERCOM_APP_ID}
              autoBoot
              autoBootProps={{
                customLauncherSelector: '#intercom-launcher',
                email: userName,
                userId: userId,
                userHash: intercomUserHash,
                hideDefaultLauncher: true,
              }}
            >
              <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                  {!isExtensionTokenRequest && (
                    <>
                      <FetchSystemData />
                      <IdentifyUser />
                      <RegisterLists />
                    </>
                  )}
                  <Router />
                </PersistGate>
              </Provider>
            </IntercomProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </StylesProvider>
    </React.StrictMode>,
    document.getElementById('root'),
  );
}

/**
 * Startup function.
 *
 * NOTE: The `store` already comes preloaded with data since `state/store.js`
 * does that.
 */
async function main() {
  await activateStore();
  preloadAuthToken();
  preloadSuperAdminCompanyIdHeader();
  render();
}

main();
