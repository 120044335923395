import React from 'react';
import { NavLink } from 'react-router-dom';
import { Link as MuiLink } from '@mui/material';

/**
 * `Material-UI` link with `react-router-dom` routing using our pages data
 * structure.
 * @param {import("react-router-dom").NavLinkProps & import("@mui/material/Link").LinkProps} props
 */

export function MuiPageNavLink(props) {
  const { children, disabled, ...rest } = props;
  return (
    <MuiLink component={disabled ? 'div' : NavLink} underline='none' {...rest}>
      {children}
    </MuiLink>
  );
}
