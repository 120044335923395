import { State } from '../../../../state/types';
import { ResidentFilters } from './ResidentListConfig';

export const BoardFilters: Filter[] = [...ResidentFilters];

// TODO move types to ListHelpers when convert that to ts
export interface Filter {
  name: string;
  companyType?: string; // companyTypes.Application | companyTypes.Facility
  defaultValue: string | number | boolean | string[] | number[] | boolean[];
  dependsOn?: string;
  disableSort?: boolean;
  display?: () => {};
  exportHeaders?: { key: string; label: string; format?: string }[];
  exportHeaders_AC?: { key: string; label: string; format?: string }[];
  label?: string;
  label_AC?: string;
  menuItemsSelector?: (state: State) => any;
  menuItemsSelector_AC?: (state: State) => any;
  queryParam: string;
  type?: string; // 'selectMenu' | 'checkbox' | 'multi' | 'twoInOne' | 'dependant' | 'autocomplete';
  primaryFilter?: string;
  menuItems?: FilterSelectOption[];
  searchPlaceholder?: string;
  searchPlaceholder_AC?: string;
  valueFormatter?: (arg0: any) => any;
}

type FilterSelectOption = {
  id: string | boolean | number;
  name: string;
};
