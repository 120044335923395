import { useCallback } from 'react';
import jsonExport from 'jsonexport/dist';
import {
  authGet,
  formatDate,
  formatPhone,
  formatCurrency,
  formatBool,
} from '../lib';

export function useExport() {
  const cb = useCallback(
    async ({ path, queryParams, notify, headersArray, fileName }) => {
      const response = await authGet([path, queryParams]);
      const { data, error } = response;
      if (error) {
        return notify('Error exporting data', 'error');
      }
      const dataArray = Array.isArray(data) ? data : data.results;
      const { rename, headers, fields } = generateFieldsForExport(
        dataArray,
        headersArray,
      );
      jsonExport(
        fields,
        {
          headers,
          rename,
        },
        (err, csv) => {
          downloadCSV(csv, fileName);
        },
      );
      // setData(generateFieldsForExport(dataArray, headersArray));
    },
    [],
  );
  return cb;
}

// code from https://github.com/marmelab/react-admin/blob/master/packages/ra-core/src/export/downloadCSV.ts
function downloadCSV(csv, filename) {
  const fakeLink = document.createElement('a');
  fakeLink.style.display = 'none';
  document.body.appendChild(fakeLink);
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    // Manage IE11+ & Edge
    window.navigator.msSaveOrOpenBlob(blob, `${filename}.csv`);
  } else {
    fakeLink.setAttribute('href', URL.createObjectURL(blob));
    fakeLink.setAttribute('download', `${filename}.csv`);
    fakeLink.click();
  }
}

function generateFieldsForExport(data, headersArray) {
  if (!headersArray) return { fields: data };
  const rename = [];
  const headers = [];
  const fields = [];
  // get headers and rename fields
  headersArray.forEach((h) => {
    rename.push(h.label);
    headers.push(h.key);
  });
  // get data values thats in the headersArray
  data.forEach((row) => {
    const valuesObj = headersArray.reduce((acc, cur) => {
      const key = cur.key;
      if (row.hasOwnProperty(key)) {
        let value = row[key];
        if (Array.isArray(value)) {
          if (cur.formatProperty) {
            value = value.map((v) => v[cur.formatProperty]).join(',');
          } else {
            value = value.join(',');
          }
        }
        if (cur.format) {
          value = formatters[cur.format]?.(value) || value;
        } else if (cur.formatter) {
          value = cur.formatter(value);
        }
        acc[key] = value;
      }
      return acc;
    }, {});
    fields.push(valuesObj);
  });
  return { rename, headers, fields };
}

const formatters = {
  date: formatDate,
  dateTime: (value) => formatDate(value, 'MM/dd/yyyy h:mm a z'),
  phone: formatPhone,
  currency: formatCurrency,
  bool: formatBool,
};

//#region typedef
/**
 * @typedef {object} exportProps
 * @property {string} path The API URL
 * @property {object} queryParams
 * @property {Array.<headersObj>} headersArray
 * @property {string} fileName The name of the generated csv
 */

/**
 * @typedef {object} headersObj
 * @property {string} key the field name sent to the API
 * @property {string} label the display name
 * @property {string} [formatProperty] value to display if passing in an array of objects
 * @property {"date"|"phone"|"currency"} [format] how to format the value
 */

//#region
