import React from 'react';
import ReactRouterPrompt from 'react-router-prompt';
import { Button, Dialog, DialogActions, Typography } from '@mui/material';

export default function Prompt({ when, message }) {
  return (
    <ReactRouterPrompt when={when}>
      {({ isActive, onConfirm, onCancel }) => (
        <Dialog maxWidth='xs' fullWidth open={isActive} onClose={onCancel}>
          <Typography sx={{ padding: 3 }}>{message}</Typography>
          <DialogActions>
            <Button
              color='primary'
              variant='contained'
              onClick={onCancel}
              sx={{
                backgroundColor: '#E5E7F5',
                '&:hover': {
                  backgroundColor: '#E5E7F5',
                },
                color: 'text.primary',
              }}
            >
              Cancel
            </Button>
            <Button color='primary' variant='contained' onClick={onConfirm}>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </ReactRouterPrompt>
  );
}
