import { useCallback } from 'react';
import { useNotify } from './useNotify';

export function useCopyTextToClipboard() {
  const notify = useNotify();
  const cb = useCallback(
    (dataToCopy) => {
      navigator.clipboard.writeText(dataToCopy);
      notify('Copied', 'info', 500);
    },
    [notify],
  );

  return cb;
}

/**
 * can copy rich text/html images etc
 */
export function useCopyToClipboard() {
  const notify = useNotify();
  const cb = useCallback(
    (htmlContent, text) => {
      const blobHtml = new Blob([htmlContent], { type: 'text/html' });
      const blobText = new Blob([text], { type: 'text/plain' });

      const data = [
        new window.ClipboardItem({
          'text/plain': blobText,
          'text/html': blobHtml,
        }),
      ];

      navigator.clipboard.write(data);
      notify('Copied', 'info', 500);
    },
    [notify],
  );

  return cb;
}
