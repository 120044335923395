const emptyArray = [];
const emptyObject = {};
export const companySelectors = {
  companies: (state) => state.companies.companies || emptyArray,
  hasNoAllowedCompanies: (state) => state.companies.hasNoAllowedCompanies,
  company: (state) => state.companies.company,
  payers: (state) => state.companies.company.payers,
  residentInternalIntegrationSettings: (state) =>
    state.companies.company.residentInternalIntegrationSettings || emptyObject,
  allInternalIntegrationSettings: (state) =>
    state.companies.company.allInternalIntegrationSettings || emptyArray,
};
