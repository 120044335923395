import React, { memo, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { CssBaseline, Button } from '@mui/material';
import { useStyles } from './notFound.styles';
import { Routes } from '../../lib';

function _NotFound() {
  const classes = useStyles();
  return (
    <Fragment>
      <CssBaseline />
      <div className={classes.layout}>
        <div className={classes.mainText}>404</div>
        <div className={classes.subText}>
          We can’t seem to find the page you’re looking for
        </div>
        <Button
          component={Link}
          variant='contained'
          to={Routes.home.path}
          className={classes.btn}
        >
          Go home
        </Button>
        <img
          src='/images/aidace404.svg'
          alt=''
          draggable={false}
          className={classes.pageImg}
        />
      </div>
    </Fragment>
  );
}

export const NotFoundPage = memo(_NotFound);
