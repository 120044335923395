import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { uiActions } from '../state';

export function useLoader() {
  const dispatch = useDispatch();
  const retFunc = useCallback(
    /**
     * Toggles the page loader
     * @param {boolean} setLoading Set to true to show page loader. Defaults to false.
     */
    (setLoading = false) => {
      dispatch(uiActions.setUILoading(setLoading));
    },
    [dispatch],
  );
  return retFunc;
}
