import { authGet, formatDate } from '../../lib';
import { analytics } from './state';
import {
  ResidentDateListGrouping,
  ResidentDateTypeType,
  ResidentDatesQueryType,
} from './types';
import { Dispatch, GetState } from '../types';

const { actions } = analytics;

export const analyticsActions = {
  ...actions,
  getResidentActivity({
    residentIDs = [],
    facilityIDs = [],
    pageNumber,
  }: {
    residentIDs: number[];
    facilityIDs: number[];
    pageNumber: number;
  }) {
    return async (dispatch: Dispatch, getState: GetState) => {
      const {
        analytics: { activity },
      } = getState();

      const response = await authGet([
        `/analytics/activity/list`,
        { residentIDs, facilityIDs, pageNumber },
      ]);
      const { data, error } = response;
      if (error) {
        return { error };
      }

      const { results, ...rest } = data;
      const newActivity =
        pageNumber === 1 ? results : activity.results.concat(results);
      dispatch(
        actions.setActivity({
          results: newActivity,
          ...rest,
        }),
      );

      return { data };
    };
  },

  getResidentCounts(facilityIDs: number[] = [], tasksAssignedToMe = true) {
    return async () => {
      const response = await authGet([
        `/analytics/resident/counts`,
        { facilityIDs, tasksAssignedToMe },
      ]);
      const { data, error } = response;
      return error ? { error } : { data };
    };
  },

  getResidentDates(
    facilityIDs: string[],
    tasksAssignedToMe: boolean,
    queryType: ResidentDatesQueryType,
    dateType: ResidentDateTypeType | undefined,
    groupBy: ResidentDateListGrouping | undefined,
    startDate: Date,
    endDate: Date,
    timeZoneID: string,
  ) {
    return async () => {
      const response = await authGet([
        `/analytics/resident/dates`,
        {
          facilityIDs,
          tasksAssignedToMe,
          queryType,
          dateType,
          groupBy,
          startDate: formatDate(startDate),
          endDate: formatDate(endDate || startDate),
          timeZoneID,
        },
      ]);
      const { data, error } = response;
      return error ? { error } : { data };
    };
  },
};
