import React, { useState, useCallback, useMemo, useEffect } from 'react';
import clsx from 'clsx';
import debounce from 'lodash.debounce';
import { IconButton, TextField } from '@mui/material';
import { ClearIcon, SearchIcon } from '../../../themes';
import { useGetQueryParams, useUpdatePageQueryParams } from '../../../hooks';
import { useStyles } from './inputs.styles';

/**
 * Material-ui TextField with validation TextInput
 * @param {import("@mui/material/TextField").TextFieldProps & searchInputProps} props
 */
export function SearchInput({
  label = 'Search',
  queryParam = 'search',
  sideEffectUpdateQuery = undefined,
  className,
  variant = 'outlined',
  iconPosition = 'end',
  ...rest
}) {
  const classes = useStyles();
  const updatePageQueryParams = useUpdatePageQueryParams();
  const { [queryParam]: search } = useGetQueryParams();
  const [inputValue, setInputValue] = useState(search || '');

  // See here why we use useMemo and not useCallback
  // https://github.com/facebook/react/issues/19240#issuecomment-652945246
  const debounceFilter = useMemo(
    () =>
      debounce((value) => {
        updatePageQueryParams({
          [queryParam]: value || null,
          ...(sideEffectUpdateQuery || {}),
        });
      }, 300),
    [queryParam, updatePageQueryParams, sideEffectUpdateQuery],
  );

  const onFilterChange = useCallback(
    (e) => {
      const { value } = e.target;
      debounceFilter(value);
      setInputValue(value);
    },
    [debounceFilter],
  );

  // keep the input synced with the query param
  useEffect(() => {
    if (search !== inputValue) {
      setInputValue(search || '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return (
    <TextField
      variant={variant}
      label={label}
      name='searchText'
      onChange={onFilterChange}
      value={inputValue}
      InputProps={{
        classes: {
          root: clsx(classes.inputRoot, className),
          focused: classes.focused,
          disabled: classes.disabled,
          error: classes.error,
          input: classes.input,
          notchedOutline: classes.notchedOutline,
        },
        startAdornment: (
          <>
            {iconPosition === 'start' && (
              <SearchIcon className={classes.label} />
            )}
          </>
        ),
        endAdornment: (
          <>
            {iconPosition === 'end' && (
              <>
                <IconButton
                  style={{ visibility: inputValue ? 'visible' : 'hidden' }}
                  onClick={() => onFilterChange({ target: { value: '' } })}
                  size='small'
                >
                  <ClearIcon size='small' className={classes.clearIcon} />
                </IconButton>
                <SearchIcon className={classes.label} />
              </>
            )}
          </>
        ),
      }}
      InputLabelProps={{
        classes: {
          root: classes.label,
          focused: classes.focused,
          error: classes.error,
          disabled: classes.disabled,
        },
      }}
      {...rest}
    />
  );
}

// #region Typedefs
/** @typedef {object} searchInputProps
 * @property {String} [label] The label for the input. Defaults to `Search`
 * @property {String} [queryParam] The query name to store in the URL. Defaults to `search`
 * @property {import("react").HTMLAttributes} [className]
 * @property {"start"|"end"} [iconPosition]
 */
// #endregion
