import React, { useState, useEffect, useCallback } from 'react';
import { Box, Button, IconButton, Typography } from '@mui/material';
import { AnnouncementPopup } from '..';
import { uiActions } from '../../state';
import { useDispatch } from 'react-redux';
import { FlexBetween, Loader } from '../ui';
import { formatDate } from '../../lib';
import { CloseIcon } from '../../themes';

function AnnouncementListItem({
  bodyHtmlContent,
  subjectHtmlContent,
  publishedDate,
  onClickReadMore,
}) {
  const [showReadMoreLink, setShowReadMoreLink] = useState(false);
  //using a callback ref so we get the info as soon as its initialized
  //see https://legacy.reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node
  const checkOverflowRef = useCallback((node) => {
    if (node !== null) {
      setShowReadMoreLink(node.scrollHeight > node.clientHeight);
    }
  }, []);

  return (
    <Box
      sx={{
        px: 3,
        pb: 3,
        pt: 2.5,
        borderBottom: '1px solid #E5E7F5',
        position: 'relative',
        '& a': {
          color: '#096EF8',
          textDecoration: 'none',
        },
      }}
    >
      <Typography
        sx={{
          color: 'text.secondary',
          fontSize: 12,
          fontWeight: 600,
        }}
      >
        {formatDate(publishedDate, 'MMMM do, yyyy')}
      </Typography>
      <Box
        sx={{ fontSize: 18, fontWeight: 600 }}
        dangerouslySetInnerHTML={{
          __html: subjectHtmlContent,
        }}
      />
      <Box
        ref={checkOverflowRef}
        sx={{ maxHeight: 160, overflow: 'hidden' }}
        dangerouslySetInnerHTML={{
          __html: bodyHtmlContent,
        }}
      />

      {showReadMoreLink && (
        <Box
          sx={{
            position: 'absolute',
            bottom: 4,
            left: 0,
            width: '100%',
            margin: 0,
            pt: 2.5,
            pl: 2,
            textAlign: 'center',
            backgroundImage:
              'linear-gradient(to bottom, rgba(255,255,255, 0.001)0%, #FFFF 100%)',
          }}
        >
          <Button
            variant={'text'}
            onClick={onClickReadMore}
            sx={{
              color: 'primary.light',
              fontSize: 14,
              fontWeight: 500,
              backgroundColor: '#FFF',
              borderRadius: '30%',
              opacity: 0.8,
              ':hover': {
                backgroundColor: '#FFF',
                opacity: 1,
              },
            }}
          >
            Read More
          </Button>
        </Box>
      )}
    </Box>
  );
}

export function AnnouncementLog() {
  const dispatch = useDispatch();

  const [dataForPopUp, setDataForPopUp] = useState(null);
  const [announcements, setAnnouncements] = useState([]);
  const [numberOfRows, setNumberOfRows] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [loading, setLoading] = useState(false);

  const getAnnouncements = useCallback(
    async (page) => {
      setPageNumber(page);
      setLoading(true);
      const { data } = await dispatch(uiActions.getAllAnnouncements(page));
      setLoading(false);
      if (data) {
        const { results, numberOfRows } = data;
        setNumberOfRows(numberOfRows);
        setAnnouncements((a) => [...(page === 1 ? [] : a), ...results]);
      }
    },
    [dispatch],
  );
  const handleClosePopup = useCallback(() => {
    setDataForPopUp(null);
  }, []);

  useEffect(() => {
    setHasMore(pageNumber < Math.ceil(numberOfRows / 10));
  }, [numberOfRows, pageNumber]);

  // get on first load
  useEffect(() => {
    getAnnouncements(1);
  }, [getAnnouncements]);

  return (
    <>
      <Box sx={{ width: 420 }}>
        <FlexBetween
          align={'center'}
          sx={{ borderBottom: '1px solid #E5E7F5', p: 3, height: 78 }}
        >
          <Box sx={{ fontSize: 18, fontWeight: 600 }}>Announcements</Box>
          <IconButton
            onClick={() => dispatch(uiActions.setAnnouncementLogOpen(false))}
          >
            <CloseIcon />
          </IconButton>
        </FlexBetween>
        <Box
          sx={{
            height: 'calc(100vh - 78px)',
            maxHeight: 'calc(100vh - 78px)',
            overflowY: 'auto',
          }}
        >
          {announcements.map((a, i) => (
            <AnnouncementListItem
              key={i}
              bodyHtmlContent={a.bodyHtmlContent}
              subjectHtmlContent={a.subjectHtmlContent}
              publishedDate={a.publishedDate}
              onClickReadMore={() => setDataForPopUp(a)}
            />
          ))}

          {loading && <Loader />}
          {hasMore && (
            <Box sx={{ p: 3, textAlign: 'center' }}>
              <Button
                sx={{ backgroundColor: '#E5E7F5' }}
                onClick={() => getAnnouncements(pageNumber + 1)}
                disabled={loading}
              >
                View More
              </Button>
            </Box>
          )}
        </Box>
      </Box>
      {!!dataForPopUp && (
        <AnnouncementPopup
          {...dataForPopUp}
          open={!!dataForPopUp}
          handleClose={handleClosePopup}
        />
      )}
    </>
  );
}
