import React, { isValidElement, cloneElement, useCallback } from 'react';
import clsx from 'clsx';
import {
  Toolbar,
  Typography,
  Tooltip,
  IconButton,
  Grow,
} from '@mui/material';
import { DeleteIcon } from '../../../themes';
import { useStyles } from './toolbar.styles';

export function ListToolbar({ numSelected, bulkActionButtons, ...props }) {
  const classes = useStyles();
  const { /* ids, rows, */ selectedIds } = props;

  return (
    <Grow in={numSelected > 0} mountOnEnter unmountOnExit direction='up'>
      <Toolbar className={clsx(classes.root, classes.highlight)}>
        <Typography
          className={classes.title}
          color='inherit'
          variant='subtitle1'
          component='div'
        >
          {numSelected} selected
        </Typography>
        {isValidElement(bulkActionButtons) ? (
          cloneElement(bulkActionButtons, { ...props })
        ) : (
          <BulkDeleteButton selectedIds={selectedIds} />
        )}
      </Toolbar>
    </Grow>
  );
}

export function BulkDeleteButton({ selectedIds }) {
  const handleDelete = useCallback(() => {
    console.log(selectedIds);
    // Implement delete. Optimistic rendering?
  }, [selectedIds]);

  return (
    <Tooltip title='Delete'>
      <IconButton aria-label='delete' onClick={handleDelete} size="large">
        <DeleteIcon />
      </IconButton>
    </Tooltip>
  );
}
