import React, { useCallback } from 'react';
import { QuickActionButtons } from './quickActions/QuickActionButtons';
import { Divider, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getColorForApplicationStatus } from '../../pages/main/caseDetails/application/applicationHelpers';
import { Routes, formatDate, generatePath } from '../../lib';
import { StatusField } from '../common';
import { GlobalSearchTypes } from './GlobalSearch';

export function GlobalSearchOption({
  option,
  classes,
  tabPosition,
  handleClose,
  clearSearch,
}) {
  const navigate = useNavigate();

  const fullName = `${option.firstName} ${
    option.middleName ? `${option.middleName} ` : ''
  }${option.lastName}`;

  const link = generatePath(
    Routes.caseDetails.path,
    { id: option.caseID },
    {
      view: 'application',
      ...(tabPosition === GlobalSearchTypes.Applications && {
        'application-id': option.applicationID,
      }),
    },
  );

  const handleClick = () => {
    clearSearch();
    handleClose();
    navigate(link);
  };

  const quickActionsOnClick = useCallback(
    (event) => {
      event.stopPropagation();
      clearSearch();
      handleClose();
    },
    [clearSearch, handleClose],
  );

  return (
    <>
      <MenuItem className={classes.searchOption} onClick={() => handleClick()}>
        <div className={classes.flexColumn}>
          <span className={classes.optionName}>
            <StatusField
              className={classes.optionName}
              value={fullName}
              badgeClassName={option.isResidentActive ? 'active' : 'inactive'}
            />
            {tabPosition === GlobalSearchTypes.Applications && (
              <span className={classes.optionFacility}>
                {' '}
                · {option.facilityName}
              </span>
            )}
          </span>
          {tabPosition === GlobalSearchTypes.Residents ? (
            <span className={classes.optionFacility}>
              {option.facilityName}
            </span>
          ) : (
            <div style={{ paddingLeft: 4 }}>
              {option.applicationTypeDisplay} · Started{' '}
              {formatDate(option.applicationStartedOn)} ·{' '}
              <span
                style={{
                  color: getColorForApplicationStatus(option.applicationStatus)
                    .color,
                }}
              >
                {option.applicationStatusDisplay}
              </span>
            </div>
          )}
        </div>
        <QuickActionButtons
          onClick={quickActionsOnClick}
          resident={option}
          iconStyles={() => ({ padding: 1, color: '#B9BDD4' })}
        />
      </MenuItem>
      <Divider className={classes.divider} />
    </>
  );
}
