import React, { memo, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { Paper, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { validateOnSubmit } from '../../lib';
import { TextInput, SelectInput } from '../../components';
import { accountActions, systemPersistSelectors } from '../../state';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: 760,
    margin: '40px auto 0px',
    padding: '70px 110px 50px 110px',
  },
  input: {
    flex: 1,
    marginBottom: 20,
  },
  title: {
    fontWeight: 600,
    fontSize: 20,
    textAlign: 'center',
    marginBottom: 20,
  },
}));

function _CreateCompany() {
  const classes = useStyles();
  const companyTypes = useSelector(systemPersistSelectors.companyTypes);
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});
  const [values, setValues] = useState({ ...initialState });

  const onChange = useCallback((event) => {
    const { name, value } = event.target;
    setValues((v) => ({ ...v, [name]: value }));
  }, []);

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const { companyEmailAddress, companyName, type, userEmailAddress } =
        values;
      validateOnSubmit({ values, schema, setErrors })
        .then(async () => {
          const response = await dispatch(
            accountActions.createCompany({
              company: {
                companyEmailAddress,
                companyName,
                type,
              },
              user: {
                email: userEmailAddress,
              },
            }),
          );
          if (!response.error) {
            setValues({ ...initialState });
          }
        })
        .catch(() => alert('an error'));
    },
    [dispatch, values],
  );

  const { companyName, companyEmailAddress, userEmailAddress, type } = values;
  return (
    <Paper elevation={3} className={classes.paper}>
      <div className={classes.title}>Create a Company</div>
      <form onSubmit={onSubmit} noValidate>
        <TextInput
          className={classes.input}
          value={companyName}
          name='companyName'
          onChange={onChange}
          label='Company Name'
          schema={schema}
          error={errors['companyName']}
          fullWidth
          addHelperTextStyle={false}
        />
        <TextInput
          className={classes.input}
          value={companyEmailAddress}
          name='companyEmailAddress'
          onChange={onChange}
          label='Company Email Address'
          schema={schema}
          error={errors['companyEmailAddress']}
          fullWidth
          addHelperTextStyle={false}
        />
        <SelectInput
          className={classes.input}
          value={type}
          name='type'
          onChange={onChange}
          label='Company Type'
          menuItems={companyTypes}
          schema={schema}
          error={errors['type']}
          fullWidth
          addHelperTextStyle={false}
        />
        <TextInput
          className={classes.input}
          value={userEmailAddress}
          name='userEmailAddress'
          onChange={onChange}
          label='User Email Address'
          schema={schema}
          error={errors['userEmailAddress']}
          fullWidth
          addHelperTextStyle={false}
        />
        <Button type='submit' fullWidth color='primary' variant='contained'>
          Submit
        </Button>
      </form>
    </Paper>
  );
}

export const CreateCompany = memo(_CreateCompany);

const initialState = {
  companyName: '',
  companyEmailAddress: '',
  userEmailAddress: '',
  type: '',
};

const schema = yup.object().shape({
  companyName: yup
    .string('Company Name must be a string')
    .required('Company Name is required'),
  type: yup
    .string('Company Type must be a string')
    .required('Company Type is required'),
  companyEmailAddress: yup
    .string('Company email address must be a string')
    .email('Invalid email')
    .required('Company email address is required'),
  userEmailAddress: yup
    .string('Users email address must be a string')
    .email('Invalid email')
    .required('Users email address is required'),
});
