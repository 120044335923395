export const globalStyles = (theme) => ({
  card: {
    boxShadow: '0 2px 12px 0 #F5F6FE, 0 2px 8px 0 #D5D8EC !important',
  },
  cancelBtn: {
    backgroundColor: '#E5E7F5',
    '&:hover': {
      backgroundColor: '#E5E7F5',
    },
    color: theme.palette.text.primary,
  },
  grid: {
    marginTop: 0,
    width: 'calc(100% + 64px)',
    maxWidth: 800,
    flexGrow: 1,
    '& $gridItem, & $gridModalItem': {
      paddingTop: 0,
      paddingBottom: 0,
      paddingRight: 32,
    },
  },
  gridItem: {
    maxWidth: 392,
    minWidth: 392,
  },
  gridModalItem: {},
  fullWidth: {
    width: '100%',
    paddingLeft: 10,
    paddingRight: 10,
  },
  formControl: {
    margin: theme.spacing(3),
  },
  fullInputWidth: {
    width: 720,
  },
  inputRow: {
    flexGrow: 1,
    marginBottom: 32,
  },
  linkButton: { color: theme.palette.primary.light },
  multiInput: {
    flexGrow: 1,
    marginTop: 0,
    marginBottom: 0,
  },
  scrollableContent: {
    overflowY: 'auto',
    width: '100%',
    maxHeight: 'calc(100vh - 172px)',
    // hide scroll bar https://stackoverflow.com/a/49278385
    scrollbarWidth: 'none' /* Firefox */,
    msOverflowStyle: 'none' /* Internet Explorer 10+ */,
    /* WebKit (Chrome) */
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  midFormButton: {
    marginBottom: 30,
    color: { color: theme.palette.primary.light },
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0,
    display: 'flex',
    cursor: 'pointer',
    '&:hover': {
      opacity: '0.6',
    },
  },
});
