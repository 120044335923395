import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useGetUsersData, useGetQueryParams } from './../../hooks/';
import jwtDecode from 'jwt-decode';
import { Routes } from '../../lib';
const { REACT_APP_EXTENSION_ID } = process.env;

export const GetToken = () => {
  const dispatch = useDispatch();
  const { token } = useGetUsersData();
  const { extId } = useGetQueryParams();

  useEffect(() => {
    async function fetchData() {
      let requiresMfa = false;
      if (extId && extId === REACT_APP_EXTENSION_ID) {
        if (token) {
          const authTokenData = jwtDecode(token);
          requiresMfa = !!authTokenData.RequiresMfaClaim;
          if (!requiresMfa) {
            window.open(
              `chrome-extension://${extId}/index.html?authToken=${token}`,
              '_self',
            );
          }
        }
        if (!token || requiresMfa) {
          window.open(
            `${Routes.login.path}?after=chrome-extension://${extId}/index.html`,
          );
          // window?.top?.postMessage({ functionToCall: 'closeIframe' }, '*');
        }
      }
    }
    fetchData();
  }, [token, extId, dispatch]);

  return <div />;
};
