import React, { useCallback, Fragment, useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { Grid } from '@mui/material';
import { AddressSearchInput } from './AddressSearch';
import { TextInput } from './TextInput';
import { SelectInput } from './SelectInput';
import { systemPersistSelectors } from '../../../state';

export function AddressFields({
  classes,
  disabled,
  schema,
  errors,
  values,
  setValues,
  addressFieldName = 'address',
  firstAddressFieldName = 'address1',
  firstAddressLabel,
  onChange,
  companyType,
  property = '',
  applicantNumber = '',
  handleAddressSearch: _handleAddressSearch,
  nestedKey,
  isModal,
  getFieldName: _getFieldName,
  viewVersion,
  initialAddress = '',
}) {
  const [manualOpen, setManualOpen] = useState(false);
  const states = useSelector(systemPersistSelectors.states, shallowEqual);

  const getFieldName = useCallback(
    (value) => {
      if (typeof _getFieldName === 'function') {
        return _getFieldName(value);
      }
      const { field } = value;
      return field + applicantNumber;
    },
    [_getFieldName, applicantNumber],
  );

  const handleAddressSearch = useCallback(
    (value = {}) => {
      if (value[firstAddressFieldName] !== '') {
        setManualOpen(true);
      }
      if (_handleAddressSearch) {
        return _handleAddressSearch(value);
      }
      const { address1, city, stateID, zip, county } = value;
      const newValues = {
        [getFieldName({
          field: firstAddressFieldName,
          property,
          applicantNumber,
        })]: address1,
        [getFieldName({ field: 'city', property, applicantNumber })]: city,
        [getFieldName({
          field: 'stateID',
          property,
          applicantNumber,
        })]: stateID,
        [getFieldName({ field: 'zip', property, applicantNumber })]: zip,
        [getFieldName({ field: 'county', property, applicantNumber })]: county,
      };

      setValues((v) => {
        return { ...v, ...newValues };
      });
    },
    [
      _handleAddressSearch,
      applicantNumber,
      firstAddressFieldName,
      getFieldName,
      setManualOpen,
      property,
      setValues,
    ],
  );
  return (
    <Grid
      container
      className={classes.grid}
      spacing={4}
      style={{ maxWidth: isModal ? 800 : 784 }}
    >
      <Grid
        item
        xs={12}
        style={{ paddingBottom: 0, paddingTop: 0, paddingRight: 32 }}
      >
        <AddressSearchInput
          viewVersion={viewVersion}
          fullWidth
          parentHandleSelect={handleAddressSearch}
          label={
            firstAddressLabel ||
            (property === 'mailing' ? 'Enter Address' : 'Enter Home address')
          }
          style={{ maxWidth: isModal ? '100%' : undefined, flexGrow: 1 }}
          initialAddress={initialAddress}
          error={
            !!errors[
              getFieldName({
                field: addressFieldName,
                property,
                applicantNumber,
              })
            ] ||
            !!errors[
              getFieldName({
                field: firstAddressFieldName,
                property,
                applicantNumber,
              })
            ]
          }
          disabled={disabled}
        />
      </Grid>
      {manualOpen && (
        <Fragment>
          <Grid
            item
            xs={isModal ? 6 : 4}
            className={isModal ? classes.gridModalItem : classes.gridItem}
          >
            <TextInput
              className={classes.inputRow}
              style={{ marginTop: 32 }}
              value={
                values[
                  getFieldName({
                    field: firstAddressFieldName,
                    property,
                    applicantNumber,
                  })
                ]
              }
              name={getFieldName({
                field: firstAddressFieldName,
                property,
                applicantNumber,
              })}
              onChange={onChange}
              label='Street Address'
              schema={schema}
              error={
                errors[
                  getFieldName({
                    field: firstAddressFieldName,
                    property,
                    applicantNumber,
                  })
                ]
              }
              nestedKey={
                nestedKey &&
                `${nestedKey}.${getFieldName({
                  field: firstAddressFieldName,
                  property,
                  applicantNumber,
                })}`
              }
              fullWidth
            />
          </Grid>
          <Grid
            item
            xs={isModal ? 6 : 4}
            className={isModal ? classes.gridModalItem : classes.gridItem}
          >
            <TextInput
              className={classes.inputRow}
              style={{ marginTop: 32 }}
              value={
                values[
                  getFieldName({ field: 'city', property, applicantNumber })
                ]
              }
              name={getFieldName({
                field: 'city',
                property,
                applicantNumber,
              })}
              onChange={onChange}
              label='City'
              schema={schema}
              error={
                errors[
                  getFieldName({ field: 'city', property, applicantNumber })
                ]
              }
              fullWidth
              nestedKey={
                nestedKey &&
                `${nestedKey}.${getFieldName({
                  field: 'city',
                  property,
                  applicantNumber,
                })}`
              }
            />
          </Grid>
          <Grid
            item
            xs={isModal ? 6 : 4}
            className={isModal ? classes.gridModalItem : classes.gridItem}
          >
            <SelectInput
              fullWidth
              //className={classes.inputRow}
              style={{ flexGrow: 1 }}
              value={
                values[
                  getFieldName({
                    field: 'stateID',
                    property,
                    applicantNumber,
                  })
                ]
              }
              name={getFieldName({
                field: 'stateID',
                property,
                applicantNumber,
              })}
              onChange={onChange}
              label='State'
              menuItems={states}
              schema={schema}
              context={{ companyType }}
              error={
                errors[
                  getFieldName({
                    field: 'stateID',
                    property,
                    applicantNumber,
                  })
                ]
              }
              nestedKey={
                nestedKey &&
                `${nestedKey}.${getFieldName({
                  field: 'stateID',
                  property,
                  applicantNumber,
                })}`
              }
            />
          </Grid>
          <Grid
            item
            xs={isModal ? 6 : 4}
            className={isModal ? classes.gridModalItem : classes.gridItem}
          >
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextInput
                  fullWidth
                  style={{ flexGrow: 1 }}
                  ///className={classes.inputRow}
                  value={
                    values[
                      getFieldName({
                        field: 'zip',
                        property,
                        applicantNumber,
                      })
                    ]
                  }
                  name={getFieldName({
                    field: 'zip',
                    property,
                    applicantNumber,
                  })}
                  onChange={onChange}
                  label='Zip'
                  schema={schema}
                  error={
                    errors[
                      getFieldName({
                        field: 'zip',
                        property,
                        applicantNumber,
                      })
                    ]
                  }
                  nestedKey={
                    nestedKey &&
                    `${nestedKey}.${getFieldName({
                      field: 'zip',
                      property,
                      applicantNumber,
                    })}`
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextInput
                  fullWidth
                  style={{ flexGrow: 1 }}
                  // className={classes.inputRow}
                  value={
                    values[
                      getFieldName({
                        field: 'county',
                        property,
                        applicantNumber,
                      })
                    ]
                  }
                  name={getFieldName({
                    field: 'county',
                    property,
                    applicantNumber,
                  })}
                  onChange={onChange}
                  label='County'
                  schema={schema}
                  error={
                    errors[
                      getFieldName({
                        field: 'county',
                        property,
                        applicantNumber,
                      })
                    ]
                  }
                  nestedKey={
                    nestedKey &&
                    `${nestedKey}.${getFieldName({
                      field: 'county',
                      property,
                      applicantNumber,
                    })}`
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Fragment>
      )}
      <div
        style={{ marginBottom: 25, marginLeft: 32, marginTop: 10 }}
        className={classes.midFormButton}
        onClick={() => setManualOpen(!manualOpen)}
      >
        {disabled ? '' : manualOpen ? 'Close manual address' : 'Manual address'}
      </div>
    </Grid>
  );
}
