import { memo } from 'react';
import { Typography, useTheme } from '@mui/material';
import { MarriedIcon } from './MarriedIcon';
import { Avatar, FlexCenter } from '../ui';
import { MaritalStatus } from '../../lib';
import { ResidentNameDisplayProps } from './types';
import { getNameLinkProps } from './utils';

export const NameWithAvatarAndStatus = memo(function (
  props: ResidentNameDisplayProps,
) {
  const theme = useTheme();

  const {
    residentName,
    isResidentActive,
    maritalStatus,
    ...nameLinkPropsValues
  } = props;

  const nameLinkProps = nameLinkPropsValues.caseId
    ? getNameLinkProps(nameLinkPropsValues)
    : {};

  return (
    <FlexCenter gap={1}>
      <Avatar
        style={{ height: '24px', width: '24px' }}
        name={residentName}
        color={theme.palette.primary.main}
        badgeColor={isResidentActive ? 'primary' : 'error'}
        variant='rounded'
        badgeSize={10}
        typographyProps={{ variant: 'caption' }}
      />
      <Typography
        variant='h6'
        color='text.primary'
        textTransform='capitalize'
        {...nameLinkProps}
      >
        {residentName}
      </Typography>
      {maritalStatus === MaritalStatus.Married && <MarriedIcon />}
    </FlexCenter>
  );
});
