import { format } from 'date-fns';
import {
  formatDate,
  formatBool,
  DateRangeFilterOptions,
} from '../../../../lib';
import {
  FieldCategories,
  ListQueryParams,
  SharedFields,
  SharedFilters,
} from './SharedListConfig';
import { CheckIcon } from '../../../../themes';
import { systemPersistSelectors, uiSelectors } from '../../../../state';

export const ApplicationListTypes = {
  AllApplications: 'AllApplications',
  OpenApplications: 'OpenApplications',
};

export const ApplicationLists = {
  AllApplications: {
    exportFileName: `applications_${format(new Date(), 'Pp')}`,
    name: 'All Applications',
    type: ApplicationListTypes.AllApplications,
  },
  OpenApplications: {
    exportFileName: `applications_open_${format(new Date(), 'Pp')}`,
    name: 'Open',
    type: ApplicationListTypes.OpenApplications,
  },
};

export const ApplicationFields = [
  {
    ...SharedFields.ResidentName,
    general: true,
  },
  {
    ...SharedFields.FacilityName,
    general: true,
  },
  {
    ...SharedFields.ReferralSource,
    general: true,
  },

  {
    ...SharedFields.PayerName,
    general: true,
  },
  {
    ...SharedFields.ApplicationStatus,
    general: true,
  },
  {
    ...SharedFields.DateForCurrentApplicationStatus,
    general: true,
  },
  {
    ...SharedFields.DaysForCurrentApplicationStatus,
    general: true,
  },
  {
    ...SharedFields.MedicaidNeededBy,
    general: true,
  },
  {
    ...SharedFields.ApplicationInfo,
    general: true,
  },
  SharedFields.AdmitDate,
  SharedFields.MedicaidCaseworker,
  SharedFields.ApplicationType,
  SharedFields.LatestApplicationNoteDate,
  SharedFields.MedicalRecordNumber,
  SharedFields.ApplicationTags,
  {
    ...SharedFields.NextCaseworkerRequestDeadline,
    general: true,
  },
  {
    ...SharedFields.ApplicationSubmitDate,
    defaultLists: [ApplicationListTypes.AllApplications],
  },
  {
    general: true,
    display: ({ renewalDate }) => formatDate(renewalDate),
    exportHeaders: [
      {
        key: 'renewalDate',
        label: 'Renewal Date',
        format: 'date',
      },
    ],
    id: 'RenewalDate',
    label: 'Renewal Date',
    profileQueryString: 'view=application',
    fieldCategory: FieldCategories.Application,
  },
  {
    display: ({ applicationAssignee }) => applicationAssignee,
    exportHeaders: [
      {
        key: 'applicationAssignee',
        label: 'Application Assignee',
      },
    ],
    id: 'ApplicationAssignee',
    label: 'Application Assignee',
    profileQueryString: 'view=application',
    disableSort: true,
    fieldCategory: FieldCategories.Application,
  },
  {
    display: ({ applicationStartedOn }) => formatDate(applicationStartedOn),
    exportHeaders: [
      {
        key: 'applicationStartedOn',
        label: 'Application Started On',
        format: 'date',
      },
    ],
    id: 'ApplicationStartedOn',
    label: 'Application Started On',
    profileQueryString: 'view=application',
    fieldCategory: FieldCategories.Application,
  },
  {
    display: ({ isOutsourced }) => isOutsourced && <CheckIcon />,
    exportHeaders: [
      {
        key: 'isOutsourced',
        label: 'Application Outsourced',
        formatter: (val) => formatBool(val),
      },
    ],
    id: 'ApplicationIsOutsourced',
    label: 'Application Outsourced',
    profileQueryString: 'view=application',
    disableSort: true,
    fieldCategory: FieldCategories.Application,
  },

  {
    display: ({ hearingDate }) => formatDate(hearingDate),
    exportHeaders: [
      {
        key: 'hearingDate',
        label: 'Hearing Date',
        format: 'date',
      },
    ],
    id: 'ApplicationHearingDate',
    label: 'Hearing Date',
    profileQueryString: 'view=application',
    fieldCategory: FieldCategories.Application,
  },
  {
    display: ({ countyName }) => formatDate(countyName),
    exportHeaders: [
      {
        key: 'countyName',
        label: 'County',
      },
    ],
    id: 'ApplicationCountyName',
    label: 'County',
    profileQueryString: 'view=application',
    disableSort: true,
    fieldCategory: FieldCategories.Application,
  },
];

export const ApplicationFilters = [
  {
    ...SharedFilters.showArchived,
    generalDocked: true,
  },
  {
    ...SharedFilters.isResidentActive,
    generalDocked: true,
  },
  {
    ...SharedFilters.facilityIds,
    generalDocked: true,
  },
  {
    ...SharedFilters.applicationStatuses,
    generalDocked: true,
    menuItemsSelector: (state, listType) =>
      listType === ApplicationListTypes.OpenApplications
        ? systemPersistSelectors.openApplicationStatuses(state)
        : SharedFilters.applicationStatuses.menuItemsSelector(state),
  },
  {
    ...SharedFilters.applicationAssigneeIds,
    menuItemsFilter: ({ menuItems }) => [
      ...menuItems,
      { id: -1, name: 'Unassigned' },
    ],
  },
  SharedFilters.assignedToMe,
  SharedFilters.facilityStateIds,
  SharedFilters.filingStateIds,
  SharedFilters.payerTypes,
  SharedFilters.payerName,
  SharedFilters.caseTags,
  SharedFilters.caseworkerIds,
  SharedFilters.medicaidNeededByFromDate,
  SharedFilters.medicaidNeededByToDate,
  SharedFilters.applicationTypes,
  SharedFilters.medicaidCaseworkerIds,
  SharedFilters.noMedicaidRequest,
  SharedFilters.medicaidNeededWithinLast60Days,
  SharedFilters.nextCaseworkerRequestDeadlineRange,
  SharedFilters.applicationSubmitDateRange,
  SharedFilters.applicationTags,
  {
    defaultValue: '',
    label: 'Outsourced',
    type: 'selectMenu',
    menuItems: [
      { id: '', name: 'All' },
      { id: true, name: 'Is Outsourced' },
      { id: false, name: 'Not Oursourced' },
    ],
    name: 'isOutsourced',
    queryParam: ListQueryParams.isOutsourced,
  },
  {
    name: 'hearingDateRange',
    defaultValue: '',
    label: 'Hearing Date',
    type: 'selectMenu',
    menuItems: [
      DateRangeFilterOptions.None,
      DateRangeFilterOptions.All,
      DateRangeFilterOptions.Today,
      DateRangeFilterOptions.ThisWeek,
      DateRangeFilterOptions.ThisMonth,
      DateRangeFilterOptions.ThisAndNextMonth,
    ],
    queryParam: ListQueryParams.hearingDateRange,
    disableSort: true,
  },
];
