import jwtDecode from 'jwt-decode';
import axios from 'axios';
import { stringify } from 'query-string';
import {
  normalizeResponseError,
  authGet,
  authPost,
  hasAuthRequestTokenPendingMfa,
  setAuthRequestToken,
  removeAuthRequestToken,
  removeSuperAdminCompanyIdHeader,
  PermissionClaims,
  Routes,
} from '../../lib';
import { systemPersistActions, uiActions, authPersistActions } from '..';
import { auth } from './state';

const { actions } = auth;

const {
  /** The base URL for the API. */
  REACT_APP_API_URL,
} = process.env;

export const authActions = {
  ...actions,
  handleLogin(
    data,
    navigate,
    redirectUrl,
    overrideRedirectCallback = undefined,
  ) {
    return (dispatch, getState) => {
      let {
        auth: { afterLoginRedirect },
      } = getState();

      const { token, expiration, requiresMfa, mfaInfo } = data;
      const authTokenData = jwtDecode(token);
      setAuthRequestToken(token, expiration, requiresMfa);

      dispatch(
        authPersistActions.persistUserData({ ...data, ...authTokenData }),
      );
      dispatch(authPersistActions.setLastActiveTime(new Date()));
      dispatch(actions.setAutoIdleLogout(false));
      dispatch(actions.loginUser());

      if (requiresMfa) {
        const verifyLoginURL = `${Routes.verifyLogin.path}?${stringify({
          mfaTo: mfaInfo?.maskedSentToValue,
          mfaType: mfaInfo?.notificationType,
        })}`;
        navigate(verifyLoginURL, { replace: true });
      } else {
        //set authToken when redirecting to chrome extension
        if (afterLoginRedirect?.indexOf('chrome-extension://') > -1) {
          afterLoginRedirect += `?authToken=${token}`;
          //here we need to use the window location since we are navigating out of the app router
          setTimeout(() => {
            window.location.replace(afterLoginRedirect);
          }, 0);
          return;
        }
        //for multi-company users redirect to facilities dashboard - bec these users have access to multiple companies and are initally logged into their default company, we don't want to potentially bring them back to a company-specific page (such as a specific resident/facility/etc) that is not from their default company
        else if (
          authTokenData.PermissionClaim?.includes(
            PermissionClaims.MultiCompanyUserClaim,
          )
        ) {
          afterLoginRedirect = Routes.facilitiesDashboard.path;
        }

        //navigate only after user data has been set
        setTimeout(() => {
          overrideRedirectCallback &&
          typeof overrideRedirectCallback === 'function'
            ? overrideRedirectCallback()
            : navigate(redirectUrl || afterLoginRedirect || Routes.home.path);
        }, 0);
      }
    };
  },
  logout(redirect = true, after = '') {
    return async (dispatch) => {
      //revoke the token on the API
      await authPost('auth/revoke/token');

      // NOTE: We could do  window.localStorage.clear(); but other JS might be
      // using localStorage, so just remove the key that our Redux app saves.
      window.localStorage.removeItem('persist:app-persist');

      dispatch(authPersistActions.removeUserData());
      removeAuthRequestToken();
      dispatch(systemPersistActions.setSuperAdminCompanyHeader(''));
      removeSuperAdminCompanyIdHeader();
      if (redirect) {
        window.location.replace(
          `${Routes.login.path}${after ? `?after=${after}` : ''}`,
        );
      }
    };
  },
  login({ email, password, navigate }) {
    return async (dispatch) => {
      dispatch(uiActions.setUILoading(true));

      // if there is already an auth token pending mfa, clear it out before logging in again
      // in order to avoid issues where user logs in and before proceeding with mfa returns to login pg to log in as a dif user - prev user authentication header is present in login request and blocks login
      if (hasAuthRequestTokenPendingMfa()) {
        await dispatch(this.logout(false));
      }

      // We are not using authPut because we don't want to send the old JWT
      const response = await axios
        .post(
          `${REACT_APP_API_URL}/Auth/login/basic`,
          {
            userName: email,
            password,
          },
          {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true,
          },
        )
        .catch(normalizeResponseError('POST', '/Auth/login/basic', {}))
        .then((res) => res);

      const { error, data } = response;
      if (error) {
        dispatch(actions.loginFailed(error));
        dispatch(uiActions.setUILoading(false));
        dispatch(
          uiActions.showError({ message: error.message || 'Login Failed' }),
        );
        return {
          error,
        };
      }

      dispatch(this.handleLogin(data, navigate));
      dispatch(uiActions.setUILoading(false));
      return {
        data,
      };
    };
  },

  forgotPassword({ email }) {
    return async (dispatch) => {
      dispatch(uiActions.setUILoading(true));
      const response = await authPost('/Account/forgotPw', {
        email,
        clientDomain: window.location.href.split('/').slice(0, 3).join('/'), // domain and port
      });
      const { error } = response;
      if (error) {
        dispatch(
          uiActions.showError({
            message:
              typeof error === 'string'
                ? error
                : error.message || 'Something went wrong',
          }),
        );
        dispatch(uiActions.setUILoading(false));
        return { error };
      }
      dispatch(uiActions.setUILoading(false));
      dispatch(
        uiActions.showNotification({
          message:
            'Request Sent. Check your email for a link to reset your password.',
        }),
      );
      return {
        data: 'success',
      };
    };
  },
  setPassword({ email, password, token, navigate }) {
    return async (dispatch) => {
      dispatch(uiActions.setUILoading(true));
      // We are not using authPut because we don't want to send the old JWT
      const response = await axios
        .post(
          `${REACT_APP_API_URL}/Account/forgotPwReset`,
          {
            email,
            newPw: password,
            token,
          },
          { headers: { 'Content-Type': 'application/json' } },
        )
        .catch(normalizeResponseError('POST', '/Account/forgotPwReset', {}))
        .then((res) => res);
      const { error } = response;

      if (error) {
        dispatch(
          uiActions.showError({
            message:
              typeof error === 'string'
                ? error
                : error.message === 'Invalid token.'
                ? "Invalid token: Click 'forgot password' on the login page to get a new link"
                : error.message || 'Failed to set the password.',
          }),
        );
        dispatch(uiActions.setUILoading(false));
        return;
      }
      dispatch(uiActions.setUILoading(false));
      dispatch(this.login({ password, email, navigate }));
    };
  },

  sendVerificationCode() {
    return async (dispatch) => {
      dispatch(uiActions.setUILoading(true));
      const response = await authGet(`${REACT_APP_API_URL}/Auth/sendMfa`);

      const { error, data } = response;
      if (error) {
        dispatch(
          uiActions.showError({ message: 'Send Verification Code Failed' }),
        );
        dispatch(uiActions.setUILoading(false));
        return {
          error,
        };
      }
      dispatch(uiActions.setUILoading(false));
      const sentTo =
        data?.mfaInfo?.notificationType === 'Email' ? 'email' : 'cell phone';
      dispatch(
        uiActions.showNotification({
          message: `Request Sent. Check your ${sentTo} for a login verification code.`,
        }),
      );
      return { data };
    };
  },
  verifyLogin({ code, rememberMe, navigate }) {
    return async (dispatch) => {
      dispatch(uiActions.setUILoading(true));
      const response = await authGet(
        `${REACT_APP_API_URL}/Auth/verifyMfa?${stringify({
          code,
          rememberMe,
        })}`,
        null,
        {},
        { withCredentials: true },
      );

      const { error, data } = response;
      if (error) {
        dispatch(actions.loginFailed(error));
        dispatch(uiActions.setUILoading(false));
        dispatch(
          uiActions.showError({
            message: error?.message || 'Login Verification Failed',
          }),
        );
        return {
          error,
        };
      }

      dispatch(this.handleLogin(data, navigate));
      dispatch(uiActions.setUILoading(false));
      return {
        data,
      };
    };
  },
  setWorkingCompany(
    companyId,
    redirectUrl,
    overrideRedirectCallback = undefined,
    navigate,
  ) {
    return async (dispatch) => {
      dispatch(uiActions.setUILoading(true));

      const response = await authPost(
        `${REACT_APP_API_URL}/Auth/workingCompany`,
        companyId,
      );

      const { error, data } = response;
      if (error) {
        dispatch(uiActions.setUILoading(false));
        dispatch(uiActions.showError({ message: 'Login Failed' }));
        return {
          error,
        };
      }

      dispatch(
        this.handleLogin(data, navigate, redirectUrl, overrideRedirectCallback),
      );
      dispatch(uiActions.setUILoading(false));
      return {
        data,
      };
    };
  },
};
