import { useTheme, useMediaQuery } from '@mui/material';

/**
 * Returns `true` if the screen is sized for medium desktop. Depends on theme.
 */
export function useIsMediumDesktop() {
  const theme = useTheme();
  return useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.mdDesktop),
  );
}

/**
 * Returns `true` if the screen is sized for tablet. Depends on theme.
 */
export function useIsTablet() {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.between('sm', 'lg'));
}

/**
 * Returns `true` if the screen is sized for small tablet. Depends on theme.
 */
export function useIsSmallTablet() {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.between('sm', 'md'));
}

/**
 * Returns `true` if the screen is sized for mobile. Depends on theme.
 */
export function useIsMobile() {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down('sm'));
}
