import { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Select, MenuItem, IconButton, SelectChangeEvent } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useGetUsersData, useGlobalRefresh } from '../../hooks';
import { SyncResidentModal } from '../modals/SyncResidentModal';
import {
  systemPersistActions,
  systemPersistSelectors,
  saasActions,
  uiActions,
} from '../../state';
import {
  CompanyIdNamePair,
  PermissionClaims,
  setSuperAdminCompanyIdHeader,
} from '../../lib';
import { SyncIcon } from '../../themes';

export function SuperAdminCompanyDropdown() {
  const dispatch = useDispatch();
  const refresh = useGlobalRefresh();
  const classes = makeStyles(() => ({
    companySelect: {
      fontSize: 24,
      fontWeight: 400,
      marginLeft: 16,
    },
    icon: {
      color: 'rgba(0,0,0,0.87)',
    },
  }))();

  const { companyID, PermissionClaim = [] } = useGetUsersData();
  const superAdminSelectedCompany = useSelector(
    systemPersistSelectors.superAdminSelectedCompany,
  );

  const [companies, setCompanies] = useState([] as CompanyIdNamePair[]);
  const [syncIntegrationModalOpen, setSyncIntegrationModalOpen] =
    useState(false);

  const onChangeCompany = useCallback(
    (e: SelectChangeEvent) => {
      const selectedCompanyId = e.target.value;
      dispatch(
        systemPersistActions.setSuperAdminCompanyHeader(selectedCompanyId),
      ); //set in system persist
      setSuperAdminCompanyIdHeader(selectedCompanyId); //set in header
      refresh(); //refresh current pg with new companyID
    },
    [dispatch, refresh],
  );

  useEffect(() => {
    (async function loadCompaniesList() {
      if (
        !companies.length &&
        PermissionClaim.includes(PermissionClaims.SuperAdminEditClaim)
      ) {
        // @ts-expect-error // need correct typing for requests
        const { error, data } = await dispatch(saasActions.getCompaniesBasic());
        if (error) {
          uiActions.showError(error.message || 'Unable to retrieve companies');
        } else {
          setCompanies(data);
        }
      }
    })();
  }, [dispatch, companies, PermissionClaim]);

  return (
    PermissionClaim.includes(PermissionClaims.SuperAdminEditClaim) && (
      <>
        {companies.length > 0 ? (
          <Select
            variant='standard'
            value={superAdminSelectedCompany || companyID}
            onChange={onChangeCompany}
            disableUnderline
            className={classes.companySelect}
            classes={{ icon: classes.icon }}
          >
            {companies.map((c, i) => (
              <MenuItem key={i} value={c.id}>
                {c.name}
              </MenuItem>
            ))}
          </Select>
        ) : null}

        <IconButton
          onClick={() => setSyncIntegrationModalOpen(true)}
          size='small'
          sx={{ mx: 1, minHeight: 28, minWidth: 28 }}
        >
          <SyncIcon />
        </IconButton>
        {syncIntegrationModalOpen && (
          <SyncResidentModal
            open={syncIntegrationModalOpen}
            handleClose={() => setSyncIntegrationModalOpen(false)}
          />
        )}
      </>
    )
  );
}
