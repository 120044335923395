import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  case: {
    nri: {},
    profileData: {
      loading: false,
      profile: {},
      spouseProfile: {},
      contacts: [],
      caseDetails: {},
      referrer: {},
    },
    notes: {}, //for the infinite scroll list
    todos: {}, //for the inifinite scroll list
  },
  loading: false,
  people: [],
  companyCaseTagOptions: [],
};

export const cases = createSlice({
  name: 'cases',
  initialState,
  reducers: {
    setNriLoading(state, action) {
      state.case.nri.loading = action.payload;
    },
    setNri(state, action) {
      state.case.nri = { ...action.payload, loading: false };
    },
    setCasePeople(state, action) {
      state.people = action.payload;
    },
    setCompanyCaseTagOptions(state, action) {
      state.companyCaseTagOptions = action.payload;
    },
    setCaseProfileLoading(state, action) {
      state.case.profileData.loading = action.payload;
    },
    //all profile pg data
    setCaseProfileData(state, action) {
      state.case.profileData = {
        ...action.payload,
        loading: false,
      };
    },
    //just actual resident profile
    setPrimaryProfile(state, action) {
      const { maritalStatus, dateForMaritalStatus } = action.payload;
      state.case.profileData.profile = {
        ...action.payload,
      };
      //update the marital status for the spouse also
      state.case.profileData.spouseProfile = {
        ...(state.case.profileData.spouseProfile || {}),
        maritalStatus,
        dateForMaritalStatus,
      };
    },
    //just actual spouse profile
    setSpouseProfile(state, action) {
      state.case.profileData.spouseProfile = action.payload;
    },
    setCaseNotes(state, action) {
      state.case.notes = action.payload;
    },
    setProfileCaseDetails(state, action) {
      state.case.profileData.caseDetails = {
        ...state.case.profileData.caseDetails,
        ...action.payload,
      };
    },
    setProfileReferrer(state, action) {
      state.case.profileData.referrer = action.payload;
    },
    setCaseContacts(state, action) {
      state.case.profileData.contacts = action.payload;
    },
    setTodos(state, action) {
      state.case.todos = action.payload;
    },
  },
});
