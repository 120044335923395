import React, { forwardRef, useEffect, useState } from 'react';
import { Chip, MenuItem } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { parse } from 'query-string';
import { useGetQueryParams, useUpdatePageQueryParams } from '../../../../hooks';

const useStyles = makeStyles((theme) => ({
  chipsContainer: {
    flexBasis: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: 10,
  },
  chips: {
    margin: 2,
    background: 'transparent',
    fontSize: 14,
  },
  icon: {
    color: theme.palette.textTheme.main,
  },
}));

export const FilterMenu = forwardRef(
  (
    { filters = {}, queryParam = 'list-filters', sideEffectUpdateQuery },
    ref,
  ) => {
    const updatePageQueryParams = useUpdatePageQueryParams();
    const { [queryParam]: queryListFilters } = useGetQueryParams();
    const addFilter = (filterId, filterName) => {
      // remove dupes and add the new filter
      // `queryListFilters` can be either undefined or a string (if only one) or an array.
      const curFilters =
        typeof queryListFilters === 'string'
          ? [queryListFilters]
          : queryListFilters || [];
      const noDupes = Array.from(new Set([...curFilters, filterId]));
      updatePageQueryParams({
        [queryParam]: noDupes,
        ...(sideEffectUpdateQuery || {}),
      });
    };

    return Object.keys(filters)
      .sort((f1, f2) => {
        const orderByf1 =
          typeof filters[f1] === 'object' ? filters[f1].name : filters[f1];
        const orderByf2 =
          typeof filters[f2] === 'object' ? filters[f2].name : filters[f2];
        return orderByf1?.localeCompare(orderByf2);
      })
      .map((key) => {
        var value =
          typeof filters[key] === 'object' ? filters[key].id : filters[key];
        var displayVal =
          typeof filters[key] === 'object' ? filters[key].name : filters[key];
        return (
          <MenuItem key={key} onClick={(_) => addFilter(key, value)}>
            {displayVal}
          </MenuItem>
        );
      });
  },
);

export function FilterChips({
  activeFilters = {},
  queryParam = 'list-filters',
  multipleFilters,
  disabled,
}) {
  const classes = useStyles();
  const updatePageQueryParams = useUpdatePageQueryParams();

  const [chipsObj, setChipsObj] = useState();

  useEffect(() => {
    if (multipleFilters) {
      // object should have keys that are the queryParam
      // with a value of object with key as the id and the value as the display name
      // Example: {application-status: undefined
      // assignees: undefined
      // case-status: undefined
      // case-tags: {UR: "UR", IncomeTrust: "Income Trust"}}
      const flattened = Object.keys(activeFilters).reduce((acc, cur) => {
        const curFilter = activeFilters[cur];
        if (typeof curFilter === 'object') {
          Object.keys(curFilter).forEach((f) => {
            acc = {
              ...acc,
              // set the key for the chipsObj to queryParam-id so that if 2 params have the same id they won't override
              // Example: {facility-2: {name: "Park Place", queryParamName: "facility"}}
              [`${cur}-${f}`]: { name: curFilter[f], queryParamName: cur },
            };
          });
        }
        return acc;
      }, {});
      setChipsObj(flattened);
    } else {
      setChipsObj(activeFilters);
    }
  }, [activeFilters, multipleFilters]);

  const removeFilter = ({ filterId, queryParamName }) => {
    const qsField = queryParamName || queryParam;
    const { [qsField]: queryListFilters } = parse(window.location.search);
    // remove the filter from the query string
    // `queryListFilters` can be either a string (if only one) or an array.
    const ensureArray = Array.isArray(queryListFilters)
      ? queryListFilters
      : [queryListFilters];
    const newFilters = ensureArray?.filter((f) => f !== filterId) || [];
    updatePageQueryParams({
      [qsField]: newFilters.length ? newFilters : null,
    });
  };

  if (!chipsObj) return null;
  // NOTE the `activeFiltersKeys` are the filter ids
  const activeFiltersKeys = Object.keys(chipsObj);

  return (
    <div className={classes.chipsContainer}>
      {activeFiltersKeys.map((key) => {
        if (!chipsObj[key]) return null;
        const {
          id = key,
          name,
          queryParamName,
        } = getFilterChipProps(key, chipsObj[key]);

        return (
          <Chip
            key={key}
            className={classes.chips}
            classes={{ deleteIcon: classes.icon }}
            size='small'
            label={name}
            disabled={disabled}
            onDelete={() =>
              removeFilter({
                filterId: id,
                queryParamName,
              })
            }
          />
        );
      })}
    </div>
  );
}

function getFilterChipProps(key, resource) {
  let name = resource;
  let queryParamName;
  let id;
  if (typeof resource === 'object') {
    // the key is queryParam-id see comment above
    const split = key?.split('-');
    name = resource.name;
    queryParamName = resource.queryParamName;
    id = split?.[split.length - 1];
  }
  return { id, name, queryParamName };
}
