import axios from 'axios';
import { format } from 'date-fns';
import { authGet, makeuuid } from '../lib';
import { WorkerAuthRequest } from './AuthRequest';

export async function upload(file, url, headers) {
  const config = {
    headers: {
      'Content-Type': file.type,
      ...headers,
    },
  };
  const response = await axios.put(url, file, config);
  return response;
}

export async function download(url, fileName) {
  axios(url, {
    url: url, //your url
    method: 'GET',
    responseType: 'blob', // important
  }).then((response) => {
    downloadFileFromResponse(response.data, fileName);
  });
}

export function downloadFileFromResponse(fileData, fileName) {
  const newUrl = window.URL.createObjectURL(new Blob([fileData]));
  const link = document.createElement('a');
  link.href = newUrl;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
}
export async function uploadToGCP(file, objectName, query) {
  const response = await authGet([
    '/cloud/signedReadWriteUrl',
    {
      objectName,
      ...(query || {}),
    },
  ]);
  if (response.error) {
    return {};
  }
  const { signedReadUrl, objectUri } = response.data;

  const gcpResponse = await upload(file.file, signedReadUrl);
  if (gcpResponse.status !== 200) {
    return {};
  }

  return { ...file, url: objectUri };
}

/**
 * uploads file with authorization to the worker project
 * @param {*} file object with properties: {name, file} the name should include the file extension
 * @param {*} folderName
 * @returns object with url to download the file
 */
export async function uploadSecureServer(file, folderName) {
  const guid = makeuuid(36);
  const fileExtension = getFileExtension(file.name);
  const objectName = `${folderName}/${format(
    new Date(),
    'MMddyyyy',
  )}${guid}.${fileExtension}`;

  const { REACT_APP_WORKER_URL } = process.env;

  const config = {
    headers: {
      'Content-Type': file.type,
    },
    withCredentials: true,
  };

  const response = await WorkerAuthRequest.put(
    `${REACT_APP_WORKER_URL}/upload/${objectName}`,
    file.file,
    config,
  )
    .then((res) => res)
    .catch((err) => err.response);
  if (response?.status === 202) {
    return { url: `${REACT_APP_WORKER_URL}/download/${objectName}` };
  } else {
    return {};
  }
}

/**
 * use to upload images from TINYMCE editor to the secure server
 * @param {*} blobInfo
 * @param {*} progress
 * @param {*} folderName
 * @returns
 */
export async function EditorSecureImageUploadHandler(
  blobInfo,
  progress,
  folderName,
) {
  const upload = blobInfo.blob();
  const file = {
    name: upload.name,
    file: upload,
  };
  const response = await uploadSecureServer(file, folderName);
  if (response?.url) {
    return response.url;
  }
}

/**
 *
 * @param {*} file object with properties: {name, file} the name should include the file extension
 * @param {*} folderName
 * @returns
 */
export async function uploadToPublicGCP(file, folderName) {
  const guid = makeuuid(36);
  const fileExtension = getFileExtension(file.name);
  const objectName = `${folderName}/${format(
    new Date(),
    'MMddyyyy',
  )}${guid}.${fileExtension}`;
  const result = await uploadToGCP(file, objectName, { isPublic: true });
  return result;
}

export async function uploadCaseDocument(file, companyId, caseId) {
  const guid = makeuuid(36);
  const objectName = `companies/${companyId}/cases/${caseId}/${guid}.${file.fileExtension}`;
  const result = await uploadToGCP(file, objectName);
  return result;
}
export async function getSignedUrl(bucketObjectName) {
  const response = await authGet([
    '/cloud/signedReadUrl',
    {
      objectName: bucketObjectName,
    },
  ]);

  if (response.status === 200) {
    const { signedReadUrl } = response.data;
    return signedReadUrl;
  }

  return null;
}

export function getFileExtension(fileName) {
  return fileName ? fileName.split('.').pop() : '';
}

export function getFileName(fileName) {
  return fileName ? fileName.split('.').slice(0, -1).join('.') : '';
}

export const nonViewableExtensions = [
  'doc',
  'dot',
  'docx',
  'xls',
  'xlt',
  'xla',
  'xlsx',
  'csv',
  'eml',
];

//these are the file extension which we are currently able to append to a pdf
export const convertableToPdfExtensions = [
  'pdf',
  'jpg',
  'jpeg',
  'png',
  'gif',
  'bmp',
  'webp',
];

export const FileTypes = {
  Internal: 'Internal',
  External: 'External',
};

export const UploadSources = {
  Scanner: 'Scanner',
  Upload: 'Upload',
};
