import React from 'react';
import clsx from 'clsx';
import { Avatar } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { sharedFormStyles } from '../modals/sharedStyles';

export function NameWithAvatar({ personName }) {
  const useStyles = makeStyles((theme) => ({
    ...sharedFormStyles(theme),
  }));
  const classes = useStyles();
  return personName ? (
    <div
      style={{
        display: 'flex',
        marginLeft: 30,
        marginBottom: 15,
        alignItems: 'center',
      }}
    >
      <Avatar className={clsx(classes.avatar, classes.avatarResident)}>
        {`${personName?.split(' ')[0]?.charAt(0)?.toUpperCase() || ''}${
          personName?.split(' ')[1]?.charAt(0)?.toUpperCase() || ''
        }`}
      </Avatar>
      <span
        style={{
          marginLeft: 10,
          fontWeight: '500',
          fontSize: 16,
        }}
      >
        {personName}
      </span>
    </div>
  ) : null;
}
