import React from 'react';
import { Box, BoxProps, Typography } from '@mui/material';

type NoContentProps = {
  titleText: string;
  captionText: string;
  imageLocation: string;
} & BoxProps;

export function NoContent({
  titleText,
  captionText,
  imageLocation,
  ...boxProps
}: NoContentProps) {
  return (
    <Box textAlign='center' {...boxProps}>
      <img src={imageLocation} alt={titleText} />
      <Typography variant='subtitle1'>{titleText}</Typography>
      <Typography
        variant='caption'
        color='text.secondary'
        whiteSpace='pre-wrap'
      >
        {captionText}
      </Typography>
    </Box>
  );
}
