import React, { cloneElement, isValidElement, Children } from 'react';
import clsx from 'clsx';
import { Paper, Slide } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  aside: {
    maxWidth: '50%',
    width: '50%',
    position: 'relative',
    marginBottom: 16,
  },
}));

export function ListAside({ rows, ids, children }) {
  const classes = useStyles();
  return Children.map(children, (aside, index) => {
    return isValidElement(aside) ? (
      <Slide
        direction='left'
        in={aside.props.isOpen}
        mountOnEnter
        unmountOnExit
      >
        <Paper
          elevation={0}
          classes={{
            root: clsx(classes.aside, aside.props.drawClasses),
          }}
        >
          {cloneElement(aside, {
            rows,
            ids,
            record: rows[aside.props.recordId],
          })}
        </Paper>
      </Slide>
    ) : null;
  });
}
