import { useCallback, useState, useEffect, useRef } from 'react';
import { Box, Grid, TextField, DialogActions, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useDispatch } from 'react-redux';
import { Editor } from '@tinymce/tinymce-react';
import { object, string } from 'yup';
import { FormModal } from './FormModal';
import { SelectInput } from '../common/inputs';
import { useNotify, useGlobalRefresh } from '../../hooks';
import { resourceActions } from '../../state';
import { validateOnSubmit, EditorSecureImageUploadHandler } from '../../lib';
import { sharedFormStyles } from './sharedStyles';
import { NameWithAvatar } from '../name';

const useStyles = makeStyles((theme) => ({ ...sharedFormStyles(theme) }));

export function NoteForm({
  autofocus = true,
  inline = false,
  open,
  handleClose,
  apiURL,
  editId,
  noteType,
  categories = [],
  refresh,
  quickActionCaseName = '',
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const globalRefresh = useGlobalRefresh();
  if (!refresh) {
    refresh = globalRefresh;
  }
  const notify = useNotify();

  const editorRef = useRef(null);

  const [submitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState({});
  const [initialHtmlContent, setInitialHtmlContent] = useState('');
  const [isInitialized, setIsInitialized] = useState(false);
  const [state, setState] = useState({
    text: '',
    type: '',
  });

  useEffect(() => {
    setState((s) => ({
      ...s,
      type: noteType || '',
    }));
  }, [noteType]);

  useEffect(() => {
    (async function () {
      if (editId) {
        const response = await dispatch(
          resourceActions.fetchResourceById({ baseUrl: apiURL, id: editId }),
        );
        const { data, error } = response;
        if (error) {
          return;
        }
        const { htmlContent, text } = data;
        setState((s) => ({ ...s, ...data }));
        setInitialHtmlContent(htmlContent || text);
      }
    })();
  }, [apiURL, dispatch, editId]);

  //setting the focus within onInit wasn't working. instead we set the initialized state and move the focus here.
  // we are not using the auto_focus property since that only focuses at the beginning and not the end.
  useEffect(() => {
    if (isInitialized && (!editId || initialHtmlContent)) {
      editorRef.current.selection.select(editorRef.current.getBody(), true);
      editorRef.current.selection.collapse(false);
      editorRef.current.focus();
      setIsInitialized(false);
    }
  }, [isInitialized, editId, initialHtmlContent]);

  const handleDelete = useCallback(async () => {
    setSubmitting(true);
    const response = await dispatch(
      resourceActions.deleteResource({
        baseUrl: apiURL,
        id: editId,
      }),
    );
    setSubmitting(false);
    const { error } = response;
    if (!error) {
      notify('Changes saved');
      refresh();
      handleClose();
    }
  }, [apiURL, dispatch, notify, refresh, editId, handleClose]);

  const onSave = useCallback(() => {
    return validateOnSubmit({
      values: state,
      schema,
      setErrors,
      context: { inlineNote: inline },
    })
      .then(async () => {
        setSubmitting(true);

        let payload = { ...state, noteId: editId };
        if (!inline) {
          //we only upload the images immediately before saving to avoid uploading for no reason
          //(in the case where they add an image and then delete it etc.)

          //only get the content once the images are finished uploading to avoid saving the images in base64
          const didSucceed = await editorRef.current
            .uploadImages()
            .then(() => {
              return true;
            })
            .catch((err) => {
              return false;
            });
          if (!didSucceed) {
            setSubmitting(false);
            notify('An error occurred while uploading images', 'error');
            return;
          }

          const htmlContent = editorRef.current.getContent();
          const text = editorRef.current.getContent({ format: 'text' });
          if (!text || !htmlContent) {
            setSubmitting(false);
            notify('Note text is required', 'error');
            return;
          }
          payload = { ...payload, htmlContent, text };
        }

        const response = await dispatch(
          resourceActions.postResource({
            baseUrl: apiURL,
            payload,
          }),
        );
        setSubmitting(false);
        const { data, error } = response;
        if (error) return notify('Error saving the note', 'error');
        if (data) {
          notify('Changes saved');
          refresh();
          if (inline) {
            setState((s) => ({ ...s, text: '', type: noteType || '' }));
          } else {
            handleClose();
          }
        }
      })
      .catch((e) => console.error('error ', e));
  }, [
    apiURL,
    dispatch,
    editId,
    handleClose,
    notify,
    refresh,
    state,
    inline,
    noteType,
  ]);

  const onChange = useCallback((event) => {
    const { value, name } = event.target;
    setState((curState) => ({ ...curState, [name]: value }));
  }, []);

  const onEditorImageUpload = useCallback(async (blobInfo, progress) => {
    return await EditorSecureImageUploadHandler(blobInfo, progress, 'Notes');
  }, []);

  return inline ? (
    <Box
      sx={{
        mx: -2,
        ...(state.text ? { p: 2, backgroundColor: '#F5F6FE', my: 2 } : {}),
      }}
    >
      <Box
        sx={{
          px: 2,
          pt: state.text ? 2 : 0,
          backgroundColor: '#FFF',
          borderRadius: '3px',
        }}
      >
        <TextField
          autoFocus={autofocus}
          label='Write a note'
          variant='outlined'
          multiline
          fullWidth
          value={state.text}
          name='text'
          onChange={onChange}
        />
        {state.text && (
          <>
            <SelectInput
              sx={{ mt: 2.5 }}
              fullWidth
              value={state.type}
              name='type'
              onChange={onChange}
              label='Category'
              menuItems={categories}
              schema={schema}
              error={errors.type}
              alphabetize
            />
            <DialogActions>
              <Button
                variant='text'
                sx={{ color: 'text.secondary', fontFamily: 'Roboto' }}
                onClick={() =>
                  setState((s) => ({ ...s, text: '', type: noteType || '' }))
                }
                disabled={submitting}
              >
                Cancel
              </Button>
              <Button
                sx={{ color: 'primary.light', fontFamily: 'Roboto' }}
                onClick={onSave}
                disabled={submitting}
              >
                Save
              </Button>
            </DialogActions>
          </>
        )}
      </Box>
    </Box>
  ) : (
    <FormModal
      handleClose={handleClose}
      open={open}
      title='Add note'
      onSave={onSave}
      isEdit={!!editId}
      handleDelete={handleDelete}
      submitting={submitting}
      rightSideHeader={<NameWithAvatar personName={quickActionCaseName} />}
      disableEnforceFocus={true} //need this in order for tinyMce to work fully within the modal
    >
      <Box p={3}>
        <Grid container rowSpacing={4} columnSpacing={5}>
          <Grid item xs={12} className={classes.gridModalItem}>
            <Editor
              tinymceScriptSrc={process.env.REACT_APP_TINY_MCE_CDN}
              apiKey={process.env.REACT_APP_TINY_MCE_API_KEY}
              initialValue={initialHtmlContent}
              init={{
                promotion: false,
                statusbar: false,
                plugins:
                  ' searchreplace autolink visualblocks  ' +
                  ' link table advlist lists autoresize',
                autoresize_bottom_margin: 0,
                placeholder: 'Write a note',
                min_height: 300,
                max_height: 620,
                menubar: 'edit  insert format table',
                toolbar:
                  ' blocks fontfamily fontsize | bold italic underline strikethrough | align numlist bullist | link ' +
                  ' | table | outdent indent| forecolor backcolor ',
                toolbar_mode: 'wrap',
                content_style: 'a { color: #096EF8; text-decoration: none }',
                contextmenu: false, //disable the context menu since chrome doesnt support auto accessing the clipboard, otherwise the only way to access copy/paste is via keyboard shortcuts
                removed_menuitems: 'copy paste cut pastetext', //remove these since they don't work, see above
                images_upload_handler: onEditorImageUpload,
                automatic_uploads: false,
                browser_spellcheck: true,
              }}
              onInit={(evt, editor) => {
                editorRef.current = editor;
                setIsInitialized(true);
              }}
            />
          </Grid>
          {categories.length > 0 && (
            <Grid item xs={6} className={classes.gridModalItem}>
              <SelectInput
                fullWidth
                value={state.type}
                name='type'
                onChange={onChange}
                label='Category'
                menuItems={categories}
                schema={schema}
                error={errors.type}
                alphabetize
              />
            </Grid>
          )}
        </Grid>
      </Box>
    </FormModal>
  );
}

const schema = object().shape({
  text: string('This field must be a string')
    .when(['$inlineNote'], {
      is: (inlineNote) => inlineNote === true,
      then: (s) => s.required('Note is a required field'),
    })
    .nullable(),
  type: string('This field must be a string').required(),
});
