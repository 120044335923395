import clsx from 'clsx';
import _get from 'lodash.get';
import makeStyles from '@mui/styles/makeStyles';
import { getColorForApplicationStatus } from '../../../../pages/main/caseDetails/application/applicationHelpers';
import { applicationStatusTypes } from '../../../../lib';
import { PrintTypography } from '../../PrintTypography';

/** TextField props.
 * @param {StatusField} props
 */
export function StatusField({
  className,
  badgeClassName,
  typographyProps = {},
  variant = 'dot',
  source,
  record = {},
  orderBy,
  value: _value,
  ...rest
}) {
  const classes = useStyles();
  const value = _value || _get(record, source);
  return value ? (
    <span>
      <PrintTypography
        component='span'
        variant='body2'
        className={clsx(classes.root, className)}
        {...typographyProps}
      >
        <div style={{ marginLeft: 4 }}>{value}</div>
        <span
          className={clsx(
            variant === 'dot' ? classes.badge : classes.bar,
            classes[badgeClassName || value.toLowerCase()],
          )}
        />
      </PrintTypography>
    </span>
  ) : null;
}

// #region Typedefs
/** @typedef {object} StatusField
 * @property {import("@mui/material/Typography").TypographyProps} [typographyProps]
 * @property {import("react").HTMLAttributes | string} [className]
 * @property {import("react").HTMLAttributes} [headerClassName] class applied to the header
 * @property {string} [badgeClassName]
 * @property {import("csstype").Properties} [cellStyle]
 * @property {"dot"|"bar"} [variant] The style of the badge. Defaults to `dot`.
 * @property {string} [source]
 * @property {string} [label]
 * @property {string} [disablePadding]
 * @property {string} [align]
 * @property {string} [sortBy] Defaults to `source`
 * @property {false} [sortable] Set to false to disable sorting for this column
 * @property [value]
 */
// #endregion

const useStyles = makeStyles((theme) => ({
  root: {
    textTransform: 'capitalize',
    display: 'inline-flex',
    position: 'relative',
    flexShrink: 0,
    verticalAlign: 'middle',
  },
  badge: {
    zIndex: 1,
    position: 'absolute',
    fontSize: '0.75rem',
    transition: 'transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    fontWeight: 500,
    lineHeight: 1,
    height: 6,
    padding: 0,
    minWidth: 6,
    borderRadius: 4,
    top: '50%',
    left: '-13px',
    transform: 'scale(1) translate(50%, -50%)',
    transformOrigin: '100% 0%',
  },
  bar: {
    zIndex: 1,
    position: 'absolute',
    fontSize: '0.75rem',
    transition: 'transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    fontWeight: 500,
    lineHeight: 1,
    height: 21,
    padding: 0,
    minWidth: 4,
    borderRadius: 1,
    top: '50%',
    left: '-13px',
    transform: 'scale(1) translate(50%, -50%)',
    transformOrigin: '100% 0%',
  },
  active: {
    background: '#0FC076',
  },
  Active: {
    background: '#0FC076',
  },
  submitted: {
    background: '#0FC076',
  },
  inactive: {
    background: '#F91429',
  },
  NotStarted: {
    background: '#F91429',
  },
  Confirmed: {
    background: '#4c34eb',
  },
  Started: {
    background: '#eb8334',
  },
  InProgress: {
    background: '#eb8334',
  },
  Needed: {
    background: '#F91429',
  },
  Expired: {
    background: '#F91429',
  },
  Completed: {
    background: '#0FC076',
  },
  StatusCheck: {
    background: '#A9A9A9',
  },
  NewAdmission: {
    background: '#A9A9A9',
  },
  PlanNeeded: {
    background: '#A9A9A9',
  },
  LTCPayerNeeded: {
    background: '#A9A9A9',
  },
  NotNeeded: {
    background: '#7620B4',
  },
  ShortTermOnly: {
    background: '#7620B4',
  },
  Reviewing: {
    background: '#A9A9A9',
  },
  ReadyToSubmit: {
    background: '#A9A9A9',
  },
  LTCPayerReceived: {
    background: '#0000FF',
  },
  CaseworkerRequest: {
    background: '#0000FF',
  },
  ResponseSubmitted: {
    background: '#0000FF',
  },
  CaseApproved: {
    background: '#0C9A5E',
  },
  MedicaidApproved: {
    background: '#0C9A5E',
  },
  IneligibleForMedicaid: {
    background: '#F91429',
  },
  Pending: {
    background: '#B9BDD4',
  },
  New: {
    background: '#FE831A',
  },
  InReview: {
    background: '#EE068F',
  },
  Rejected: {
    background: '#F91429',
  },

  // application statuses:
  Preparation: {
    background: getColorForApplicationStatus(applicationStatusTypes.Preparation)
      .color,
  },
  Submitted: {
    background: getColorForApplicationStatus(applicationStatusTypes.Submitted)
      .color,
  },
  AdjustmentNeeded: {
    background: getColorForApplicationStatus(
      applicationStatusTypes.AdjustmentNeeded,
    ).color,
  },
  PendingApproval: {
    background: getColorForApplicationStatus(
      applicationStatusTypes.PendingApproval,
    ).color,
  },
  NotPursuing: {
    background: getColorForApplicationStatus(applicationStatusTypes.NotPursuing)
      .color,
  },
  Appealed: {
    background: getColorForApplicationStatus(applicationStatusTypes.Appealed)
      .color,
  },
  //these below may be used for other types of statuses as well
  Approved: {
    background: getColorForApplicationStatus(applicationStatusTypes.Approved)
      .color,
  },
  Denied: {
    background: getColorForApplicationStatus(applicationStatusTypes.Denied)
      .color,
  },

  Draft: {
    background: '#B9BDD4',
  },
  Published: {
    background: '#096EF8',
  },
  CurrentlyShowing: {
    background: '#0FC076',
  },
}));
