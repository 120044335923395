import { useMemo } from 'react';
import { parse } from 'query-string';
import { useSearchParams } from 'react-router-dom';

/**
 * @returns {object} - Returns the query params as an object
 * using query-string `parse`
 * and useSearchParams from react router
 */
export function useGetQueryParams() {
  const [searchParams] = useSearchParams();
  return useMemo(() => parse(searchParams.toString()), [searchParams]);
}
