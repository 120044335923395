import React, { useEffect } from 'react';
import {
  init as SentryInit,
  ErrorBoundary,
  configureScope as SentryConfigureScope,
  setUser as SentrySetUser,
} from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import LogRocket from 'logrocket';
import ReactGA from 'react-ga4';
import LogrocketFuzzySearchSanitizer from 'logrocket-fuzzy-search-sanitizer';
import { Box, Button, Typography } from '@mui/material';
import { useIntercom } from 'react-use-intercom';
import { useGetUsersData } from '../../hooks';

function initializeErrorLogging() {
  const {
    REACT_APP_SENTRY_DSN,
    REACT_APP_ENV,
    REACT_APP_ERRORS_DEV,
    REACT_APP_LOGROCKET_APPID,
  } = process.env;

  const isDev = REACT_APP_ENV === 'development';

  const usingDevConfig = isDev && REACT_APP_ERRORS_DEV !== 'true';

  const devConfig = usingDevConfig
    ? {
        beforeSend() {
          // Do not send errors in development.
          return null;
        },
      }
    : undefined;

  if (isDev && !usingDevConfig) {
    console.warn('Reporting errors to https://sentry.io during development.');
  }

  SentryInit({
    dsn: REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.5, //50% to start with
    environment: REACT_APP_ENV,
    ...devConfig,
  });

  //don't use log rocket in development
  if (!usingDevConfig) {
    const privateFieldNames = [
      'firstName',
      'lastName',
      'middle',
      'name',
      'dob',
      'dateOfBirth',
      'ssn',
      'address',
    ];
    const { requestSanitizer, responseSanitizer } =
      LogrocketFuzzySearchSanitizer.setup([...privateFieldNames]);

    LogRocket.init(REACT_APP_LOGROCKET_APPID, {
      dom: {
        // uncomment below to santize all DOM data, for now we are blacklisting specific elments using `data-private` attributes on relevant elements
        // inputSanitizer: true,
        // textSanitizer: true,
      },
      network: {
        requestSanitizer,
        responseSanitizer,
      },
      release: REACT_APP_ENV,
    });

    //get the log rocket link in the sentry errors
    LogRocket.getSessionURL((sessionURL) => {
      SentryConfigureScope((scope) => {
        scope.setExtra('sessionURL', sessionURL);
      });
    });

    //link to google analytics
    LogRocket.getSessionURL(function (sessionURL) {
      ReactGA.event({ category: 'LogRocket', action: sessionURL });
    });
  }
}

initializeErrorLogging();

/**
 * Error boundary component that reports errors to https://sentry.io
 * NOTE: You don't _need_ to render this error boundary to report errors.
 * Error boundaries are primarily for catching React _rendering_ errors and
 * showing an error result UI.
 */

function ErrorFallback() {
  return (
    <Box display='flex' alignItems='center' justifyContent='center'>
      <Typography variant='h6'>An unexpected error has occurred</Typography>
      <div>
        <Button variant='contained' href='/' style={{ marginLeft: 10 }}>
          Go home
        </Button>
      </div>
    </Box>
  );
}

export function IdentifyUser() {
  const { update: updateIntercom } = useIntercom();
  const {
    userId,
    companyType,
    companyID,
    companyName,
    firstName,
    lastName,
    userName,
    UserTypeClaim,
    intercomUserHash,
  } = useGetUsersData();

  useEffect(() => {
    //identify users
    LogRocket.identify(userName, {
      name: `${firstName} ${lastName}`,
      email: userName,
      companyType: companyType,
      companyID: companyID,
      companyName: companyName,
      userType: UserTypeClaim,
      userId: userId,
    });

    SentrySetUser({
      email: userName,
      id: userId,
      name: `${firstName} ${lastName}`,
    });

    //update intercom user info whenever it changes (after logging out/back in etc)
    if (firstName) {
      updateIntercom({
        name: `${firstName} ${lastName}`,
        email: userName,
        userId: userId,
        userHash: intercomUserHash,
        company: {
          companyId: companyID,
          name: companyName
        }
      });
    }
  }, [
    userId,
    companyType,
    companyID,
    companyName,
    firstName,
    lastName,
    userName,
    UserTypeClaim,
    intercomUserHash,
    updateIntercom,
  ]);

  return null;
}

export class ErrorLogging extends React.Component {
  render() {
    return (
      <>
        <ErrorBoundary fallback={ErrorFallback} showDialog>
          {this.props.children}
        </ErrorBoundary>
      </>
    );
  }
}
