import React from 'react';
import { Typography } from '@mui/material';

// import { useStyles } from './fields.styles';

/** FunctionField props.
 * @param {FunctionFieldProps} props
 */
export function FunctionField({
  className,
  onClick,
  typographyProps = {},
  source,
  record = {},
  render = () => {},
  ...rest
}) {
  // const classes = useStyles();

  return (
    <Typography
      component='span'
      onClick={onClick ? () => onClick(record) : null}
      variant='body2'
      className={className}
      {...typographyProps}
    >
      {render(record, source)}
    </Typography>
  );
}

// #region Typedefs
/** @typedef {object} FunctionFieldProps
 * @property {import("@mui/material/Typography").TypographyProps} [typographyProps]
 * @property {import("react").HTMLAttributes} [className]
 * @property {import("react").HTMLAttributes} [headerClassName] class applied to the header
 * @property {import("csstype").Properties} [cellStyle]
 * @property {string} source
 * @property {string} label
 * @property {function} render
 * @property {string} [disablePadding]
 * @property {string} [align]
 * @property {string} [sortBy] Defaults to `source`
 * @property {false} [sortable] Set to false to disable sorting for this column
 */
// #endregion
