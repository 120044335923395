import React, { useCallback, useState } from 'react';
import { Checkbox, FormControlLabel, FormControl, FormHelperText, FormGroup } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { reach } from 'yup';
import clsx from 'clsx';
import { useEffect } from 'react';

const useStyles = makeStyles((theme) => ({
  checkbox: {
    color: theme.palette.outlineTheme.main,
    '&$checkboxChecked': {
      color: theme.palette.primary.light,
    },
  },
  checkboxChecked: {},
}));

// /**
//  * Material-ui Checkbox with validation
//  * @param {CheckboxInputProps}
//  */
export function CheckboxInput({ checkboxes = [], row = true, ...props }) {
  const [errorMessage, setErrorMessage] = useState();

  return (
    <FormControl
      error={!!errorMessage}
      component='fieldset'
      // className={classes.formControl}
    >
      <FormGroup row={row}>
        {checkboxes.map((c, i) => {
          return (
            <Input
              key={i}
              {...props}
              {...c}
              setErrorMessage={setErrorMessage}
            />
          );
        })}
      </FormGroup>
      <FormHelperText>{errorMessage}</FormHelperText>
    </FormControl>
  );
}

function Input(props) {
  const {
    checkboxProps: _checkboxProps = {},
    labelProps = {},
    className,
    schema,
    context,
    setErrorMessage,
    error,
  } = props;
  const { nestedKey, ...checkboxProps } = _checkboxProps;
  const { onBlur, onChange } = checkboxProps;
  const classes = useStyles();
  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    setErrorMessage(error);
  }, [error, setErrorMessage]);

  useEffect(() => {
    // this is for when the validation is based of of another field
    // and the values of the context is true/false for example the pasRequested field on facilityIntake
    if (context) {
      const valuesArr = Object.keys(context).reduce((acc, cur) => {
        acc.push(context[cur]);
        return acc;
      }, []);
      if (valuesArr.every((v) => v === false)) {
        setErrorMessage(undefined);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context]);

  const handleBlur = useCallback(
    (event) => {
      typeof onBlur === 'function' && onBlur(event);
      if (schema) {
        const { name, checked } = event.target;
        setIsDirty(true);
        reach(schema, nestedKey || name)
          .validate(checked, { context })
          .then(() => setErrorMessage(false))
          .catch((err) => setErrorMessage(err.errors?.[0]));
      }
    },
    [context, nestedKey, onBlur, schema, setErrorMessage],
  );

  const handleChange = useCallback(
    (event) => {
      typeof onChange === 'function' && onChange(event);
      const { name, checked } = event.target;
      if (schema && isDirty) {
        reach(schema, nestedKey || name)
          .validate(checked, { context })
          .then(() => setErrorMessage(false))
          .catch((err) => setErrorMessage(err.errors?.[0]));
      }
    },
    [context, isDirty, nestedKey, onChange, schema, setErrorMessage],
  );

  return (
    <FormControlLabel
      control={
        <Checkbox
          className={clsx(className)}
          onBlur={handleBlur}
          onChange={handleChange}
          classes={{
            root: classes.checkbox,
            checked: classes.checkboxChecked,
          }}
          // helperText={errorMessage || undefined}
          {...checkboxProps}
        />
      }
      {...labelProps}
    />
  );
}

// #region Typedefs
// /** @typedef  checkboxes
//  * @type {Array}
//  * @property {Object[] checkboxProps} checkboxes
//  * @property {import("@mui/material/FormControlLabel").FormControlLabelProps} labelProps
//  * @type {import("@mui/material/Checkbox").CheckboxProps} checkboxProps
//  */

// #endregion

export function CustomCheckbox({ checked }) {
  const classes = useStyles();
  return (
    <Checkbox
      checked={checked}
      classes={{
        root: classes.checkbox,
        checked: classes.checkboxChecked,
      }}
    />
  );
}
