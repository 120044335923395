import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { TextInput } from './TextInput';
import { inputStyles } from './inputs.styles';
const customStyles = ({ borderColor }) =>
  makeStyles((theme) => ({
    root: {
      borderColor: borderColor || theme.palette.outlineTheme.main,
      minWidth: '50px',
      fontSize: '18px',
      padding: '0px',
      fontWeight: 600,
      height: 51.3,
      '&$selected': { backgroundColor: '#096EF8', color: '#FFFFFF !important' },
    },
    selected: {},
  }))();

const customInputStyles = ({ borderColor }) =>
  makeStyles((theme) => ({
    ...inputStyles(theme),
    inputRoot: {
      '& $notchedOutline': {
        borderColor: borderColor || theme.palette.outlineTheme.main,
      },
      '&:hover:not($disabled):not($error) $notchedOutline': {
        borderColor: borderColor || theme.palette.outlineTheme.main,
      },
      '&$focused:not($disabled):not($error) $notchedOutline': {
        borderColor: borderColor || theme.palette.outlineTheme.main,
      },
      borderTopRightRadius: '0px',
      borderBottomRightRadius: '0px',
      marginRight: '0px',
    },
    inputRow: {
      flexGrow: 1,
      marginBottom: 8,
    },
  }))();

export function ButtonInput({
  inputProps,
  buttonGroupProps,
  buttonPropsArray,
  styles = { borderColor: '#8A91A3' },
}) {
  const classes = customStyles(styles);
  const inputClasses = customInputStyles(styles);

  return (
    <div style={{ display: 'inline-flex', height: '66px', width: '100%' }}>
      <TextInput
        {...inputProps}
        classes={inputClasses}
        style={{
          marginRight: '0px',
          marginLeft: '0px',
          width: '100%',
        }}
      />
      <ToggleButtonGroup
        style={{ marginTop: '8px', marginBottom: '0px' }}
        {...buttonGroupProps}
        exclusive
      >
        {buttonPropsArray.map((b, i) => {
          const { label, ...rest } = b;
          return (
            <ToggleButton
              key={i}
              {...rest}
              style={
                i === 0
                  ? {
                      borderTopLeftRadius: '0px',
                      borderBottomLeftRadius: '0px',
                      borderLeftWidth: '0px',
                      color: '#000000',
                    }
                  : {
                      color: '#000000',
                    }
              }
              className={inputClasses.inputRow}
              classes={{
                root: classes.root,
                selected: classes.selected,
              }}
            >
              {label}
            </ToggleButton>
          );
        })}
      </ToggleButtonGroup>
    </div>
  );
}
