import React, { memo, useState, useCallback } from 'react';
import clsx from 'clsx';
import { Link, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { Button, CircularProgress } from '@mui/material';
// Local
import { AuthLayout } from './AuthLayout';
import { TextInput } from '../../components';
import { Routes, validateOnSubmit } from '../../lib';
import { useDispatch, useSelector } from 'react-redux';
import { authActions, uiSelectors } from '../../state';
import { useStyles } from './Auth.styles';
import { useGetQueryParams } from '../../hooks';

function _LoginPage() {
  const dispatch = useDispatch();
  const uiLoading = useSelector(uiSelectors.loading);
  const classes = useStyles();
  const navigate = useNavigate();
  const { after } = useGetQueryParams();

  if (after) {
    dispatch(
      authActions.setAfterLoginRoute({
        redirectTo: after,
      }),
    );
  }
  const [formState, setFormState] = useState({
    email: '',
    password: '',
  });
  const [errors, setErrors] = useState({});

  /** @param {SyntheticEvent<HTMLButtonElement>} e */
  async function onClickLogin(e) {
    const { email, password } = formState;
    e.preventDefault();
    validateOnSubmit({ values: formState, schema, setErrors }).then(() => {
      dispatch(authActions.login({ email, password, navigate }));
    });
  }

  const onChange = useCallback((e) => {
    const { name, value } = e.target;
    setFormState((f) => ({ ...f, [name]: value }));
  }, []);

  return (
    <AuthLayout>
      <h1 className={clsx(classes.title, classes.welcome)}>Welcome</h1>
      <p className={classes.welcomeSubText}>Sign in to continue</p>
      <form noValidate onSubmit={onClickLogin}>
        <div className={classes.form}>
          <TextInput
            className={classes.inputSpacer}
            required
            fullWidth
            id='email'
            label='Email'
            type='email'
            name='email'
            autoComplete='email'
            autoFocus
            value={formState.email}
            onChange={onChange}
            disabled={uiLoading}
            schema={schema}
            error={errors['email']}
          />
          <TextInput
            className={classes.inputSpacer}
            required
            fullWidth
            name='password'
            label='Password'
            type='password'
            id='password'
            autoComplete='current-password'
            value={formState.password}
            onChange={onChange}
            disabled={uiLoading}
            schema={schema}
            error={errors['password']}
          />
        </div>
        <div className={classes.actions}>
          <div className={classes.submitWrapper}>
            <Button
              type='submit'
              variant='contained'
              color='primary'
              className={classes.submit}
              disabled={!formState.email || !formState.password || uiLoading}
              onClick={onClickLogin}
              disableFocusRipple
              disableRipple
              disableTouchRipple
            >
              Sign In
            </Button>
          </div>
          <Button
            to={Routes.forgotPassword.path}
            color='primary'
            component={Link}
            className={classes.forgotPassword}
            disabled={uiLoading}
          >
            Forgot password?
          </Button>
        </div>
        {uiLoading && (
          <div className={classes.loader}>
            <CircularProgress size={24} />
          </div>
        )}
      </form>
    </AuthLayout>
  );
}

export const LoginPage = memo(_LoginPage);

const schema = yup.object().shape({
  email: yup
    .string('Email address must be a string')
    .email('Invalid email')
    .required('Email address is required'),
  password: yup
    .string('Password must be a string')
    .required('Password is required'),
});
