import { lighten } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { SITE_HEADER_HEIGHT } from '../../../lib';

export const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    minHeight: theme.spacing(8),
    height: theme.spacing(8),
    position: 'absolute',
    left: 0,
    right: 0,
    top: `-${SITE_HEADER_HEIGHT}px`,
  },
  highlight:
    theme.palette.mode === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
}));
