import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { rootReducer } from './state';

let persistor;

// Need this to solve issue with redux-persist
// https://github.com/reduxjs/redux-toolkit/pull/175
const customizedMiddleware = getDefaultMiddleware({
  serializableCheck: false,
});

if (
  process.env.NODE_ENV === 'development' &&
  !(process.env.REACT_APP_REDUX_LOGGER_DISABLED === 'true')
) {
  const { logger } = require(`redux-logger`);
  customizedMiddleware.push(logger);
}

const persistConfig = {
  key: 'app-persist',
  storage,
  whitelist: ['authPersist', 'systemPersist'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./state/rootReducer', () => {
    const newRootReducer = persistReducer(
      persistConfig,
      require('./state').rootReducer,
    );
    store.replaceReducer(newRootReducer);
  });
}

const store = configureStore({
  reducer: persistedReducer,
  middleware: customizedMiddleware,
});

/** Starts the process of persisting and syncing the store. */
export function activateStore() {
  function activatingStore(resolve, reject) {
    function persistStoreCompleted() {
      resolve(store);
    }
    persistor = persistStore(store, null, persistStoreCompleted);
    // crossTabSync(store, persistConfig);
  }
  return new Promise(activatingStore);
}

export { store, persistor };
