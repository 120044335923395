import { useState, useCallback, useEffect } from 'react';
import _isEqual from 'lodash.isequal';
import { Popover, Paper, Checkbox, Typography } from '@mui/material';
import { useNotify, useLoader } from '../../../../hooks';
import { authPost, sortById } from '../../../../lib';
import { FlexCenter, FlexColumn } from '../../../../components';

export function ApplicationTagsInput({
  tagOptions,
  applicationId,
  initialTags,
  anchorEl,
  setAnchorEl,
  open,
  updateTags,
}) {
  const notify = useNotify();
  const setLoader = useLoader();

  const [tags, setTags] = useState([]);

  //set the tags in the state, use the options and set the relevant ones to true
  useEffect(() => {
    if (tagOptions.length > 0) {
      const mapped = tagOptions.map((t) => ({
        ...t,
        isOn: initialTags.some((i) => i.id === t.id),
      }));

      setTags(mapped);
    }
  }, [tagOptions, initialTags]);

  const onSave = useCallback(async () => {
    // only save if changes were made
    const newVal = sortById(tags.filter((t) => t.isOn)); //filter for selected ones
    const oldVal = sortById(initialTags);

    if (!_isEqual(oldVal, newVal)) {
      setLoader(true);
      const response = await authPost(`/applications/${applicationId}/tags`, {
        tagIDs: newVal,
      });
      setLoader(false);
      const { error, data } = response;
      if (error) {
        return notify('An error occurred while saving the tags', 'error');
      }
      notify('Changes saved');
      updateTags(data);
    }

    setAnchorEl(false);
  }, [
    applicationId,
    notify,
    setAnchorEl,
    tags,
    initialTags,
    updateTags,
    setLoader,
  ]);

  const onChangeTagSelected = useCallback((e) => {
    const { name, checked } = e.target;
    setTags((s) => {
      const index = s.findIndex((t) => t.id === +name);
      const newState = [...s];
      newState[index].isOn = checked;
      return newState;
    });
  }, []);

  return (
    <Popover
      onClose={onSave}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
    >
      <Paper
        sx={{
          paddingX: '9px',
          paddingRight: '40px',
          boxShadow: 'none',
        }}
      >
        <FlexColumn>
          {tags.map((t) => (
            <FlexCenter key={t.id}>
              <Checkbox
                size='small'
                name={t.id.toString()}
                checked={t.isOn}
                onChange={onChangeTagSelected}
              />
              <Typography variant='caption'>{t.name}</Typography>
            </FlexCenter>
          ))}
        </FlexColumn>
      </Paper>
    </Popover>
  );
}
