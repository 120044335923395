import React from 'react';
import { Box, Grid, styled } from '@mui/material';

export const StyledGridItem = styled(Grid, {
  shouldForwardProp: (prop) => prop !== 'inputRow' && prop !== 'noPaddingRight',
})(({ inputRow, noPaddingRight }) => ({
  flexGrow: inputRow ? 1 : undefined,
  marginBottom: inputRow ? 32 : undefined,
  paddingTop: 0,
  paddingBottom: 0,
  paddingRight: !noPaddingRight ? 32 : undefined,
}));

export function CustomGrid({ children, useFormPadding = true }) {
  return (
    <Box sx={{ padding: useFormPadding ? '0px 32px 0px 32px' : undefined }}>
      <Grid
        sx={{
          marginTop: 0,
          width: 'calc(100% + 64px)',
          maxWidth: 800,
          flexGrow: 1,
        }}
        spacing={4}
        container
      >
        {children}
      </Grid>
    </Box>
  );
}
