import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { Backdrop, CircularProgress } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import { uiSelectors } from '../../state';

const useStyles = makeStyles((theme) => ({
  loadingBackdrop: {
    backgroundColor: 'rgba(255,255,255,0.7)',
    zIndex: theme.zIndex.modal + 100,
  },
  loader: {
    width: '100%',
    textAlign: 'center',
    paddingTop: 30,
  },
}));

export const PageLoader = memo(() => {
  const classes = useStyles();
  const loading = useSelector(uiSelectors.loading);

  return (
    <Backdrop className={classes.loadingBackdrop} open={loading}>
      <CircularProgress disableShrink size={128} thickness={1} />
    </Backdrop>
  );
});

export function SuspensePageLoader(){
  const classes = useStyles();
 
  return (
    <Backdrop className={classes.loadingBackdrop} open={true}>
      <CircularProgress disableShrink size={128} thickness={1} />
    </Backdrop>
  );
};

export const Loader = memo(({ className, size = 24 }) => {
  const classes = useStyles();

  return (
    <div className={className || classes.loader}>
      <CircularProgress size={size} disableShrink />
    </div>
  );
});
