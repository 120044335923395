import React from 'react';
import _get from 'lodash.get';
import { Link } from '@mui/material';

// import { useStyles } from './fields.styles';

/** EmailField props.
 * @param {EmailFieldProps} props
 */
export function EmailField({
  className,
  linkProps = {},
  source,
  record = {},
  orderBy,
  ...rest
}) {
  // const classes = useStyles();
  const stopPropagation = (e) => e.stopPropagation();
  const value = _get(record, source);
  if (!value) return null;
  return (
    <Link
      href={`mailto:${value}`}
      onClick={stopPropagation}
      className={className}
      underline='none'
      {...linkProps}
    >
      {value}
    </Link>
  );
}

// #region Typedefs
/** @typedef {object} EmailFieldProps
 * @property {import("@mui/material/Link").LinkProps} [linkProps]
 * @property {import("react").HTMLAttributes} [className]
 * @property {import("react").HTMLAttributes} [headerClassName] class applied to the header
 * @property {import("csstype").Properties} [cellStyle]
 * @property {string} source
 * @property {string} label
 * @property {string} [disablePadding]
 * @property {string} [align]
 * @property {string} [sortBy] Defaults to `source`
 * @property {false} [sortable] Set to false to disable sorting for this column
 */
// #endregion
