import React, { Fragment, cloneElement } from 'react';
import { Tooltip } from '@mui/material';
export function ReadOnlyComponent({
  children,
  isReadOnly,
  message = 'This data is integrated and must be edited at the source',
  placement = 'bottom-end',
}) {
  return isReadOnly ? (
    <>
      <Tooltip title={message} placement={placement}>
        <div style={{ display: 'inline' }}>
          {cloneElement(children, { disabled: true })}
        </div>
      </Tooltip>
    </>
  ) : (
    <>{children}</>
  );
}
