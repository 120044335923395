import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { uiActions } from '../state';

export function useRefresh(viewName: string) {
  const dispatch = useDispatch();
  return useCallback(
    (doRefresh = true) =>
      doRefresh && dispatch(uiActions.incrementViewVersion(viewName)),
    [viewName, dispatch],
  );
}
