import { useGetUsersData } from '../../../../hooks';
import { NavButton } from '../../../../components';
import { InfoOutlinedIcon } from '../../../../themes';
import { Routes, generatePath, pluralize } from '../../../../lib';
import { ListQueryParams } from '../../ListViews/helpers/SharedListConfig';

type Props = {
  overdueTasksCount: number;
  selectedFacilityIds: string[];
  onlyShowAssignedToMe: boolean;
};

export default function OverdueTasksButton({
  overdueTasksCount,
  selectedFacilityIds,
  onlyShowAssignedToMe,
}: Props) {
  const { CompanyUserIdClaim: myUserId } = useGetUsersData();

  if (!overdueTasksCount) return null;

  return (
    <NavButton
      alwaysShowArrow
      variant='outlined'
      icon={<InfoOutlinedIcon color='error' />}
      label={`${overdueTasksCount} Overdue ${pluralize(
        'task',
        overdueTasksCount,
      )}`}
      link={generatePath(
        Routes.alertsPage.path,
        {},
        {
          [ListQueryParams.facilityIds]: selectedFacilityIds,
          ...(onlyShowAssignedToMe && { assignees: [myUserId] }),
        },
      )}
    />
  );
}
