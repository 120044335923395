import React, {
  useState,
  Fragment,
  useMemo,
  useCallback,
  useEffect,
} from 'react';
import clsx from 'clsx';
import { Button } from '@mui/material';
import { SearchInput } from '../../../common';
import { FilterListIcon } from '../../../../themes';
import { FiltersSelect } from './FiltersSelect';
import { FilterChips } from './ListFilter';

import { useStyles } from './inputs.styles';
import { useGetQueryParams } from '../../../../hooks';

/**
 *
 * @param {Props} props
 */
export function ListFiltersActions({
  hasSearchField,
  searchProps = {},
  filtersProps = {},
  className,
  hideChips,
  hideToggleShowBtn,
  children,
  searchAndFilterInline,
  renderSearchField,
}) {
  const classes = useStyles();
  const [showFilters, setShowFilters] = useState(true);
  const [allActiveFiltersObj, setAllActiveFilterObj] = useState({});

  const filtersObj = useMemo(() => {
    return filtersProps.filters?.reduce((acc, cur) => {
      const { filter, queryParam } = cur;
      acc[queryParam] = filter;
      return acc;
    }, {});
  }, [filtersProps.filters]);

  const queryFilters = useMemo(() => {
    return filtersProps.filters?.map((f) => f.queryParam);
  }, [filtersProps.filters]);

  const populateFiltersObj = useCallback(
    (queryParam, newFilter) => {
      if (!allActiveFiltersObj) {
        return setAllActiveFilterObj({});
      }
      const filterObj = Array.isArray(newFilter)
        ? newFilter.reduce((acc, cur) => {
            acc[queryParam] = acc[queryParam] || {};
            acc[queryParam][cur] = filtersObj[queryParam]?.[cur];
            return acc;
          }, {})
        : newFilter
        ? {
            [queryParam]: { [newFilter]: filtersObj[queryParam]?.[newFilter] },
          }
        : { [queryParam]: {} };
      setAllActiveFilterObj((cur) => ({ ...cur, ...filterObj }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filtersObj],
  );

  //if an old filter is no longer relevant (not in the query list anymore), remove from active filters obj so we don't see the chips for it
  useEffect(() => {
    Object.keys(allActiveFiltersObj).forEach((f) => {
      //if the key is not one of our current queryFilters, clear it out
      if (!queryFilters.includes(f)) {
        allActiveFiltersObj[f] = undefined;
      }
    });
    //NOTE ignoring for allActiveFiltersObj (effectEvent)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryFilters]);

  return (
    <div className={className}>
      <div className={classes.filtersFirstRow}>
        {hasSearchField && !searchAndFilterInline ? (
          renderSearchField ? (
            renderSearchField(<SearchInput {...searchProps} />)
          ) : (
            <SearchInput {...searchProps} />
          )
        ) : (
          <span />
        )}
        {!hideToggleShowBtn && (
          <Button
            startIcon={<FilterListIcon />}
            onClick={() => setShowFilters(!showFilters)}
            color='primary'
            className={classes.filterBtn}
          >
            {showFilters ? 'Hide Filters' : 'Show Filters'}
          </Button>
        )}
      </div>
      {Array.isArray(filtersProps.filters) && (
        <Fragment>
          <div
            className={clsx(
              classes.filterRow,
              { [classes.withChildren]: !!children },
              filtersProps.className,
            )}
          >
            {hasSearchField && searchAndFilterInline && (
              <SearchInput
                className={clsx(classes.filterSelect)}
                size={'small'}
                {...searchProps}
              />
            )}
            {filtersProps.filters.map((f, i) => {
              const {
                label,
                filter: filterMenus,
                queryParam,
                className,
                sideEffectUpdateQuery = {},
                style = {},
              } = f;
              return (
                <Fragment key={i}>
                  <HandleFilter
                    populateFiltersObj={populateFiltersObj}
                    queryParam={queryParam}
                    filters={filterMenus}
                  />
                  {showFilters && (
                    <FiltersSelect
                      label={label}
                      filters={filterMenus}
                      queryParam={queryParam}
                      sideEffectUpdateQuery={sideEffectUpdateQuery}
                      className={clsx(classes.filterSelect, className)}
                      style={{ ...style }}
                      size={'small'}
                    />
                  )}
                </Fragment>
              );
            })}
            {children}
          </div>
          {!hideChips && (
            <FilterChips
              activeFilters={allActiveFiltersObj}
              disabled={filtersProps.loading}
              multipleFilters={filtersProps.hasMultipleFilters}
            />
          )}
        </Fragment>
      )}
    </div>
  );
}

function HandleFilter({ populateFiltersObj, queryParam, filters }) {
  const { [queryParam]: listFromQuery } = useGetQueryParams();
  // populate the active filters when the query param changes
  // NOTE we are putting the `filters` in the dependencies array so that
  // we ensure that we populate the object after all the lists are loaded
  useEffect(() => {
    populateFiltersObj(queryParam, listFromQuery);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listFromQuery, queryParam, filters]);

  return null;
}

// #region Typedefs
/**
 * @typedef {object} Props
 * @property {bool} [hasSearchField] If true display the search field with the `searchProps`
 * @property {bool} [hideToggleShowBtn] If true the show/hide filters button will be hidden
 * @property {bool} [searchAndFilterInline] If true the search and filters will show on the same line
 * @property {filterProps} filtersProps
 * @property {searchInputProps&import("@mui/material/TextField").TextFieldProps} [searchProps] props passed to the search component
 * @property {import("react").HTMLAttributes} [className]
 */

/**
 * @typedef {object} searchInputProps
 * @property {String} [label] The label for the input. Defaults to `Search`
 * @property {String} [queryParam] The query name to store in the URL. Defaults to `search`
 * @property {import("react").HTMLAttributes} [className]
 */

/**
 * @typedef {object} filterProps
 * @property {boolean} loading boolean if fetching from the API
 * @property {boolean} [hasMultipleFilters]
 * @property {Array.<filterParams>} filters
 * @property {import("react").HTMLAttributes} [className]
 */

/**
 * @typedef {object} filterParams
 * @property {string} label The label for the input.
 * @property {string} queryParam The query name to store in the URL.
 * @property {object} filter  Key value pairs with the key as the id to be sent to the API and the value as the display name
 * @property {import("react").CSSProperties} [style]
 * @property {import("react").HTMLAttributes} [className]
 */

//#endregion
