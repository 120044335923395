import { authGet, authPost } from '../../lib';
import { uiActions } from '..';

export const accountActions = {
  createCompany(data) {
    return async (dispatch) => {
      dispatch(uiActions.setUILoading(true));
      const response = await authPost('/saas/createCompany', {
        ...data,
        clientDomain: window.location.href.split('/').slice(0, 3).join('/'), // domain and port
      });
      const { error } = response;
      if (error) {
        dispatch(
          uiActions.showError({
            message:
              typeof error === 'string'
                ? error
                : error.message || 'Something went wrong',
          }),
        );
        dispatch(uiActions.setUILoading(false));
        return { error };
      }
      dispatch(uiActions.setUILoading(false));
      return {
        data: 'success',
      };
    };
  },
  getUserProfile() {
    return async (dispatch) => {
      const response = await authGet(['/users/profile']);
      const { error, data } = response;
      if (error) {
        dispatch(
          uiActions.showError({
            message: error.message || 'Something went wrong',
          }),
        );
        return { error };
      }

      return { data };
    };
  },
  saveUserProfile(payload) {
    return async (dispatch) => {
      const response = await authPost(['/users/profile'], payload);
      const { error, data } = response;
      if (error) {
        dispatch(
          uiActions.showError({
            message: error.message || 'Something went wrong',
          }),
        );
        return { error };
      }
      return { data };
    };
  },
  getRecentActivity(filter) {
    return async (dispatch) => {
      const response = await authGet(['/users/activity/recent', { filter }]);
      const { error, data } = response;
      if (error) {
        dispatch(
          uiActions.showError({
            message: error.message || 'Something went wrong',
          }),
        );
        return { error };
      }

      return { data };
    };
  },
};
