import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    width: '25ch',
  },
  inputRoot: {
    '& $notchedOutline': {
      borderColor: theme.palette.outlineTheme.main,
    },
    '&:hover:not($disabled):not($error) $notchedOutline': {
      borderColor: theme.palette.outlineTheme.main,
    },
    '&$focused:not($disabled):not($error) $notchedOutline': {
      borderColor: theme.palette.outlineTheme.main,
    },
  },
  input: {
    padding: '16.5px 14px 14.5px 14px',
  },
  label: {
    color: theme.palette.textTheme.main,
    '&$focused:not($disabled):not($error)': {
      color: theme.palette.textTheme.main,
    },
  },
  notchedOutline: {},
  disabled: {},
  error: {},
  focused: {},
  helperText: {
    position: 'absolute',
    bottom: -21,
  },
  addressSearchContainer: {
    flexGrow: 1,
    position: 'relative',
    display: 'flex',
  },
  addressSearchPaper: {
    position: 'absolute',
    zIndex: 10,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
    top: 45,
    maxHeight: 350,
    overflowY: 'auto',
  },
  // filtersRoot: {
  //   width: '100%',
  //   // padding: 24,
  //   paddingTop: 25,
  //   paddingBottom: 25,
  // },
  // filtersFirstRow: {
  //   display: 'flex',
  //   alignItems: 'center',
  //   justifyContent: 'space-between',
  // },
  filterSelect: {
    minWidth: 200,
    width: '100%',
    marginRight: 24,
    height: 36,
    '& .MuiSelect-select': {
      padding: '0 32px 0 8px',
    },
  },
  filterRow: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    marginTop: 16,
    marginBottom: 8,
    gap: 16,
  },
  withChildren: {
    display: 'inline-flex',
  },
}));
