import makeStyles from '@mui/styles/makeStyles';
import { SIDEBAR_RIGHT_WIDTH, SITE_HEADER_HEIGHT } from '../lib';

const drawerWidth = 272;

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    background: '#FFFFFF',
    color: '#00AC99',
    zIndex: theme.zIndex.drawer - 1,
    borderBottom: '1px solid #E5E7F5',
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth + SIDEBAR_RIGHT_WIDTH,
    width: `calc(100% - ${drawerWidth + SIDEBAR_RIGHT_WIDTH}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  toolBar: {
    display: 'grid',
    gridTemplateColumns: '420px 1fr 420px',
    // TODO: check if this has to change for tablet - CG
    height: SITE_HEADER_HEIGHT,
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  tabbedHeader: {
    borderBottom: 'none',
    '& $title': {
      fontSize: 22,
    },
  },
  logo: {
    height: 24,
  },
  title: {
    textTransform: 'capitalize',

    fontSize: 28,
    fontWeight: 500,
    color: theme.palette.text.primary,
  },
  search: {
    color: theme.palette.textTheme.main,
  },
  margin: {
    marginLeft: 30,
  },
  guestLogo: {
    height: 32,
  },
  guestText: {
    textAlign: 'center',

    fontSize: 14,
    color: theme.palette.textTheme.main,
  },
}));
