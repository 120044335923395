import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  useGetQueryParams,
  useIsMobile,
  useNotify,
  useUpdatePageQueryParams,
} from '../../../hooks';

import DayCalendar from './DayCalendar';
import WeekCalendar from './WeekCalendar';
import SummaryCalendar from './SummaryCalendar';

import {
  Box,
  Button,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import OverdueTasksButton from './components/OverdueTasksButton';
import {
  ColoredIcon,
  FlexBetween,
  FlexCenter,
  FlexColumn,
  Loader,
  NoContent,
} from '../../../components';
import FilterOption from '../ListViews/filters/FilterOption';
import {
  ArrowDropDownIcon,
  NavigateBeforeIcon,
  NavigateNextIcon,
  UnfoldLessIcon,
  UnfoldMoreIcon,
} from '../../../themes';

import { addDays, parse } from 'date-fns';
import { dateQueryParamFormat } from './utils';
import {
  Facility,
  formatDate,
  formatDateRange,
  getMondayForWeekOfDate,
} from '../../../lib';

import {
  analyticsActions,
  facilitySelectors,
  ResidentDateListGrouping,
  ResidentDatesQueryType,
  ResidentDatesResult,
  ResidentDateTypeType,
} from '../../../state';

const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

const calculateStartAndEndDates = (
  currentView: CalendarViewType,
  currentSelectedDate: Date,
  currentSummaryViewOptionKey: SummaryViewOptionsKeys | undefined,
) => {
  const today = new Date();
  let newStartDate, newEndDate;
  switch (currentView) {
    case CalendarViews.day:
      newStartDate = newEndDate = currentSelectedDate;
      break;
    case CalendarViews.week:
      newStartDate = getMondayForWeekOfDate(currentSelectedDate);
      newEndDate = addDays(newStartDate, 6); // the next Sunday
      break;
    case CalendarViews.summary:
      newStartDate = today;
      // currentSummaryViewOption should always be set when the selected view is summary, but Typescript doesn't know that
      // if somehow we do end up in here without a currentSummaryViewOption, default to an obviously wrong - but not invalid - date
      const numDaysToAdd = currentSummaryViewOptionKey
        ? SummaryViewOptions[currentSummaryViewOptionKey].numDays
        : 0;
      newEndDate = addDays(today, numDaysToAdd);
      break;
    default:
      throw new Error(`Unknown CalendarView: ${currentView}`);
  }
  return [newStartDate, newEndDate];
};

type Props = {
  onlyShowAssignedToMe: boolean;
  overdueTasksCount: number;
  selectedFacilityIds: string[];
  calendarHeight?: string;
  defaultExpanded?: boolean;
};

function Calendar(props: Props) {
  const {
    onlyShowAssignedToMe,
    overdueTasksCount,
    selectedFacilityIds,
    calendarHeight = '282px',
  } = props;

  const dispatch = useDispatch();
  const notify = useNotify();
  const isMobile = useIsMobile();

  // selectedFacilityNames is only needed when printing the full-page calendar
  const allFacilities: Facility[] = useSelector(
    facilitySelectors.facilityOptions,
  );
  const [selectedFacilityNames, setSelectedFacilityNames] = useState<string[]>(
    [],
  );
  useEffect(() => {
    const names = allFacilities
      .filter((f) => selectedFacilityIds.includes(f.id.toString()))
      .map((f) => f.name);
    setSelectedFacilityNames(names);
  }, [selectedFacilityIds, allFacilities]);

  // @ts-expect-error // needs correct typing
  const {
    view = CalendarViews.day,
    selectedDateStr = formatDate(new Date(), dateQueryParamFormat),
    summaryViewOptionKey = SummaryViewOptions.next7Days.id,
  }: QueryParamsType = useGetQueryParams();

  const selectedDate = useMemo(
    () => parse(selectedDateStr, dateQueryParamFormat, new Date()),
    [selectedDateStr],
  );

  const [dateRange, setDateRange] = useState(
    calculateStartAndEndDates(view, selectedDate, summaryViewOptionKey),
  );
  const [initialDataLoaded, setInitialDataLoaded] = useState(false);
  const [data, setData] = useState<ResidentDatesResult>(
    {} as ResidentDatesResult,
  );
  const [allExpanded, setAllExpanded] = useState(false);
  const [allCollapsed, setAllCollapsed] = useState(false);

  const loadResidentDates = useCallback(
    async (
      queryType: ResidentDatesQueryType,
      dateType?: ResidentDateTypeType,
      groupBy?: ResidentDateListGrouping,
    ) => {
      // @ts-expect-error // need to type requests properly
      const { data, error } = await dispatch(
        analyticsActions.getResidentDates(
          selectedFacilityIds,
          onlyShowAssignedToMe,
          queryType,
          dateType,
          groupBy,
          dateRange[0],
          dateRange[1],
          tz,
        ),
      );

      if (error) {
        notify('Something went wrong, please try again later', 'error');
        return {} as ResidentDatesResult;
      }

      return data as ResidentDatesResult;
    },
    [selectedFacilityIds, onlyShowAssignedToMe, dateRange, dispatch, notify],
  );

  useEffect(() => {
    (async function () {
      const queryType = view === CalendarViews.summary ? 'Aggregate' : 'Range';
      const data = await loadResidentDates(queryType);
      setData(data);
      setInitialDataLoaded(true);
    })();
  }, [view, loadResidentDates, notify]);

  const onClickExpandCollapse = (allExpanded: boolean) => {
    setAllExpanded(allExpanded);
    setAllCollapsed(!allExpanded);
  };

  const hasNoContent =
    (data.resultQueryType === 'Range' &&
      !Object.keys(data.dateListLookup || {})?.length) ||
    (data.resultQueryType === 'Aggregate' && !data.dateGroupList?.length);

  if (!initialDataLoaded) return <Loader />;

  const HeaderComponent = isMobile ? FlexColumn : FlexBetween;

  return (
    <>
      <HeaderComponent mb='20px' gap='12px' flexWrap='wrap' displayPrint='none'>
        <DatePickerSection
          view={view}
          selectedDate={selectedDate}
          summaryViewOptionKey={summaryViewOptionKey}
          dateRange={dateRange}
          setDateRange={setDateRange}
        />

        <Box display='flex' gap='12px'>
          {view === CalendarViews.summary && (
            <Box>
              <IconButton onClick={() => onClickExpandCollapse(false)}>
                <UnfoldLessIcon />
              </IconButton>
              <IconButton onClick={() => onClickExpandCollapse(true)}>
                <UnfoldMoreIcon />
              </IconButton>
            </Box>
          )}
          <OverdueTasksButton
            overdueTasksCount={overdueTasksCount}
            onlyShowAssignedToMe={onlyShowAssignedToMe}
            selectedFacilityIds={selectedFacilityIds}
          />
        </Box>
      </HeaderComponent>

      {/* when printing the page display this text summary instead of the components, which are bulkier */}
      <Box display='none' displayPrint='block' marginBottom={2}>
        <Box display='flex' gap={0.5}>
          <Typography variant='caption2'>
            Showing {view} view for{' '}
            {formatDateRange(dateRange[0], dateRange[1])}.
          </Typography>
          {onlyShowAssignedToMe && (
            <Typography variant='caption2'>
              Only showing tasks assigned to me
            </Typography>
          )}
        </Box>
        <Typography variant='caption2'>
          Facilities:{' '}
          {!selectedFacilityIds.length
            ? 'All'
            : selectedFacilityNames.join(', ')}
        </Typography>
      </Box>

      <Box
        height={calendarHeight}
        overflow='auto'
        sx={{
          '@media print': {
            height: '100%',
            overflow: 'visible',
          },
        }}
      >
        {hasNoContent ? (
          <NoContent
            titleText='Get going with calendar dates'
            captionText='Tasks and applications with upcoming due dates will show up here.'
            imageLocation='/images/no-calendar-items.svg'
            marginTop='24px'
          />
        ) : view === CalendarViews.day ? (
          <DayCalendar
            dateListLookup={data.dateListLookup}
            date={selectedDate}
          />
        ) : view === CalendarViews.week ? (
          <WeekCalendar
            dateListLookup={data.dateListLookup}
            dateRange={dateRange}
          />
        ) : (
          <SummaryCalendar
            eventGroups={data.dateGroupList}
            loadResidentDates={loadResidentDates}
            allExpanded={allExpanded}
            allCollapsed={allCollapsed}
            setAllExpanded={setAllExpanded}
            setAllCollapsed={setAllCollapsed}
          />
        )}
      </Box>
    </>
  );
}

type DatePickerSectionProps = {
  view: CalendarViewType;
  selectedDate: Date;
  summaryViewOptionKey: SummaryViewOptionsKeys;
  dateRange: Date[];
  setDateRange: (arg0: Date[]) => void;
};
const DatePickerSection = ({
  view,
  selectedDate,
  summaryViewOptionKey,
  dateRange,
  setDateRange,
}: DatePickerSectionProps) => {
  const isMobile = useIsMobile();
  const updatePageQueryParams = useUpdatePageQueryParams();

  const canUseDatePicker = view !== CalendarViews.summary;

  const updateStartAndEndDates = useCallback(
    (
      currentView: CalendarViewType,
      currentSelectedDate: Date,
      currentSummaryViewOptionKey: SummaryViewOptionsKeys | undefined,
    ) =>
      setDateRange(
        calculateStartAndEndDates(
          currentView,
          currentSelectedDate,
          currentSummaryViewOptionKey,
        ),
      ),
    [setDateRange],
  );

  const onChangeView = useCallback(
    (_: unknown, newView: CalendarViewType) => {
      if (newView) {
        const newSummaryViewOption =
          newView === CalendarViews.summary
            ? SummaryViewOptions.next7Days.id
            : undefined;
        updatePageQueryParams({
          view: newView,
          summaryViewOptionKey: newSummaryViewOption,
        });

        updateStartAndEndDates(newView, selectedDate, newSummaryViewOption);
      }
    },
    [selectedDate, updateStartAndEndDates, updatePageQueryParams],
  );

  const onChangeSummaryViewOption = useCallback(
    (selectedId: SummaryViewOptionsKeys) => {
      const newValue = SummaryViewOptions[selectedId].id;
      updatePageQueryParams({ summaryViewOptionKey: newValue });
      updateStartAndEndDates(view, selectedDate, newValue);
    },
    [view, selectedDate, updateStartAndEndDates, updatePageQueryParams],
  );

  const onChangeSelectedDate = useCallback(
    (newValue: Date | null) => {
      if (newValue) {
        updatePageQueryParams({
          selectedDateStr: formatDate(newValue, dateQueryParamFormat),
        });
        updateStartAndEndDates(view, newValue, summaryViewOptionKey);
      }
    },
    [view, summaryViewOptionKey, updateStartAndEndDates, updatePageQueryParams],
  );

  const incrementSelectedDate = useCallback(
    (forwards: boolean) => {
      let numDaysToChange;
      switch (view) {
        case CalendarViews.day:
          numDaysToChange = 1;
          break;
        case CalendarViews.week:
          numDaysToChange = 7;
          break;
        default:
          throw new Error(`Cannot use datepicker for CalendarView ${view}`);
      }
      const newValue = addDays(
        selectedDate,
        forwards ? numDaysToChange : -numDaysToChange,
      );
      onChangeSelectedDate(newValue);
    },
    [selectedDate, view, onChangeSelectedDate],
  );

  const getIncrementButton = useCallback(
    (isIncrement: boolean) => (
      <IconButton
        disabled={!canUseDatePicker}
        onClick={() => canUseDatePicker && incrementSelectedDate(isIncrement)}
        size='small'
        sx={{ padding: '0px' }}
      >
        <ColoredIcon
          Icon={!isIncrement ? NavigateBeforeIcon : NavigateNextIcon}
          iconColor='text.primary'
        />
      </IconButton>
    ),
    [canUseDatePicker, incrementSelectedDate],
  );

  const DatePickerWrapper = isMobile ? FlexColumn : FlexCenter;

  return (
    <DatePickerWrapper gap='12px'>
      <Box display='flex' justifyContent='center'>
        {/* datepicker */}
        <CalendarDatePicker
          dateRange={dateRange}
          selectedDate={selectedDate}
          onAccept={onChangeSelectedDate}
          disabled={!canUseDatePicker}
        />
        {/* previous/next arrows */}
        <Box display='flex'>
          {getIncrementButton(false)}
          {getIncrementButton(true)}
        </Box>
      </Box>

      {/* view radio group */}
      <ToggleButtonGroup
        exclusive
        fullWidth={isMobile}
        onChange={onChangeView}
        value={view}
        size='small'
      >
        {Object.values(CalendarViews).map((view) => {
          return (
            <ToggleButton key={view} value={view}>
              {view}
            </ToggleButton>
          );
        })}
      </ToggleButtonGroup>

      {/* summary view options dropdown / today button */}
      {view === CalendarViews.summary && !!summaryViewOptionKey ? (
        <FilterOption
          label=''
          menuItems={Object.values(SummaryViewOptions)}
          name='summaryViewOption'
          onChange={onChangeSummaryViewOption}
          selectedMenuItems={[SummaryViewOptions[summaryViewOptionKey]]}
          selectMenu
          closeOnChange
        />
      ) : (
        <Button
          onClick={() => onChangeSelectedDate(new Date())}
          size='small'
          variant='outlined'
        >
          Today
        </Button>
      )}
    </DatePickerWrapper>
  );
};

type CalendarDatePickerProps = {
  dateRange: Date[];
  selectedDate: Date;
  onAccept: (arg0: Date | null) => void;
  disabled: boolean;
};
const CalendarDatePicker = ({
  dateRange,
  selectedDate,
  onAccept,
  disabled,
}: CalendarDatePickerProps) => {
  const [open, setOpen] = useState(false);
  const [localValue, setLocalValue] = useState(selectedDate);

  return (
    <Box>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          showDaysOutsideCurrentMonth
          open={open}
          onChange={(newValue) => newValue && setLocalValue(newValue)}
          onAccept={onAccept}
          onClose={() => setOpen(false)}
          slots={{
            field: DatePickerButton as any, // https://github.com/mui/mui-x/issues/9775
          }}
          slotProps={{
            field: {
              dateRange,
              setCalendarOpen: setOpen,
              calendarOpen: open,
              disabled,
            } as any, // https://github.com/mui/mui-x/issues/9775
            actionBar: {
              actions: ['today'],
            },
          }}
          value={localValue}
          views={['year', 'month', 'day']}
        />
      </LocalizationProvider>
    </Box>
  );
};

type DatePickerButtonProps = {
  dateRange: Date[];
  disabled: boolean;
  setCalendarOpen: (arg0: boolean) => {};
  calendarOpen: boolean;
  InputProps: { ref?: (arg0: HTMLButtonElement | null) => void };
};
const DatePickerButton = ({
  dateRange,
  disabled,
  setCalendarOpen,
  calendarOpen,
  InputProps: { ref } = {},
}: DatePickerButtonProps) => {
  const [startDate, endDate] = dateRange;
  const dateDisplay = formatDateRange(startDate, endDate);
  return (
    <Button
      endIcon={!disabled && <ArrowDropDownIcon />}
      onClick={() => !disabled && setCalendarOpen(!calendarOpen)}
      ref={ref}
      sx={{ minWidth: '150px' }}
    >
      <Typography variant='subtitle2'>{dateDisplay}</Typography>
    </Button>
  );
};

const CalendarViews = {
  day: 'Day',
  week: 'Week',
  summary: 'Summary',
} as const;
type CalendarViewsKeys = keyof typeof CalendarViews;
type CalendarViewType = (typeof CalendarViews)[CalendarViewsKeys];

// kludge: the typescript typing relies on the id being the same as the key
const SummaryViewOptions = {
  next7Days: { name: 'Next 7 Days', id: 'next7Days', numDays: 7 },
  next30Days: { name: 'Next 30 Days', id: 'next30Days', numDays: 30 },
  next60Days: { name: 'Next 60 Days', id: 'next60Days', numDays: 60 },
  next90Days: { name: 'Next 90 Days', id: 'next90Days', numDays: 90 },
} as const;
type SummaryViewOptionsKeys = keyof typeof SummaryViewOptions;

type QueryParamsType = {
  view: CalendarViewType;
  selectedDateStr: string;
  summaryViewOptionKey: SummaryViewOptionsKeys;
};

export default memo(Calendar);
