import { useSelector } from 'react-redux';
import { CheckboxInput } from '../inputs';
import { uiSelectors } from '../../../state';

export function AddToEMR({ checked, onChange }) {
  const showAddToEMR = useSelector(uiSelectors.showAddToEMR);

  return (
    showAddToEMR && (
      <CheckboxInput
        checkboxes={[
          {
            labelProps: {
              label: 'Add to EMR',
            },
            checkboxProps: {
              name: 'addToEMR',
              checked,
              onChange,
            },
          },
        ]}
      />
    )
  );
}
