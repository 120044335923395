import { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useGetUsersData, useLoader } from '../../hooks';

import { Box } from '@mui/material';
import { RegionDetails } from './RegionDetails';
import { Loader } from '../../components';

import { Region } from './types';
import { companyActions, uiActions } from '../../state';

const RegionsPage = memo(function () {
  const dispatch = useDispatch();
  const setLoader = useLoader();

  const { companyID } = useGetUsersData();

  const [initialDataLoaded, setInitialDataLoaded] = useState(false);
  const [regionsData, setRegionsData] = useState([] as Region[]);
  const [refreshData, setRefreshData] = useState(false);

  const loadData = useCallback(async () => {
    const response = await dispatch(companyActions.getCompanyRegions());
    // @ts-ignore // need to type requests properly
    const { data, error } = response;
    if (error) {
      return;
    } else {
      setRegionsData(data);
      setInitialDataLoaded(true);
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(uiActions.setPageNameHeader('Regions'));
    return () => {
      dispatch(uiActions.setPageNameHeader(''));
    };
  }, [dispatch]);

  useEffect(() => {
    setLoader(true);
    loadData();
    setLoader(false);
  }, [setLoader, loadData]);

  useEffect(() => {
    if (refreshData) {
      setRefreshData(false);
      loadData();
    }
  }, [refreshData, loadData]);

  if (!initialDataLoaded) {
    return <Loader />;
  }

  return (
    <Box width='min(100%, 720px)' mt={5}>
      <RegionDetails
        regions={regionsData}
        companyId={companyID}
        setRefreshData={setRefreshData}
      />
    </Box>
  );
});

export default RegionsPage;
