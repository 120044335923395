export const sharedFormStyles = (theme) => ({
  flex: {
    ...flex,
  },
  // TODO: combine all grid classes/styles into one (search for grid:) - CG
  grid: {
    marginTop: 0,
    width: 'calc(100% + 64px)',
    maxWidth: 800,
    flexGrow: 1,
    '& $gridItem, & $gridModalItem': {
      paddingTop: 0,
      paddingBottom: 0,
      paddingRight: 32,
    },
  },
  // need to leave this class here so the class exists for the styles nested under grid
  gridItem: {},
  gridNoteItem: {
    maxWidth: 'inherit',
    minWidth: 'inherit',
  },
  gridFullWidthItem: {
    maxWidth: 'inherit',
    minWidth: 'inherit',
  },
  gridModalItem: {},
  formPadding: {
    padding: '0px 32px 0px 32px',
  },
  inputRow: {
    flexGrow: 1,
    marginBottom: 32,
  },
  multiInput: {
    flexGrow: 1,
    marginTop: 0,
    marginBottom: 0,
  },
  pageSmallTitle: {
    color: '#000000',
    fontSize: 16,
    fontWeight: 500,
  },
  netIncomeAmount: {
    color: '#003FED',
    fontSize: 16,
    fontWeight: 600,
  },
  fullInputWidth: {
    width: 720,
  },
  inputSubText: {
    color: theme.palette.textTheme.main,
    fontSize: 12,
    marginLeft: 20,
    marginBottom: 20,
    width: '100%',
    ...flex,
  },
  inputSubTextLink: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: -28,
    marginBottom: 0,
    color: theme.palette.primary.light,
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0,
    cursor: 'pointer',
    '&:hover': {
      opacity: '0.6',
    },
  },
  divider: {
    color: '#E5E7F5',
  },
  cancelBtn: {
    backgroundColor: '#E5E7F5',
    '&:hover': {
      backgroundColor: '#E5E7F5',
    },
    color: theme.palette.text.primary,
  },
  deleteBtn: {
    color: theme.palette.primary.light,
    textTransform: 'none',
    fontWeight: 500,
  },
  actionBtns: {
    color: theme.palette.outlineTheme.main,
    cursor: 'pointer',
  },
  listView: {
    justifyContent: 'space-between',
    '& span:not(:last-child)': {
      flex: 1,
      fontSize: 16,
    },
  },

  subText: {
    color: theme.palette.textTheme.main,
    fontSize: 14,
    fontWeight: 500,
  },
  requestDate: {
    color: '#000000',
    fontSize: 14,
    fontWeight: 600,
  },
  deadlineText: {
    color: theme.palette.textTheme.main,
    fontSize: 14,
    fontWeight: 600,
  },
  displayNone: {
    display: 'none',
  },
  chipsContainer: {
    flexBasis: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: 10,
    marginTop: -12,
    paddingLeft: 20,
    paddingRight: 20,
  },
  chips: {
    //margin: 12,
    marginTop: 12,
    marginRight: 10,
    background: '#E5E7F5',
    color: 'rgba(0,0,0,0.87)',
    fontFamily: 'Roboto',
    fontSize: 14,
  },
  chipIcon: {
    color: 'rgba(0,0,0,0.6)',
  },
  avatarMe: {
    backgroundColor: '#0F0190',
    color: 'ffffff',
  },
  avatarAssignee: {
    backgroundColor: theme.palette.primary.light,
    color: '#ffffff',
  },
  avatarResident: {
    backgroundColor: '#FA595F',
    color: '#ffffff',
    cursor: 'pointer',
  },
  avatar: {
    fontSize: 12,
    fontWeight: 500,
    height: 30,
    width: 30,
  },
  addButton: {
    cursor: 'pointer',
    letterSpacing: 0,
    fontSize: 14,
    padding: '10px 10px 10px 5px',
    borderRadius: 3,
    '&:hover': {
      backgroundColor: '#F5F6FE',
    },
  },
  addIcon: {
    color: theme.palette.primary.light,
    marginLeft: 5,
  },
  sectionHeader: {
    letterSpacing: 0,
    fontSize: 14,
    display: 'flex',
    alignItems: 'center',
  },
  sectionTitle: {
    fontSize: 18,
    fontWeight: 500,
    marginBottom: 16,
  },
  separationLine: {
    width: '100%',
    height: 1,
    marginBottom: 30,
    backgroundColor: '#D5D8EC',
  },
  midFormButton: {
    marginBottom: 40,
    color: theme.palette.primary.light,
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0,
    display: 'flex',
    cursor: 'pointer',
    '&:hover': {
      opacity: '0.6',
    },
  },
  modalSubtitle: {
    color: '#000000',
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0,
    display: 'flex',
    marginBottom: 20,
    marginTop: 15,
  },
  expandIcon: {
    color: theme.palette.primary.light,
    marginLeft: 15,
    cursor: 'pointer',
    '&:hover': {
      opacity: '0.6',
    },
  },
  setDefaultLink: {
    color: theme.palette.primary.light,
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0,
    display: 'flex',
    cursor: 'pointer',
    '&:hover': {
      opacity: '0.6',
    },
  },
  checkbox: {
    color: theme.palette.outlineTheme.main,
    '&$checkboxChecked': {
      color: theme.palette.primary.light,
    },
  },
  checkboxChecked: {},
  errorText: {
    color: '#f44336',
    textTransform: 'none',
    fontSize: 14,
    margin: 0,
  },
  sortableField: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: '1px solid gray',
    borderRadius: 3,
    padding: '4px 12px',
    marginBottom: 8,
    background: '#fff',
    '& .MuiSvgIcon-root': {
      cursor: 'grab',
    },
  },
  checkCircleIcon: {
    color: '#0FC076',
    padding: 6,
  },
  secondaryIconButtons: {
    color: theme.palette.text.secondary,
    padding: 6,
  },
  addListItemButton: {
    color: theme.palette.primary.light,
    textTransform: 'capitalize',
    '&:hover': {
      color: theme.palette.primary.light,
    },
    marginBottom: 40,
  },
});

const flex = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};
