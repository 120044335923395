import React from 'react';
import _get from 'lodash.get';
import { Typography, Tooltip } from '@mui/material';
import { DoneIcon, ClearIcon } from '../../../../themes';

// import { useStyles } from './fields.styles';

/** BooleanField props.
 * @param {BooleanFieldProps} props
 */
export function BooleanField({
  className,
  typographyProps = {},
  source,
  record = {},
  emptyText,
  valueLabelTrue = 'True',
  valueLabelFalse = 'False',
  orderBy,
  ...rest
}) {
  // const classes = useStyles();
  const value = _get(record, source);
  let ariaLabel = value ? valueLabelTrue : valueLabelFalse;

  return (
    <Typography
      component='span'
      variant='body2'
      className={className}
      {...typographyProps}
    >
      <Tooltip title={ariaLabel}>
        {!!value ? (
          <DoneIcon data-testid='true' />
        ) : (
          <ClearIcon data-testid='false' />
        )}
      </Tooltip>
    </Typography>
  );
}

// #region Typedefs
/** @typedef {object} BooleanFieldProps
 * @property {import("@mui/material/Typography").TypographyProps} [typographyProps]
 * @property {import("react").HTMLAttributes} [className]
 * @property {import("react").HTMLAttributes} [headerClassName] class applied to the header
 * @property {import("csstype").Properties} [cellStyle]
 * @property {string} source
 * @property {string} label
 * @property {string} [emptyText]
 * @property {string} [valueLabelTrue] Tooltip with the text if true
 * @property {string} [valueLabelFalse] Tooltip with the text if true
 * @property {string} [disablePadding]
 * @property {string} [align]
 * @property {string} [sortBy] Defaults to `source`
 * @property {false} [sortable] Set to false to disable sorting for this column
 */
// #endregion
