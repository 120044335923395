import { useState, useEffect } from 'react';
import { get, set } from 'idb-keyval';

/**
 * use IdbKeyVal storage for data that needs to persist even after the user logs out,
 * since we clear out the regular redux store - even the persisted one - on logout.
 * Use this instead of localStorage b/c localStorage is not supported on the PWA
 *
 * b/c this is async, the value may not be set immediately -
 * you can check that it has a value / or that it equals the default value before reading it
 * (default value will only be set after the data loads, if its empty)
 * @param {string} key
 * @param {*} defaultValue
 * @returns
 */
export const useIdbKeyVal = (key, defaultValue) => {
  const [value, setValue] = useState();

  //set the initial state async (pull from storage, or use default)
  useEffect(() => {
    get(key).then((data) => setValue(data || defaultValue));
  }, [key, defaultValue]);

  //saving updated value
  useEffect(() => {
    if (value || value === defaultValue) {
      set(key, value); //this also returns a promise
    }
  }, [key, value, defaultValue]);

  return [value, setValue];
};
