import React, { useState } from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Input,
  Paper,
  IconButton,
  Select,
  MenuItem,
} from '@mui/material';
import { EditIcon, DeleteIcon, CheckCircleIcon, Cancel } from '../../../themes';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
  selectTableCell: {
    width: 60,
    textAlign: 'right',
  },
  tableCell: {
    width: 130,
    height: 40,
  },
  input: {
    width: '100%',
    height: 40,
  },
  noValue: {
    color: '#6C7493',
  },
}));

function EditableTableRow(props) {
  const { row, fields, onRevert, onDelete, onChange, saveRow } = props;
  const classes = useStyles();

  const [isEditMode, setIsEditMode] = useState(false);
  const [previousValue, setPreviousValue] = useState();

  const onToggleEdit = () => {
    setPreviousValue({ ...row });
    isEditMode && saveRow && saveRow(row);
    setIsEditMode(!isEditMode);
  };

  const handleCancel = () => {
    onRevert(row.index, previousValue);
    setIsEditMode(false);
  };

  return (
    <TableRow key={row.index}>
      {fields.map((f, i) => (
        <EditableTableCell
          key={i}
          {...{
            row,
            isEditMode: isEditMode,
            ...f,
            // name: f.name,
            // isSelect: f.isSelect,
            // selectOptions: f.selectOptions,
            // header: f.header,
            // isEditable: f.isEditable,
            // isFileName: f.isFileName,
            onChange,
            // renderView: f.renderView,
          }}
        />
      ))}

      <TableCell className={classes.selectTableCell}>
        {isEditMode ? (
          <>
            <IconButton aria-label='revert' color={'primary'} onClick={handleCancel} size="large">
              <Cancel />
            </IconButton>
            <IconButton aria-label='done' color={'primary'} onClick={onToggleEdit} size="large">
              <CheckCircleIcon />
            </IconButton>
          </>
        ) : (
          <>
            <IconButton aria-label='edit' onClick={onToggleEdit} size="large">
              <EditIcon />
            </IconButton>
            {onDelete && (
              <IconButton aria-label='delete' onClick={() => onDelete(row.index)} size="large">
                <DeleteIcon />
              </IconButton>
            )}
          </>
        )}
      </TableCell>
    </TableRow>
  );
}

const EditableTableCell = ({
  row,
  isEditMode,
  name,
  isSelect,
  selectOptions,
  header,
  isEditable,
  isFileName,
  onChange,
  renderView,
  emptyValue,
  inputMaxLength,
}) => {
  const classes = useStyles();
  return (
    <TableCell
      align='left'
      className={clsx(classes.tableCell, !row[name] && classes.noValue)}
    >
      {isEditable && isEditMode ? (
        <>
          {!isSelect ? (
            <Input
              value={row[name]}
              placeholder={header}
              name={name}
              onChange={(e) => onChange(e, row)}
              className={classes.input}
              inputProps={{ maxLength: inputMaxLength }}
            />
          ) : (
            <Select
              value={row[name]}
              placeholder={header}
              name={name}
              onChange={(e) => onChange(e, row)}
              className={classes.input}
            >
              {selectOptions.map((s) => (
                <MenuItem value={s.id}>{s.name}</MenuItem>
              ))}
            </Select>
          )}
        </>
      ) : renderView ? (
        renderView(row)
      ) : isFileName && row.fileExtension ? (
        `${row[name] || 'Untitled'}.${row.fileExtension}`
      ) : isSelect ? (
        selectOptions.find((o) => o.id === row[name])?.name || emptyValue
      ) : (
        row[name] || emptyValue
      )}
    </TableCell>
  );
};

export function EditableTable(props) {
  const { hideHeader, fields, onDelete, rows, setRows, saveRow } = props;
  const classes = useStyles();

  const onChange = (e, row) => {
    const value = e.target.value;
    const name = e.target.name;
    const { index: rowIndex } = row;
    const newRows = rows.map((row, i) => {
      if (i === rowIndex) {
        const newRow = { ...row, [name]: value };
        return newRow;
      }
      return row;
    });
    setRows(newRows);
  };

  const onRevert = (rowIndex, oldValues) => {
    const newRows = rows.map((row, i) => {
      if (i === rowIndex) {
        return { ...oldValues };
      }
      return { ...row };
    });
    setRows(newRows);
  };

  return (
    <Paper className={classes.root}>
      <Table className={classes.table} aria-label='caption table'>
        {!hideHeader && (
          <TableHead>
            <TableRow>
              {fields.map((field) => (
                <TableCell align='left'> {field.header}</TableCell>
              ))}
              <TableCell align='left' />
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          {rows.map((row, i) => (
            <EditableTableRow
              key={i}
              row={{ ...row, index: i }}
              saveRow={saveRow}
              fields={fields}
              onRevert={onRevert}
              onDelete={onDelete}
              onChange={onChange}
            />
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
}
