import React from 'react';
import { Box, useTheme, Tooltip } from '@mui/material';

export function ExplanationTooltip({ title, ...props }) {
  const theme = useTheme();
  return (
    <Tooltip
      title={<Box sx={{ width: 231 }}>{title}</Box>}
      placement='left-end'
      {...props}
    >
      <Box
        sx={{
          color: theme.palette.primary.light,
          fontFamily: 'Roboto',
          fontSize: 12,
          fontWeight: 500,
          width: 'max-content',
          float: 'right',
        }}
      >
        What is this?
      </Box>
    </Tooltip>
  );
}
