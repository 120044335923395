import { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NewDashboardPage from './NewDashboardPage';
import OldDashboardPage from './OldDashboardPage';
import { CaseNavigationSource } from '../../lib';
import { uiActions, uiSelectors } from '../../state';

function DashboardPage() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      uiActions.setCaseDetailsSourcePage(CaseNavigationSource.dashboard),
    );
  }, [dispatch]);

  const isNewDashboardOn = useSelector(uiSelectors.isNewDashboardOn);
  return isNewDashboardOn ? <NewDashboardPage /> : <OldDashboardPage />;
}

export default memo(DashboardPage);
