import React, { useState, useCallback } from 'react';
import _get from 'lodash.get';
import { Switch } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  switchBase: {
    '&$checked': {
      color: '#061BEE',
    },
    '&$checked + $track': {
      backgroundColor: '#061BEE',
      opacity: 0.4,
    },
  },
  checked: {},
  track: {},
}));

/** SwitchField props.
 * @param {SwitchFieldProps} props
 */
export function SwitchField({
  className,
  chipProps = {},
  source,
  record = {},
  orderBy,
  onChange,
  ...rest
}) {
  if (!onChange)
    throw new Error('`initialValue` and `onChange` are required props');
  const classes = useStyles();
  const value = _get(record, source);
  const [state, setState] = useState(!!value);

  const handleChange = useCallback(
    (e) => {
      const { checked } = e.target;
      setState(checked);
      onChange({ value: checked, record });
    },
    [onChange, record],
  );

  return (
    <Switch
      checked={state}
      onChange={handleChange}
      color='primary'
      name='state'
      size='small'
      classes={{
        switchBase: classes.switchBase,
        checked: classes.checked,
        track: classes.track,
      }}
    />
  );
}

// #region Typedefs
/** @typedef {object} SwitchFieldProps
 * @property {import("@mui/material/Switch").SwitchProps} [switchProps]
 * @property {import("react").HTMLAttributes} [className]
 * @property {import("react").HTMLAttributes} [headerClassName] class applied to the header
 * @property {import("csstype").Properties} [cellStyle]
 * @property {string} source
 * @property {string} label
 * @property {string} [disablePadding]
 * @property {string} [align]
 * @property {string} [sortBy] Defaults to `source`
 * @property {false} [sortable] Set to false to disable sorting for this column
 * @property {({value, record})=>} onChange
 */
// #endregion
