import React, { useState, useEffect, useCallback, useRef } from 'react';
import clsx from 'clsx';
import { TextField, Paper, MenuItem, InputAdornment } from '@mui/material';
import { useSelector } from 'react-redux';
import PlacesAutocomplete, {
  geocodeByAddress,
} from 'react-places-autocomplete';
import { useStyles } from './inputs.styles';
import { CloseIcon } from '../../../themes';
import { systemPersistSelectors } from '../../../state';

const blankAddressFields = {
  address1: '',
  city: '',
  stateID: '',
  zip: '',
  county: '',
};

export function AddressSearchInput(props) {
  const classes = useStyles();
  const states = useSelector(systemPersistSelectors.states);
  const {
    className,
    disabled,
    placeholder,
    parentHandleSelect,
    fullWidth = true,
    style,
    initialAddress = '',
    viewVersion,
    ...otherProps
  } = props;

  const [address, setAddress] = useState('');
  const [stateOb, setStateObj] = useState({});
  const isFirstTime = useRef(true);

  useEffect(() => {
    if (isFirstTime && !initialAddress.includes('undefined')) {
      setAddress(initialAddress);
    }
  }, [initialAddress]);

  useEffect(() => {
    const obj = states.reduce((acc, cur) => {
      acc[cur.shortName] = cur.id;
      return acc;
    }, {});
    setStateObj(obj);
  }, [states]);

  useEffect(() => {
    if (isFirstTime.current) {
      isFirstTime.current = false;
      return;
    }
    // This resets the `address` field ie after saving a form but not navigating away.
    if (viewVersion) {
      setAddress('');
    }
  }, [viewVersion]);

  const handleChange = useCallback((address) => {
    setAddress(address);
  }, []);

  const handleSelect = useCallback(
    async (address) => {
      const results = await geocodeByAddress(address);
      if (results.length) {
        const { formatted_address, address_components } = results[0];
        setAddress(formatted_address);
        const parsedValues = address_components.reduce(
          (acc, cur) => {
            switch (cur.types[0]) {
              case 'street_number':
                acc.address1 = cur.short_name;
                break;
              case 'route':
                if (acc.address1) {
                  acc.address1 = `${acc.address1} ${cur.long_name}`;
                }
                break;
              case 'locality':
              case 'neighborhood':
                acc.city = cur.short_name;
                break;
              case 'administrative_area_level_1':
                acc.stateID = stateOb[cur.short_name];
                acc.stateShortName = cur.short_name;
                break;
              case 'postal_code':
                acc.zip = cur.short_name;
                break;
              case 'administrative_area_level_2':
                acc.county = cur.short_name;
                break;
              default:
            }

            return acc;
          },
          // need to set all fields to empty string so that if any of the
          // fields don't come back it wii display properly
          { ...blankAddressFields },
        );
        parentHandleSelect(parsedValues);
      }
    },
    [parentHandleSelect, stateOb],
  );

  const onError = useCallback((status, clearSuggestions) => {
    console.log('Google Maps API returned error with status: ', status);
    clearSuggestions();
  }, []);

  return (
    <PlacesAutocomplete
      value={address}
      onChange={handleChange}
      onSelect={handleSelect}
      onError={onError}
      googleCallbackName={'placesAPICallback'}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div
          className={clsx(classes.addressSearchContainer, className)}
          style={style}
        >
          <TextField
            variant='outlined'
            fullWidth={fullWidth}
            {...getInputProps()}
            // error={!!errorMessage || (errorMessage !== false && !!error)}
            // helperText={errorMessage !== false ? errorMessage || error : ' '}
            // FormHelperTextProps={{
            //   classes: { root: clsx({ [classes.helperText]: addHelperTextStyle }) },
            //   children: ' ',
            // }}
            InputProps={{
              classes: {
                root: classes.inputRoot,
                focused: classes.focused,
                disabled: classes.disabled,
                error: classes.error,
                input: classes.input,
                notchedOutline: classes.notchedOutline,
              },
              autoComplete: 'nope',
              endAdornment: disabled ? null : (
                <InputAdornment position='end'>
                  <CloseIcon
                    style={{ color: '#003FED', cursor: 'pointer' }}
                    onClick={() => {
                      setAddress('');
                      parentHandleSelect({
                        address1: '',
                        city: '',
                        state: '',
                        stateID: '',
                        zip: '',
                        county: '',
                      });
                    }}
                  />
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              classes: {
                root: classes.label,
                focused: classes.focused,
                error: classes.error,
                disabled: classes.disabled,
              },
            }}
            autoComplete='nope'
            disabled={disabled}
            {...otherProps}
          />
          <Paper className={classes.addressSearchPaper} elevation={5}>
            {suggestions.map((suggestion, i) => {
              return (
                <MenuItem
                  {...getSuggestionItemProps(suggestion)}
                  key={i}
                  component='div'
                >
                  {suggestion.description}
                </MenuItem>
              );
            })}
          </Paper>
        </div>
      )}
    </PlacesAutocomplete>
  );
}
