import React, { useEffect, useState, useMemo } from 'react';
import {
  Button,
  Checkbox,
  ClickAwayListener,
  FormControlLabel,
  Grow,
  TextField,
  InputAdornment,
  Popper,
  Paper,
  MenuList,
  MenuItem,
} from '@mui/material';
import { SearchIcon } from '../../../themes';
import { useUpdatePageQueryParams, useGetQueryParams } from '../../../hooks';
import { useStyles } from './FilterDropdown.styles';

export default function FilterDropdown({
  filterType,
  filters,
  queryParam,
  style,
  alphabetize = false,
}) {
  const classes = useStyles();
  const updatePageQueryParams = useUpdatePageQueryParams();
  const { [queryParam]: queryListFilters } = useGetQueryParams();
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  const filtersSorted = useMemo(
    () =>
      alphabetize
        ? [...filters].sort((m1, m2) => m1.name.localeCompare(m2.name))
        : filters,
    [filters, alphabetize],
  );

  useEffect(() => {
    setSelectedFilters(
      typeof queryListFilters === 'string'
        ? [queryListFilters]
        : queryListFilters || [],
    );
  }, [queryListFilters]);

  const selectAll = (e) => {
    if (e.target.checked) {
      let remainingFilters = [];
      filters.map((filter) =>
        remainingFilters.push(
          filterType === 'Author' ? filter.personID.toString() : filter.id,
        ),
      );
      updatePageQueryParams({
        [queryParam]: remainingFilters,
      });
    } else {
      updatePageQueryParams({
        [queryParam]: [],
      });
    }
  };

  const updateFilterParams = (e) => {
    let updatedFilters = [];
    if (selectedFilters.includes(e.target.name)) {
      updatedFilters = selectedFilters.filter(
        (value) => value !== e.target.name,
      );
    } else {
      updatedFilters = Array.from(new Set([...selectedFilters, e.target.name]));
    }
    updatePageQueryParams({
      [queryParam]: updatedFilters,
    });
  };

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div className={classes.filterDropdownContainer} style={style}>
      <div>
        <Button
          className={classes.filterDropdownLabel}
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup='true'
          onClick={handleToggle}
        >
          <span>
            {filterType}
            {selectedFilters?.length > 0 ? `: ${selectedFilters.length}` : ''}
          </span>
        </Button>
        <Popper
          sx={{ zIndex: 2 }}
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'left top' : 'left bottom',
              }}
            >
              <Paper className={classes.filterDropdown}>
                <ClickAwayListener onClickAway={handleClose}>
                  <div>
                    <div className={classes.filterDropdownSearch}>
                      <TextField
                        onChange={(e) => setSearchValue(e.target.value)}
                        placeholder={`Find ${filterType}`}
                        className={classes.search}
                        size={'small'}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position='end'>
                              <SearchIcon
                                style={{ fontSize: 22, color: '#8990B6' }}
                              />
                            </InputAdornment>
                          ),
                        }}
                        value={searchValue}
                        variant='standard'
                      />
                    </div>
                    <MenuList
                      className={classes.filtersDropdownList}
                      autoFocusItem={open}
                      id='menu-list-grow'
                      onKeyDown={handleListKeyDown}
                    >
                      <MenuItem>
                        <FormControlLabel
                          className={classes.filtersDropdownListItem}
                          control={
                            <Checkbox
                              checked={
                                selectedFilters.length === filters.length
                              }
                              onChange={(e) => selectAll(e)}
                              name='all'
                              color='primary'
                            />
                          }
                          label='Select all'
                          size='small'
                        />
                      </MenuItem>
                      {Object.values(filtersSorted)
                        .filter((filter) =>
                          filter.name.match(new RegExp(searchValue, 'i')),
                        )
                        .map((filter, i) => (
                          <MenuItem key={i}>
                            <FormControlLabel
                              className={classes.filtersDropdownListItem}
                              control={
                                <Checkbox
                                  checked={selectedFilters?.includes(
                                    filterType === 'Author'
                                      ? filter.personID.toString()
                                      : filter.id,
                                  )}
                                  onChange={(e) => updateFilterParams(e)}
                                  name={
                                    filterType === 'Author'
                                      ? filter.personID.toString()
                                      : filter.id
                                  }
                                  color='primary'
                                />
                              }
                              label={filter.name}
                              size='small'
                            />
                          </MenuItem>
                        ))}
                    </MenuList>
                  </div>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
}
