import { forwardRef } from 'react';
import { Button, useTheme } from '@mui/material';
import { AddCircleIcon } from '../../../themes';

export const AddIconButtonWithText = forwardRef(
  ({ onClick, text, className, ...props }, ref) => {
    const theme = useTheme();
    return (
      <Button
        ref={ref}
        className={className}
        variant='text'
        onClick={onClick}
        {...props}
        sx={{
          textTransform: 'capitalize',
          fontSize: 14,
          fontWeight: 500,
          color: theme.palette.primary.light,
        }}
      >
        <AddCircleIcon sx={{ marginRight: '8px', height: 16.8, width: 16.8 }} />
        {text}
      </Button>
    );
  },
);
