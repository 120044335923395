import React, { useState, useCallback, useEffect } from 'react';
import clsx from 'clsx';
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  FormHelperText,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { reach } from 'yup';

const useStyles = makeStyles((theme) => ({
  radio: {
    color: theme.palette.outlineTheme.main,
    '&$radioChecked': {
      color: theme.palette.primary.light,
    },
  },
  radioChecked: {},
}));

/**
 * Material-ui Radio with validation
 * @param {RadioInputProps}
 */
export function RadioInput({
  addHelperTextStyle = true,
  className,
  context,
  disabled,
  error,
  label,
  labelProps = {},
  name,
  nestedKey,
  onChange,
  onBlur,
  radios = [],
  row = true,
  schema,
  value,
  radioGroupProps,
  ...props
}) {
  const classes = useStyles();
  const [isDirty, setIsDirty] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  useEffect(() => {
    // this is for when the validation is based of of another field
    // and the values of the context is true/false for example the pasRequested field on facilityIntake
    if (context) {
      const valuesArr = Object.keys(context).reduce((acc, cur) => {
        acc.push(context[cur]);
        return acc;
      }, []);
      if (valuesArr.every((v) => v === false)) {
        setErrorMessage(undefined);
      }
    }
  }, [context]);

  const handleBlur = useCallback(
    (event) => {
      typeof onBlur === 'function' && onBlur(event);
      const { name, value } = event.target;
      if (schema) {
        setIsDirty(true);
        reach(schema, nestedKey || name)
          .validate(value, { context })
          .then(() => setErrorMessage(false))
          .catch((err) => setErrorMessage(err.errors?.[0]));
      }
    },
    [context, nestedKey, onBlur, schema],
  );

  const handleChange = useCallback(
    (event) => {
      typeof onChange === 'function' && onChange(event);
      const { name, value } = event.target;
      if (schema && isDirty) {
        reach(schema, nestedKey || name)
          .validate(value, { context })
          .then(() => setErrorMessage(false))
          .catch((err) => setErrorMessage(err.errors?.[0]));
      }
    },
    [context, isDirty, nestedKey, onChange, schema],
  );

  return (
    <FormControl
      className={className}
      component='fieldset'
      error={!!errorMessage || (errorMessage !== false && !!error)}
    >
      <FormHelperText
        classes={{ root: clsx({ [classes.helperText]: addHelperTextStyle }) }}
        style={{ top: -15 }}
      >
        {errorMessage !== false ? errorMessage || error : undefined}
      </FormHelperText>
      <FormLabel
        component='legend'
        error={!!errorMessage || (errorMessage !== false && !!error)}
        {...labelProps}
      >
        {label}
      </FormLabel>

      <RadioGroup
        aria-label={name}
        name={name}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        row={row}
        {...radioGroupProps}
      >
        {radios.map((r, i) => {
          const {
            label: radioLabel,
            value: radioValue,
            radioProps = {},
            labelProps = {},
            disabled: optionDisabled,
          } = r;
          return (
            <FormControlLabel
              key={i}
              control={
                <Radio
                  color='default'
                  {...radioProps}
                  classes={{
                    root: classes.radio,
                    checked: classes.radioChecked,
                  }}
                  disabled={disabled || optionDisabled}
                />
              }
              labelPlacement='end'
              value={radioValue}
              label={radioLabel}
              {...labelProps}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
}

// #region Typedefs
/** @typedef {object} RadioInputProps
 * @property {import("@mui/material/Radio").RadioProps} radioProps
 * @property {import("@mui/material/FormControlLabel").FormControlLabelProps} labelProps
 * @property {Object} [context] An object with the name and value of the field to use as Yup context
 * @property {Object} schema The defined Yup schema
 * @property {string} [nestedKey] If the value is a nested value provide the path. Example nestedKey='residentContacts.firstName'.
 * @property {string} error The error message when failing validation on submit
 * @property {boolean} [addHelperTextStyle] Boolean to add position absolute to helper text. Defaults to true.
 */
// #endregion
