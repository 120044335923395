import React from 'react';
import _get from 'lodash.get';
import { Typography } from '@mui/material';
import { formatDate } from '../../../../lib';

/** DateField props.
 * @param {DateFieldProps} props
 */
export function DateField({
  className,
  typographyProps = {},
  source,
  record = {},
  format: formatString = 'MM/dd/yyyy',
  orderBy,
  ...rest
}) {
  const value = _get(record, source);
  if (value == null || value === '') return null;
  return (
    <Typography
      component='span'
      variant='body2'
      className={className}
      {...typographyProps}
    >
      {formatDate(value, formatString)}
    </Typography>
  );
}

// #region Typedefs
/** @typedef {object} DateFieldProps
 * @property {import("@mui/material/Typography").TypographyProps} [typographyProps]
 * @property {string} [format]
 * @property {import("react").HTMLAttributes} [className]
 * @property {import("react").HTMLAttributes} [headerClassName] class applied to the header
 * @property {import("csstype").Properties} [cellStyle]
 * @property {string} source
 * @property {string} label
 * @property {string} [emptyText]
 * @property {string} [disablePadding]
 * @property {string} [align]
 * @property {string} [sortBy] Defaults to `source`
 * @property {false} [sortable] Set to false to disable sorting for this column
 */
// #endregion
