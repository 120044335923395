import { uiActions } from '..';
import { authGet } from '../../lib';
export const documentActions = {
  getDocGenConfigs({ caseId, residentId }) {
    return async (dispatch) => {
      const response = await authGet([
        '/Docs/gen/configs',
        { caseId, residentId },
      ]);
      const { data, error } = response;
      if (error) {
        dispatch(
          uiActions.showError({
            message: error.message || 'Something went wrong',
          }),
        );
        return { error };
      }

      return { data };
    };
  },
};
