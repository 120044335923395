import { Auth, Resident } from '../../lib';
import { State } from '../types';

const emptyObject = {};
export const residentSelectors = {
  resident: (state: State): Resident => state.residents.resident,
  residentCaseId: (state: State) => state.residents.resident?.caseID,
  residentId: (state: State) => state.residents.resident?.id,
  residentMaritalStatus: (state: State) =>
    state.residents.resident?.maritalStatus,
  residentLongTermCoverage: (state: State) =>
    state.residents.resident?.longTermCoverage,
  residentStayDetails: (state: State) => state.residents.resident.stayDetails,
  residentIntegrationOverview: (state: State) =>
    state.residents.resident.integrationOverview || emptyObject,
  residentRfmsInfo: (state: State) => state.residents.resident.rfmsInfo,

  spouse: (state: State) => state.residents.spouse,
  spouseStayDetails: (state: State) => state.residents.spouse.stayDetails,
  latestAuth: (state: State): Auth => state.residents.resident.latestAuth,
};
