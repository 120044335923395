import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
  Fragment,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import {
  Autocomplete,
  Button,
  Checkbox,
  Menu,
  MenuItem,
  Paper,
  Popover,
  TextField,
  Typography,
} from '@mui/material';
import { ArrowDropDownIcon, CancelIcon, SearchIcon } from '../../../../themes';
import { uiActions, uiSelectors } from '../../../../state';
import { useStyles } from './listFilters.styles';
import _isEqual from 'lodash.isequal';
import FilterBase from './FilterBase';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { FlexCenter } from '../../../../components';

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

// #region Typedefs
/**
* @typedef {object} Props
  @property {array}  [menuItems] these are the options for the filter
  @property {array}  [selectedMenuItems] currently selected filters -use for multiple, and menuItems; this is list of just Ids.
  @property {any}  [value] current value of the filter - use for contents and checkbox
  @property {string}  [label] filter label for display on the button: i.e. {label}: selectedMenuItems[0] +1 (however many more there are if applicable)
  @property {string}  [name] filter name, use for matching to redux ui.currentListFilter to auto open
  @property {function}  [onChange] to handle the change of selected filters -> will get the new list of selected ones - just the ids, should override the previous
  @property {bool}  [multiple] is this a multi-option or just a regular select. if false, onChange just gets id
  @property {bool}  [selectMenu] single option select vs multi
  @property {bool} [autocomplete] single option select with autocomplete (search field)
  @property {function}  [displayContentsFunc] if filter is custom, render contents in the popover (comes from filterOption.display())
  @property {object}  [displayContentsParam] the param to pass to displayContentsFunc, needs to include onchange (we will override to also close if closeOnChange)
  @property {bool}  [checkbox] is this a checkbox filter
  @property {bool}  [closeOnChange] to close the popup right after handling onchange (not for multi-select)
  @property {object}  [paperStyles] optional to style the div
  @property {function}  [onClear] function onClick of the 'remove' icon for a non-docked filter option, if this is left out, the close icon will not render
  @property {bool}  [isMoreButton] more button label always stays the same and the colors are different
  @property {string}  [searchPlaceholder] placeholder for the search in multi select filter using the autocomplete
  @property {bool}  [twoInOne] filter is 2 in 1 - like a range, requires displayContentsFunc and filter2 
  @property {object}  [filter2] when filter is 2in1, this is the second filter: {name, label, display}
  @property {any}  [filter2Value]
  @property {string} [groupedOptionsLabel] for a 'multiple' filter, the label for the grouped options - all the non-selected ones.  Default is 'All filters'
  @property {any} [customButtonStyles] custom styles for the button, optional
  @property {function} [valueFormatter] optional function to format the value for the filter label
  @property {function} [renderButton] optional function to render the button instead of using the default button+label; ({onClick, ref})=> <Button {...{onClick, ref}}/>
*/
// #endregion

/**
 * @param {Props} props
 */
export default function FilterOption(props) {
  const {
    active,
    menuItems,
    selectedMenuItems = [],
    value,
    label,
    name,
    onChange,
    multiple = false,
    autocomplete = false,
    selectMenu = false,
    displayContentsFunc = null,
    displayContentsParam = null,
    checkbox = false,
    closeOnChange = false,
    paperStyles = {},
    onClear,
    isMoreButton = false,
    searchPlaceholder,
    twoInOne = false,
    filter2 = {},
    filter2Value,
    groupedOptionsLabel = 'All filters',
    customButtonStyles = null,
    valueFormatter,
    renderButton = null,
    disableAutoOpen = false,
  } = props;
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [pendingValues, setPendingValues] = useState([]); //used only for the autocomplete - multi type
  const [curHoverOption, setCurHoverOption] = useState({});

  const open = Boolean(anchorEl);
  const classes = useStyles({ open, isMoreButton });
  const currentListFilter = useSelector(uiSelectors.currentListFilter);
  const buttonRef = useRef();

  useEffect(() => {
    if (currentListFilter === name && !disableAutoOpen) {
      setAnchorEl(buttonRef.current);
    }
  }, [currentListFilter, setAnchorEl, buttonRef, name, disableAutoOpen]);

  //for multi type: sort by selected and add 'selected' property to use groupby
  const sortedBySelectedMenuItems = useMemo(
    () =>
      menuItems &&
      Array.isArray(menuItems) &&
      menuItems
        .map((o) => {
          const selected = selectedMenuItems.some(({ id }) => o.id === id);
          return { ...o, selected };
        })
        .sort((o1, o2) => {
          return o2.selected - o1.selected;
        }),
    [menuItems, selectedMenuItems],
  );

  //open the popper for the filter options
  const handleButtonClick = (event) => {
    setPendingValues(sortedBySelectedMenuItems?.filter((o) => o.selected));
    setAnchorEl(buttonRef.current);
  };

  const handleFilterChange = useCallback(
    (selected) => {
      const selectedValuesIDs = Array.isArray(selected)
        ? selected.map((i) => i.id)
        : selected;
      onChange(selectedValuesIDs);
    },
    [onChange],
  );

  //handles closing the autocomplete and the popper - only if they click out of it, applies the filters
  const handleClose = (event, reason) => {
    if (reason === 'toggleInput') {
      return;
    }

    //if closeOnChange, the onchange was already handled, we just want to close now
    if (!closeOnChange) {
      handleFilterChange(pendingValues);
    }

    if (currentListFilter === name) {
      dispatch(uiActions.setCurrentListFilter(''));
    }
    setAnchorEl(null);
  };

  const {
    display: filter2Display,
    name: filter2Name,
    label: filter2Label,
    valueFormatter: filter2ValueFormatter,
  } = filter2;

  const firstValue =
    value ??
    (Array.isArray(selectedMenuItems)
      ? selectedMenuItems[0]
      : selectedMenuItems);

  //for select when multiple = false
  const isCurrentOptionSelected = (id) => id === (firstValue?.id ?? firstValue);

  const formatValue = (value, formatter) => {
    if (formatter && typeof formatter === 'function') {
      return formatter(value);
    }
    return value;
  };

  const calculateDisplayValue = () => {
    if (
      checkbox ||
      isMoreButton ||
      (renderButton && typeof renderButton === 'function')
    ) {
      //checkbox filter: no display label, no dropdown
      //more button doesn't have an additional display
      return '';
    }

    if (twoInOne) {
      const display = `${
        value ? formatValue(value, valueFormatter) : `All`
      } - ${
        filter2Value ? formatValue(filter2Value, filter2ValueFormatter) : `All`
      }`;
      return value || filter2Value ? display : 'All';
    }

    //all other filter types
    if (firstValue || (selectMenu && firstValue === false)) {
      return typeof firstValue !== 'object'
        ? formatValue(
            menuItems?.find((f) => f.id === firstValue)?.name ?? firstValue,
            valueFormatter,
          )
        : formatValue(
            menuItems?.find((f) => f.id === firstValue.id)?.name,
            valueFormatter,
          ) +
            `${
              selectedMenuItems?.length > 1
                ? ` + ${selectedMenuItems.length - 1}`
                : ``
            }`;
    }
    return 'All'; //no filter set
  };
  const displayValue = calculateDisplayValue();

  const { onChange: displayOnChange, ...restDisplayContentsParam } =
    displayContentsParam || {};

  const handleAutocompleteChange = useCallback(
    (event, newValue) => {
      if (closeOnChange) {
        handleFilterChange(newValue);
        setPendingValues(newValue);
      } else {
        setPendingValues(newValue);
      }
    },
    [closeOnChange, handleFilterChange],
  );
  const onSelectOnly = (value) => {
    handleAutocompleteChange(null, [value]);
    if (closeOnChange) {
      handleClose(null, null);
    }
  };

  const [twoInOneState, setTwoInOneState] = useState({
    val1: value,
    val2: filter2Value,
  });
  //reset when value changes - handle clear all
  useEffect(() => {
    setTwoInOneState({ val1: value, val2: filter2Value });
  }, [value, filter2Value, setTwoInOneState]);
  const handleTwoInOneChange = useCallback(
    (twoInOneState) => {
      const { val1, val2 } = twoInOneState;
      onChange(val1, { [filter2Name]: val2 });
    },
    [onChange, filter2Name],
  );

  const buttonProps = {
    className: clsx(
      classes.residentsFilterPopperButtons,
      onClear ? classes.filterOptionClearable : null,
      customButtonStyles,
    ),
    sx: { border: active ? '1px solid #096EF8' : '1px solid #B9BDD4' },
  };

  function getClearButton(sx) {
    return (
      onClear && (
        <CancelIcon
          sx={sx}
          fontSize='small'
          className={classes.cancelIcon}
          onClick={(e) => {
            e.stopPropagation();
            onClear();
          }}
        />
      )
    );
  }

  return checkbox ? (
    <Button onClick={() => onChange(name, !value)} {...buttonProps}>
      {label}
      {getClearButton({ ml: 0.5 })}
    </Button>
  ) : (
    <Fragment>
      {renderButton && typeof renderButton === 'function' ? (
        renderButton({
          onClick: handleButtonClick,
          ref: buttonRef,
        })
      ) : (
        <Button onClick={handleButtonClick} {...buttonProps} ref={buttonRef}>
          {label}
          {`${label.length > 0 && displayValue.length > 0 ? `: ` : ''}`}
          {displayValue
            ? `${
                displayValue.length + label.length > 38 && !onClear
                  ? `${displayValue.slice(0, 38 - label.length)}...`
                  : `${displayValue}`
              }`
            : ''}
          <ArrowDropDownIcon />
          {getClearButton()}
        </Button>
      )}

      {selectMenu ? (
        /* regular select for non multi-option*/
        <Menu
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          onClose={handleClose}
          classes={{ paper: classes.menuPaper }}
        >
          {menuItems.map((a, index) => {
            const { id, name } = a;
            const selected = isCurrentOptionSelected(id);
            return (
              <MenuItem
                className={classes.option}
                key={index}
                style={selected ? { backgroundColor: '#00000014' } : null}
                value={id}
                onClick={(event) => {
                  closeOnChange && handleClose(null, null); //close the popup
                  onChange(id); //set the filter
                }}
              >
                {name}
              </MenuItem>
            );
          })}
        </Menu>
      ) : (
        <Popover
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          onClose={!multiple ? handleClose : undefined}
          PaperProps={{
            overflow: 'auto',
            sx: { width: 250 },
          }}
        >
          <Paper style={paperStyles}>
            {multiple || autocomplete ? (
              /* autocomplete for a multi-option filter select (or autocomplete without multi) */
              <Autocomplete
                disableClearable
                classes={{
                  paper: classes.paper,
                  option: classes.option,
                  popperDisablePortal: classes.popperDisablePortal,
                }}
                disableCloseOnSelect={!closeOnChange}
                disablePortal
                fullWidth
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => {
                  //ignore the 'selected' flag we added when comparing
                  const { selected: selectedOpt, ...restOption } = option;
                  const { selected: selectedVal, ...restValue } = value;
                  return _isEqual({ ...restOption }, { ...restValue });
                }}
                multiple={multiple}
                onChange={handleAutocompleteChange}
                onClose={handleClose}
                onHighlightChange={(e, o, r) => {
                  setCurHoverOption(o);
                }}
                open
                options={multiple ? sortedBySelectedMenuItems : menuItems}
                groupBy={
                  multiple //only group selected/unselected for multi select
                    ? (option) => (option.selected ? 'selected' : 'all')
                    : undefined
                }
                //renderGroup will be ignored when multiple=false
                renderGroup={(group) => {
                  if (group.group === 'selected') {
                    //selected ones at least 2, include the clear selected items link
                    return (
                      <Fragment key={group.group}>
                        {pendingValues.length > 1 && (
                          <div
                            className={classes.clearSelectedItemsButton}
                            onClick={() => {
                              handleAutocompleteChange(null, []);
                              closeOnChange && handleClose(null, null);
                            }}
                          >
                            Clear selected items
                          </div>
                        )}
                        {group.children}
                      </Fragment>
                    );
                  } else {
                    return (
                      <Fragment key={group.group}>
                        <div className={classes.allItemsLabelWrapper}>
                          <div className={classes.allItemsLabel}>
                            {groupedOptionsLabel}
                          </div>
                          {!isMoreButton &&
                            pendingValues.length <
                              sortedBySelectedMenuItems.length && (
                              <div
                                className={classes.clearSelectedItemsButton}
                                onClick={() => {
                                  handleAutocompleteChange(
                                    null,
                                    sortedBySelectedMenuItems,
                                  );
                                  closeOnChange && handleClose(null, null);
                                }}
                              >
                                Select All
                              </div>
                            )}
                        </div>
                        {group.children}
                      </Fragment>
                    );
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    //prevent backspace from deselecting values
                    onKeyDown={(event) => {
                      if (event.key === 'Backspace' || event.key === 'Delete') {
                        event.stopPropagation();
                      }
                    }}
                    ref={params.InputProps.ref}
                    InputProps={{
                      inputProps: { ...params.inputProps },
                      endAdornment: <SearchIcon />,
                    }}
                    autoFocus
                    fullWidth
                    placeholder={searchPlaceholder || 'Find filters...'}
                    sx={{
                      borderBottom: '1px solid #dfe2e5',
                      '& .MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                      },
                    }}
                  />
                )}
                renderOption={(props, option, { selected }) => {
                  const showSelectOnly =
                    selected &&
                    pendingValues.length > 1 &&
                    _isEqual(curHoverOption, option);

                  const applyBackgroundColor =
                    !multiple && isCurrentOptionSelected(option.id);

                  return (
                    <li
                      {...props}
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                        backgroundColor: applyBackgroundColor
                          ? '#00000014'
                          : undefined,
                      }}
                      key={option.id}
                    >
                      <FlexCenter gap={1} flex={1}>
                        {multiple && (
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            checked={selected}
                          />
                        )}
                        <Typography variant='caption'>{option.name}</Typography>
                      </FlexCenter>
                      {showSelectOnly && (
                        <div className={classes.selectOnlyLink}>
                          <span
                            onClick={(e) => {
                              e.stopPropagation();
                              onSelectOnly(option);
                            }}
                          >
                            only
                          </span>
                        </div>
                      )}
                    </li>
                  );
                }}
                renderTags={() => null}
                sx={{ width: 320, maxWidth: '100%' }}
                value={multiple ? pendingValues : undefined} //when multiple is false, we don't want the selected one to be prefilled in the search bar
              />
            ) : twoInOne ? (
              <FilterBase
                content={
                  <div>
                    {!!displayContentsFunc &&
                      typeof displayContentsFunc === 'function' &&
                      displayContentsFunc({
                        onChange: (val) =>
                          setTwoInOneState((prev) => ({
                            ...prev,
                            val1: val,
                          })),
                        label,
                        name,
                        value: twoInOneState.val1,
                      })}

                    {!!filter2Display &&
                      typeof filter2Display === 'function' &&
                      filter2Display({
                        onChange: (val) =>
                          setTwoInOneState((prev) => ({
                            ...prev,
                            val2: val,
                          })),
                        label: filter2Label,
                        value: twoInOneState.val2,
                        filter2Name,
                      })}
                  </div>
                }
                handleClose={handleClose}
                onChange={() => {
                  handleTwoInOneChange(twoInOneState);
                  closeOnChange && handleClose();
                }}
              />
            ) : /**any other kind of filter - just render the contents */
            !!displayContentsFunc &&
              typeof displayContentsFunc === 'function' ? (
              displayContentsFunc({
                onChange: (val) => {
                  displayOnChange(val);
                  closeOnChange && handleClose();
                },
                ...restDisplayContentsParam,
                handleClose,
              })
            ) : null}
          </Paper>
        </Popover>
      )}
    </Fragment>
  );
}
