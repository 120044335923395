import { createSlice } from '@reduxjs/toolkit';

const initialState = { resources: {} };

export const lists = createSlice({
  name: 'lists',
  initialState,
  reducers: {
    registerLists(state, action) {
      state.resources = { ...action.payload };
    },
    populateList(state, action) {
      const {
        list,
        data: { params, ...rest },
      } = action.payload;
      state.resources[list] = {
        ...rest,
        params: { ...state.resources[list]?.params, ...params },
      };
    },
    updateListParams(state, action) {
      const { list, params } = action.payload;
      state.resources[list] = {
        ...state.resources[list],
        params: { ...state.resources[list]?.params, ...params },
      };
    },
  },
});
