import { format } from 'date-fns';
import { Typography } from '@mui/material';

import {
  companyTypes,
  formatDate,
  formatBool,
  residentStatusTypes,
  formatCurrency,
} from '../../../../lib';
import {
  FieldCategories,
  ListQueryParams,
  SharedFields,
  SharedFilters,
} from './SharedListConfig';
import { CheckIcon } from '../../../../themes';
import { StatusField, PayerNameWithStatus } from '../../../../components';
import { systemPersistSelectors } from '../../../../state';

export const ResidentListTypes = {
  All: 'All',
  InProgress: 'InProgress',
  Referred: 'Referred',
  Coverage: 'Coverage',
  MissingCoverage: 'MissingCoverage',
  UR: 'UR',
  URHmo: 'URHmo',
  URMedicare: 'URMedicare',
  Clinical: 'Clinical',
};

//these are the 'system defined views'
export const ResidentLists = {
  All: {
    exportFileName: `residents_${format(new Date(), 'Pp')}`,
    name: 'All Residents',
    type: ResidentListTypes.All,
    name_AC: 'All Clients',
  },
  InProgress: {
    exportFileName: `applicants-InProgress_${format(new Date(), 'Pp')}`,
    name: 'In Progress',
    type: ResidentListTypes.InProgress,
  },
  Referred: {
    exportFileName: `clients-referred${format(new Date(), 'Pp')}`,
    name: 'Referred',
    type: ResidentListTypes.Referred,
    companyType: companyTypes.Application,
  },
  Coverage: {
    exportFileName: `coverage_${format(new Date(), 'Pp')}`,
    name: 'All Coverage',
    type: ResidentListTypes.Coverage,
    companyType: companyTypes.Facility,
  },
  MissingCoverage: {
    exportFileName: `missing_coverage_${format(new Date(), 'Pp')}`,
    name: 'Missing Coverage',
    type: ResidentListTypes.MissingCoverage,
    companyType: companyTypes.Facility,
  },
  UR: {
    exportFileName: `residents-UR_${format(new Date(), 'Pp')}`,
    name: 'UR',
    type: ResidentListTypes.UR,
    companyType: companyTypes.Facility,
  },
  URHmo: {
    exportFileName: `residents-UR-HMO_${format(new Date(), 'Pp')}`,
    name: 'UR HMO',
    type: ResidentListTypes.URHmo,
    companyType: companyTypes.Facility,
  },
  URMedicare: {
    exportFileName: `residents-UR-Medicare_${format(new Date(), 'Pp')}`,
    name: 'UR Medicare',
    type: ResidentListTypes.URMedicare,
    companyType: companyTypes.Facility,
  },
  Clinical: {
    exportFileName: `residents-Clinical_${format(new Date(), 'Pp')}`,
    name: 'Clinical',
    type: ResidentListTypes.Clinical,
    companyType: companyTypes.Facility,
  },
};

export const ResidentFields = [
  {
    ...SharedFields.ResidentName,
    general: true,
  },
  {
    ...SharedFields.FacilityName,
    general: true,
  },
  {
    ...SharedFields.ReferralSource,
    general: true,
  },
  {
    ...SharedFields.CaseStatus,
    defaultLists: [ResidentListTypes.All, ResidentListTypes.Referred],
    disableSort: true,
  },
  {
    ...SharedFields.PayerName,
    defaultLists: [
      ResidentListTypes.InProgress,
      ResidentListTypes.All,
      ResidentListTypes.UR,
      ResidentListTypes.URHmo,
      ResidentListTypes.URMedicare,
      ResidentListTypes.Coverage,
      ResidentListTypes.MissingCoverage,
    ],
  },
  {
    ...SharedFields.ApplicationStatus,
    disableSort: true,
    defaultLists: [
      ResidentListTypes.InProgress,
      ResidentListTypes.All,
      ResidentListTypes.Referred,
    ],
  },
  {
    ...SharedFields.DateForCurrentApplicationStatus,
    defaultLists: [ResidentListTypes.InProgress],
  },
  {
    ...SharedFields.DaysForCurrentApplicationStatus,
    defaultLists: [ResidentListTypes.InProgress],
  },
  {
    display: (record) =>
      record.residentClinicalStatusDisplay ? (
        <StatusField
          source='residentClinicalStatusDisplay'
          label='Resident Clinical status'
          record={record}
          badgeClassName={record.residentClinicalStatus}
        />
      ) : (
        'N/A'
      ),
    exportHeaders: [
      {
        key: 'residentClinicalStatusDisplay',
        label: 'Resident Clinical Status',
      },
    ],
    id: 'ResidentClinicalStatus',
    label: 'Resident Clinical Status',
    profileQueryString: 'view=clinical',
    disableSort: true,
    companyType: companyTypes.Facility,
    defaultLists: [
      ResidentListTypes.InProgress,
      ResidentListTypes.All,
      ResidentListTypes.UR,
      ResidentListTypes.URHmo,
      ResidentListTypes.URMedicare,
      ResidentListTypes.Clinical,
    ],
    fieldCategory: FieldCategories.Clinical,
  },
  {
    display: (record) =>
      record.clinicalStatusDisplay ? (
        <StatusField
          source='clinicalStatusDisplay'
          label='Latest LOC Status'
          record={record}
          badgeClassName={record.clinicalStatus}
        />
      ) : (
        'N/A'
      ),
    exportHeaders: [
      {
        key: 'clinicalStatusDisplay',
        label: 'Latest LOC Status',
      },
    ],
    id: 'ClinicalStatus',
    label: 'Latest LOC Status',
    profileQueryString: 'view=clinical',
    disableSort: true,
    companyType: companyTypes.Facility,
    defaultLists: [
      ResidentListTypes.InProgress,
      ResidentListTypes.All,
      ResidentListTypes.UR,
      ResidentListTypes.URHmo,
      ResidentListTypes.URMedicare,
      ResidentListTypes.Clinical,
    ],
    fieldCategory: FieldCategories.Clinical,
  },
  {
    ...SharedFields.MedicaidNeededBy,
    defaultLists: [
      ResidentListTypes.InProgress,
      ResidentListTypes.UR,
      ResidentListTypes.URHmo,
      ResidentListTypes.URMedicare,
    ],
  },
  {
    ...SharedFields.ApplicationInfo,
    defaultLists: [ResidentListTypes.InProgress],
  },
  {
    ...SharedFields.CoPayerName,
    defaultLists: [
      ResidentListTypes.URMedicare,
      ResidentListTypes.Coverage,
      ResidentListTypes.MissingCoverage,
    ],
  },
  {
    defaultLists: [ResidentListTypes.Clinical],
    display: ({ facilityState }) => facilityState,
    exportHeaders: [
      {
        key: 'facilityState',
        label: 'State',
      },
    ],
    id: 'FacilityState',
    label: 'State',
    profileQueryString: 'view=census',
    companyType: companyTypes.Facility,
    fieldCategory: FieldCategories.General,
  },
  {
    ...SharedFields.IncomePaymentStatus,
    defaultLists: [ResidentListTypes.All, ResidentListTypes.Referred],
    disableSort: true,
  },
  {
    ...SharedFields.AdmitDate,
    defaultLists: [
      ResidentListTypes.All,
      ResidentListTypes.UR,
      ResidentListTypes.URHmo,
      ResidentListTypes.URMedicare,
      ResidentListTypes.Coverage,
      ResidentListTypes.MissingCoverage,
    ],
  },
  {
    ...SharedFields.IncomeNetAmount,
    disableSort: true,
  },
  {
    ...SharedFields.MedicaidCaseworker,
    disableSort: true,
  },
  {
    ...SharedFields.ApplicationType,
    disableSort: true,
  },
  SharedFields.LatestApplicationNoteDate,
  SharedFields.MedicalRecordNumber,
  SharedFields.NextCaseworkerRequestDeadline,
  SharedFields.ApplicationSubmitDate,
  SharedFields.ApplicationTags,
  // {
  //   defaultLists: [ResidentListTypes.URHmo],
  //   display: ({ daysCoveredAt100Percent }) => daysCoveredAt100Percent,
  //   exportHeaders: [
  //     {
  //       key: 'daysCoveredAt100Percent',
  //       label: 'Days Covered at 100%',
  //     },
  //   ],
  //   id: 'DaysCoveredAt100Percent',
  //   label: 'Days Covered at 100%',
  //   profileQueryString: 'view=census',
  //   companyType: companyTypes.Facility,
  // },
  {
    display: (record) =>
      record.nriStatusDisplay ? (
        <StatusField
          source='nriStatusDisplay'
          label='NRI Status'
          record={record}
          badgeClassName={record.nriStatus}
        />
      ) : (
        ''
      ),
    exportHeaders: [{ key: 'nriStatusDisplay', label: 'NRI Status' }],
    id: 'NRIStatus',
    isForNRIWorkflow: true,
    label: 'NRI Status',
    profileQueryString: 'view=nri',
    disableSort: true,
    companyType: companyTypes.Facility,
    fieldCategory: FieldCategories.General,
  },
  {
    display: ({ latestIntakeNoteDate }) => formatDate(latestIntakeNoteDate),
    exportHeaders: [
      {
        key: 'latestIntakeNoteDate',
        label: 'Latest Intake Note Date',
        format: 'date',
      },
    ],
    id: 'LatestIntakeNoteDate',
    label: 'Latest Intake Note Date',
    profileQueryString: 'view=notes',
    fieldCategory: FieldCategories.Intake,
  },
  {
    defaultLists: [ResidentListTypes.Clinical],
    display: ({ levelOfCareExpirationDate }) =>
      formatDate(levelOfCareExpirationDate),
    exportHeaders: [
      {
        key: 'levelOfCareExpirationDate',
        label: 'Level Of Care Expiration',
        format: 'date',
      },
    ],
    id: 'LevelOfCareExpirationDate',
    label: 'Level of Care Expiration',
    profileQueryString: 'view=clinical',
    companyType: companyTypes.Facility,
    fieldCategory: FieldCategories.Clinical,
  },
  {
    defaultLists: [ResidentListTypes.Clinical],
    display: ({ pasrr1StatusDisplay }) => pasrr1StatusDisplay,
    exportHeaders: [
      {
        key: 'pasrr1StatusDisplay',
        label: 'PASRR 1 Status',
      },
    ],
    id: 'Pasrr1Status',
    label: 'PASRR 1 Status',
    profileQueryString: 'view=clinical',
    disableSort: true,
    companyType: companyTypes.Facility,
    fieldCategory: FieldCategories.Clinical,
  },
  {
    defaultLists: [ResidentListTypes.Clinical],
    display: ({ pasrr2StatusDisplay }) => pasrr2StatusDisplay,
    exportHeaders: [
      {
        key: 'pasrr2StatusDisplay',
        label: 'PASRR 2 Status',
      },
    ],
    id: 'Pasrr2Status',
    label: 'PASRR 2 Status',
    profileQueryString: 'view=clinical',
    disableSort: true,
    companyType: companyTypes.Facility,
    fieldCategory: FieldCategories.Clinical,
  },
  {
    display: ({ authStatusDisplay }) => authStatusDisplay,
    exportHeaders: [
      {
        key: 'authStatusDisplay',
        label: 'Auth Status',
      },
    ],
    id: 'AuthStatus',
    label: 'Auth Status',
    profileQueryString: 'view=census&subView=auths',
    companyType: companyTypes.Facility,
    disableSort: true,
    fieldCategory: FieldCategories.Auth,
  },
  {
    defaultLists: [ResidentListTypes.Clinical, ResidentListTypes.URHmo],
    display: ({ authBeginDate }) => formatDate(authBeginDate),
    exportHeaders: [
      {
        key: 'authBeginDate',
        label: 'Auth Begin Date',
      },
    ],
    id: 'AuthBeginDate',
    label: 'Auth Begin Date',
    profileQueryString: 'view=census&subView=auths',
    companyType: companyTypes.Facility,
    fieldCategory: FieldCategories.Auth,
  },
  {
    defaultLists: [ResidentListTypes.Clinical, ResidentListTypes.URHmo],
    display: ({ authEndDate }) => formatDate(authEndDate),
    exportHeaders: [
      {
        key: 'authEndDate',
        label: 'Auth End Date',
      },
    ],
    id: 'AuthEndDate',
    label: 'Auth End Date',
    profileQueryString: 'view=census&subView=auths',
    companyType: companyTypes.Facility,
    fieldCategory: FieldCategories.Auth,
  },
  {
    defaultLists: [ResidentListTypes.URHmo],
    display: ({ authNextUpdateDue }) => formatDate(authNextUpdateDue),
    exportHeaders: [
      {
        key: 'authNextUpdateDue',
        label: 'Auth Next Update Due Date',
      },
    ],
    id: 'AuthNextUpdateDue',
    label: 'Auth Next Update Due Date',
    profileQueryString: 'view=census&subView=auths',
    companyType: companyTypes.Facility,
    fieldCategory: FieldCategories.Auth,
  },
  {
    display: ({ authPayerName }) => authPayerName,
    exportHeaders: [
      {
        key: 'authPayerName',
        label: 'Auth Payer',
      },
    ],
    id: 'AuthPayerName',
    label: 'Auth Payer',
    profileQueryString: 'view=census&subView=auths',
    companyType: companyTypes.Facility,
    fieldCategory: FieldCategories.Auth,
  },
  {
    display: ({ authLevel }) => authLevel,
    exportHeaders: [
      {
        key: 'authLevel',
        label: 'Auth Level',
      },
    ],
    id: 'AuthLevel',
    label: 'Auth Level',
    profileQueryString: 'view=census&subView=auths',
    companyType: companyTypes.Facility,
    fieldCategory: FieldCategories.Auth,
  },
  {
    display: ({ authNumber }) => authNumber,
    exportHeaders: [
      {
        key: 'authNumber',
        label: 'Auth Number',
      },
    ],
    id: 'AuthNumber',
    label: 'Auth Number',
    profileQueryString: 'view=census&subView=auths',
    companyType: companyTypes.Facility,
    fieldCategory: FieldCategories.Auth,
  },
  {
    defaultLists: [ResidentListTypes.Clinical],
    display: ({ latestClinicalNoteDate }) => formatDate(latestClinicalNoteDate),
    exportHeaders: [
      {
        key: 'latestClinicalNoteDate',
        label: 'Latest Clinical Note Date',
        format: 'date',
      },
    ],
    id: 'LatestClinicalNoteDate',
    label: 'Latest Clinical Note Date',
    profileQueryString: 'view=notes',
    companyType: companyTypes.Facility,
    fieldCategory: FieldCategories.Clinical,
  },
  {
    defaultLists: [
      ResidentListTypes.UR,
      ResidentListTypes.URHmo,
      ResidentListTypes.URMedicare,
      ResidentListTypes.Coverage,
      ResidentListTypes.MissingCoverage,
    ],
    display: ({ longTermPayerName, longTermIsPending }) => (
      <PayerNameWithStatus
        payerName={longTermPayerName}
        payerIsPending={longTermIsPending}
        isLongTerm
      />
    ),
    exportHeaders: [
      {
        key: 'longTermPayerName',
        label: 'Long Term Payer',
      },
    ],
    id: 'LongTermPayerName',
    label: 'Long Term Payer',
    profileQueryString: 'view=census',
    companyType: companyTypes.Facility,
    fieldCategory: FieldCategories.Coverage,
  },
  {
    defaultLists: [
      ResidentListTypes.UR,
      ResidentListTypes.URHmo,
      ResidentListTypes.URMedicare,
    ],
    display: ({ latestUrNoteDate }) => formatDate(latestUrNoteDate),
    exportHeaders: [
      {
        key: 'latestUrNoteDate',
        label: 'Latest UR Note Date',
        format: 'date',
      },
    ],
    id: 'LatestUrNoteDate',
    label: 'Latest UR Note Date',
    profileQueryString: 'view=notes',
    companyType: companyTypes.Facility,
    fieldCategory: FieldCategories.UR,
  },
  {
    defaultLists: [
      ResidentListTypes.Coverage,
      ResidentListTypes.MissingCoverage,
    ],
    display: ({ missingFullCoverageCount }) =>
      missingFullCoverageCount + ' days',
    exportHeaders: [
      {
        key: 'missingFullCoverageCount',
        label: 'Days missing primary',
      },
    ],
    id: 'MissingFullCoverageCount',
    label: 'Days missing primary',
    profileQueryString: 'view=census',
    companyType: companyTypes.Facility,
    fieldCategory: FieldCategories.Coverage,
  },
  {
    defaultLists: [
      ResidentListTypes.Coverage,
      ResidentListTypes.MissingCoverage,
    ],
    display: ({ missingPartialCoverageCount }) =>
      missingPartialCoverageCount + ' days',
    exportHeaders: [
      {
        key: 'missingPartialCoverageCount',
        label: 'Days missing secondary',
      },
    ],
    id: 'MissingPartialCoverageCount',
    label: 'Days missing secondary',
    profileQueryString: 'view=census',
    companyType: companyTypes.Facility,
    fieldCategory: FieldCategories.Coverage,
  },
  {
    defaultLists: [
      ResidentListTypes.Coverage,
      ResidentListTypes.MissingCoverage,
    ],
    display: ({ hasPendingCoverages }) =>
      hasPendingCoverages > 0 ? <CheckIcon /> : '',
    exportHeaders: [
      {
        key: 'hasPendingCoverages',
        label: 'Pending',
        formatter: (val) => formatBool(val > 0),
      },
    ],
    id: 'HasPendingCoverages',
    label: 'Pending',
    profileQueryString: 'view=census',
    width: 96,
    companyType: companyTypes.Facility,
    fieldCategory: FieldCategories.Coverage,
  },
  {
    defaultLists: [
      ResidentListTypes.Coverage,
      ResidentListTypes.MissingCoverage,
    ],
    display: ({ isBleeding }) => isBleeding && <CheckIcon />,
    exportHeaders: [
      {
        key: 'isBleeding',
        label: 'Bleeding',
        formatter: (val) => formatBool(val),
      },
    ],
    id: 'IsBleeding',
    label: 'Bleeding',
    profileQueryString: 'view=census',
    width: 96,
    companyType: companyTypes.Facility,
    fieldCategory: FieldCategories.Coverage,
  },
  {
    defaultLists: [
      ResidentListTypes.UR,
      ResidentListTypes.URHmo,
      ResidentListTypes.URMedicare,
    ],
    display: ({ dischargePlanDisplay, caseStatus }) =>
      caseStatus === residentStatusTypes.ShortTerm ? dischargePlanDisplay : '',
    exportHeaders: [
      {
        key: 'dischargePlanDisplay',
        label: 'Discharge Plan',
      },
    ],
    id: 'DischargePlan',
    label: 'Discharge Plan',
    profileQueryString: 'view=census',
    companyType: companyTypes.Facility,
    disableSort: true,
    fieldCategory: FieldCategories.General,
  },
  {
    defaultLists: [
      ResidentListTypes.UR,
      ResidentListTypes.URHmo,
      ResidentListTypes.URMedicare,
    ],
    display: ({ plannedDischargeDate, caseStatus }) =>
      caseStatus === residentStatusTypes.ShortTerm
        ? formatDate(plannedDischargeDate)
        : '',
    exportHeaders: [
      {
        key: 'plannedDischargeDate',
        label: 'Planned Discharge Date',
      },
    ],
    id: 'PlannedDischargeDate',
    label: 'Planned Discharge Date',
    profileQueryString: 'view=census',
    companyType: companyTypes.Facility,
    fieldCategory: FieldCategories.General,
  },
  {
    defaultLists: [
      ResidentListTypes.UR,
      ResidentListTypes.URHmo,
      ResidentListTypes.URMedicare,
    ],
    display: ({ estimatedEndDate }) => formatDate(estimatedEndDate),
    exportHeaders: [
      {
        key: 'estimatedEndDate',
        label: 'Planned Cut Date',
      },
    ],
    id: 'EstimatedEndDate',
    label: 'Planned Cut Date',
    profileQueryString: 'view=census',
    companyType: companyTypes.Facility,
    fieldCategory: FieldCategories.Coverage,
  },
  {
    display: ({ rfmsAccountStatusDisplay }) => rfmsAccountStatusDisplay,
    exportHeaders: [
      {
        key: 'rfmsAccountStatusDisplay',
        label: 'RFMS Status',
      },
    ],
    id: 'RfmsAccountStatus',
    label: 'RFMS Status',
    profileQueryString: 'view=liability',
    companyType: companyTypes.Facility,
    disableSort: true,
    fieldCategory: FieldCategories.Financial,
  },
  {
    display: ({ rfmsAccountNumber }) => rfmsAccountNumber,
    exportHeaders: [
      {
        key: 'rfmsAccountNumber',
        label: 'RFMS Account Number',
      },
    ],
    id: 'RfmsAccountNumber',
    label: 'RFMS Account Number',
    profileQueryString: 'view=liability',
    disableSort: true,
    companyType: companyTypes.Facility,
    fieldCategory: FieldCategories.Financial,
  },
  {
    display: ({ rfmsAccountBalance }) => formatCurrency(rfmsAccountBalance),
    exportHeaders: [
      {
        key: 'rfmsAccountBalance',
        label: 'RFMS Account Balance',
      },
    ],
    id: 'RfmsAccountBalance',
    label: 'RFMS Account Balance',
    profileQueryString: 'view=liability',
    companyType: companyTypes.Facility,
    fieldCategory: FieldCategories.Financial,
  },
  {
    display: ({ latestNote }) => (
      <Typography
        sx={{
          overflow: 'hidden',
          WebkitLineClamp: 3,
          WebkitBoxOrient: 'vertical',
          display: '-webkit-box',
          textOverflow: 'ellipsis',
        }}
      >
        {latestNote}
      </Typography>
    ),
    exportHeaders: [
      {
        key: 'latestNote',
        label: 'Latest Note',
      },
    ],
    id: 'LatestNote',
    label: 'Latest Note',
    profileQueryString: 'view=notes',
    disableSort: true,
    fieldCategory: FieldCategories.Notes,
  },
  {
    exportHeaders: [
      {
        key: 'latestNoteDate',
        label: 'Latest Note Date',
        format: 'date',
      },
    ],
    display: ({ latestNoteDate }) => formatDate(latestNoteDate),
    id: 'LatestNoteDate',
    label: 'Latest Note Date',
    profileQueryString: 'view=notes',
    disableSort: true,
    fieldCategory: FieldCategories.Notes,
  },
  {
    display: ({ secondLatestNote }) => secondLatestNote,
    exportHeaders: [
      {
        key: 'secondLatestNote',
        label: 'Second Latest Note',
      },
    ],
    id: 'SecondLatestNote',
    label: 'Second Latest Note',
    profileQueryString: 'view=notes',
    disableSort: true,
    fieldCategory: FieldCategories.Notes,
  },
  {
    exportHeaders: [
      {
        key: 'secondLatestNoteDate',
        label: 'Second Latest Note Date',
        format: 'date',
      },
    ],
    display: ({ secondLatestNoteDate }) => formatDate(secondLatestNoteDate),
    id: 'SecondLatestNoteDate',
    label: 'Second Latest Note Date',
    profileQueryString: 'view=notes',
    disableSort: true,
    fieldCategory: FieldCategories.Notes,
  },
  {
    display: ({ isSkilledNursing }) => isSkilledNursing && <CheckIcon />,
    exportHeaders: [
      {
        key: 'isSkilledNursing',
        label: 'Is Skilled',
        formatter: (val) => formatBool(val),
      },
    ],
    id: 'IsSkilledNursing',
    label: 'Is Skilled',
    profileQueryString: 'view=census',
    companyType: companyTypes.Facility,
    fieldCategory: FieldCategories.General,
  },
  {
    display: ({ hasDocsDue }) => hasDocsDue && <CheckIcon />,
    exportHeaders: [
      {
        key: 'hasDocsDue',
        label: 'Is Docs Needed',
        formatter: (val) => formatBool(val),
      },
    ],
    id: 'HasDocsDue',
    label: 'Is Docs Needed',
    profileQueryString: 'view=application',
    fieldCategory: FieldCategories.Application,
  },
  {
    display: ({ hasOverdueDocs }) => hasOverdueDocs && <CheckIcon />,
    exportHeaders: [
      {
        key: 'hasOverdueDocs',
        label: 'Has Overdue Docs',
        formatter: (val) => formatBool(val),
      },
    ],
    id: 'HasOverdueDocs',
    label: 'Has Overdue Docs',
    profileQueryString: 'view=application',
    fieldCategory: FieldCategories.Application,
  },
  ...(process.env.REACT_APP_ENV !== 'production'
    ? [
        {
          display: ({ applicationStatusCategoryDisplay }) =>
            applicationStatusCategoryDisplay,
          exportHeaders: [
            {
              key: 'applicationStatusCategoryDisplay',
              label: 'Application Status Category',
            },
          ],
          id: 'ApplicationStatusCategory',
          label: 'Application Status Category',
          profileQueryString: 'view=application',
          fieldCategory: FieldCategories.Application,
        },
        {
          display: ({ applicationTypeNeededDisplay }) =>
            applicationTypeNeededDisplay,
          exportHeaders: [
            {
              key: 'applicationTypeNeededDisplay',
              label: 'Needed Application Type',
            },
          ],
          id: 'ApplicationTypeNeeded',
          label: 'Needed Application Type',
          profileQueryString: 'view=application',
          fieldCategory: FieldCategories.Application,
        },
      ]
    : []),
];

//`OR` filters, isOrFilter: true (will also be a regular filter)
export const ResidentFilters = [
  {
    name: 'isBleeding',
    defaultValue: '',
    label: 'Bleeding',
    queryParam: ListQueryParams.isBleeding,
    type: 'selectMenu',
    menuItems: [
      { id: '', name: 'All' },
      { id: true, name: 'Is bleeding' },
      { id: false, name: 'Not bleeding' },
    ],
    companyType: companyTypes.Facility,
    isOrFilter: true,
  },
  {
    name: 'hasPending',
    defaultValue: '',
    label: 'Pending',
    dockedLists: [
      ResidentListTypes.Coverage,
      ResidentListTypes.MissingCoverage,
    ],
    queryParam: ListQueryParams.hasPending,
    type: 'selectMenu',
    menuItems: [
      { id: '', name: 'All' },
      { id: true, name: 'Has pending' },
      { id: false, name: 'No pending' },
    ],
    companyType: companyTypes.Facility,
    isOrFilter: true,
  },
  {
    name: 'hasPrivate',
    defaultValue: '',
    label: 'Private Pay',
    queryParam: ListQueryParams.hasPrivate,
    type: 'selectMenu',
    menuItems: [
      { id: '', name: 'All' },
      { id: true, name: 'Has private pay' },
      { id: false, name: 'No private pay' },
    ],
    companyType: companyTypes.Facility,
    isOrFilter: true,
  },
  {
    name: 'isMissingCoverage',
    defaultValue: '',
    label: 'Missing Coverage',
    queryParam: ListQueryParams.isMissingCoverage,
    type: 'selectMenu',
    menuItems: [
      { id: '', name: 'All' },
      { id: true, name: 'Is missing coverage' },
      { id: false, name: 'Not missing coverage' },
    ],
    companyType: companyTypes.Facility,
    isOrFilter: true,
  },
  {
    name: 'nriStatuses',
    defaultValue: [],
    isForNRIWorkflow: true,
    label: 'NRI Status',
    menuItemsSelector: (state) => systemPersistSelectors.nriStatuses(state),
    queryParam: ListQueryParams.nriStatuses,
    type: 'multi',
    searchPlaceholder: 'Find NRI statuses...',
    companyType: companyTypes.Facility,
  },
  {
    name: 'pasrr1Statuses',
    defaultValue: [],
    label: 'PASRR 1 Status',
    menuItemsSelector: (state) =>
      systemPersistSelectors.pasrrStatusTypes(state),
    queryParam: ListQueryParams.pasrr1Statuses,
    type: 'multi',
    searchPlaceholder: 'Find PASRR 1 statuses...',
    companyType: companyTypes.Facility,
  },
  {
    name: 'pasrr2Statuses',
    defaultValue: [],
    label: 'PASRR 2 Status',
    menuItemsSelector: (state) =>
      systemPersistSelectors.pasrrStatusTypes(state),
    queryParam: ListQueryParams.pasrr2Statuses,
    type: 'multi',
    searchPlaceholder: 'Find PASRR 2 statuses...',
    companyType: companyTypes.Facility,
  },
  {
    name: 'authStatuses',
    defaultValue: [],
    label: 'Auth Status',
    menuItemsSelector: (state) => systemPersistSelectors.authStatusTypes(state),
    queryParam: ListQueryParams.authStatuses,
    type: 'multi',
    searchPlaceholder: 'Find auth statuses...',
    companyType: companyTypes.Facility,
  },
  {
    name: 'noApplication',
    defaultValue: false,
    label: 'No Application',
    queryParam: ListQueryParams.noApplication,
    type: 'checkbox',
    isOrFilter: true,
  },
  {
    name: 'hasOpenApplications',
    defaultValue: '',
    type: 'selectMenu',
    menuItems: [
      { id: '', name: 'All' },
      { id: true, name: 'Has open application' },
      { id: false, name: 'No open application' },
    ],
    label: 'Application',
    queryParam: ListQueryParams.hasOpenApplications,
    isOrFilter: true,
  },
  {
    name: 'missingFullRange',
    defaultValue: '',
    label: 'Days missing primary',
    type: 'selectMenu',
    menuItems: [
      { id: '', name: 'All' },
      { id: 'OneToThirty', name: '1 - 30' },
      { id: 'ThirtyOneToSixty', name: '31 - 60' },
      { id: 'SixyOneToNinety', name: '61-90' },
      { id: 'NinetyOneToOneTwenty', name: '91 - 120' },
      { id: 'OneTwentyPlus', name: '120+' },
    ],
    queryParam: ListQueryParams.missingFullRange,
    disableSort: true,
    companyType: companyTypes.Facility,
    isOrFilter: true,
  },
  {
    name: 'missingPartialRange',
    defaultValue: '',
    label: 'Days missing secondary',
    type: 'selectMenu',
    menuItems: [
      { id: '', name: 'All' },
      { id: 'OneToThirty', name: '1 - 30' },
      { id: 'ThirtyOneToSixty', name: '31 - 60' },
      { id: 'SixyOneToNinety', name: '61-90' },
      { id: 'NinetyOneToOneTwenty', name: '91 - 120' },
      { id: 'OneTwentyPlus', name: '120+' },
    ],
    queryParam: ListQueryParams.missingPartialRange,
    disableSort: true,
    companyType: companyTypes.Facility,
    isOrFilter: true,
  },
  {
    name: 'authEndWithin14Days',
    defaultValue: false,
    label: 'Auth Ends Within 14 Days',
    queryParam: ListQueryParams.authEndWithin14Days,
    type: 'checkbox',
    companyType: companyTypes.Facility,
  },
  {
    name: 'rfmsStatuses',
    defaultValue: [],
    label: 'RFMS Status',
    menuItemsSelector: (state) => systemPersistSelectors.rfmsStatuses(state),
    queryParam: ListQueryParams.rfmsStatuses,
    type: 'multi',
    searchPlaceholder: 'Find RFMS statuses...',
    companyType: companyTypes.Facility,
  },
  {
    name: 'dischargePlans',
    defaultValue: [],
    label: 'Discharge Plan',
    menuItemsSelector: (state) => systemPersistSelectors.dischargePlans(state),
    queryParam: ListQueryParams.dischargePlans,
    type: 'multi',
    searchPlaceholder: 'Find Discharge Plans...',
    companyType: companyTypes.Facility,
  },
  {
    ...SharedFilters.showArchived,
    generalDocked: true,
  },
  {
    ...SharedFilters.isResidentActive,
    generalDocked: true,
    isOrFilter: true,
  },
  {
    ...SharedFilters.facilityIds,
    generalDocked: true,
  },
  {
    ...SharedFilters.caseStatuses,
    dockedLists: [ResidentListTypes.All],
    isOrFilter: true,
  },
  {
    defaultValue: [],
    label: 'Resident Clinical Status',
    menuItemsSelector: (state) =>
      systemPersistSelectors.residentClinicalStatuses(state),
    name: 'residentClinicalStatuses',
    queryParam: ListQueryParams.residentClinicalStatuses,
    type: 'multi',
    searchPlaceholder: 'Find resident clinical statuses...',
    companyType: companyTypes.Facility,
    dockedLists: [ResidentListTypes.Clinical],
  },
  {
    defaultValue: [],
    label: 'Latest LOC Status',
    menuItemsSelector: (state) =>
      systemPersistSelectors.clinicalEligibilityStatuses(state),
    name: 'clinicalStatuses',
    queryParam: ListQueryParams.clinicalStatuses,
    type: 'multi',
    searchPlaceholder: 'Find LOC statuses...',
    companyType: companyTypes.Facility,
    dockedLists: [ResidentListTypes.Clinical],
  },
  SharedFilters.facilityStateIds,
  SharedFilters.filingStateIds,
  SharedFilters.payerName,
  SharedFilters.hasCoPayer,
  SharedFilters.assignedToMe,
  SharedFilters.caseworkerIds,
  SharedFilters.medicaidNeededByFromDate,
  SharedFilters.medicaidNeededByToDate,
  SharedFilters.medicaidCaseworkerIds,
  SharedFilters.applicationAssigneeIds,
  SharedFilters.medicaidNeededWithinLast60Days,
  SharedFilters.nextCaseworkerRequestDeadlineRange,
  SharedFilters.applicationSubmitDateRange,
  SharedFilters.applicationTags,
  {
    ...SharedFilters.caseTags,
    isOrFilter: true,
  },
  {
    ...SharedFilters.applicationStatuses,
    isOrFilter: true,
  },
  ...(process.env.REACT_APP_ENV !== 'production'
    ? [
        {
          defaultValue: [],
          label: 'Application Status Category',
          menuItemsSelector: (state) =>
            systemPersistSelectors.applicationStatusCategories(state),
          name: 'applicationStatusCategories',
          queryParam: ListQueryParams.applicationStatusCategories,
          type: 'multi',
          searchPlaceholder: 'Find application status categories...',
          isOrFilter: true,
        },
        {
          defaultValue: [],
          label: 'Needed Application Type',
          menuItemsSelector: (state) =>
            systemPersistSelectors.facilityApplicationTypes(state),
          menuItemsSelector_AC: (state) =>
            systemPersistSelectors.acApplicationTypes(state),
          name: 'applicationTypesNeeded',
          queryParam: ListQueryParams.applicationTypesNeeded,
          type: 'multi',
          searchPlaceholder: 'Find needed application types...',
          isOrFilter: true,
        },
      ]
    : []),
  {
    ...SharedFilters.applicationTypes,
    isOrFilter: true,
  },
  {
    ...SharedFilters.payerTypes,
    isOrFilter: true,
  },
  {
    name: 'isSkilledNursing',
    defaultValue: '',
    label: 'Is Skilled',
    queryParam: ListQueryParams.isSkilledNursing,
    type: 'selectMenu',
    menuItems: [
      { id: '', name: 'All' },
      { id: true, name: 'Is skilled' },
      { id: false, name: 'Is not skilled' },
    ],
    companyType: companyTypes.Facility,
    fieldCategory: FieldCategories.General,
  },
  {
    name: 'hasDocsDue',
    defaultValue: '',
    label: 'Docs needed',
    queryParam: ListQueryParams.hasDocsDue,
    type: 'selectMenu',
    menuItems: [
      { id: '', name: 'All' },
      { id: true, name: 'Needs documents' },
      { id: false, name: 'Does not need documents' },
    ],
  },
  {
    name: 'hasOverdueDocs',
    defaultValue: '',
    label: 'Docs overdue',
    queryParam: ListQueryParams.hasOverdueDocs,
    type: 'selectMenu',
    menuItems: [
      { id: '', name: 'All' },
      { id: true, name: 'Has overdue documents' },
      { id: false, name: 'Does not have overdue documents' },
    ],
  },
  {
    name: 'hasIncomeData',
    defaultValue: '',
    label: 'Has Income Data',
    queryParam: ListQueryParams.hasIncomeData,
    type: 'selectMenu',
    menuItems: [
      { id: '', name: 'All' },
      { id: true, name: 'Has Income Data' },
      { id: false, name: 'No Income Data' },
    ],
  },
];
