import { createSlice } from '@reduxjs/toolkit';

const initialState = { data: {}, lastActiveTime: null };

export const authPersist = createSlice({
  name: 'authPersist',
  initialState,
  reducers: {
    persistUserData(state, action) {
      state.data = { ...action.payload };
    },
    removeUserData(state, action) {
      state.data = {};
    },
    setLastActiveTime(state, action) {
      state.lastActiveTime = action.payload;
    },
  },
});
