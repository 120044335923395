import React, { useState, useCallback, useEffect } from 'react';
import clsx from 'clsx';
import { FormControlLabel, FormControl, FormHelperText, Switch } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { reach } from 'yup';

const useStyles = makeStyles((theme) => ({
  switchBase: {
    '&$checked': {
      color: theme.palette.primary.light,
    },
    '&$checked + $track': {
      backgroundColor: theme.palette.primary.light,
      opacity: 0.4,
    },
  },
  checked: {},
  track: {},
}));

/**
 * Material-ui Radio with validation
 * @param {SwitchInputProps}
 */
export function SwitchInput({
  addHelperTextStyle = true,
  checked,
  className,
  context,
  error,
  formControlProps = {},
  label,
  labelProps = {},
  name,
  nestedKey,
  onChange,
  onBlur,
  row = true,
  schema,
  ...props
}) {
  const classes = useStyles();
  const [isDirty, setIsDirty] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  useEffect(() => {
    // this is for when the validation is based of of another field
    // and the values of the context is true/false for example the pasRequested field on facilityIntake
    if (context) {
      const valuesArr = Object.keys(context).reduce((acc, cur) => {
        acc.push(context[cur]);
        return acc;
      }, []);
      if (valuesArr.every((v) => v === false)) {
        setErrorMessage(undefined);
      }
    }
  }, [context]);

  const handleBlur = useCallback(
    (event) => {
      typeof onBlur === 'function' && onBlur(event);
      const { name, checked } = event.target;
      if (schema) {
        setIsDirty(true);
        reach(schema, nestedKey || name)
          .validate(checked, { context })
          .then(() => setErrorMessage(false))
          .catch((err) => setErrorMessage(err.errors?.[0]));
      }
    },
    [context, nestedKey, onBlur, schema],
  );

  const handleChange = useCallback(
    (event) => {
      typeof onChange === 'function' && onChange(event);
      const { name, checked } = event.target;
      if (schema && isDirty) {
        reach(schema, nestedKey || name)
          .validate(checked, { context })
          .then(() => setErrorMessage(false))
          .catch((err) => setErrorMessage(err.errors?.[0]));
      }
    },
    [context, isDirty, nestedKey, onChange, schema],
  );

  return (
    <FormControl
      error={!!errorMessage}
      component='fieldset'
      {...formControlProps}
    >
      <FormControlLabel
        control={
          <Switch
            className={clsx(className)}
            onBlur={handleBlur}
            onChange={handleChange}
            checked={checked}
            name={name}
            classes={{
              switchBase: classes.switchBase,
              checked: classes.checked,
              track: classes.track,
            }}
            {...props}
          />
        }
        label={label}
        {...labelProps}
      />
      <FormHelperText>{errorMessage}</FormHelperText>
    </FormControl>
  );
}

// #region Typedefs
/** @typedef {object} SwitchInputProps
 * @property {import("@mui/material/Switch").SwitchProps} switchProps
 * @property {import("@mui/material/FormControlLabel").FormControlLabelProps} labelProps
 * @property {Object} [context] An object with the name and value of the field to use as Yup context
 * @property {Object} schema The defined Yup schema
 * @property {string} [nestedKey] If the value is a nested value provide the path. Example nestedKey='residentContacts.firstName'.
 * @property {string} error The error message when failing validation on submit
 * @property {boolean} [addHelperTextStyle] Boolean to add position absolute to helper text. Defaults to true.
 */
// #endregion
