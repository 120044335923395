import React, { useEffect, useState } from 'react';
import { IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { FormModal } from './FormModal';
import { Cancel, LaunchIcon } from '../../themes';
import { useNotify } from '../../hooks';
import { getSignedUrl } from '../../lib';

const useStyles = makeStyles((theme) => ({
  innerWrap: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },

  outerWrap: {
    width: '100%',
    height: '0',
    paddingBottom: '56.25%',
    position: 'relative',
    '& img': {
      maxWidth: '100%',
      width: 20,
    },
  },

  iframe: {
    width: '100%',
    height: '100%',
  },
}));

export function DocumentViewModal({ open, handleClose, file }) {
  const classes = useStyles();
  const notify = useNotify();
  const [url, setUrl] = useState('');

  useEffect(() => {
    if (file.previewURL) {
      setUrl(file.previewURL);
    } else {
      async function getFile() {
        const signedUrl = await getSignedUrl(file.bucketObjectName);
        if (!signedUrl) {
          notify('Error retrieving document', 'error');
        }
        setUrl(signedUrl);
      }
      getFile();
    }
  }, [file, notify]);

  useEffect(() => {
    if (url) {
      const iframe = document.getElementById('documentFrame');
      if (
        (file.fileExtension || file.customName)?.match(/.(jpg|jpeg|png|gif)$/i)
      ) {
        iframe.contentWindow.document.body.innerHTML = `<img style="width: 100%;" src=${url}/>`;
      } else {
        iframe.src = url;
      }
    }
  }, [file, url]);

  return (
    <FormModal
      handleClose={handleClose}
      open={open}
      title={file.name || file.customName}
      hideFooter={true}
      size='lg'
      disableScrollContent={true} //the iframe ends up with its own scroll bar so we don't need the modal's one
      paperProps={{ style: { top: 16 } }}
      rightSideHeader={
        <>
          <IconButton
            onClick={() => {
              window.open(url, '_blank', 'noopener,noreferrer');
              handleClose();
            }}
          >
            <LaunchIcon />
          </IconButton>
          <IconButton onClick={handleClose} size='large'>
            <Cancel />
          </IconButton>
        </>
      }
    >
      <div
        style={{
          height: 'calc(100vh - 120px)',
        }}
      >
        <iframe
          data-private //hides from LogRocket
          id='documentFrame'
          title={file.fileName}
          className={classes.iframe}
        />
      </div>
    </FormModal>
  );
}
