import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  facilityOptions: [],
  hasNoFacilities: false,
  facilityOptionsLoading: false,
  facilities: {}, //{ 1: {payers:[]} }
};

export const facilities = createSlice({
  name: 'facilities',
  initialState,
  reducers: {
    setFacilityOptionsLoading(state, action) {
      state.facilityOptionsLoading = action.payload;
    },
    setFacilities(state, action) {
      state.facilityOptions = action.payload;
      state.hasNoFacilities = state.facilityOptions.length === 0;
    },
    setFacilityLoading(state, action) {
      const { id } = action.payload;
      state.facilities[id] = { ...(state.facilities[id] || {}), loading: true };
    },
    setPayersForFacility(state, action) {
      const { id, ...rest } = action.payload;
      state.facilities = {
        ...state.facilities,
        [id]: { ...state.facilities[id], ...rest },
      };
    },
  },
});
