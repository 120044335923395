import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { uiActions } from '../state';

export function useGlobalRefresh() {
  const dispatch = useDispatch();
  return useCallback(
    (doRefresh = true) =>
      doRefresh && dispatch(uiActions.incrementGlobalViewVersion()),
    [dispatch],
  );
}
