import React from 'react';
import { CssBaseline } from '@mui/material';
// Local
import { Notification } from '../../components';
import { useStyles } from './Auth.styles';

export function AuthLayout({ children }) {
  const classes = useStyles();

  return (
    <main className={classes.main}>
      <CssBaseline />
      <div className={classes.formSection}>
        <img
          src='/images/aidace-logo-full.svg'
          alt=''
          className={classes.logo}
        />
        <div style={{ width: '100%' }}>{children}</div>
        <p className={classes.bottomText}>
          New to aidace?{' '}
          <a
            target='_blank'
            rel='noopener noreferrer'
            className={classes.link}
            href='https://www.aidace.com/contact-us/'
          >
            Request more info
          </a>
        </p>
      </div>
      <div className={classes.formImgSection}>
        <div className={classes.imgSpacer} />
        <img src='/images/aidace-login-image.svg' alt='' />
        <div className={classes.titleContainer}>
          <p className={classes.title}>
            The Medicaid specialist’s ultimate tool
          </p>
          <p className={classes.subTitle}>
            Don’t get lost in the Medicaid maze. Organize. Manage. Forecast.
          </p>
          <p className={classes.smallText}>A Bitbean company</p>
        </div>
      </div>
      <Notification />
    </main>
  );
}
