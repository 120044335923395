import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.textTheme.main,
    fontSize: 16,
    textTransform: 'none',
    width: 'inherit',
    minWidth: 'inherit',
    '&$selected': {
      color: theme.palette.primary.light,
    },
  },
  selected: {},
  indicator: {
    backgroundColor: theme.palette.primary.light,
  },
}));
