import React, { Suspense } from 'react';
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
  Outlet,
  useLocation,
} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { isAfter } from 'date-fns';
import { PermissionClaims } from '../../lib';
import { authActions } from '../../state';
import { useGetUsersData } from '../../hooks';
import { MainLayout } from '../../layouts';
import { ErrorLogging, SuspensePageLoader } from '../../components';
import { PublicRoutes, PrivateRoutes } from './routes';

const PrivateRoute = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { pathname, search } = location;
  const { token, expiration, PermissionClaim } = useGetUsersData();
  const hasPermission = checkPermissions(PermissionClaim, pathname);
  if (!token || isAfter(new Date(), new Date(expiration))) {
    if (pathname !== PublicRoutes.login.path) {
      dispatch(
        authActions.setAfterLoginRoute({
          redirectTo: pathname + search,
        }),
      );
    }
    return <Navigate to={PublicRoutes.login.path} />;
  }

  if (!hasPermission) return <Navigate to={PublicRoutes.notFound.path} />;

  return token ? (
    <Suspense fallback={<SuspensePageLoader />}>
      <MainLayout>
        <Outlet />
      </MainLayout>
    </Suspense>
  ) : (
    <Navigate to={PublicRoutes.login.path} />
  );
};

const router = createBrowserRouter([
  {
    errorElement: <ErrorLogging />,
    children: Object.values(PublicRoutes),
  },
  {
    element: <PrivateRoute />,
    errorElement: <ErrorLogging />,
    children: Object.values(PrivateRoutes),
  },
]);

export default function App() {
  return <RouterProvider router={router} />;
}

function checkPermissions(PermissionClaim, path) {
  const permissions = {
    [PrivateRoutes.createCompany.path]: [PermissionClaims.SuperAdminEditClaim],
    [PrivateRoutes.superAdminUsersPage.path]: [
      PermissionClaims.SuperAdminEditClaim,
    ],
    [PrivateRoutes.companies.path]: [PermissionClaims.SuperAdminEditClaim],
    [PrivateRoutes.announcements.path]: [PermissionClaims.SuperAdminEditClaim],

    [PrivateRoutes.adminUsersPage.path]: [
      PermissionClaims.SuperAdminEditClaim,
      PermissionClaims.CompanyEditClaim,
    ],
    [PrivateRoutes.adminSettings.path]: [
      PermissionClaims.SuperAdminEditClaim,
      PermissionClaims.CompanyEditClaim,
    ],
    [PrivateRoutes.facilitiesDashboard.path]: [
      PermissionClaims.SuperAdminEditClaim,
      PermissionClaims.MultiCompanyUserClaim,
    ],
    [PrivateRoutes.facilitiesSuperAdmin.path]: [
      PermissionClaims.SuperAdminEditClaim,
    ],
    [PrivateRoutes.regions.path]: [
      PermissionClaims.SuperAdminEditClaim,
      PermissionClaims.CompanyEditClaim,
    ],
  };
  // return true if the route is not in the permissions object or
  // the user has one of the permissions in his PermissionClaim

  return (
    !permissions[path] ||
    permissions[path].some((permission) => PermissionClaim.includes(permission))
  );
}
